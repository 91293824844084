import "./MaterialsPage.css";

import axios from "axios";
import i18next from "i18next";
import PropTypes from 'prop-types';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";
import Config from "../../helpers/Config";
import Logger from "../../helpers/Logger";
import Range from "../../helpers/Range";
import Utils from "../../helpers/Utils";
import YouTubeHelper from "../../helpers/YouTubeHelper";
import Course from "../../models/Course";
import Group from "../../models/Group";
import Lesson from "../../models/Lesson";
import Material from "../../models/Material";
import Question from "../../models/Question";
import Theme from "../../models/Theme";
import User from "../../models/User";
import BackBtn from "./BackBtn";
import MaterialForm from "./MaterialForm";
import QuestionForm from "./QuestionForm";
import QuestionView from "./QuestionView";

import { Link } from "react-router-dom";
import VideoLinkHelper from "../../helpers/VideoLinkHelper";
import ListItem from "./ListItem";
import PdfView from "./PdfView";
import QuestionCardForm from "./QuestionCardForm";
import QuestionCardView from "./QuestionCardView";
import QuestionGroupForm from "./QuestionGroupForm";
import QuestionGroupView from "./QuestionGroupView";
import UploadsList from "./UploadsList";
import DateHelper from "../../helpers/DateHelper";
// import moment from "moment";

import MaterialControls from "./MaterialControls";
import MaterialsPageNavPanelMd from "./MaterialsPageNavPanelMd";
import MaterialsPageNavPanelXs from "./MaterialsPageNavPanelXs";
import MaterialsPageQuestionBox from "./MaterialsPageQuestionBox";
import MaterialView2 from "./MaterialView2";


class MaterialsPage3 extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			materialFormModalIsOpen: false,
			materialTheoryFormModalIsOpen: false,
			materialTestFormModalIsOpen: false,
			
			currentMaterial: null,
			currentMaterialIndex: 0, // load first
			// currentMaterialIndex: null, // don't load first
			
			materials: [],
			materialsActiveOnly: true,
			
			materialsShowArchived: false,
			materialsShowNotPublic: props.user.can('seeMaterialsDrafts'),
			
			materialToEdit: null,
			
			questionFormModalIsOpen: false,
			questionToEdit: null,
			questionToEditIndex: null,
			currentQuestionIndex: 0,
			
			imgViewerIsOpen: false,
			imgViewerCurrentIndex: null,
			imgViewerUrls: [],
			
			homeworks: [],
			currentHomework: null,
			currentHomeworkIndex: null,
			homeworkToEdit: null,
			homeworkToEditIndex: null,
			homeworkFormModalIsOpen: false,
			
			groups: [],
			// questionsStates: [], // менки домашних заданий
			
			useQuestionEditModal: true,
			
			questionCardToEdit: null,
			questionCardFormModalIsOpen: false,
			
			questionGroupFormModalIsOpen: false,
			
			currentVariant: null,
			
		};
		
		// this.materialsShowArchivedChbxRef = React.createRef();
		// this.materialsShowNotPublicChbxRef = React.createRef();
		
	}
	
	materials = {
		
		get: () => {
			return this.state.materials
		},
		
		getById: (id) => {
			return this.state.materials.find((x) => x.id == id);
		},
		
		getByIndex: (index) => {
			return this.state.materials[index];
		},
		
		loadList: (
			materialsShowArchived = this.state.materialsShowArchived,
			materialsShowNotPublic = this.state.materialsShowNotPublic,
			afterSuccess
		) => {
			
			const logName = 'MaterialsPage.materials.loadList';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(materialsShowArchived, 'materialsShowArchived', logAllow)
			Logger.log(materialsShowNotPublic, 'materialsShowNotPublic', logAllow)
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'themeId': this.props.theme.id,
				'drafts': materialsShowNotPublic ? 1 : 0,
				'archived': materialsShowArchived ? 1 : 0,
				//'progressForUserId': this.props.user.id,
			};
			
			const user = this.props.user;
			Logger.log(user, 'user', logAllow);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials/list'),
				data: {},
				params: params,
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.loadList.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				let materials = response.data.map(materialData => new Material(materialData));
				Logger.log(materials, 'materials [before filtering]', logAllow)
				
				// если не админ
				if (!user.can('manageMaterials') && !user.can('manageMaterialsAccess')) {
					
					// фильтруем материалы по праву доступа
					let allowedMaterials = materials.filter((material) => {
						return material.hasAccess(user.groups_ids);
					});
					
					materials = allowedMaterials;
					
					Logger.log(materials, 'materials [after filtering]', logAllow);
					
				}
				
				Logger.log(materials, 'materials', logAllow);
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				// restore last material by id
				
				let currentMaterial = this.state.currentMaterial;
				
				let theme = this.props.theme;
				Logger.log(theme, 'theme', logAllow);
				
				if (theme) {
					
					let storedMaterialId = Config.getMaterialId(theme.id);
					Logger.log(storedMaterialId, 'storedMaterialId', logAllow);
					
					if (storedMaterialId) {
						currentMaterial = this.materials.getById(storedMaterialId);
					}
					
				}
				
				Logger.log(currentMaterial, 'currentMaterial', logAllow);
				
				if (!currentMaterial) {
					currentMaterial = materials[0];
				}
				
				if (currentMaterial) {
					
					currentMaterial.has_details = 0;
					
					let currentMaterialIndex = this.materials.getIndexById(currentMaterial.id);
					this.materials.setCurrentByIndex(currentMaterialIndex);
					
					// this.materials.setCurrent(currentMaterial);
					
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		loadOne: (materialId, afterSuccess) => {
			
			const logName = 'MaterialsPage.materials.loadOne';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!materialId) {
				window.alert(i18next.t("matrial id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			Logger.log(this.props.preloader, 'this.props.preloader', logAllow);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials') + '/' + materialId,
				data: {},
				params: {
					accessToken: Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.loadOne.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				console.log('error.response = %o', error.response);
				
				if (error.response && error.response.data) {
					window.alert(error.response.data.message);
				} else {
					window.alert(error.message);
				}
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		setVariant: (variant) => {
			this.setState({
				currentVariant: variant,
				currentQuestionIdex: 0,
			})
		},
		
		delVariant: (varianId) => {
			
			if (!varianId) {
				return
			}
			
			if (!window.confirm(i18next.t('Shure?'))) {
				return
			}
			
			this.props.preloader?.show()
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/del-variant'),
				params: {
					'accessToken': Utils.getUserToken(),
					'variantId': varianId,
				},
				data: {},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.delVariant.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				let currentMaterial = this.state.currentMaterial
				
				let variants = currentMaterial.variants
				
				let deletedVariantIndex = variants.findIndex((variant) => {
					return variant.id == varianId
				})
				
				// moving questions into material ('without variants' zone)
				// перемещаем задания в материал (в зону 'вне вариантов')
				
				let deletedVariant = variants[deletedVariantIndex]
				let deletedVariantQuestions = deletedVariant?.children
				
				currentMaterial.questions = currentMaterial.questions.concat(deletedVariantQuestions)
				
				// удаляем вариант из материала на клиенте
				
				variants.splice(deletedVariantIndex, 1)
				
				// variants = variants.filter((variant) => {
				// 	return variant.id != varianId
				// })
				
				currentMaterial.variants = variants
				
				this.setState({
					currentMaterial: currentMaterial,
					currentVariant: null,
				})
				
				this.props.preloader?.hide()
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				this.props.preloader?.hide()
				console.log(`axiosError = %o`, axiosError)
				window.alert(axiosError)
			})
			
		},
		
		afterCreate: (response) => {
			
			const logName = 'MaterialsPage.materials.afterCreate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = new Material(response.data);
			let materials = this.state.materials;
			materials.push(material);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: material,
					materialFormModalIsOpen: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpdate: (response) => {
			
			const logName = 'MaterialsPage.materials.afterUpdate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			const material = new Material(response.data);
			const materials = this.state.materials;
			
			let materialIndex = this.materials.getIndexById(material.id);
			
			materials[materialIndex] = material;
			
			this.setState((prevState) => {
				return {
					materials: materials,
					materialFormModalIsOpen: false,
					materialToEdit: null,
				}
			});
			
			if (this.state.currentMaterial) {
				this.setState((prevState) => {
					return {
						currentMaterial: material,
					}
				});
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					materialFormModalIsOpen: !prevState.materialFormModalIsOpen,
				}
			});
		},
		
		// сохранить текущий порядок элементов
		saveSort: (materials) => {
			
			const logName = 'MaterialsPage.materials.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			materials.forEach((material) => {
				ids.push(material.id);
			});
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (materialIndex) => {
			
			let materials = this.state.materials;
			let newMaterialIndex = Utils.arrayMoveUp(materials, materialIndex);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterialIndex: newMaterialIndex,
				}
			});
			
			this.materials.saveSort(materials);
			
		},
		
		moveDown: (materialIndex) => {
			
			let materials = this.state.materials;
			let newMaterialIndex = Utils.arrayMoveDown(materials, materialIndex);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterialIndex: newMaterialIndex,
				}
			});
			
			this.materials.saveSort(materials);
			
		},
		
		upd: (materialIndex, data, confirmMsg) => {
			
			const logName = 'MaterialsPage.upd';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.log(materialIndex, 'materialIndex', logAllow);
			Logger.log(data, 'data', logAllow);
			
			let material = this.state.materials[materialIndex];
			Logger.log(material, 'material', logAllow);
			
			if (!material) {
				return;
			}
			
			if (confirmMsg) {
				if (!window.confirm(confirmMsg)) {
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					return;
				}
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('materials') + '/' + material.id,
				data: data,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.upd';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let material = new Material(response.data);
				
				let materials = this.state.materials;
				
				materials[materialIndex] = material;
				
				if ((material.is_del == 1 && this.state.materialsShowArchived == 0) || (material.is_public == 0 && this.state.materialsShowNotPublic == 0)) {
					materials.splice(materialIndex, 1);
				}
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addCard: () => {
			
			const logName = 'MaterialsPage.materials.addCard';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			
			let currentQuestionIdex = this.state.currentQuestionIndex;
			
			let currentQuestion = material.questions[currentQuestionIdex];
			
			let newCard = new Question();
			
			newCard.view_type_alias = 'card';
			
			if (currentQuestion && currentQuestion.isGroup()) {
				newCard.group_id = currentQuestion.id;
			}
			
			this.setState((prevState) => {
				return {
					questionToEdit: newCard,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addGroup: () => {
			
			const logName = 'MaterialPage.material.addGroup';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			
			let newGroup = new Question();
			
			newGroup.view_type_alias = 'group';
			
			let groups = material.getGroups();
			
			let newGroupNum = groups.length + 1;
			
			newGroup.name = i18next.t("Group") + ' ' + newGroupNum;
			
			this.setState((prevState) => {
				return {
					questionToEdit: newGroup,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addVariant: () => {
			
			const logName = 'MaterialPage.material.addVariant';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let currentMaterial = this.state.currentMaterial;
			
			if (currentMaterial) {
				
				this.props.preloader?.show()
			
				axios({
					method: 'post',
					url: Utils.apiUrl('materials/add-variant'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						'materialId': currentMaterial.id,
					},
				}).then((response) => {
					
					const logName = 'MaterialPage.material.addVariant.ajax.done'
					const logAllow = 1
					const logCollapse = 0
					
					Logger.groupStart(logName, logAllow, logCollapse)
					
					Logger.log(response, 'response', logAllow)
					
					let newVariant = new Question(response.data)
					
					currentMaterial.variants.push(newVariant)
					
					this.setState({
						currentMaterial: currentMaterial,
					})

					this.props.preloader?.hide()
					
					Logger.groupEnd(logAllow)
					
				}).catch((axiosError) => {
					
					this.props.preloader?.hide()
					
					window.alert(axiosError)
					
				})
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		getIndexById: (materialId) => {
			return this.state.materials.findIndex(x => x.id == materialId);
		},
		
		setCurrent: (material, editMode) => {
			
			const logName = 'MaterialsPage.materials.setCurrent';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (material === null) {
				
				this.setState({
					currentMaterial: null,
				})
				
				return
				
			}
			
			Logger.log(material.name, 'material.name', logAllow);
			Logger.log(material.id, 'material.id', logAllow);
			Logger.log(material, 'material', logAllow);
			
			let materials = this.state.materials;
			
			let materialIndex = this.materials.getIndexById(material.id);
			Logger.log(materialIndex, 'materialIndex', logAllow);
			
			materials[materialIndex] = material;
			
			// localStorage.setItem(Config.coursesCurrentMaterialIdKey, material.id);
			// localStorage.setItem(Config.coursesCurrentMaterialIndexKey, materialIndex);
			
			// save current material for current theme
			if (this.props.theme) {
				let themeId = this.props.theme.id;
				Config.setMaterialIndex(themeId, materialIndex);
				Config.setMaterialId(themeId, material.id);
			}
			
			let storedQuestionIndex = Config.getQuestionIndex(material.id);
			let currentQuestionIndex = storedQuestionIndex ? storedQuestionIndex : 0;
			
			let newState = {
				currentMaterial: material,
				currentMaterialIndex: materialIndex,
				currentQuestionIndex: currentQuestionIndex,
				currentVariant: material.variants.length > 0 ? material.variants[0] : null,
				materials: materials,
				materialToEdit: null,
			};
			
			Logger.log(editMode, 'editMode', logAllow);
			
			if (editMode) {
				Logger.log('set edit mode', null, logAllow);
				newState.materialToEdit = material;
			}
			
			Logger.log(newState, 'newState', logAllow);
			
			this.setState((prevState) => {
				return newState;
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrentByIndex: (materialIndex, editMode) => {
			
			const logName = 'MaterialsPage.materials.setCurrentByIndex';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.materials[materialIndex];
			Logger.log(material, 'material', logAllow);
			
			if (!material) {
				return;
			}
			
			if (material.has_details == 1) {
				
				this.materials.setCurrent(material, editMode);
				
			} else {
				
				this.materials.loadOne(material.id, (response) => {
					
					let material = new Material(response.data);
					
					material.has_details = 1;
					
					this.materials.setCurrent(material, editMode);
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrentById: (materialId, editMode) => {
			
			let material = this.materials.getById(materialId);
			
			if (!material) {
				return;
			}
			
			if (material.has_details == 1) {
				
				this.materials.setCurrent(material, editMode);
				
			} else {
				
				this.materials.loadOne(material.id, (response) => {
					
					let material = new Material(response.data);
					
					material.has_details = 1;
					
					this.materials.setCurrent(material, editMode);
					
				});
				
			}
			
		},
		
		prev: () => {
			
			let materials = this.state.materials;
			
			let currentMaterialIndex = this.state.currentMaterialIndex;
			
			let newMaterialIndex = Utils.arrayPrevIndex(materials, currentMaterialIndex);
			
			this.materials.setCurrentByIndex(newMaterialIndex);
			
		},
		
		next: () => {
			
			const logName = 'MaterialsPage.next';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let materials = this.state.materials;
			
			let currentMaterialIndex = this.state.currentMaterialIndex;
			Logger.log(currentMaterialIndex, 'currentMaterialIndex', logAllow);
			
			let newMaterialIndex = Utils.arrayNextIndex(materials, currentMaterialIndex);
			Logger.log(newMaterialIndex, 'newMaterialIndex', logAllow);
			
			this.materials.setCurrentByIndex(newMaterialIndex);
			
			Logger.groupEnd(logAllow);
			
		},
		
		edit: (materialIndex) => {
			this.materials.setCurrentByIndex(materialIndex, 1);
		},
		
	};
	
	questions = {
		
		get: () => {
			
			let questions = []
			
			const currentMaterial = this.state.currentMaterial
			const currentVariant = this.state.currentVariant
			
			if (currentMaterial) {
				
				if (currentVariant) {
					
					questions = currentVariant.children
					
				} else {
					
					questions = currentMaterial.is_dynamic == 1
						? currentMaterial.dynamic
							? currentMaterial.dynamic.questions
							: currentMaterial.questions
						: currentMaterial.questions
					
				}
				
			}
			
			return questions
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					questionFormModalIsOpen: !prevState.questionFormModalIsOpen,
				}
			});
		},
		
		setMaterialAndVariant: (question, oldMaterialId, newMaterialId, variantId) => {
			// todo
		},
		
		setVariant: (question, materialId, variantId) => {
			
			const logName = 'MaterialsPage.questions.setVariant'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			let questionIndex = null
			
			let materials = this.state.materials
			
			if (Array.isArray(materials)) {
				
				let materialIndex = materials.findIndex((material) => {
					return material.id == materialId
				})
				
				let material = materials[materialIndex]
				Logger.log(material, 'material', logAllow)
				
				if (material) {
					
					// удаляем из общего списка
				
					// let materialQuestions = material.questions
					
					questionIndex = material.questions.findIndex((question) => {
						return question.id == question.id
					})
					
					if (questionIndex >= 0) {
						material.questions.splice(questionIndex, 1)
					}
					
					// material.questions = materialQuestions
					// materials[materialIndex] = material
					
					// дабавляем в варианты
					
					let variants = material.variants
					
					if (Array.isArray(variants)) {
						
						let variantIndex = variants.findIndex((variant) => {
							return variant.id == variantId
						})
						
						let variant = variants[variantIndex]
						
						if (variant) {
							
							let variantQuestions = variant.children
							
							if (Array.isArray(variantQuestions)) {
								
								let variantQuestionIndex = variantQuestions.findIndex(x => x.id == question.id)
								Logger.log(variantQuestionIndex, 'variantQuestionIndex', logAllow)
								
								if (variantQuestionIndex >= 0) {
								// if (0) {
									
									variantQuestions[variantQuestionIndex] = question
									
								} else {
									
									variantQuestions.push(question)
									
									variantQuestionIndex = variantQuestions.length - 1
									
								}
								
								variant.children = variantQuestions
								
								variants[variantIndex] = variant
								
								material.variants = variants
								
								questionIndex = variantQuestionIndex
								
							}
							
							materials[materialIndex] = material
					
							this.setState({
								materials: materials,
								currentVariant: variant,
								currentQuestionIdex: questionIndex,
							})
							
						}
						
					}
					
				}
				
			} else {
				
				console.error('materials is not array')
				
			}
			
			Logger.groupEnd(logAllow)
			
			return questionIndex;
			
		},
		
		afterSubmitSuccess: (response, materialId, variantId) => {
			
			const logName = 'MaterialsPage.questions.afterSubmitSuccess';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let currentMaterial = this.state.currentMaterial;
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			
			let currentMaterialQuestions = currentMaterial.questions;
			Logger.log(currentMaterialQuestions, 'questions', logAllow);
			
			let questionData = response.data;
			Logger.log(questionData, 'questionData', logAllow);
			
			let editedQuestion = new Question(questionData);
			Logger.log(editedQuestion, 'question', logAllow);
			
			let isNewQuestion = currentMaterialQuestions.filter(x => x.id == editedQuestion.id).length < 1;
			Logger.log(isNewQuestion, 'isCreated', logAllow);
			
			let currentQuestionIndex = this.state.questionToEditIndex;
			
			let materials = this.state.materials;
			
			if (editedQuestion.isCard()) {
				
				let groupIndex = currentMaterialQuestions.findIndex(x => x.id == editedQuestion.group_id);
				Logger.log(groupIndex, 'groupIndex', logAllow);
				
				currentQuestionIndex = groupIndex;
				
				if (groupIndex >= 0) {
					
					let group = currentMaterialQuestions[groupIndex];
					Logger.log(group, 'group', logAllow);
					
					if (group) {
						
						let cards = group.group_items;
						
						let cardIndex = cards.findIndex(x => x.id == editedQuestion.id);
						Logger.log(cardIndex, 'cardIndex', logAllow);
						
						if (cardIndex >= 0) {
							cards[cardIndex] = editedQuestion;
						} else {
							cards.push(editedQuestion);
						}
						
						group.group_items = cards;
						
					}
					
					currentMaterialQuestions[groupIndex] = group;
					
				}
				
			} else {
				
				// если это НЕ карточка
				
				// определяем целевой материал
				
				let targetMaterial = currentMaterial
				let targetMaterialIndex = this.state.currentMaterialIndex
				
				if (materialId) {
					
					targetMaterialIndex = materials.findIndex((material) => {
						return material.id == materialId
					})
					
					targetMaterial = materials[targetMaterialIndex]
					
				}
				
				if (currentMaterial.id == targetMaterial.id) {
					
					// если МАТЕРИАЛ прежний
					
					if (isNewQuestion) {
						
						// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое
						
						if (variantId) {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							currentQuestionIndex = this.questions.setVariant(editedQuestion, materialId, variantId)
							
							// this.setState({
							// 	currentVariant: ,
							// })
							
						} else {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое + ВАРИАНТ не задан
							
							// добавляем в общий список заданий текущего материала
							
							currentMaterial.questions.push(editedQuestion)
							
							currentQuestionIndex = currentMaterial.questions.length - 1
							
							this.setState({
								currentVariant: null,
							})
							
						}
						
					} else {
						
						// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое
						
						// ищем задание в вариантах - и удаляем оттуда
							
						targetMaterial.variants.forEach((variant) => {
							
							let variantQuestionIndex = variant.children.findIndex((question) => {
								return question.id == editedQuestion.id
							})
							
							if (variantQuestionIndex >= 0) {
								variant.children.splice(variantQuestionIndex, 1)
							}
							
						})
						
						if (variantId) {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое + ВАРИАНТ задан
							
							// помещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое + ВАРИАНТ не задан
							
							// заменяем это задание внутри текущего материала
							
							let editedQuestionIndex = currentMaterial.questions.findIndex((question) => {
								return question.id == editedQuestion.id
							})
							
							currentMaterial.questions[editedQuestionIndex] = editedQuestion
							
						}
						
					}
					
				} else {
					
					// если МАТЕРИАЛ изменился
					
					if (isNewQuestion) {
						
						// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое
						
						if (variantId) {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое + ВАРИАНТ не задан
							
							// добавляем в общий список заданий заданного варианта
							
							targetMaterial.questions.push(editedQuestion)
							
						}
						
						// переключаемся на этот материал
						currentMaterial = targetMaterial
						
					} else {
						
						// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое
						
						// удаляем задание из текущего материала
						
						let editedQuestionIndex = currentMaterial.questions.findIndex((question) => {
							return question.id == editedQuestion.id
						})
						
						currentMaterial.questions.splice(editedQuestionIndex, 1)
						
						currentQuestionIndex = 0
						
						if (variantId) {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое + ВАРИАНТ не задан
							
							// перемещаем в другой материал
							
							targetMaterial.questions.push(editedQuestion)
							
						}
						
					}
					
				}
				
			}
			
			currentMaterial.questions = currentMaterialQuestions;
			
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			materials[this.state.currentMaterialIndex] = currentMaterial;
			
			this.questions.saveSort(currentMaterialQuestions);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: currentMaterial,
					questionToEdit: null,
					questionFormModalIsOpen: false,
					currentQuestionIndex: currentQuestionIndex,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (bindId, questionIndex) => {
			
			const logName = 'MaterialsPage.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			let question = material.questions[questionIndex];
			let questions = material.questions;
			
			Logger.log(bindId, 'bindId', logAllow);
			Logger.log(questionIndex, 'questionIndex', logAllow);
			
			if (!bindId) {
				window.alert(i18next.t("bind id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (!window.confirm(i18next.t("Hide task #{{id}}?", {id: question.id}))) {
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question-binds') + '/' + bindId,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				this.props.preloader.hide();
				
				questions.splice(questionIndex, 1);
				
				let currentQuestionIndex = this.state.currentQuestionIndex;
				
				if (currentQuestionIndex > questions.length - 1) {
					currentQuestionIndex = questions.length - 1;
				}
				
				this.setState((prevState) => {
					return {
						currentMaterial: material,
						currentQuestionIndex: currentQuestionIndex,
					}
				});
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		saveSort: (questions, afterSuccess) => {
			
			const logName = 'MaterialsPage.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			Logger.log(questions, 'questions', logAllow);
			
			questions.forEach((question) => {
				// Logger.log(question, 'question', logAllow);
				if (question) {
					ids.push(question.id);
				}
			});
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('test-question-binds/save-sort'),
				data: {
					material_id: this.state.currentMaterial.id,
					questions_ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			const logName = 'MaterialsPage.questions.moveUp';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			let questions = material.questions;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = questions.length - 1;
			}
			
			Logger.log(questions, 'questions (before move)', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			Logger.log(questions, 'questions (after move)', logAllow);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveDown: (currentIndex) => {
			
			const logName = 'MaterialsPage.moveDown';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.log(currentIndex, 'currentIndex', logAllow);
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			Logger.log(material, 'material', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions', logAllow);
			
			if (currentIndex === questions.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			Logger.log(newIndex, 'newIndex', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveToPosition: (oldIndex, newIndex) => {
			
			let questions = currentMaterial.questions;
			
			Utils.arrayMove(questions, oldIndex, newIndex);
			
			const currentMaterial = this.state.currentMaterial
			
			currentMaterial.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: currentMaterial,
					questionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
		},
		
		edit: (question, questionIndex) => {
			
			const logName = 'MaterialsPage.questions.edit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(question, 'question', logAllow);
			
			this.setState((prevState) => {
				return {
					questionToEdit: question,
					questionToEditIndex: questionIndex,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		goto: (questionIndex) => {
			
			let currentMaterial = this.state.currentMaterial;
			
			if (currentMaterial) {
				
				Config.setQuestionIndex(currentMaterial.id, questionIndex);
				
				this.setState((prevState) => {
					return {
						currentQuestionIndex: questionIndex,
					}
				});
				
			}
			
		},
		
		prev: () => {
			// let currentMaterial = this.state.currentMaterial
			let questions = this.questions.get()
			let currentIndex = this.state.currentQuestionIndex
			let newIndex = Utils.arrayPrevIndex(questions, currentIndex)
			this.questions.goto(newIndex)
		},
		
		next: () => {
			// let currentMaterial = this.state.currentMaterial;
			let questions = this.questions.get()
			let currentIndex = this.state.currentQuestionIndex
			let newIndex = Utils.arrayNextIndex(questions, currentIndex)
			this.questions.goto(newIndex)
		},
		
		copyToMaterial: (question) => {
			
			const logName = 'MaterialsPage.questions.copy'
			const logAllow = 1
			const logCollapsed = 0
			
			Logger.groupStart(logName, logAllow, logCollapsed)
			
			let currentMaterial = this.state.currentMaterial
			Logger.log(currentMaterial.id, 'currentMaterial.id', logAllow);
			
			let materialId = window.prompt('Copy question to Material ID', currentMaterial.id);
			
			if (materialId) {
				
				axios({
					method: 'get',
					url: Utils.apiUrl('questions/copy'),
					data: {},
					params: {
						accessToken: Utils.getUserToken(),
						questionId: question.id,
						materialId: materialId,
					},
				}).then((response) => {
					
					const logName = 'MaterialsPage.copy.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					let copy = new Question(response.data);
					Logger.log(copy, 'copy', logAllow);
					Logger.log(copy.material_id, 'copy.material_id', logAllow);
					
					if (copy.material_id == currentMaterial.id) {
						
						currentMaterial.questions.push(copy);
						
						this.setState((prevState) => {
							return {
								currentMaterial: currentMaterial,
								currentQuestionIndex: currentMaterial.questions.length - 1,
							}
						});
						
					}
					
					window.alert(i18next.t("Copied successfully"));
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					console.log(error);
					// window.alert(error.response.data.message);
				});
				
			}
			
			Logger.groupEnd(logAllow)
			
		},
		
		moveToMaterial: (question) => {
			
			let toMaterialId = window.prompt('Material ID', currentMaterial.id);
			
			let currentMaterial = this.state.currentMaterial
			
			if (toMaterialId) {
				axios({
					method: 'get',
					url: Utils.apiUrl('questions/move'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						questionId: question.id,
						fromMaterialId: currentMaterial.id,
						toMaterialId: toMaterialId,
					},
				}).then((response) => {
					
					const logName = 'MaterialsPage.move.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					let questionIndex = currentMaterial.questions.indexOf(question);
					currentMaterial.questions.splice(questionIndex, 1);
					
					this.setState((prevState) => {
						return {
							currentMaterial: currentMaterial,
						}
					});
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					console.log(error);
					// window.alert(error.response.data.message);
				});
			}
			
		},
		
		disableUnwantedAnswerTemplates: (question, questionIndex) => {
			
			const currentMaterial = this.state.currentMaterial
			
			this.props.preloader?.show()
			
			axios({
				method: 'post',
				url: Utils.apiUrl('questions/disable-unwanted-use-answer-templates'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'questionId': question.id,
				},
			}).then((response) => {
				
				const logName = 'disableUnwantedUseAnswerTemplates.ajax.done'
				const logAllow = 1
				const logCollapsed = 0
				
				Logger.groupStart(logName, logAllow, logCollapsed)
				
				Logger.log(response, 'response', logAllow)
				
				this.props.preloader?.hide()
				
				let updatedQuestion = new Question(response.data)
				
				currentMaterial.questions[questionIndex] = updatedQuestion
				
				this.setState((prevState) => {
					return {
						currentMaterial: currentMaterial,
					}
				})
				
				Logger.groupEnd(logAllow)
				
			}).catch((error) => {
				
				this.props.preloader?.hide()
				
				console.log(error)
				window.alert(error)
				
			})
		
		},
		
		setAsHomework: (question, questionIndex) => {
			
			// todo
			
		}
		
	};
	
	groups = {
		
		load: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('groups'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'name',
					'filter[is_active]': 1,
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.groups.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let groups = [];
				
				response.data.forEach((groupData) => {
					let group = new Group(groupData);
					groups.push(group);
				});
				
				this.setState((prevState) => {
					return {
						groups: groups,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.theme !== prevProps.theme
			|| this.state.materialsShowArchived !== prevState.materialsShowArchived
			|| this.state.materialsShowNotPublic !== prevState.materialsShowNotPublic
		) {
			this.materials.loadList();
		}
	}
	
	componentDidMount() {
		
		this.materials.loadList(
			this.state.materialsShowArchived,
			this.state.materialsShowNotPublic,
			() => {
				if (this.props.user.is('teacher')) {
					this.groups.load()
				}
			}
		);
		
	}
	
	render() {
		
		const logName = 'MaterialsPage3.render'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		Logger.log(this.props, 'this.props', logAllow)
		Logger.log(this.state, 'this.state', logAllow)
		
		const user = this.props.user;
		
		const userIsOwner = user.is(['owner'])
		Logger.log(userIsOwner, 'userIsOwner', logAllow)
		
		const currentMaterial = this.state.currentMaterial
		
		if (currentMaterial) {
			Logger.log(currentMaterial.name, 'currentMaterial.name', logAllow);
			Logger.log(currentMaterial.id, 'currentMaterial.id', logAllow);
		}
		
		const currentTheme = this.props.theme;
		const currentThemeNum = this.props.currentThemeIndex + 1;
		const themesCount = this.props.themes.length;
		const themeNum = i18next.t("{{num}} / {{count}}", {
			'num': currentThemeNum,
			'count': themesCount,
		});
		
		// todo эта форма здесь нужна только для окна создания НОВОГО материала
		const materialForm = currentMaterial ? (
			<MaterialForm
				model={currentMaterial}
				course={this.props.course}
				themeId={currentTheme?.id}
				// themes={this.props.themes}
				cancel={() => {
					this.setState((prevState) => {
						return {
							materialFormModalIsOpen: 0,
						}
					});
				}}
				afterCreate={this.materials.afterCreateSuccess}
				afterUpdate={this.materials.afterUpdateSuccess}
				preloader={this.props.preloader}
				user={user}
			/>
		) : null
		
		const backBtnTitle = i18next.t("Back to courses, lessons and themes");
		
		let currentMaterialNum = this.state.currentMaterialIndex === null ? 0 : this.state.currentMaterialIndex + 1;
		let materialsCount = this.state.materials.length;
		let materialsCounter = i18next.t("{{num}} / {{count}}", {
			'num': currentMaterialNum,
			'count': materialsCount,
		});
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'MaterialsPage MaterialsPage3'}>
				
				<div className="content-root row">
					
					<section className="current-material-col col-xl-9 col-lg-8 col-sm-8 order-2 order-md-1">
						
						<MaterialsPageNavPanelMd
							
							preloader={this.props.preloader}
							
							course={this.props.course}
							theme={currentTheme}
							lesson={this.props.lesson}
							material={currentMaterial}
							
							materialsCounter={materialsCounter}
							
							themeNum={themeNum}
							themesCount={themesCount}
							
							nextTheme={this.props.nextTheme}
							nextThemeIndex={this.props.nextThemeIndex}
							
							prevTheme={this.props.prevTheme}
							prevThemeIndex={this.props.prevThemeIndex}
							
							back={this.props.back}
							backBtnTitle={backBtnTitle}
							
							materialsManager={this.materials}
							
							showPrevTheme={this.props.showPrevTheme}
							showNextTheme={this.props.showNextTheme}
							
						/>
						
						{/* sm- nav panel */}
						<MaterialsPageNavPanelXs
							
							preloader={this.props.preloader}
							
							course={this.props.course}
							theme={currentTheme}
							lesson={this.props.lesson}
							material={currentMaterial}
							
							themeNum={themeNum}
							themesCount={themesCount}
							
							back={this.props.back}
							backBtnTitle={backBtnTitle}
							
							materialsManager={this.materials}
							
						/>
						
						{(	
							currentMaterial 
							&& (
								user.can('manageMaterials') 
								|| user.can('manageMaterialsAccess') 
								|| currentMaterial.hasAccess(user.groups_ids)
							)
						 ) && (
							
							<>
								<MaterialView2
									preloader={this.props.preloader}
									user={user}
									material={currentMaterial}
									course={this.props.course}
									theme={this.props.theme}
									lesson={this.props.lesson}
								/>
							</>
						
						)}
					
					</section>
					
					<section className="materials-list-col col-xl-3 col-lg-4 col-sm-4 order-1 order-md-2 d-none d-md-block">
						
						<div className="materials-list-box">
							
							<div className="page-head-box">
								<h2>{i18next.t("Materials")}</h2>
							</div>
							
							<div className="list materials-list materials-list-desktop">
								
								{user.can('manageMaterials') && (
									
									<div className="controls top-controls materials-controls materials-top-controls">
										
										<button type={'button'}
												className={[
													'add-material-btn',
													'my-btn',
													// 'my-btn-sm',
													'my-btn-wide',
												].join(' ')}
												onClick={(event) => {
													this.setState((prevState) => {
														return {
															materialToEdit: null,
															materialFormModalIsOpen: true,
														}
													});
												}}
											// title={i18next.t("Add material")}
										>+ {i18next.t("Add material")}</button>
									
									</div>
								
								)}
								
								{user.can('showRemovedContent') && (
									
									<div className={'controls show-removed-controls text-center'}>
										<label htmlFor={'show-removed'}>
											<input
												id={'show-removed'}
												type={'checkbox'}
												checked={this.state.materialsShowArchived}
												onChange={(event) => {
													this.setState((prevState) => {
														return {
															materialsShowArchived: event.target.checked,
														}
													});
													// this.materials.load();
												}}
											/> {i18next.t("Archive")}
										</label>
									</div>
								
								)}
								
								{(user.can('seeMaterialsDrafts')) && (
									
									<div className={'controls show-draft-controls text-center'}>
										<label htmlFor={'show-not-public'}>
											<input
												id={'show-not-public'}
												type={'checkbox'}
												checked={this.state.materialsShowNotPublic}
												onChange={(event) => {
													this.setState((prevState) => {
														return {
															materialsShowNotPublic: event.target.checked,
														}
													});
													// this.materials.load();
												}}
											/> {i18next.t("Drafts")}
										</label>
									</div>
								
								)}
								
								{/* material nav controls */}
								{(this.state.materials.length > 0 && this.state.currentMaterialIndex !== null) && (
									
									<div className="materials-nav-controls">
										
										<div className="row">
											
											<div className="col">
												<button
													type={'button'}
													className={[
														'prev-material-btn',
														'my-btn',
														// 'my-btn-sm',
														'my-btn-wide',
													].join(' ')}
													onClick={(event) => {
														this.materials.prev();
													}}
													title={i18next.t("Prev. material")}
												><Icon.ChevronLeft/></button>
											</div>
											
											<div className="col-auto">
												<div className="counter">
													{currentMaterialNum} / {this.state.materials.length}
												</div>
											</div>
											
											<div className="col">
												<button
													type={'button'}
													className={[
														'next-material-btn',
														'my-btn',
														// 'my-btn-sm',
														'my-btn-wide',
													].join(' ')}
													onClick={(event) => {
														this.materials.next();
													}}
													title={i18next.t("Next material")}
												><Icon.ChevronRight/></button>
											</div>
										
										</div>
									
									</div>
									
								)}
								
								{(this.state.materials.length > 0) ? (
									
									<div className={'body materials-list-body'}>
										
										{this.state.materials.map((material, materialIndex) => {
											
											const logName = 'MaterialsPage.materials.map'
											const logAllow = 0
											const logCollapse = 0
											
											Logger.groupStart(logName, logAllow, logCollapse)
											
											Logger.log(material, 'material', logAllow)
											
											let progress = material.isExam() ? 0 : material.getProgress();
											Logger.log(progress, 'progress', logAllow)
											
											let classNameData = [
												'material-preview',
												'list-item',
												'list-item-active',
											];
											
											if (currentMaterial && material.id === currentMaterial.id) {
												classNameData.push('list-item-current');
											}
											
											if (material.is_del == 1) {
												classNameData.push('list-item-archived');
											}
											
											if (material.is_exam == 1) {
												classNameData.push('is_exam');
											}
											
											if (material.is_trainer == 1) {
												classNameData.push('is_trainer');
											}
											
											if (!material.is_public == 1) {
												classNameData.push('list-item-draft');
											}
											
											let materialDebugInfo = [
												'#' + material.id,
												'created_at: ' + material.created_at,
												'created_by: #' + material.created_by,
											];
											
											if (material.copy_of_id) {
												materialDebugInfo.push('copy of #' + material.copy_of_id);
											}
											
											Logger.groupEnd(logAllow)
											
											return (
												
												<div
													key={`material-${material.id}-preview`}
													id={`material-${material.id}-preview`}
													className={classNameData.join(' ')}
													onClick={(event) => {
														this.materials.setCurrentByIndex(materialIndex);
													}}
													// title={progress + '%'}
													// data-type-alias={material.type_alias}
													// data-type-alias={material.isExam() ? 'exam' : ''}
													data-material-id={material.id}
													data-dynamic-id={material?.dynamic?.id}
												>
													{progress > 0 && (
														<div className="progress-box">
															<div className="progress" style={{
																width: progress + '%',
															}}></div>
														</div>
													)}
													
													<div className={'content'}>
														
														<div className={'title'}>{material.name}</div>
														
														{(material.groups_binds && material.groups_binds.length > 0) && (
															
															<>
																
																<div className={`groups-binds access-list my-2`}>
																	
																	{material.groups_binds.map((materialGroupBind) => {
																		
																		const logName = 'material.groups_binds.map'
																		const logAllow = 0
																		const logCollapse = 0
																		
																		Logger.groupStart(logName, logAllow, logCollapse)
																		
																		Logger.log(materialGroupBind, 'materialGroupBind', logAllow)
																		
																		// отображать студенту только те доступы, который относятся к его группам
																		if (user.isStudent() && !user.groups_ids.includes(materialGroupBind.group_id)) {
																			return ''
																		}
																		
																		// === moment formatted
																		
																		// let dateTimeFormat = 'DD.MM.YY hh:mm'
																		// let accessStartDateTime = moment(materialGroupBind.access_start_unixtime * 1000).format(dateTimeFormat)
																		// let accessEndDateTime = materialGroupBind.access_end_unixtime
																		// 	? moment(materialGroupBind.access_end_unixtime * 1000).format(dateTimeFormat)
																		// 	: i18next.t("Not restricted")
																		
																		// === not formatted
																		
																		// let accessStartDateTime = materialGroupBind.access_start
																		// let accessEndDateTime = materialGroupBind.access_end
																		// 	? materialGroupBind.access_end
																		// 	: i18next.t("Not restricted")
																		
																		// === my DateHelper formatted
																		
																		let accessStartDateTime = 
																		
																		DateHelper.formatDate({
																			date: materialGroupBind.access_start_unixtime * 1000,
																			format: 'd.m.y',
																		})
																		
																		+ ' ' +
																		
																		DateHelper.formatTime({
																			time: materialGroupBind.access_start_unixtime * 1000,
																			format: 'h:m',
																		})
														
																		let accessEndDateTime = materialGroupBind.access_end
																			
																			? 
																			
																			DateHelper.formatDate({
																				date: materialGroupBind.access_end_unixtime * 1000,
																				format: 'd.m.y',
																			})
																			
																			+ ' ' +
																			
																			DateHelper.formatTime({
																				time: materialGroupBind.access_end_unixtime * 1000,
																				format: 'h:m',
																			})
																			
																			: i18next.t("Not restricted")
																		
																		Logger.groupEnd(logAllow)
																		
																		return (
																			
																			<div className={`group-bind access-list-item small text-muted`}>
																				{materialGroupBind.group_name} : {accessStartDateTime} – <b>{accessEndDateTime}</b>
																			</div>
																			
																		)
																		
																	})}
																	
																</div>
																
															</>
															
														)}
														
														{user.can('debugInfo') && (
															<div className={'debug-info'}>
																{materialDebugInfo.join(' | ')}
															</div>
														)}
														
													</div>
													
													{user.can('manageMaterials') && (
														
														<div className="controls material-preview-controls bottom-controls">
															
															{/*<button
																type={'button'}
																className={[
																	'material-edit-btn',
																	'edit-material-btn',
																	'my-btn',
																	'my-btn-sm',
																	// 'btn-primary',
																].join(' ')}
																onClick={(event) => {
																	event.stopPropagation();
																	this.materials.edit(materialIndex);
																}}
															><Icon.Pencil/></button>*/}
															
															{this.state.materials.length > 1 && (
																<button
																	type={'button'}
																	className={[
																		'move-up-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.moveUp(materialIndex);
																	}}
																><Icon.ArrowUp/></button>
															)}
															
															{this.state.materials.length > 1 && (
																<button
																	type={'button'}
																	className={[
																		'move-down-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.moveDown(materialIndex);
																	}}
																><Icon.ArrowDown/></button>
															)}
															
															{material.is_public == 0 && material.is_del == 0 && (
																<button
																	type={'button'}
																	className={[
																		'public-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_public: 1,
																		});
																	}}
																	title={i18next.t("Show for students")}
																><Icon.Eye/></button>
															)}
															
															{material.is_public == 1 && material.is_del == 0 && (
																<button
																	type={'button'}
																	className={[
																		'unpublic-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_public: 0,
																		});
																	}}
																	title={i18next.t("Hide for students")}
																><Icon.EyeSlash/></button>
															)}
															
															{material.is_del == 0 && (
																<button
																	type={'button'}
																	className={[
																		'del-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_del: 1,
																		}, i18next.t("Are you shure?"));
																	}}
																	title={i18next.t("Move to archive")}
																><Icon.Trash/></button>
															)}
															
															{material.is_del == 1 && (
																<button
																	type={'button'}
																	className={[
																		'undel-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_del: 0,
																		});
																	}}
																	title={i18next.t("Return from archive")}
																><Icon.ArrowCounterclockwise/></button>
															)}
															
															<button
																type={'button'}
																className={[
																	'copy-material-btn',
																	'my-btn',
																	'my-btn-sm',
																].join(' ')}
																onClick={(event) => {
																	event.stopPropagation();
																	let themeId = window.prompt(i18next.t("Theme ID"), material.theme_id);
																	if (themeId) {
																		if (this.props.preloader) {
																			this.props.preloader.show();
																		}
																		axios({
																			method: 'post',
																			url: Utils.apiUrl('materials/copy-to'),
																			data: {},
																			params: {
																				'accessToken': Utils.getUserToken(),
																				materialId: material.id,
																				themeId: themeId,
																			},
																		}).then((response) => {
																			
																			const logName = 'MaterialsPage.copyMaterial';
																			const logAllow = 1;
																			const logCollapsed = 0;
																			
																			Logger.groupStart(logName, logAllow, logCollapsed);
																			
																			Logger.log(response, 'response', logAllow);
																			
																			if (themeId == material.theme_id) {
																				let copy = new Material(response.data);
																				let materials = this.state.materials;
																				materials.push(copy);
																				materials.sort(Utils.defaultModelSort);
																				this.setState((prevState) => {
																					return {
																						materials: materials,
																					}
																				});
																			}
																			
																			if (this.props.preloader) {
																				this.props.preloader.hide();
																			}
																			
																			Logger.groupEnd(logAllow);
																			
																		}).catch((error) => {
																			if (this.props.preloader) {
																				this.props.preloader.hide();
																			}
																			console.log('error.response = %o', error.response);
																			if (error.response && error.response.data) {
																				window.alert(error.response.data.message);
																			} else {
																				window.alert(error.message);
																			}
																		});
																	}
																}}
																title={i18next.t("Copy to") + '...'}
															><Icon.ClipboardCheck/></button>
															
															<button
																type={'button'}
																className={[
																	'show-material-page-btn',
																	'my-btn',
																	'my-btn-sm',
																].join(' ')}
																onClick={(event) => {
																	event.stopPropagation();
																}}
																title={i18next.t("Open on a separate page")}
															>
																<Link
																	to={'/material/' + material.id}
																	target={'_blank'}
																><Icon.ArrowUpRightCircleFill/></Link>
															</button>
														
														</div>
													
													)}
												
												</div>
											
											);
											
										})}
									
									</div>
								
								) : (
									
									<div className={'text-center'}>{i18next.t("Materials not found")}</div>
								
								)}
							
							</div>
						
						</div>
					
					</section>
					
					<div className="modals">
						
						<Modal
							className={[
								'material-form-modal',
								'wide-modal',
							].join(' ')}
							show={this.state.materialFormModalIsOpen}
							onHide={this.materials.formModalToggle}
							size={'lg'}
							keyboard={false}
							backdrop={'static'}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{this.state.materialToEdit ? (
										i18next.t("Edit material #{{id}}", {
											id: this.state.materialToEdit.id,
										})
									) : (
										i18next.t("New material")
									)}
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
								{materialForm}
							</Modal.Body>
						
						</Modal>
						
					</div>
					
				</div>
				
			</div>
			
		);
		
	}
	
}

MaterialsPage3.propTypes = {
	course: PropTypes.instanceOf(Course).isRequired,
	theme: PropTypes.instanceOf(Theme).isRequired,
	lesson: PropTypes.instanceOf(Lesson).isRequired,
	preloader: PropTypes.object,
	user: PropTypes.instanceOf(User),
	themes: PropTypes.arrayOf(PropTypes.instanceOf(Theme)),
	back: PropTypes.func,
	showPrevTheme: PropTypes.func,
	showNextTheme: PropTypes.func,
	prevTheme: PropTypes.instanceOf(Theme),
	prevThemeIndex: PropTypes.number,
	nextTheme: PropTypes.instanceOf(Theme),
	nextThemeIndex: PropTypes.number,
	currentThemeIndex: PropTypes.number,
	alert: PropTypes.object,
	skins: PropTypes.array,
};

MaterialsPage3.defaultProps = {
	skins: [],
};

export default withRouter(MaterialsPage3);