import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Utils from "../../helpers/Utils";
import i18next from "i18next";
import Question from "../../models/Question";
import User from "../../models/User";
import Answer from "../../models/Answer";
import Config from "../../helpers/Config";
import axios from "axios";
import Material from "../../models/Material";
import imgTrueWhite from "../../img/true-white.png";
import imgX from "../../img/x.png";
import draftToHtml from 'draftjs-to-html';
import "./TrainerQuestionView.css";
import FormDataHelper from "../../helpers/FormDataHelper";
import UploadsList from "./UploadsList";
import clearIcon from '../../img/refresh.png';
import audioIcon from '../../img/audio.png';
import * as Icon from 'react-bootstrap-icons';


export default class TrainerQuestionView extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		const logName = 'TrainerQuestionView.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let question = props.question;
		Logger.log(question.id, 'question.id', logAllow);
		Logger.log(question, 'question', logAllow);
		
		Logger.log(props, 'props', logAllow);
		
		// let accordancesArray = [];
		let accordancesArray = question.getAccordancesArray(question.view_type_alias === 'completion' ? 0 : 1);
		Logger.log(accordancesArray, 'accordancesArray', logAllow);
		
		let completionRightAnswers = Question.getCompletionAnswers(question.about);
		Logger.log(completionRightAnswers, 'completionRightAnswers', logAllow);
		
		// if (this.props.mode == 'pass') {
		completionRightAnswers = Utils.arrayShuffle(completionRightAnswers);
		// }
		
		// если есть ответ, вставляем значения из него
		
		// let latestAnswer = props.answers[0];
		// let latestAnswer = props.material.answers[0];
		let latestAnswer = this.props.answer || question.latest_answer;
		Logger.log(latestAnswer, 'latestAnswer', logAllow);
		
		let completions = [];
		
		let answerText = '';
		
		let pollSelected = [];
		
		if (latestAnswer) {
			
			let latestAnswerData = latestAnswer.answer_data;
			Logger.log(latestAnswerData, 'latestAnswerData', logAllow);
			
			if (latestAnswerData) {
				
				if (latestAnswerData.accordances) {
					accordancesArray = latestAnswerData.accordances;
				}
				completions = latestAnswerData.completions;
				answerText = latestAnswerData.text;
				pollSelected = latestAnswerData.pollSelected;
				
				if (question.view_type_alias === 'completion_cloud' && !latestAnswer.is_right) {
					// completions = [];
				}
				
			}
			
		}
		
		// todo refactor сделать названия полей и переменных более понятными
		
		this.state = {
			latestAnswer: latestAnswer,
			accordances: accordancesArray, //
			unusedClouds: completionRightAnswers,
			selectedVariantAlias: null, // ответ, выбранный в completion_select (выбор из вариантов)
			completions: completions || [], // ответы, введённые в поля внутри about (completion_write и тп)
			answerText: answerText, // ответ в свободной форме
			pollSelected: pollSelected,
			imgViewerIsOpen: false,
			variantsArray: question.getVariantsArray(question.use_shuffle),
			comment: latestAnswer ? latestAnswer.comment : '',
			alertModalIsOpen: false,
			studentFiles: [],
		};
		
		this.answerStudentFilesInputRef = React.createRef();
		this.answerTeacherFilesInputRef = React.createRef();
		
		if (!latestAnswer || (latestAnswer && !latestAnswer.is_right)) {
			
			let audioUploadData = question.sounds[0];
			let audio = this.props.audio;
			
			// if (audioUploadData) {
			//     let audio = new Audio(audioUploadData.url);
			//     audio.play();
			// }
			
			if (audio && audioUploadData) {
				audio.src = audioUploadData.url
				audio.load();
				audio.play();
			}
			
		}
		
		Logger.groupEnd(logAllow);
		
	}
	
	clear = (event) => {
		
		const logName = 'TrainerQuestionView.clear';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let latestAnswer = this.state.latestAnswer;
		Logger.log(latestAnswer, 'latestAnswer', logAllow);
		
		// if (latestAnswer) {
		//     Logger.groupEnd(logAllow);
		//     return;
		// }
		
		let question = this.props.question;
		
		let completionAnswers = Question.getCompletionAnswers(question.about);
		// Logger.log(completionAnswers, 'completionAnswers', logAllow);
		
		completionAnswers = Utils.arrayShuffle(completionAnswers);
		
		let accordancesArray = question.getAccordancesArray(question.view_type_alias === 'completion' ? 0 : 1);
		
		let variantsArray = question.getVariantsArray(question.use_shuffle);
		
		this.setState((prevState) => {
			return {
				latestAnswer: null,
				usedClouds: [],
				completions: [],
				unusedClouds: completionAnswers,
				accordances: accordancesArray,
				variantsArray: variantsArray,
			}
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	getErrors = () => {
		
		const logName = 'TrainerQuestionView.getErrors';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let errs = [];
		
		const question = this.props.question;
		
		const completionRightAnswers = Question.getCompletionAnswers(question.about);
		Logger.log(completionRightAnswers, 'completionRightAnswers', logAllow);
		Logger.log(this.state.completions, 'this.state.completions', logAllow);
		
		if (this.state.completions.length < completionRightAnswers.length) {
			errs.push(i18next.t("Not all fields are filled"));
		}
		
		if (question.view_type_alias === 'completion_cloud') {
			if (this.state.unusedClouds.length > 0) {
				errs.push(i18next.t("Not all clouds are used"));
			}
		} else if (question.view_type_alias === 'poll') {
			if (this.state.pollSelected.length === 0) {
				errs.push(i18next.t("Not answer options selected"));
			}
		} else if (question.view_type_alias === 'completion') {
			let accordances = this.state.accordances;
			let accordancesWithAnswers = this.state.accordances.filter(accordance => accordance.answer);
			if (accordancesWithAnswers.length < accordances.length) {
				errs.push(i18next.t("Not all fields are filled"));
			}
		} else if (question.view_type_alias === 'string') {
			if (!this.state.answerText) {
				errs.push(i18next.t("Answer is empty"));
			}
		}
		
		// файлы
		let studentFilesInput = this.answerStudentFilesInputRef.current;
		if (question.view_type_alias === 'files' && studentFilesInput) {
			let files = studentFilesInput.files;
			if (files.length > 0) {
				if (question.files_as_answers_count && files.count > question.files_as_answers_count) {
					errs.push([i18next.t("Too many files selected. Maximum files count: {{count}}",
						{count: question.files_as_answers_count})]);
				}
			} else {
				errs.push([i18next.t("No files selected")])
			}
		}
		
		Logger.groupEnd(logAllow);
		
		return errs;
		
	}
	
	validate = () => {
		return this.getErrors().length === 0;
	};
	
	// отправить данные ответа на сервер
	sendAnswer = (data) => {
		
		const logName = 'TrainerQuestionView.sendAnswer';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(FormDataHelper.getValues(data), 'FormDataHelper.getValues(data)', logAllow);
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		const questionData = {...this.props.question}
		
		// чтобы не было рекурсии
		questionData['latest_answer'] = null;
		
		axios({
			method: 'post',
			url: Utils.apiUrl('test-answer-student'),
			data: data,
			params: {
				'accessToken': Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'TrainerQuestionView.sendAnswer.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let answer = new Answer(response.data);
			Logger.log(answer, 'answer', logAllow);
			
			this.setState((prevState) => {
				return {
					latestAnswer: answer,
					// completions: [],
				}
			});
			
			if (this.props.afterSendAnswer) {
				this.props.afterSendAnswer(response, answer);
			}
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Utils.axiosErrorAlert(error);
			
		});
		
		Logger.groupEnd(logAllow);
		
	}
	
	// собарть данные ответа и отправить на сервер (sendAnswer)
	saveAnswer = (event) => {
		
		const logName = 'TrainerQuestionView.saveAnswer';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		// const question = this.props.question;
		
		let errors = this.getErrors();
		Logger.log(errors, 'errors', logAllow);
		
		if (errors.length > 0) {
			// errors.unshift(i18next.t("Пожалуйста, устраните следующие ошибки:\n\n"));
			// alert(errors.join("\n"));
			// let errsHtml = errors.map();
			if (this.props.alert) {
				this.props.alert.show(i18next.t("Not all fields are filled correctly"), null, false);
			}
			Logger.groupEnd(logAllow);
			return;
		}
		
		const question = {...this.props.question};
		
		// чтобы не было рекурсии
		question['latest_answer'] = null;
		
		let answerData = {
			accordances: this.state.accordances,
			completions: this.state.completions,
			text: this.state.answerText,
			pollSelected: this.state.pollSelected,
			// clouds: this.state.usedClouds,
		};
		Logger.log(answerData, 'answerData', logAllow);
		
		let formData = new FormData();
		
		formData.append('material_id', this.props.material_id.toString());
		formData.append('question_id', question.id);
		formData.append('question_data', JSON.stringify(question));
		formData.append('answer_data', JSON.stringify(answerData));
		
		this.sendAnswer(formData);
		
		Logger.groupEnd(logAllow);
		
	};
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const logName = 'TrainerQuestionView.render #' + this.props.question.id;
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let addBrs = true;
		
		let question = {...this.props.question};
		Logger.log(question.id, 'question.id', logAllow);
		Logger.log(question, 'question', logAllow);
		
		Logger.log(this.state, 'this.state', logAllow);
		Logger.log(this.props, 'this.props', logAllow);
		
		// вставляем поля ввода вместо заглушек
		
		let completionsRightAnswers = Question.getCompletionAnswers(question.about);
		Logger.log(completionsRightAnswers, 'completionsRightAnswers', logAllow);
		
		let completionsUserAnswers = this.state.completions;
		
		let completionIndex = 0;
		
		// let latestAnswer = question.latest_answer || this.state.latestAnswer;
		let latestAnswer = this.state.latestAnswer;
		Logger.log(latestAnswer, 'latestAnswer', logAllow);
		
		let latestAnswerId = latestAnswer ? latestAnswer.id : null;
		let variantsArray = this.state.variantsArray;
		Logger.log(variantsArray, 'variantsArray', logAllow);
		
		// let rightVariants = question.getRightVariants();
		// Logger.log(rightVariants, 'rightVariants', logAllow);
		
		// let answerTextFieldName = `question-${question.id}-answer`;
		
		const disabled =
			this.props.mode !== 'demo'
			&& (this.props.mode === 'check' || (latestAnswer ? (latestAnswer.is_right || !this.props.reanswers || latestAnswer.is_waiting) : false))
		;
		Logger.log(disabled, 'disabled', logAllow);
		
		// готовим к отображению текст задания полями ввода / формулами
		//
		// для этого:
		// - собираем данные о полях ввода (Question.getCompletionAnswers)
		// - разбиваем текст на массив, при помощи регулярок (Question.completionPlugRegex)
		// - получаем массив, состоящий только из "чистых" кусков обычного текста, без спец-конутрукций
		// - заводим новый массив, в который сложим компоненты, готовые к рендерингу
		// - заменяем каждый такой кусок на тег span с "живым" html внутри и добавляем в
		//      допустимы простые инлайн-теги типа b, i, u, small, strong, sub, sup и тд
		// - после каждого такого элемента добавляем в массив рекат-компонент с полем ввода,
		//      используя данные, полученные на первом шаге
		// - при рендеринге выводится итоговый массив компонентов
		//
		// ограничения такого подхода:
		// - недопустимо наличие тегов типа p, div, table и тд, так как они будут прерваны span-ами при вставке полей
		
		let aboutData = [];
		
		if (question.about) {
			
			let aboutText = question.about;
			Logger.log(aboutText, 'aboutText', logAllow);
			
			let lettersOnly = true;
			
			completionsRightAnswers.forEach((completionsRightAnswer) => {
				if (completionsRightAnswer.size > 1) {
					lettersOnly = false;
				}
			});
			
			Logger.log(lettersOnly, 'lettersOnly', logAllow);
			
			if (question.has_formulas > 0) {
				
				aboutData = Question.renderableFormulas(aboutText, addBrs);
				
			} else {
				
				let strings = aboutText.split("\n");
				// let strings = [question.about];
				// Logger.log(strings, 'strings', logAllow);
				
				strings.forEach((string) => {
					
					// Logger.groupStart('stringDataItem', logAllow);
					
					let stringData = string.split(Question.completionPlugRegex);
					Logger.log(stringData, 'stringData', logAllow);
					
					stringData.forEach((stringDataItem, stringIndex) => {
						
						// Logger.log(stringDataItem, 'stringDataItem', logAllow);
						
						// aboutData.push(stringDataItem);
						
						aboutData.push(
							<span
								dangerouslySetInnerHTML={{__html: stringDataItem}}
								// key={'string_' + stringIndex}
							></span>
						);
						
						let isNotLast = stringIndex < stringData.length - 1;
						let isEmpty = stringDataItem === '';
						let rightAnswer = completionsRightAnswers[completionIndex];
						
						if (isNotLast || (isNotLast && isEmpty)) {
							
							let input = <b>???</b>;
							
							if (question.view_type_alias === 'completion_select') {
								
								let variants = question.getVariantsByPosition(completionIndex + 1);
								// Logger.log(variants, 'variants', logAllow);
								
								input =
									<select
										className={[
											'form-control',
											'completion-input',
										].join(' ')}
										onChange={(event) => {
											let input = event.target;
											let val = input.value;
											let index = parseInt(input.getAttribute('data-index'));
											completionsUserAnswers[index] = val;
											this.setState((prevState) => {
												return {
													completions: completionsUserAnswers,
												}
											});
										}}
										// value={this.state.completionsUserAnswers[completionIndex]}
										data-index={completionIndex}
										disabled={disabled}
									>
										<option value=""></option>
										{variants.map((variant) => {
											let value = variant.name;
											let selected = this.props.mode === 'pass'
												? completionsUserAnswers[completionIndex] == value
												: completionsRightAnswers[completionIndex] == value;
											return (
												<option selected={selected} value={value}>
													{value}
												</option>
											);
										})}
									</select>;
								
							} else if (question.view_type_alias === 'completion_cloud') {
								
								Logger.log(this.state.completions, 'this.state.completions', logAllow);
								
								let cloudText = this.state.completions[completionIndex] || '';
								// Logger.log(cloudText, 'cloudText', logAllow);
								
								// let rightAnswer = completionsRightAnswers[completionIndex];
								
								input = <span
									className={[
										'completion-input',
										cloudText ? 'completion-input-filled' : 'completion-input-unfilled',
										lettersOnly ? 'completion-input-letter' : 'completion-input-word',
									].join(' ')}
									data-size={rightAnswer.size}
								>{cloudText}</span>;
								
							} else {
								
								input = <input
									
									type="text"
									
									className={[
										'form-control',
										'completion-input',
									].join(' ')}
									
									onChange={(event) => {
										let input = event.target;
										let val = input.value;
										let completions = this.state.completions;
										let index = parseInt(input.getAttribute('data-index'));
										completions[index] = val;
										this.setState((prevState) => {
											return {
												completions: completions,
											}
										});
									}}
									
									value={this.state.completions[completionIndex]}
									
									// !!! dev only
									// defaultValue={rightAnswer.text}
									
									placeholder={this.props.mode === 'demo' ? rightAnswer.text : ''}
									
									data-size={rightAnswer.size}
									
									style={{
										width: Question.completionInputMinSize * rightAnswer.size + 'px',
									}}
									
									readOnly={latestAnswer ? latestAnswer.is_right : false}
									
									data-index={completionIndex}
								
								/>;
								
							}
							
							aboutData.push(input);
							
							completionIndex++;
							
						}
						
					});
					
					if (addBrs && stringData.length > 0) {
						aboutData.push(<br/>);
					}
					
					// Logger.groupEnd(logAllow);
					
				});
				
			}
			
		}
		
		Logger.log(aboutData, 'aboutData', logAllow);
		
		let imgs = question.images.map((imageUploadData, imageIndex) => {
			return {
				src: imageUploadData.url,
			};
		});
		Logger.log(imgs, 'imgs', logAllow);
		
		let errors = this.getErrors();
		let isValid = errors.length === 0;
		
		let debugInfoArray = [
			'#' + question.id,
			'bind: ' + question.bind_id,
			'num: ' + this.props.num,
			'path: ' + this.props.path,
			'answer: ' + latestAnswerId,
			'disabled: ' + disabled,
		];
		let debugInfoString = debugInfoArray.join(' | ');
		
		let mediaColSize = 'col-12';
		
		let questionsCount = this.props.questionsCount;
		let questionNum = this.props.questionIndex + 1;
		let progress = questionNum / questionsCount * 100;
		
		let showSaveAnswerBtn = this.props.user.can('applyAnswers');
		
		// let allowClear = !latestAnswer || (latestAnswer && !latestAnswer.is_right);
		let allowClear = true;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div
				key={'question_' + question.id}
				className={[
					'trainer-question',
					latestAnswer ? 'has-answer' : '',
					this.props.showAnswerState ? (
						latestAnswer ? (
							latestAnswer.is_right
								? 'right-answer'
								: 'wrong-answer'
						) : ''
					) : '',
				].join(' ')}
				data-question-id={question.id}
				data-answer-id={latestAnswerId}
				data-has-errors={errors.length > 0}
				data-debug-info={debugInfoString}
				data-view-type-alias={question.view_type_alias}
			>
				
				{/* отладка */}
				{this.props.user.can('debugInfo') && (
					<div className={'debug-info text-center'}
						 title={i18next.t("Debug info")}
					>{debugInfoString}</div>
				)}
				
				<div className="controls top-controls">
					
					{(questionsCount > 1) &&
						
						<>
							
							{(this.props.prevBtnOnClick) &&
								<button
									type={'button'}
									className={[
										'prev-btn',
										'my-btn',
									].join(' ')}
									onClick={this.props.prevBtnOnClick}
								><Icon.ArrowLeftShort/> {i18next.t("Prev. task")}</button>
							}
							
							{(this.props.nextBtnOnClick) &&
								<button
									type={'button'}
									className={[
										'next-btn',
										'my-btn',
									].join(' ')}
									onClick={this.props.nextBtnOnClick}
								>{i18next.t("Next task")} <Icon.ArrowRightShort/></button>
							}
						
						</>
						
					}
				
				</div>
				
				<div className="controls top-controls">
					
					<div className="row">
						
						<div className="col-2 d-none"></div>
						
						<div className="col-auto align-self-center">
							
							<button
								type={'button'}
								className={[
									'clear-btn ',
									// 'my-btn',
									'trainer-btn',
								].join(' ')}
								onClick={(event) => {
									this.clear();
								}}
								style={{
									visibility: allowClear ? 'visible' : 'hidden',
								}}
							><img src={clearIcon} alt=""/></button>
						
						</div>
						
						<div className="col align-self-center">
							
							<div className="progress-box">
								<div className="progress">
									<div
										className="progress-bar"
										role="progressbar"
										style={{
											width: progress + '%',
										}}
										aria-valuenow={questionsCount + '/' + questionNum}
										aria-valuemin="0"
										aria-valuemax="100"
									>{questionNum} / {questionsCount}</div>
								</div>
							</div>
						
						</div>
						
						<div className="col-2 d-none"></div>
					
					</div>
				
				</div>
				
				<div className={'body row'}>
					
					{/* media */}
					{question.hasFiles() ? (
						
						<div className={[
							'media',
							'media-col',
							mediaColSize,
							'align-self-center',
						].join(' ')}>
							
							{question.hasFiles(['images']) && (
								<div className={'files-box images'}>
									{question.images.filter(uploadBind => uploadBind.active == 1).map((imageUploadData, imageIndex) => {
										return (
											<div className={'media-box img-box'}>
												<a href={imageUploadData.url}
												   className={'img-link'}
												   target={'_blank'}
												   key={'img_' + imageIndex}
												>
													<img src={imageUploadData.preview_url}
														 alt="img"
														 onClick={(event) => {
															 // todo
														 }}
														 className={[
															 'clickable',
														 ].join(' ')}
													/>
												</a>
												<small
													className={'upload-name text-muted'}>{imageUploadData.name}</small>
											</div>
										);
									})}
								</div>
							)}
							
							{question.hasFiles(['sounds']) && (
								
								<div className={'files-box sounds'}>
									
									{question.sounds.filter(uploadBind => uploadBind.active == 1).map((uploadData) => {
										
										return (
											
											<div className="media-box sound-box">
												
												<button
													type={'button'}
													className={[
														'audio-btn',
														'trainer-btn',
														'trainer-btn-lg',
														// !this.props.audio.paused && this.props.audioId == uploadData.id ? 'trainer-btn-active' : 'trainer-btn-inactive'
													].join(' ')}
													onClick={(event) => {
														this.props.audioToggle(uploadData.id, uploadData.url);
													}}
												><img src={audioIcon} alt="play sound button"/></button>
												
												{/*<audio
                                                    src={uploadData.url}
                                                    controls={true}
                                                />*/}
											
											</div>
										
										);
										
									})}
								
								</div>
							)}
							
							{question.hasFiles(['different_files']) && (
								<UploadsList uploads={question.different_files}/>
							)}
						
						</div>
					
					) : ''}
					
					<div className={"task-and-answer col-12 align-self-center text-center"}>
						
						<div className="task">
							{aboutData}
						</div>
					
					</div>
					
					{(!latestAnswer && this.state.unusedClouds.length > 0) &&
						
						<div className={"clouds col-12 align-self-center text-center"}>
							
							<>
								{this.state.unusedClouds.map((cloud, index) => {
									return (
										<button
											className={'cloud'}
											onClick={(event) => {
												
												const logName = 'TrainerQuestionView.cloud.click';
												const logAllow = 1;
												const logCollapsed = 0;
												
												Logger.groupStart(logName, logAllow, logCollapsed);
												
												let completions = this.state.completions;
												completions.push(cloud.text);
												
												let unusedClouds = this.state.unusedClouds;
												Logger.log(unusedClouds, 'unusedClouds (before splice)', logAllow);
												unusedClouds.splice(index, 1);
												Logger.log(unusedClouds, 'unusedClouds (after splice)', logAllow);
												
												this.setState((prevState) => {
													return {
														// completionsUserAnswers: completionsUserAnswers,
														unusedClouds: unusedClouds,
														completions: completions
													}
												});
												
												Logger.groupEnd(logAllow);
												
											}}
											disabled={disabled}
										>{cloud.text}</button>
									);
								})}
							</>
						
						</div>
						
					}
				
				</div>
				
				{/* студент // элементы управления */}
				<div className="controls bottom-controls pass-bottom-controls text-center">
					
					{(showSaveAnswerBtn) &&
						<>
							{latestAnswer ? (
								<>
									{latestAnswer.is_right ? (
										<>
											<button
												type={'button'}
												className={[
													'answer-btn',
													'right-answer-btn',
													'my-btn',
													'my-btn-success',
												].join(' ')}
												onClick={(event) => {
													// this.clear(event);
												}}
												disabled={true}
											>{i18next.t("Right")}&nbsp;<img src={imgTrueWhite}
																			className={'icon right-icon'} alt={'icon'}/>
											</button>
										</>
									) : (
										<>
											<button
												type={'button'}
												className={[
													'answer-btn',
													'wrong-answer-btn',
													'my-btn',
													'my-btn-danger',
												].join(' ')}
												onClick={(event) => {
													this.clear(event);
												}}
											>
												{/*{i18next.t("Wrong")}&nbsp;<Icon.ArrowClockwise/>*/}
												{i18next.t("Wrong")}&nbsp;<img src={imgX} className={'icon right-icon'}
																			   alt={'icon'}/>
											</button>
										</>
									)}
								</>
							) : (
								<>
									<button
										type={'button'}
										className={[
											'answer-btn',
											'save-answer-btn',
											'my-btn',
											'my-btn-primary',
										].join(' ')}
										onClick={(event) => {
											this.saveAnswer();
										}}
									>{i18next.t("Check")}&nbsp;<img src={imgTrueWhite} className={'icon right-icon'}
																	alt={'icon'}/></button>
								</>
							)}
						</>
					}
				
				</div>
			
			</div>
		
		);
		
	}
	
}

TrainerQuestionView.propTypes = {
	
	question: PropTypes.instanceOf(Question).isRequired,
	user: PropTypes.instanceOf(User).isRequired,
	answers: PropTypes.arrayOf(PropTypes.instanceOf(Answer)),
	
	material_id: PropTypes.number,
	material: PropTypes.instanceOf(Material),
	
	mode: PropTypes.oneOf(['demo', 'pass', 'check']),
	
	afterCheck: PropTypes.func,
	afterSendAnswer: PropTypes.func,
	
	preloader: PropTypes.object,
	alert: PropTypes.object,
	
	path: PropTypes.string,
	num: PropTypes.any, // порядковый номер в списке
	reanswers: PropTypes.bool, // возможность ответить несколько раз
	showAnswerState: PropTypes.bool, // отображать статус верный / не верный ответ
	weightVisible: PropTypes.bool,
	
	// progress
	questionsCount: PropTypes.number,
	questionIndex: PropTypes.number,
	
	nextBtnOnClick: PropTypes.func,
	prevBtnOnClick: PropTypes.func,
	
	audioToggle: PropTypes.func,
	
};

TrainerQuestionView.defaultProps = {
	answers: [],
	reanswers: true,
};