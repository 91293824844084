import "./AdminHomePage.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import axios from "axios";
import Calendar from "react-calendar";

import Logger from "../../../helpers/Logger";
import Utils from "../../../helpers/Utils";
import Config from "../../../helpers/Config";
import Question from "../../../models/Question";
import QuestionView from "../../common/QuestionView";
import User from "../../../models/User";
import DateHelper from "../../../helpers/DateHelper";
import Sched from "../../../models/Sched";
import SchedsSmallTable from "../../common/SchedsSmallTable";

export default class AdminHomePage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			notifications: [
				// 'Tomorrow full-time lesson at 13.00',
				// 'The teacher gave grades for the test work “Глагол”',
				// 'The teacher gave grades for the test work “Существительное”',
				// 'Added a new test task on the topic “Знаки препинания”',
			],
			
			checklist: [],
			
			scheds: [],
			
			currentDate: new Date(),
			
		};
		
	}
	
	// задания для ручной проверки
	checklist = {
		
		load: () => {
			
			const logName = 'AdminHomePage.checklist.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			// todo
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	// расписание
	scheds = {
		
		load: (date) => {
			
			const logName = 'AdminHomePage.scheds.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const dateFormatted = DateHelper.formatDate({
				date: date,
				format: 'y-m-d',
			});
			Logger.log(dateFormatted, 'dateFormatted', logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('scheds'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[date]': dateFormatted,
					'sort': 'time',
				},
			}).then((response) => {
				
				const logName = 'AdminHomePage.scheds.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let scheds = response.data.map(schedData => new Sched(schedData));
				Logger.log(scheds, 'scheds', logAllow);
				
				this.setState((prevState) => {
					return {
						scheds: scheds,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	componentDidMount() {
		// this.checklist.load();
		this.scheds.load(this.state.currentDate);
	}
	
	render() {
		
		return (
			
			<div className={'admin-home-page'}>
				
				<div className="row">
					
					<div className="col-xl-9 col-lg-8 col-md-7">
						
						<section className="notifications">
							
							<h2>{i18next.t("Notifications")}</h2>
							
							<div className="list">
								
								{this.state.notifications.length > 0 ? (
									
									this.state.notifications.map((text) => {
										return (
											<div className="list-item list-item-active notification">
												{text}
											</div>
										);
									})
								
								) : (
									
									<div className="list-item">
										{i18next.t("Notifications not found")}
									</div>
								
								)}
							
							</div>
						
						</section>
						
						<section className="checklist">
							
							<h2>{i18next.t("Tasks")}</h2>
							
							{this.state.checklist.length > 0 ? (
								
								this.state.checklist.map((checklistItem) => {
									
									const logName = 'AdminHomePage.render.checklist.map';
									const logAllow = 1;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									const answer = checklistItem;
									Logger.log(answer, 'answer', logAllow);
									
									const questionData = answer.question_data;
									Logger.log(questionData, 'questionData', logAllow);
									
									const question = new Question(questionData);
									Logger.log(question, 'question', logAllow);
									
									Logger.groupEnd(logAllow);
									
									return (
										<div className="list-item question-box" key={'uncheked-answer_' + answer.id}>
											<QuestionView
												question={question}
												user={this.props.user}
												answers={[answer]}
												mode={'check'}
												preloader={this.props.preloader}
												afterCheck={(isRight, response) => {
													// let checklist = this.state.checklist;
													// checklist.splice()
												}}
											/>
										</div>
									);
								})
							
							) : (
								
								<div className={'list'}>
									<div className={'list-item'}>
										{i18next.t("No tasks available")}
									</div>
								</div>
							
							)}
						
						</section>
					
					</div>
					
					<div className="col-xl-3 col-lg-4 col-md-5">
						
						<section className="calendar-box">
							
							<div className="h2">{i18next.t("Schedule")}</div>
							
							<div className="list">
								<Calendar
									locale={i18next.language}
									value={this.state.currentDate}
									onChange={(date) => {
										this.setState((prevState) => {
											return {
												currentDate: date,
											}
										});
										this.scheds.load(date);
									}}
								/>
							</div>
						
						</section>
						
						<section className="sched">
							
							<div className="list scheds-list">
								
								{this.state.scheds.length > 0 ? (
									
									<SchedsSmallTable
										scheds={this.state.scheds}
									/>
								
								) : (
									
									<div className={'list-item'}>
										{i18next.t("No schedule for selected date")}
									</div>
								
								)}
							
							</div>
						
						</section>
					
					</div>
				
				</div>
			
			</div>
		
		);
	}
	
}

AdminHomePage.propTypes = {
	user: PropTypes.instanceOf(User).isRequired,
	breadcrumbs: PropTypes.object,
	preloader: PropTypes.object,
};

AdminHomePage.defaultProps = {};