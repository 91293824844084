import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import i18next from "i18next";
import "./AlertModal.css";
import Logger from "../../helpers/Logger";

export default class AlertModal extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			// isOpen: props.isOpen,
		};
		
		this.confirmBtnRef = React.createRef()
		this.cancelBtnRef = React.createRef()
		
	}
	
	focusCancelBtn = () => {
		
		const logName = 'AlertModal.focusCancelBtn'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let cancelBtn = this.cancelBtnRef.current
		Logger.log(cancelBtn, 'cancelBtn', logAllow)
		
		if (cancelBtn) {
			cancelBtn.focus()
		}
		
		Logger.groupEnd(logAllow)
		
	}
	
	focusConfirmBtn = () => {
		
		const logName = 'AlertModal.focusConfirmBtn'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let confirmBtn = this.confirmBtnRef.current
		Logger.log(confirmBtn, 'confirmBtn', logAllow)
		
		if (confirmBtn) {
			confirmBtn.focus()
		}
		
		Logger.groupEnd(logAllow)
		
	}
	
	componentDidMount() {
		
		const logName = 'AlertModal.didMount'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		// this.focusConfirmBtn()
		// this.focusCancelBtn()
		
		Logger.groupEnd(logAllow)
		
	}
	
	render() {
		
		const logName = 'AlertModal.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<Modal
				className={'AlertModal'}
				show={this.props.isOpen}
				onHide={() => {
					if (this.props.useCancel) {
						if (this.props.cancel) {
							this.props.cancel()
						}
					} else {
						if (this.props.confirm) {
							this.props.confirm()
						}
					}
				}}
				onShow={() => {
					if (this.props.useCancel) {
						this.focusCancelBtn()
					} else {
						this.focusConfirmBtn()						
					}
				}}
				size={'sm'}
				backdrop={'static'}
				// keyboard={true}
			>
				
				{this.props.title && (
					<Modal.Header>
						<Modal.Title>
							{this.props.title}
						</Modal.Title>
					</Modal.Header>
				)}
				
				{this.props.body && (
					<Modal.Body>
						{this.props.body}
					</Modal.Body>
				)}
				
				<Modal.Footer>
					<div className="container">
						{this.props.useCancel ? (
							<div className="row">
								<div className="col-6 cancel-btn-col">
									{this.props.cancel && (
										<button 
											type={'button'}
											ref={this.cancelBtnRef}
											className={[
												'cancel-btn',
												'my-btn',
												'my-btn-wide',
												'my-btn-danger',
											].join(' ')}
											onClick={this.props.cancel}
										>{this.props.cancelText}</button>
									)}
								</div>
								<div className="col-6 confirm-btn-col">
									<button 
										type={'button'}
										ref={this.confirmBtnRef}
										className={[
											'confirm-btn',
											'my-btn',
											'my-btn-success',
										].join(' ')}
										onClick={this.props.confirm}
									>{this.props.confirmText}</button>
								</div>
							</div>
						) : (
							<div className="confirm-btn-box">
								<button 
									type={'button'}
									ref={this.confirmBtnRef}
									className={[
										'confirm-btn',
										'my-btn',
										'my-btn-success',
									].join(' ')}
									onClick={this.props.confirm}
								>{this.props.confirmText}</button>
							</div>
						)}
					</div>
				</Modal.Footer>
			
			</Modal>
		);
	}
	
}

AlertModal.propTypes = {
	isOpen: PropTypes.bool,
	title: PropTypes.any,
	body: PropTypes.any,
	cancel: PropTypes.func,
	confirm: PropTypes.func,
	useCancel: PropTypes.bool,
	cancelText: PropTypes.string,
	confirmText: PropTypes.string,
};

AlertModal.defaultProps = {
	cancelText: 'Cancel',
	confirmText: 'Ok',
};