import User from "./User";
import Logger from "../helpers/Logger";
import Utils from "../helpers/Utils";

export default class Answer {
	
	constructor(data = {}) {
		
		const logName = 'Answer.constructor';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(data, 'data', logAllow);
		
		this.id = data.id;
		this.material_id = data.material_id;
		this.question_id = data.question_id;
		
		// this.question_data = typeof data.question_data === 'string'
		// 	? JSON.parse(data.question_data)
		// 	: data.question_data;
		
		if (typeof data.question_data === 'string') {
			
			try {
				
				this.question_data = JSON.parse(data.question_data);
				
			} catch(e) {
				
				console.log('JSON.parse ERROR [' + typeof e + '] = %o', e);
				
				if (Utils.isLoc()) {
					window.alert([
						'JSON.parse ERROR: ' + e,
						'answer_id: ' + data.id,
					].join("\n\n"));
				}
				
				Logger.log(this.question_data, 'this.question_data', logAllow);
				
			}
			
		} else {
			
			this.question_data = data.question_data;
			
		}
		
		// this.answer_data = typeof data.answer_data === 'string'
		// 	? JSON.parse(data.answer_data)
		// 	: this.answer_data;
		
		if (typeof data.answer_data === 'string') {
			
			try {
				
				this.answer_data = JSON.parse(data.answer_data);
				
			} catch(e) {
				
				console.log('JSON.parse ERROR [' + typeof e + '] = %o', e);
				
				if (Utils.isLoc()) {
					window.alert([
						'JSON.parse ERROR: ' + e,
						'answer_id: ' + data.id,
					].join("\n\n"));
				}
				
				Logger.log(data.answer_data, 'data.answer_data', logAllow);
				
			}
			
		} else {
			
			this.answer_data = data.answer_data;
			
		}
		
		this.is_right = data.is_right;
		this.is_waiting = data.is_waiting;
		this.created_at = data.created_at;
		this.user = data.user ? new User(data.user) : null;
		this.comment = data.comment;
		
		this.student_files = data.student_files || [];
		this.teacher_files = data.teacher_files || [];
		this.path = data.path || {};
		
		this.grade = data.grade;
		
		Logger.groupEnd(logAllow);
		
	}
	
	hasTeacherComment = () => {
		return (this.comment && this.comment !== 'null') || this.teacher_files.length > 0;
	}
	
	getMaxPoints = () => {
		
		const logName = 'Answer.getMaxPoints'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		Logger.log(this.question_data, 'this.question_data', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return parseFloat(this.question_data?.weight)
	}
	
	getStudentPoints = () => {
		
		const maxPoints = this.getMaxPoints()
		
		let studentPoints = this.is_right == 1
			? maxPoints
			: this.grade
				? maxPoints * parseFloat(this.grade) / 100
				: 0
		
		return studentPoints
		
	}
	
}