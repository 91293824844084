import React from 'react';
import PropTypes from 'prop-types';
import Config from "../../helpers/Config";
import i18next from "i18next";

export default class AcceptedFilesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {
        return (
            <div className={'AcceptedFilesList'}>
                <div><small><b>{i18next.t("Accepted file types")}</b></small></div>
                {Config.acceptedFiles.map(data => {
                    return (
                        <div><small>{data.name} <span className="badge badge-primary file-ext-badge">{data.ext}</span></small></div>
                    );
                })}
            </div>
        );
    }

}

AcceptedFilesList.propTypes = {};

AcceptedFilesList.defaultProps = {};