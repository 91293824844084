import React from 'react';
import PropTypes from 'prop-types';

class PdfView extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		let url = this.props.url;
		
		if (!this.props.toolbar) {
			url += '#toolbar=0';
		}
		
		return (
			
			<div className={'pdf-view-box'}>
				
				<iframe src={url} className={'pdf-view'}/>
				
				{(this.props.name) &&
					<div className="h6 text-center">
						{(this.props.nameUrl) ? (
							<a href={this.props.nameUrl} target={'_blank'} className={'pdf-name pdf-link my-btn'}>
								{this.props.name}
							</a>
						) : (
							<span className={'pdf-name'}>{this.props.name}</span>
						)}
						
					</div>
				}
				
			</div>
		);
	}
	
}

PdfView.propTypes = {
	url: PropTypes.string.isRequired,
	name: PropTypes.string,
	nameUrl: PropTypes.string,
	toolbar: PropTypes.bool,
};

PdfView.defaultProps = {};

export default PdfView;