import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import User from "../../models/User";
import Group from "../../models/Group";
import Rand from "../../helpers/Rand";

import "./UserForm.css";
import ModelsPage from "./ModelsPage";
import Tree from "./Tree";

export default class UserForm extends React.Component {
	
	mode = null;
	
	constructor(props) {
		
		super(props);
		
		const model = props.model ? {...props.model} : new User();
		
		this.mode = model.id ? 'update' : 'create';
		
		if (this.mode == 'create') {
			
			model.is_active = 1;
			model.role_id = props.role_id;
			model.groups_ids = props.groups_ids || [];
			
			if (props.deps.length == 1) {
				model.deps_ids.push(props.deps[0].id);
			}
			
			if (Config.allowFormFake()) {
				model.email = Rand.email('test_', 'studerus.ru');
				model.name = Rand.name();
				model.surname = Rand.surname();
				model.patron = Rand.patron();
			}
			
		}
		
		if (Utils.isLoc()) {
			// model.restricted_course_access = 1;
		}
		
		this.state = {
			model: model,
			groups: props.groups,
			showSelectedGroupsOnly: false,
			showSelectedCoursesOnly: false,
		};
		
	}
	
	inputChange = (event) => {
		
		const logName = 'UserForm.inputChange';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		const target = event.target;
		Logger.log(target, 'target', logAllow);
		
		let attr = target.getAttribute('id');
		Logger.log(attr, 'attr', logAllow);
		
		const isChbxList = target.hasAttribute('data-chbx-list');
		Logger.log(isChbxList, 'isChbxList', logAllow);
		
		if (isChbxList) {
			
			attr = target.getAttribute('name');
			
			const array = model[attr] || [];
			Logger.log(array, 'array (before)', logAllow);
			
			const checked = target.checked;
			Logger.log(checked, 'checked', logAllow);
			
			let value = target.value;
			Logger.log(value, 'value', logAllow);
			
			if (target.getAttribute('data-value-type') === 'int') {
				value = parseInt(value);
			}
			
			const valueIndex = array.indexOf(value);
			Logger.log(valueIndex, 'valueIndex', logAllow);
			
			if (checked && valueIndex < 0) {
				array.push(value);
			} else {
				array.splice(valueIndex, 1);
			}
			
			Logger.log(array, 'array (after)', logAllow);
			
			model[attr] = array;
			
		} else {
			
			const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
			
			model[attr] = value;
			
		}
		
		Logger.log(model[attr], 'model[attr]', logAllow);
		Logger.log(model, 'model', logAllow);
		
		this.setState({
			model: model,
		});
		
		Logger.groupEnd(logAllow);
		
	}
	
	submit = (event) => {
		
		const logName = 'UserForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		event.preventDefault();
		
		const form = event.currentTarget;
		
		if (form.checkValidity()) {
			
			const model = this.state.model;
			Logger.log(model, 'model', logAllow);
			
			// if (model.role_id == Config.studentRoleId && model.groups_ids.length < 1) {
			// 	window.alert(i18next.t("Please select groups"));
			// 	return;
			// }
			
			if (
				[Config.teacherRoleId, Config.adminRoleId].includes(parseInt(model.role_id))
				&& model.deps_ids.length < 1
			) {
				window.alert(i18next.t("Please select departments"));
				return;
			}
			
			let method = this.mode == 'update' ? 'put' : 'post';
			
			let url = this.mode == 'update'
				? Utils.apiUrl('users') + '/' + model.id // update
				: Utils.apiUrl('users/custom-create') // create
			;
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: method,
				url: url,
				data: model,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				if (this.props.afterSubmit) {
					this.props.afterSubmit(response, this.mode);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
			}).catch((error) => {
				
				this.props.preloader?.hide()
				
				window.alert(error.response?.data?.message || error);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	}
	
	groups = {
		
		toggle: (groupId, useConfirm = 0) => {
			
			const logName = 'UserForm.groups.toggle';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			const user = this.state.model;
			Logger.log(user, 'user', logAllow);
			
			if (useConfirm) {
				if (!window.confirm(i18next.t("Are you shure?"))) {
					return;
				}
			}
			
			groupId = groupId.toString();
			// Logger.log(groupId, 'groupId', logAllow);
			
			let groupsIds = user.groups_ids;
			Logger.log(groupsIds, 'groupsIds', logAllow);
			
			let groupIdIndex = Array.isArray(groupsIds) ? groupsIds.indexOf(groupId) : -1;
			Logger.log(groupIdIndex, 'groupIdIndex', logAllow);
			
			let groups = user.groups;
			
			let group = Array.isArray(groups) ? groups.filter(group => group && group.id == groupId)[0] : null;
			Logger.log(group, 'group', logAllow);
			
			const groupIndex = group && Array.isArray(groups) ? groups.indexOf(group) : -1;
			Logger.log(groupIndex, 'groupIndex', logAllow);
			
			if (groupIdIndex >= 0) {
				user.groups_ids.splice(groupIdIndex, 1);
			} else {
				user.groups_ids.push(groupId);
			}
			
			if (groupIndex >= 0) {
				user.groups.splice(groupIndex, 1);
			} else if (group) {
				user.groups.push(group);
			}
			
			Logger.log(user, 'user', logAllow);
			
			this.setState((prevState) => {
				return {
					model: user,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}
		
	}
	
	render() {
		
		const logName = 'UserForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		Logger.log(model.name, 'model.name', logAllow);
		Logger.log(model.surname, 'model.surname', logAllow);
		Logger.log(model.id, 'model.id', logAllow);
		Logger.log(model.courses_ids, 'model.courses_ids', logAllow);
		Logger.log(model.restricted_course_access, 'model.restricted_course_access', logAllow);
		
		let deps = this.props.deps;
		Logger.log(deps, 'deps', logAllow);
		
		let groups = this.props.groups;
		Logger.log(groups, 'groups', logAllow);
		
		let user = this.props.user;
		Logger.log(user, 'user', logAllow);
		
		let groupsLoadParams = {
			'filter[is_active]': 1,
			'sort': 'name',
		};
		
		if (this.state.showSelectedGroupsOnly) {
			if (model.groups_ids.length > 0) {
				groupsLoadParams['filter[id][in]'] = model.groups_ids;
			} else {
				groupsLoadParams['filter[id]'] = -1;
			}
		}
		
		let coursesLoadParams = {
			'filter[is_active]': 1,
			'sort': 'name',
		};
		
		if (this.state.showSelectedCoursesOnly) {
			if (model.courses_ids.length > 0) {
				coursesLoadParams['filter[id][in]'] = model.courses_ids;
			} else {
				coursesLoadParams['filter[id]'] = -1;
			}
		}
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<form className={'UserForm'} onSubmit={this.submit}>
				
				{/*{this.props.model && (
					<>
						<label htmlFor={'is_active'}>
							<input
								id={'is_active'}
								type={'checkbox'}
								checked={model.is_active == 1}
								onChange={this.inputChange}
							/> {i18next.t("Active")}
						</label>
						<hr/>
					</>
				)}*/}
				
				{(model.id != null && user && user.isAny(['admin', 'owner'])) &&
					
					<button
						
						className={[
							'my-btn',
							'reset-password-btn',
						].join(' ')}
						
						type={'button'}
						
						onClick={(event) => {
							
							if (!window.confirm(i18next.t("Shure?"))) {
								return;
							}
							
							if (this.props.preloader) {
								this.props.preloader.show();
							}
							
							axios({
								method: 'post',
								url: Utils.apiUrl('users/reset-password'),
								data: {},
								params: {
									'accessToken': Utils.getUserToken(),
									user_id: model.id,
								},
							}).then((response) => {
								
								const logName = 'UserForm.resetPassword.ajax.done';
								const logAllow = 1;
								const logCollapsed = 0;
								
								Logger.groupStart(logName, logAllow, logCollapsed);
								
								Logger.log(response, 'response', logAllow);
								
								if (this.props.preloader) {
									this.props.preloader.hide();
								}

								let user = new User(response.data);
								user.copyInviteText()
								
								// window.alert([
								// 	i18next.t("New password sent to user's e-mail"),
								// 	'',
								// 	user.email,
								// ].join("\n"));
								
								// user.copyInviteText()
								
								Logger.groupEnd(logAllow);
								
							}).catch((error) => {
								
								if (this.props.preloader) {
									this.props.preloader.hide();
								}
								
								console.log('error.response = %o', error.response);
								
								if (error.response && error.response.data) {
									window.alert(error.response.data.message);
								} else {
									window.alert(error.message);
								}
								
							});
							
						}}
						
					>{i18next.t("Reset password")}</button>
				}
				
				{this.mode == 'update' && (
					<div className={'form-group status required'}>
						<label htmlFor={'status'}>{i18next.t("Status")}</label>
						<select
							id={'status'}
							name={'status'}
							className={'form-control'}
							value={model.status}
							onChange={this.inputChange}
							required={true}
						>
							<option value={'1'}>{i18next.t("Active")}</option>
							<option value={'0'}>{i18next.t("Not active")}</option>
						</select>
					</div>
				)}
				
				{/*{this.props.roles.length > 0 && (*/}
				<div className={'form-group role_id required'}>
					<label htmlFor={'role_id'}>{i18next.t("Role")}</label>
					<select
						id={'role_id'}
						name={'role_id'}
						className={'form-control'}
						value={model.role_id}
						onChange={this.inputChange}
						required={true}
					>
						<option value={''}></option>
						{this.props.roles.map((role, roleIndex) => {
							return (
								<option value={role.id}>{i18next.t(role.name)}</option>
							);
						})}
					</select>
				</div>
				{/*)}*/}
				
				<div className="form-group email required">
					<label htmlFor={'email'}>{i18next.t("E-mail")}</label>
					<input id={'email'}
						   type="email"
						   className={'form-control'}
						   value={model.email}
						   onChange={this.inputChange}
						   required={true}
						   disabled={model.id}
					/>
				</div>
				
				<div className="form-group surname required">
					<label htmlFor={'surname'}>{i18next.t("Last name")}</label>
					<input id={'surname'}
						   type="text"
						   className={'form-control'}
						   value={model.surname}
						   onChange={this.inputChange}
						// required={true}
					/>
				</div>
				
				<div className="form-group name required">
					<label htmlFor={'name'}>{i18next.t("First name")}</label>
					<input id={'name'}
						   type="text"
						   className={'form-control'}
						   value={model.name}
						   onChange={this.inputChange}
						// required={true}
					/>
				</div>
				
				<div className="form-group patron">
					<label htmlFor={'patron'}>{i18next.t("Middle name")}</label>
					<input
						id={'patron'}
						type={'text'}
						className={'form-control'}
						value={model.patron}
						onChange={this.inputChange}
						// required={true}
					/>
				</div>
				
				{(deps && deps.length > 0) && (
					
					<fieldset className={'deps required'}>
						
						<legend>{i18next.t("Departments")}</legend>
						
						{(model.isStudent()) &&
							<div className={'alert alert-info'}>
								{i18next.t("Для студента указание подразделений не обзательно. Достаточно выбрать группы. Студент увидит курсы тех подразделений, к которым приязаны его группы и тех, что указаны напрямую. Также есть возможность указать отдельные доступные курсы, выбрав опцию \"Ограниченный доступ к курсам\" (см. ниже).")}
							</div>
						}
						
						<Tree
							data={deps}
							itemViewer={(dep) => {
								
								const logName = 'UserForm.depsTree.itemViewer';
								const logAllow = 0;
								const logCollapsed = 0;
								
								Logger.groupStart(logName, logAllow, logCollapsed);
								
								const key = 'deps_ids_' + dep.id;
								
								Logger.log(model.deps_ids, 'model.deps_ids', logAllow);
								Logger.log(dep.id, 'dep.id', logAllow);
								
								const checked = Array.isArray(model.deps_ids) ? model.deps_ids.indexOf(dep.id.toString()) >= 0 : false;
								Logger.log(checked, 'checked', logAllow);
								
								Logger.groupEnd(logAllow);
								
								return (
									<label htmlFor={key}>
										<input
											key={key}
											id={key}
											name={'deps_ids'}
											type={'checkbox'}
											checked={checked}
											onChange={this.inputChange}
											value={dep.id}
											data-chbx-list={true}
										/> {dep.name} <small className={'text-muted'}>#{dep.id}</small>
									</label>
								);
								
							}}
							style={{
								listSyleType: 'none',
							}}
						/>
					
					</fieldset>
				
				)}
				
				<fieldset className={'group-selector'}>
					
					<legend>{i18next.t("Groups")}</legend>
					
					<label htmlFor={'selected-groups-only'}>
						<input
							id={'selected-groups-only'}
							type={'checkbox'}
							checked={this.state.showSelectedGroupsOnly}
							onChange={(event) => {
								
								let checked = event.target.checked;
								
								this.setState((prevState) => {
									return {
										showSelectedGroupsOnly: checked,
									}
								});
								
							}}
						/> {i18next.t("Selected only")}
					</label>
					
					<ModelsPage
						loadUrl={Utils.apiUrl('groups')}
						loadParams={groupsLoadParams}
						model={Group}
						preloader={this.props.preloader}
						rowOnClick={(group) => {
							this.groups.toggle(group.id);
						}}
						rowClassNameMaker={(group) => {
							
							const logName = 'UserForm.groupsModelsPage.rowClassNameMaker';
							const logAllow = 0;
							const logCollapsed = 0;
							
							Logger.groupStart(logName, logAllow, logCollapsed);
							
							Logger.log(group, 'group', logAllow);
							
							const user = this.state.model;
							Logger.log(user, 'user', logAllow);
							
							let className = '';
							
							if (Array.isArray(user.groups_ids) && user.groups_ids.includes(group.id.toString())) {
								className = 'table-success';
							}
							
							Logger.log(className, 'className', logAllow);
							
							Logger.groupEnd(logAllow);
							
							return className;
							
						}}
						instantFilter={true}
						gridViewCols={{
							id: {
								name: 'ID',
								// alias: 'id',
								filter: 'number',
								width: '150px',
							},
							name: {
								name: i18next.t("Name"),
								filter: 'string',
								filterLike: true,
							},
							deps: {
								name: i18next.t("Departments"),
								value: (group) => {
									return group.deps.length > 1 ? (
										<ol className={'pl-4'}>
											{group.deps.map((dep) => {
												return (
													<li>{dep.name}</li>
												);
											})}
										</ol>
									 ) : (group.deps[0] ? group.deps[0].name : null);
								}
							},
						}}
						afterLoad={(response, models, page) => {
							let groups = this.state.groups;
							groups = groups.concat(models);
							this.setState((prevState) => {
								return {
									groups: groups,
								}
							});
						}}
					/>
				
				</fieldset>
				
				<fieldset className={'course-picker'}>
					
					<legend>{i18next.t("Courses")}</legend>
					
					<label htmlFor={'restricted_course_access'}>
						<input
							id={'restricted_course_access'}
							type={'checkbox'}
							checked={model.restricted_course_access == 1}
							onChange={this.inputChange}
						/> {i18next.t("Restricted course access")}
					</label>
					
					{(model.restricted_course_access == 1) &&
						
						<>
							
							<br/>
							
							<label htmlFor={'selected-courses-only'}>
								<input
									id={'selected-courses-only'}
									type={'checkbox'}
									checked={this.state.showSelectedCoursesOnly}
									onChange={(event) => {
										
										let checked = event.target.checked;
										
										this.setState((prevState) => {
											return {
												showSelectedCoursesOnly: checked,
											}
										});
										
									}}
								/> {i18next.t("Selected only")}
							</label>
							
							<ModelsPage
								loadUrl={Utils.apiUrl('courses')}
								loadParams={coursesLoadParams}
								preloader={this.props.preloader}
								gridViewCols={{
									id: {
										name: 'ID',
										filter: 'number',
									},
									name: {
										name: i18next.t("Title"),
										filter: 'text',
										filterLike: true,
									},
									department_id: {
										name: i18next.t("Department ID"),
										value: (course) => {
											return [
												course.department_id,
												course.department_name,
											].join(' | ');
										},
										filter: 'number',
									},
								}}
								rowOnClick={(course) => {
									
									const logName = 'UserForm.coursePicker.rowOnClick';
									const logAllow = 0;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									Logger.log(model, 'model [before]', logAllow);
									
									if (model) {
										
										let oldCoursesIds = model.courses_ids;
										Logger.log(oldCoursesIds, 'oldCoursesIds', logAllow);
										
										Utils.arrayToggleElement(model.courses_ids, course.id);
										// Logger.log(newCoursesIds, 'newCoursesIds', logAllow);
										
										// model.courses_ids = newCoursesIds;
										// Logger.log(model, 'model [after]', logAllow);
										
										this.setState((prevState) => {
											return {
												model: model,
											}
										});
										
									}
									
									Logger.groupEnd(logAllow);
									
								}}
								rowClassNameMaker={(course) => {
									let out = [];
									let isSelected = model.courses_ids.includes(course.id);
									if (isSelected) {
										out.push('table-success');
									}
									return out.join(' ');
								}}
								// showRealoadBtn={true}
							/>
						
						</>
						
					}
				
				</fieldset>
				
				<div className="controls">
					
					<hr/>
					
					<div className="row">
						
						<div className="col-6">
							{this.props.cancel && (
								<button type={'button'}
										className={'my-btn my-btn-danger'}
										onClick={(event) => {
											event.stopPropagation();
											this.props.cancel();
										}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col-6 text-right">
							<button type={'submit'}
									className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
		
		);
		
	}
	
}

UserForm.propTypes = {
	model: PropTypes.instanceOf(User),
	afterSubmit: PropTypes.func,
	cancel: PropTypes.func,
	group_id: PropTypes.any,
	role_id: PropTypes.any,
	groups: PropTypes.arrayOf(PropTypes.instanceOf(Group)),
	preloader: PropTypes.object,
	deps: PropTypes.arrayOf(PropTypes.object),
	roles: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.instanceOf(User),
};

UserForm.defaultProps = {
	model: null,
	deps: [],
	roles: [
		User.roles[2], // student
	],
	groups: [],
};