import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import User from "../../models/User";
import Rand from "../../helpers/Rand";
import DateHelper from "../../helpers/DateHelper";
import Group from "../../models/Group";
import ModelsPage from "./ModelsPage";

export default class StudentForm extends React.Component {
	
	constructor(props) {
		
		const logName = 'StudentForm.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		super(props);
		
		let model = props.model ? {...props.model} : new User();
		Logger.log(model, 'model', logAllow);
		
		if (this.props.group_id) {
			model.groups_ids.push(this.props.group_id.toString());
		}
		
		this.state = {
			model: model,
		};
		
		// this.state.model.group_id = this.props.group_id;
		
		if (!props.model && Config.allowFormFake()) {
			
			// fake
			
			// this.state.model.email = Rand.email();
			
			this.state.model.email =
				'student_'
				+ DateHelper.dateTime(Date.now(), 'ymd', 'hms', '-')
				+ '@studerus.ru';
			
			// this.state.model.email = 'malitvin@kpfu.ru';
			
			// this.state.model.email = 'rising.flames@yandex.ru';
			this.state.model.surname = 'Тестов';
			this.state.model.name = Rand.name();
			this.state.model.patron = Rand.patron();
			
		}
		
		Logger.groupEnd(logAllow);
		
	}
	
	groups = {
		toggle: (group) => {
			const user = this.state.model;
			const groupId = group.id.toString();
			const groupIdIndex = user.groups_ids.indexOf(groupId);
			if (groupIdIndex >= 0) {
				delete user.groups_ids[groupIdIndex];
				delete user.groups[groupIdIndex];
			} else {
				user.groups_ids.push(groupId);
				user.groups.push(group);
			}
			this.setState((prevState) => {
				return {
					model: user,
				}
			});
		}
	};
	
	inputChange = (event) => {
		
		const logName = 'TeacherForm.inputChange';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		const target = event.target;
		Logger.log(target, 'target', logAllow);
		
		let attr = target.getAttribute('id');
		Logger.log(attr, 'attr', logAllow);
		
		const isChbxList = target.hasAttribute('data-chbx-list');
		Logger.log(isChbxList, 'isChbxList', logAllow);
		
		if (isChbxList) {
			
			attr = target.getAttribute('name');
			
			const array = model[attr] || [];
			Logger.log(array, 'array (before)', logAllow);
			
			const checked = target.checked;
			Logger.log(checked, 'checked', logAllow);
			
			let value = target.value;
			Logger.log(value, 'value', logAllow);
			
			if (target.getAttribute('data-value-type') === 'int') {
				value = parseInt(value);
			}
			
			const valueIndex = array.indexOf(value);
			Logger.log(valueIndex, 'valueIndex', logAllow);
			
			if (checked && valueIndex < 0) {
				array.push(value);
			} else {
				array.splice(valueIndex, 1);
			}
			
			Logger.log(array, 'array (after)', logAllow);
			
			model[attr] = array;
			
		} else {
			
			const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
			model[attr] = value;
			
		}
		
		Logger.log(model[attr], 'model[attr]', logAllow);
		Logger.log(model, 'model', logAllow);
		
		this.setState({
			model: model,
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	submit = (event) => {
		
		const logName = 'StudentForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state.model, 'this.state.model', logAllow);
		
		event.preventDefault();
		
		const form = event.currentTarget;
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let method = this.props.model ? 'put' : 'post';
			
			let url = this.props.model
				// ? Utils.apiUrl('users/update-student') // update
				? Utils.apiUrl('users') + '/' + this.props.model.id // update
				: Utils.apiUrl('users/create-student') // create
			;
			
			axios({
				method: method,
				url: url,
				data: this.state.model,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				if (this.props.afterSubmit) {
					this.props.afterSubmit(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
			}).catch((error) => {
				
				const logName = 'StudentForm.submit.ajax.catch';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				let response = error.response;
				Logger.log(response, 'response', logAllow);
				
				let msg = error;
				
				if (response.data) {
					msg = response.data.map(err => err.message).join("\n");
				}
				
				Logger.log(msg, 'msg', logAllow);
				
				if (this.props.alert) {
					this.props.alert.show(i18next.t("Error"), msg);
				} else {
					window.alert(msg);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	render() {
		
		const model = this.state.model;
		
		return (
			
			<form className={'StudentForm'} onSubmit={this.submit}>
				
				{model.role_alias !== 'student' && 
					<div className="form-group email required">
						<label htmlFor={'email'}>E-mail</label>
						<input id={'email'}
							type="email"
							className={'form-control'}
							value={model.email}
							onChange={this.inputChange}
							required={true}
						/>
					</div>
				}
				
				
				<div className="form-group surname required">
					<label htmlFor={'surname'}>{i18next.t("Last name")}</label>
					<input id={'surname'}
						   type="text"
						   className={'form-control'}
						   value={model.surname}
						   onChange={this.inputChange}
						   required={true}
					/>
				</div>
				
				<div className="form-group name required">
					<label htmlFor={'name'}>{i18next.t("First name")}</label>
					<input id={'name'}
						   type="text"
						   className={'form-control'}
						   value={model.name}
						   onChange={this.inputChange}
						   required={true}
					/>
				</div>
				
				<div className="form-group patron">
					<label htmlFor={'patron'}>{i18next.t("Middle name")}</label>
					<input id={'patron'}
						   type="text"
						   className={'form-control'}
						   value={model.patron}
						   onChange={this.inputChange}
						// required={true}
					/>
				</div>
				
				{this.props.groups.length > 0 && (
					
					<fieldset className={'groups-picker'}>
						
						<legend>{i18next.t("Groups")}</legend>
						
						{/*this.state.groups.map((group , groupIndex) => {

                            const logName = 'UserForm.groups.map';
                            const logAllow = 1;
                            const logCollapsed = 0;

                            Logger.groupStart(logName, logAllow, logCollapsed);

                            const key = 'groups_ids_' + group.id;

                            Logger.log(group, 'group', logAllow);
                            Logger.log(group.id, 'group.id', logAllow);
                            Logger.log(model.groups_ids, 'model.groups_ids', logAllow);

                            const checked = model.groups_ids.indexOf(group.id.toString()) >= 0;
                            Logger.log(checked, 'checked', logAllow);

                            Logger.groupEnd(logAllow);

                            return (
                                <div>
                                    <label htmlFor={key}>
                                        <input
                                            key={key}
                                            id={key}
                                            name={'groups_ids'}
                                            type={'checkbox'}
                                            checked={checked}
                                            onChange={this.inputChange}
                                            value={group.id}
                                            data-chbx-list={true}
                                            // data-value-type={'int'}
                                        /> {group.getName()}
                                    </label>
                                </div>
                            );
                        })*/}
						
						<div className="selected-groups list-group mb-2">
							{model.groups.map(group => {
								return (
									<div
										className={[
											'list-group-item',
											'list-group-item-action',
											// 'list-group-item-success',
											'clickable',
										].join(' ')}
										onClick={(event) => {
											// if (!window.confirm(i18next.t("Are you shure?"))) {
											//     return;
											// }
											this.groups.toggle(group);
										}}
									>{group.name} <small>#{group.id}</small></div>
								);
							})}
						</div>
						
						<ModelsPage
							loadUrl={Utils.apiUrl('groups')}
							model={Group}
							preloader={this.props.preloader}
							rowOnClick={(group) => {
								// if (!window.confirm(i18next.t("Are you shure?"))) {
								//     return;
								// }
								this.groups.toggle(group);
							}}
							rowClassNameMaker={(group) => {
								
								const logName = 'UserForm.groupsModelsPage.rowClassMaker';
								const logAllow = 1;
								const logCollapsed = 0;
								
								Logger.groupStart(logName, logAllow, logCollapsed);
								
								Logger.log(group, 'group', logAllow);
								
								const user = this.state.model;
								Logger.log(user, 'user', logAllow);
								
								let className = '';
								
								if (user.groups_ids.includes(group.id.toString())) {
									className = 'table-success';
								}
								
								Logger.log(className, 'className', logAllow);
								
								Logger.groupEnd(logAllow);
								
								return className;
								
							}}
							instantFilter={true}
							gridViewCols={{
								id: {
									name: 'ID',
									// alias: 'id',
									filter: 'number',
									width: '150px',
								},
								name: {
									name: i18next.t("Title"),
									filter: 'string',
									filterLike: true,
								},
								deps: {
									name: i18next.t("Departments"),
									value: (group) => {
										return (
											group.deps.map((dep) => {
												return (
													<div>{dep.name}</div>
												);
											})
										);
									}
								},
							}}
						/>
					
					</fieldset>
				
				)}
				
				<div className="controls mt-5">
					
					<div className="row">
						
						<div className="col-6">
							{this.props.cancel && (
								<button type={'button'}
										className={'my-btn my-btn-danger'}
										onClick={(event) => {
											this.props.cancel();
										}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col-6 text-right">
							<button type={'submit'}
									className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
		
		);
		
	}
	
}

StudentForm.propTypes = {
	model: PropTypes.instanceOf(User),
	afterSubmit: PropTypes.func,
	cancel: PropTypes.func,
	group_id: PropTypes.any,
	groups: PropTypes.arrayOf(PropTypes.instanceOf(Group)),
	preloader: PropTypes.object,
	alert: PropTypes.object,
};

StudentForm.defaultProps = {
	model: null,
};