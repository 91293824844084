import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import * as Icon from "react-bootstrap-icons";
import BackBtn from "./BackBtn";

class MaterialsPageNavPanelMd extends React.Component {
	
	constructor(props) {
		
		super(props)
		
		this.state = {}
		
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const theme = this.props.theme
		const material = this.props.material
		const themeNum = this.props.themeNum
		const materialsCounter = this.props.materialsCounter
		const themesCount = this.props.themesCount
		const backBtnTitle = this.props.backBtnTitle
		
		return (
			
			<div className={'MaterialsPageNavPanelMd'}>
				
				<div className="nav-panel theme-nav-panel page-head-box d-none d-md-block">
					
					<div className={'row'}>
						
						<div className="col-auto back-btn-col align-self-center">
							<BackBtn onClick={this.props.back} title={backBtnTitle}/>
						</div>
						
						{/*<div className={'title-col col align-self-center'}>
							
							<small className={'theme-info text-muted'}>
								{this.props.course.name} &bull; {this.props.lesson.name} &bull; {i18next.t("Theme")} {themeNum}
							</small>
							
							<h2 className={'theme-name'}>
								{theme.name}
							</h2>
							
							{(material) &&
								<small className={'material-name text-muted'}>{i18next.t("Material")} {materialsCounter} &bull; {material.name}</small>
							}
							
						</div>*/}
						
						<div className={'title-col col align-self-center'}>
							
							<small className={'theme-info text-muted'}>
								
								{this.props.course.name} &bull; {this.props.lesson.name}
								
								{(material) && (
									<> &bull; {theme.name}</>
								)}
							
							
							</small>
							
							{(material) ? (
								<h2 className={'material-name'}>
									{material.name}
								</h2>
							) : (
								<h2 className={'theme-name'}>
									{theme.name}
								</h2>
							)}
							
							{(material) &&
								<small className={'material-name text-muted'}>
									{i18next.t("Theme")} {themeNum} &bull; {i18next.t("Material")} {materialsCounter}
								</small>
							}
						
						</div>
						
						<div className={'controls-col col-12 col-md-auto text-center text-md-right mt-3 mt-md-0'}>
							
							{(themesCount > 1) &&
								<>
									<button
										type={'button'}
										className={[
											'prev-theme-btn',
											'my-btn',
											'my-btn-sm',
											// 'btn-primary',
										].join(' ')}
										onClick={this.props.showPrevTheme}
										title={[
											i18next.t("Previous theme"),
											this.props.prevTheme.name,
											(this.props.prevThemeIndex + 1) + ' ' + i18next.t("of") + ' ' + themesCount,
										].join(' | ')}
									><Icon.ChevronLeft/></button>
									
									<button
										type={'button'}
										className={[
											'next-theme-btn',
											'my-btn',
											'my-btn-sm',
											// 'btn-primary',
										].join(' ')}
										onClick={this.props.showNextTheme}
										title={[
											i18next.t("Next theme"),
											this.props.nextTheme.name,
											(this.props.nextThemeIndex + 1) + ' ' + i18next.t("of") + ' ' + themesCount,
										].join(' | ')}
									><Icon.ChevronRight/></button>
								</>
							}
							
							<button
								type={'button'}
								className={[
									'reload-theme-btn',
									'my-btn',
									'my-btn-sm',
									// 'btn-primary',
								].join(' ')}
								onClick={() => {
									this.props.materialsManager.loadList();
								}}
								title={i18next.t("Reload")}
							><Icon.ArrowCounterclockwise/></button>
							
							<button
								type={'button'}
								className={[
									'close-theme-btn',
									'my-btn',
									'my-btn-sm',
									// 'btn-primary',
								].join(' ')}
								onClick={this.props.back}
								title={this.props.backBtnTitle}
							><Icon.X/></button>
						
						</div>
					
					</div>
				
				</div>
			
			</div>
		
		);
		
	}
	
}

MaterialsPageNavPanelMd.propTypes = {
	
	preloader: PropTypes.object,
	
	course: PropTypes.object,
	theme: PropTypes.object,
	lesson: PropTypes.object,
	material: PropTypes.object,
	
	materialsCounter: PropTypes.object,
	
	themeNum: PropTypes.number,
	themesCount: PropTypes.number,
	
	nextTheme: PropTypes.object,
	nextThemeIndex: PropTypes.number,
	
	prevTheme: PropTypes.object,
	prevThemeIndex: PropTypes.number,
	
	back: PropTypes.func,
	backBtnTitle: PropTypes.string,
	
	materialsManager: PropTypes.object,
	
	showPrevTheme: PropTypes.func,
	showNextTheme: PropTypes.func,
	
}

MaterialsPageNavPanelMd.defaultProps = {}

export default MaterialsPageNavPanelMd