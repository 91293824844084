import "./AnkaraAside.css";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import LangMenu from "../../common/LangMenu";
import Config from "../../../helpers/Config";

export default class AnkaraAside extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		return (
			
			<aside className={'aside ankara-aside'}>
				
				<a
					className={'logo-box'}
					href={Config.ankaraUrl}
					target={'_blank'}
				></a>
				
				<div className="controls">
					
					{this.props.items.map((asideItem) => {
						let url = asideItem.url || '/' + asideItem.alias;
						let isCurrent = asideItem.alias === this.props.asideItemsCurrentAlias;
						return asideItem.external ? (
							<a
								key={'aside-item_' + asideItem.alias}
								href={asideItem.url}
								className={[
									'aside-control',
									asideItem.alias,
									isCurrent ? 'current' : '',
								].join(' ')}
								target={'_blank'}
								title={asideItem.name}
							></a>
						) : (
							<Link
								key={'aside-item_' + asideItem.alias}
								className={[
									'aside-control',
									asideItem.alias,
									isCurrent ? 'current' : '',
								].join(' ')}
								to={url}
								title={asideItem.name}
							>
								{(asideItem.badgeText) &&
									<span className={'badge'}>
                                        {asideItem.badgeText}
                                    </span>
								}
							</Link>
						);
					})}
				
				</div>
				
				<div className="bottom-controls">
					<LangMenu
						langs={this.props.langs}
						showCurrentLangName={false}
						changeLang={this.props.changeLang}
					/>
				</div>
			
			</aside>
		
		);
		
	}
	
}

AnkaraAside.propTypes = {
	items: PropTypes.any,
	langs: PropTypes.any,
	langCode: PropTypes.string.isRequired,
	changeLang: PropTypes.func,
	badgeText: PropTypes.string,
};

AnkaraAside.defaultProps = {};