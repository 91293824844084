import React from 'react';
import PropTypes from 'prop-types';

import "./Tree.css";

export default class Tree extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {
        return (
            <ul className={'Tree'} style={this.props.style}>
                {this.props.data.map(dataItem => {
                    const children = dataItem.children;
                    return (
                        <li>
                            {this.props.itemViewer(dataItem)}
                            {(Array.isArray(children) && children.length > 0) &&
                                <Tree
                                    data={children}
                                    itemViewer={this.props.itemViewer}
                                />
                            }
                        </li>
                    );
                })}
            </ul>
        );
    }

}

Tree.propTypes = {
    data: PropTypes.array.isRequired,
    itemViewer: PropTypes.func.isRequired,
    style: PropTypes.object,
};

Tree.defaultProps = {
    // itemViewer: (dataItem) => {
    //     return dataItem.name;
    // },
    style: {},
};