import React from 'react';
import PropTypes from 'prop-types';
import Question from "../../models/Question";
import Logger from '../../helpers/Logger';

// import './QuestionCardViewBulat.css';

class QuestionCardViewBulat extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			translationIsDisplayed: false,
			playPause: false,
			audioRef: React.createRef(),
			cardFormModalIsOpen: false,
			cardToEdit: null,
		};
		
	}
	
	cards = {
		toggletTranslationIsDisplayed: () => {
			this.setState((prevState) => {
				return {
					translationIsDisplayed: !prevState.translationIsDisplayed,
				}
			})
		},
		togglePlayPause: () => {
			this.setState((prevState) => {
				return {
					playPause: !prevState.playPause,
				}
			})
		},
		edit: (card) => {
			this.setState((prevState) => {
				return {
					cardToEdit: card,
					cardFormModalIsOpen: true,
				}				
			});
		},
		del: () => {

		}
	};
	
	componentDidMount() {
		// todo ajax
	}
	
	componentDidUpdate() {
		// if(this.state.audioRef.current.currentTime === this.state.audioRef.current.duration){
		// 	this.state.audioRef.current.pause();
		// 	this.cards.togglePlayPause();
		// }
	}

	render() {
		
		// todo bulat 2022-12-25
		
		const logName = 'QuestionCardViewBulat.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		let question = this.props.card;
		Logger.log(this.state.audioRef, 'this.state.audioRef', logAllow);
		let sound = question ? question.sounds[0] : null;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'QuestionCardViewBulat'}>
				
				<div className={'QuestionCardBody'} style={{transform: this.state.translationIsDisplayed && 'rotateY(180deg)'}}>
					
					{(question) &&
						
						<>
							
							<div className={'front side'}>
								
								<div>{question.card_front_text}</div>
								
								{(sound) &&
									<div className="media-box sound-box">
										{this.state.playPause ?
											<button
												onClick={()=>{
													this.state.audioRef.current.pause();
													this.cards.togglePlayPause();
												}}>
												<img className="sound-img color-sound-img" alt="sound"/>
											</button> :
											<button
												onClick={()=>{
													this.state.audioRef.current.play();
													this.cards.togglePlayPause();
												}}>
												<img className="sound-img" alt="sound"/>
											</button>
										}
										<audio
											className='audio-card'
											src={this.props.card ? sound.url : ""}
											ref={this.state.audioRef}
											controls={true}
										/>
									</div>
								}
								
								<div className="button-block">
									
									<button onClick={()=>{this.props.selectPrevCard()}} className='arrow-btn'>
										<img className="arrow arrow-left" alt="arrow-left"/>
									</button>
									
									<button
										className='show-hide-btn'
										onClick={()=>{this.cards.toggletTranslationIsDisplayed()}}
									>
										<span>Show translation</span>
										<img className='closed-eye' alt="closed-eye"/>
									</button>
									
									<button onClick={()=>{this.props.selectNextCard()}} className='arrow-btn'>
										<img className="arrow arrow-right" alt="arrow-right"/>
									</button>
								
								</div>
							
							</div>
							
							<div className={'back side'}>
								
								<div>{question.card_back_text}</div>
								
								{sound &&
									
									<div className="media-box sound-box">
										
										{this.state.playPause ?
											
											<button
												onClick={()=>{
													this.state.audioRef.current.pause();
													this.cards.togglePlayPause();
												}}>
												<img className="sound-img color-sound-img" alt="sound"/>
											</button>
											
											:
											
											<button
												onClick={()=>{
													this.state.audioRef.current.play();
													this.cards.togglePlayPause();
												}}>
												<img className="sound-img" alt="sound"/>
											</button>
											
										}
										
										<audio
											className={'audio-card'}
											src={sound.url}
											ref={this.state.audioRef}
											controls={true}
										/>
									
									</div>
									
								}
								
								<div className="button-block">
									
									<button onClick={()=>{this.props.selectPrevCard()}} className='arrow-btn'>
										<img className="arrow arrow-left" alt="arrow-left"/>
									</button>
									
									<button
										className='show-hide-btn'
										onClick={()=>{this.cards.toggletTranslationIsDisplayed()}}
									>
										<span className='color-text' >Hide translation</span>
										<img className='opened-eye' alt="opened-eye"/>
									</button>
									
									<button onClick={()=>{this.props.selectNextCard()}} className='arrow-btn'>
										<img className="arrow arrow-right" alt="arrow-right"/>
									</button>
								
								</div>
							
							</div>
							
						</>
						
					}
					
				</div>
				
			</div>
			
		);
		
	}
	
}

QuestionCardViewBulat.propTypes = {
	preloader: PropTypes.object,
	card: PropTypes.instanceOf(Question).isRequired,
};

QuestionCardViewBulat.defaultProps = {
	card: null
};

export default QuestionCardViewBulat;