import "./QuestionGroupForm.css";

import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import Question from "../../models/Question";
import FormDataHelper from "../../helpers/FormDataHelper";
import QuestionCardForm from './QuestionCardForm';
import FoldableList from "./FoldableList";

export default class QuestionGroupForm extends React.Component {
	
	modeCreate = 1;
	modeUpdate = 0;
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Question();
		
		if (model.id) {
			this.modeCreate = 0;
			this.modeUpdate = 1;
		}
		
		this.state = {
			model: model,
		};
		
		model.view_type_alias = 'group';
		model.need_manual_check = 0;
		model.use_shuffle = 0;
		model.material_id = this.props.materialId;
		
		if (Utils.isDev()) {
			// fake
		}
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const attr = target.getAttribute('name');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
	};
	
	submit = (event) => {
		
		const logName = 'QuestionGroupFormBulat.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		event.preventDefault();
		
		const form = event.currentTarget;
		const model = this.state.model;
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const formData = FormDataHelper.get(model);
			
			axios({
				method: this.modeUpdate ? 'put' : 'post',
				url: Utils.apiUrl('questions') + (this.state.model.id ? '/' + this.state.model.id : ''),
				data: formData,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'QuestionGroupFormBulat.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.afterSuccess) {
					this.props.afterSuccess(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				const logName = 'QuestionGroupFormBulat.submit.ajax.error';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(error, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (this.props.afterError) {
					this.props.afterError(error);
				}
				
				if (Utils.isDev(Config)) {
					window.alert(error);
				}
				
				Logger.groupEnd(logAllow);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	render() {
		
		const logName = 'QuestionGroupFormBulat.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const model = this.state.model;
		
		const cards = model.group_items;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'QuestionGroupForm2'}>
				
				<form onSubmit={this.submit}>
					
					<div className={'form-group name required'}>
						<label htmlFor={'name'}>{i18next.t("Group name")}</label>
						<input
							id={'name'}
							name={'name'}
							type={'text'}
							className={'form-control'}
							value={model.name}
							onChange={this.inputChange}
							placeholder={''}
							required={true}
						/>
					</div>
					
					<div className="controls">
						
						<div className="row">
							
							<div className="col-6">
								{this.props.cancel && (
									<button
										type={'button'}
										className={'my-btn my-btn-danger'}
										onClick={(event) => {
											this.props.cancel();
										}}
									>{i18next.t("Cancel")}</button>
								)}
							</div>
							
							<div className="col-6 text-right">
								<button
									type={'submit'}
									className={'my-btn my-btn-primary'}
								>{i18next.t("Save")}</button>
							</div>
						
						</div>
					
					</div>
				
				</form>
				
				{(cards.length > 0) &&
					
					<div className="cards">
						
						{cards.map((card, cardIndex) => {
							
							let cardNum = cardIndex + 1;
							
							// let cardName = i18next.t("Card #{{id}}", {id: card.id});
							let cardName = i18next.t("Card {{num}} | #{{id}}", {num: cardNum, id: card.id});
							
							return (
								<FoldableList name={cardName}>
									<QuestionCardForm
										model={card}
										groups={this.props.groups}
										useGroupSelector={false}
										materialId={this.props.materialId}
										afterSuccess={(response, updatedCard) => {
											
											cards[cardIndex] = updatedCard;
											
											this.setState((prevState) => {
												return {
													model: model,
												}
											});
											
											if (this.props.afterCardSuccess) {
												this.props.afterCardSuccess(response, updatedCard);
											}
											
										}}
										afterUploadUpdSuccess={(response, soundBindId, data, soundBindIndex) => {
											
											// const logName = 'QuestionGroupForm.card.afterUploadSuccess';
											// const logAllow = 1;
											// const logCollapsed = 0;
											//
											// Logger.groupStart(logName, logAllow, logCollapsed);
											//
											// Logger.log(response, 'response', logAllow);
											// Logger.log(soundBindId, 'soundBindId', logAllow);
											// Logger.log(data, 'data', logAllow);
											// Logger.log(soundBindIndex, 'soundBindIndex', logAllow);
											//
											// let updatedSoundData = response.data;
											// Logger.log(updatedSoundData, 'updatedSoundData', logAllow);
											//
											// let sounds = model.sounds;
											// Logger.log(sounds, 'sounds [before]', logAllow);
											//
											// if (updatedSoundData.active == 1) {
											// 	sounds[soundBindIndex] = updatedSoundData;
											// } else {
											// 	sounds.splice(soundBindIndex, 1);
											// }
											//
											// Logger.log(sounds, 'sounds [after]', logAllow);
											//
											// model.sounds = sounds;
											//
											// this.setState((prevState) => {
											// 	return {
											// 		model: model,
											// 	}
											// });
											//
											// Logger.groupEnd(logAllow);
											
										}}
										cancel={this.props.cancel}
										preloader={this.props.preloader}
									/>
								</FoldableList>
							);
							
						})}
					
					</div>
					
				}
			
			</div>
		
		);
		
	}
	
}

QuestionGroupForm.propTypes = {
	
	model: PropTypes.object,
	groups: PropTypes.arrayOf(PropTypes.instanceOf(Question)),
	materialId: PropTypes.number.isRequired,
	
	afterSuccess: PropTypes.func,
	afterError: PropTypes.func,
	afterCardSuccess: PropTypes.func,
	afterCardError: PropTypes.func,
	
	cancel: PropTypes.func,
	
	preloader: PropTypes.object,
	
};

QuestionGroupForm.defaultProps = {
	model: null,
	groups: [],
};