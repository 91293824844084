import React from 'react';
import PropTypes from 'prop-types';
import ArrowLeftBlack from '../../img/arrow-left-black.svg';


export default class BackBtn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {

        let extraClassNameString = Array.isArray(this.props.className)
            ? this.props.className.join(' ')
            : this.props.className;

        return (
            <span
                className={[
                    'back-btn',
                    'clickable',
                ].join(' ') + (extraClassNameString ? ' ' + extraClassNameString : '')}
                onClick={this.props.onClick}
                title={this.props.title || ''}
            ><img src={ArrowLeftBlack} alt={'back-btn'}/></span>
        );

    }

}

BackBtn.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

BackBtn.defaultProps = {};