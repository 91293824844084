import Rand from "../helpers/Rand"

/**
 * Шаблон верного ответа к заданию
 * 
 * @typedef {Object} QuestionRightAnswerTemplate
 * @property {string} id
 * @property {string} content Содержание ответа
 * @property {int} grade Процент от максимального балла, который даётся за этот ответ
 */
export default class QuestionRightAnswerTemplateModel {

	constructor ({
		id = null,
		content = null,
		grade = null,
	} = {}) {
		this.id = id || Rand.id()
		this.content = content
		this.grade = grade
	}

}