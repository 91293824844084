import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import User from "../../models/User";
import Utils from "../../helpers/Utils";
import axios from "axios";
import Logger from "../../helpers/Logger";
import StudentView from "./StudentView";

class StudentViewPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			student: null,
		};
	}
	
	getModelId = () => {
		return this.props.match.params.id;
	}
	
	loadModel = () => {
		
		const logName = 'StudentViewPage.loadModel';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let modelId = this.getModelId();
		Logger.log(modelId, 'modelId', logAllow);
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('users') + '/' + modelId,
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'StudentViewPage.loadModel.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			let student = new User(response.data);
			
			this.setState((prevState) => {
				return {
					student: student,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			console.log('error.response = %o', error.response);
			
			if (error.response && error.response.data) {
				window.alert(error.response.data.message);
			} else {
				window.alert(error.message);
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
	}
	
	componentDidMount() {
		this.loadModel();
	}
	
	render() {
		
		return (
			
			<div className={'StudentViewPage'}>
				
				{(this.state.student) &&
					<StudentView
						student={this.state.student}
						user={this.props.user}
						preloader={this.props.preloader}
					/>
				}
				
			</div>
			
		);
		
	}
	
}

StudentViewPage.propTypes = {
	
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	
	user: PropTypes.instanceOf(User).isRequired,
	preloader: PropTypes.object,
	
};

StudentViewPage.defaultProps = {};

export default withRouter(StudentViewPage);