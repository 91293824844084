import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Rand from "../../helpers/Rand";
import QuestionVariant from "./QuestionVariant";
import i18next from "i18next";
import Question from "../../models/Question";
import axios from "axios";
import Utils from "../../helpers/Utils";
import * as Icon from 'react-bootstrap-icons';
import UploadsGrid from "./UploadsGrid";
import Material from "../../models/Material";
import TextEditor from "./TextEditor";
import FormDataHelper from "../../helpers/FormDataHelper";
import Formula from "./Formula";
import AcceptedFilesList from "./AcceptedFilesList";
// import {Editor} from "@tinymce/tinymce-react";
import DateHelper from "../../helpers/DateHelper";

import 'draft-js/dist/Draft.css';
import "./QuestionForm.css";
import FoldableList from "./FoldableList";
import {Editor} from "@tinymce/tinymce-react";

export default class QuestionForm2 extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		const logName = 'QuestionForm2.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(props, 'props', logAllow);
		
		const model = props.model ? {...props.model} : new Question();
		Logger.log(model, 'model', logAllow);
		
		this.state = {
			
			model: model,
			
			// предпросмотр формул
			formula: '',
			// formula: 'sum_(i=1)^n i^3=((n(n+1))/2)^2',
			
			editor: 'custom',
			
		};
		
		let allowFormFake = Config.allowFormFake();
		Logger.log(allowFormFake, 'allowFormFake', logAllow);
		
		if (!model.view_type_alias) {
			model.view_type_alias = 'string';
		}
		
		if (!model.weight) {
			model.weight = 1;
		}
		
		if (allowFormFake) {
			model.about = 'Задание ' + DateHelper.dateTime();
		}
		
		// Logger.log(this.state.variants, 'this.state.variants', logAllow);
		
		this.imageFileInputRef = React.createRef();
		this.soundFileInputRef = React.createRef();
		this.differentFilesInputRef = React.createRef();
		this.aboutEditorRef = React.createRef();
		this.tablesTinyEditorRef = React.createRef();
		
		Logger.groupEnd(logAllow);
		
	}
	
	inputChange = (event) => {
		
		const logName = 'QuestionForm2.inputChange';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);

		const model = this.state.model;
		
		const target = event.target;
		
		const attr = target.getAttribute('id');
		
		let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
		
		// файлы в качестве ответа
		if (attr === 'view_type_alias' && value === 'files') {
			model.files_as_answers = 1;
			model.need_manual_check = 1;
		}
		
		// блокируем опцию "формулы", если тип - подстановка (с полями ввода в описании задания)
		if (attr === 'view_type_alias' && value.indexOf('completion_') === 0) {
			model.has_formulas = 0;
		}
		
		// автоматически включаем опцию "ручная проверка", если выбран тип "свободный ввод"
		if (attr === 'view_type_alias' && value === 'string') {
			model.need_manual_check = 1;
		}
		
		// блокируем возможность задать  ручную проверку
		if (attr === 'view_type_alias' && ['accordances'].includes(value)) {
			model.need_manual_check = 0;
		}
		
		this.setState((prevState) => {
			return {
				model: model,
			}
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	getFormData = () => {
		
		const logName = 'QuestionForm2.getFormData';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let model = this.state.model;
		
		const formData = FormDataHelper.get(model);
		
		Logger.groupEnd(logAllow);
		
		return formData;
		
	};
	
	submit = (event) => {
		
		const logName = 'QuestionForm2.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		if (this.props.preloader) {
			// this.props.preloader.show();
		}
		
		event.preventDefault();
		
		const form = event.currentTarget;
		Logger.log(form, 'form', logAllow);
		
		if (form.checkValidity()) {
			
			let formData = this.getFormData();
			Logger.log(formData, 'formData', logAllow);
			
			let formDataValues = FormDataHelper.getValues(formData);
			Logger.log(formDataValues, 'formDataValues', logAllow);
			
			const model = this.state.model;
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: model.id ? 'put' : 'post',
				url: Utils.apiUrl('questions' + (model.id ? '/' + model.id : '')),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				data: formData,
			})
				.then((response) => {
					
					const logName = 'QuestionForm2.submit.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.log(response, 'response', logAllow);
					
					this.props.afterSubmit(response, this.state.material_id);
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	variants = {
		
		add: (event) => {
			
			const logName = 'QuestionForm2.variants.add';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			// костыль для серверного рендеринга придётся ради совместимости юзать и здесь
			let newVariantKey = Rand.id();
			
			let newVariant = {
				position: "", // для completion_select
				name: "",
				is_right: 0,
			};
			
			let variants = this.state.variants;
			
			variants[newVariantKey] = newVariant;
			
			this.setState((prevState) => {
				return {
					variants: variants,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (event, alias) => {
			
			const logName = 'QuestionForm2.variants.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!window.confirm('Уверены?')) {
				return;
			}
			
			let variants = this.state.variants;
			
			delete variants[alias];
			
			this.setState((prevState) => {
				return {
					variants: variants,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		upd: (event, alias) => {
			
			const logName = 'QuestionForm2.variants.upd';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(alias, 'alias', logAllow);
			
			const target = event.target;
			
			// const value = target.type === 'checkbox' ? (target.checked ? "1" : "0") : target.value;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const attr = target.getAttribute('id');
			
			let newVariants = this.state.variants;
			
			newVariants[alias][attr] = value;
			
			Logger.log(newVariants, 'newVariants', logAllow);
			
			this.setState((prevState) => {
				return {
					variants: newVariants,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	accordances = {
		
		add: () => {
			
			const logName = 'QuestionForm2.accordances.add';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let newAccordanceAlias = Rand.id();
			
			let newAccordance = {
				left: "",
				right: "",
			};
			
			let newAccordances = this.state.accordances;
			newAccordances[newAccordanceAlias] = newAccordance;
			
			this.setState((prevState) => {
				return {
					accordances: newAccordances,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (alias) => {
			
			const logName = 'QuestionForm2.accordances.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(alias, 'alias', logAllow);
			
			if (!window.confirm(i18next.t("Are you shure?"))) {
				return;
			}
			
			let newAccordances = this.state.accordances;
			Logger.log(newAccordances, 'newAccordances', logAllow);
			
			delete newAccordances[alias];
			
			this.setState((prevState) => {
				return {
					accordances: newAccordances,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		upd: (event, alias) => {
			
			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.getAttribute('id');
			
			let newAccordances = this.state.accordances;
			newAccordances[alias][name] = value;
			
			this.setState({
				accordances: newAccordances,
			});
			
		}
		
	};
	
	uploads = {
		
		del: (uploadBindId) => {
			
			const logName = 'QuestionForm2.images.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!window.confirm(i18next.t("Are you shure?"))) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('upload-binds/' + uploadBindId),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				data: {
					'active': 0,
				},
			}).then((response) => {
				
				const logName = 'QuestionForm2.images.del';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let newUpload = response.data;
				let newModel = this.state.model;
				
				Question.uploadAttrs.forEach((attr) => {
					newModel[attr].forEach((uploadBind, index) => {
						if (uploadBind.id == uploadBindId) {
							newModel[attr][index] = newUpload;
						}
					});
				});
				
				this.setState((prevState) => {
					return {
						model: newModel,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				// todo catch
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpd: (response, id, data) => {
			
			let newUpload = response.data;
			let newModel = this.state.model;
			
			Question.uploadAttrs.forEach((attr) => {
				newModel[attr].forEach((upload, index) => {
					if (upload.id == id) {
						newModel[attr][index] = newUpload;
					}
				});
			});
			
			this.setState((prevState) => {
				return {
					model: newModel,
				}
			});
			
		}
		
	};
	
	editors = [
		'custom',
		// 'draft',
		'textarea',
		// 'cke5',
	];
	
	render() {
		
		const logName = 'QuestionForm2.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		// Logger.log(this.refs, 'this.refs', logAllow);
		
		const model = this.state.model;
		
		const formulaText = this.state.formula;
		Logger.log(formulaText, 'formulaText', logAllow);
		
		const formulaHelp = Question.formulaHelp;
		
		const stylesCount = 8;
		
		const styles = [
			0, // стиль не выбран
		];
		
		for (let i = 1; i <= stylesCount; i++) {
			styles.push(i);
		}
		
		const currentBgStyleAlias = model.bg_style_alias;
		const currentBorderStyleAlias = model.border_style_alias;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<form className={'QuestionForm2'} onSubmit={this.submit}>
				
				<div className={'need_manual_check__chbx-box'}>
					<label htmlFor={'need_manual_check'}>
						<input
							id={'need_manual_check'}
							type={'checkbox'}
							onChange={this.inputChange}
							checked={model.need_manual_check == 1}
							disabled={!model.isManualCheckAllowed() || model.isManualCheckRequired()}
						/> {i18next.t("Manual check")}
					</label>
				</div>
				
				<div className={'files_as_answers__chbx-box'}>
					<label htmlFor={'files_as_answers'}>
						<input
							id={'files_as_answers'}
							type={'checkbox'}
							onChange={this.inputChange}
							checked={model.files_as_answers == 1}
							disabled={model.view_type_alias == 'files'}
						/> {i18next.t("Files as answers")}
					</label>
				</div>
				
				<div className={'has_formulas__chbx-box'}>
					<label htmlFor={'has_formulas'}>
						<input
							id={'has_formulas'}
							type={'checkbox'}
							onChange={this.inputChange}
							checked={model.has_formulas == 1}
							disabled={model.view_type_alias.indexOf('completion_') === 0}
						/> {i18next.t("Formulas")}
					</label>
				</div>
				
				{/*<div className={'use_shuffle__chbx-box'}>
                    <label htmlFor={'use_shuffle'}>
                        <input
                            id={'use_shuffle'}
                            type={'checkbox'}
                            onChange={this.inputChange}
                            checked={model.use_shuffle == 1}
                            disabled={model.view_type_alias.indexOf('completion_') === 0}
                        /> {i18next.t("Перемешивать")}
                    </label>
                </div>*/}
				
				<hr/>
				
				<div className={'form-group material_id required'}>
					<label htmlFor={'material_id'}>{i18next.t("Material")}</label>
					{/*<input id={'material_id'}
                           type="number"
                           min={1}
                           step={1}
                           className={'form-control'}
                           value={model.material_id}
                           onChange={this.inputChange}
                           required={true}
                    />*/}
					<select
						id="material_id"
						className={[
							'form-control',
						].join(' ')}
						value={model.material_id}
						onChange={this.inputChange}
						required={true}
					>
						<option value=""></option>
						{this.props.materials.map((material, materialIndex) => {
							return (
								<option value={material.id}>{material.name}</option>
							);
						})}
					</select>
				</div>
				
				{/*<div className={'form-group group_id'}>
					<label htmlFor={'group_id'}>{i18next.t("Group of tasks")}</label>
					<select
						id={'group_id'}
						name={'group_id'}
						className={'form-control'}
						onChange={this.inputChange}
						value={model.group_id}
					>
						<option value="">{i18next.t("None")}</option>
						
					</select>
				</div>*/}
				
				<div className={'form-group view-type required'}>
					<label htmlFor={'view_type_alias'}>{i18next.t("Type")}</label>
					<select
						id={'view_type_alias'}
						className={'form-control'}
						onChange={this.inputChange}
						value={model.view_type_alias}
						required
					>
						<option value=""></option>
						{Object.getOwnPropertyNames(Question.viewTypes).map((viewTypeAlias) => {
							return <option key={Rand.id()}
										   value={viewTypeAlias}>{Question.viewTypes[viewTypeAlias]}</option>
						})}
					</select>
				</div>
				
				<div className={'form-group answers_type_alias required'}>
					<label htmlFor={'answers_type_alias'}>{i18next.t("Answer type")}</label>
					<select
						id={'answers_type_alias'}
						name={'answers_type_alias'}
						className={'form-control'}
						onChange={this.inputChange}
						value={model.answers_type_alias}
						required={true}
					>
						<option value=""></option>
						{Object.getOwnPropertyNames(Question.answersTypes).map((answerTypeAlias, answerTypeAliasIndex) => {
							let answerTypeName = Question.answersTypes[answerTypeAlias];
							return (
								<option value={answerTypeAlias}>{answerTypeName}</option>
							);
						})}
					</select>
				</div>
				
				<div className={'form-group weight'}>
					<label htmlFor={'weight'}>{i18next.t("Points")}</label>
					<input id={'weight'}
						   name={'weight'}
						   type="number"
						   min={1}
						   // max={}
						   step={0.1}
						   className={'form-control'}
						   value={model.weight}
						   onChange={this.inputChange}
						   required={false}
					/>
					{/*<label htmlFor={'is_weight_visible'}>
                        <input
                            id={'is_weight_visible'}
                            name={'is_weight_visible'}
                            type={'checkbox'}
                            onChange={this.inputChange}
                            checked={model.is_weight_visible == 1}
                        /> {i18next.t("Отображать для студентов")}
                    </label>*/}
				</div>
				
				<div className={'form-group name'}>
					<label htmlFor={'name'}>{i18next.t("Title")}</label>
					<input type={'text'}
						   id={'name'}
						   value={model.name}
						   onChange={this.inputChange}
						   className={'form-control'}
					/>
				</div>
				
				<div className={'form-group about'}>
					
					<label htmlFor={'about'}>{i18next.t("Main text")}</label>
					
					{(Utils.isLoc()) ? (
						<select
							name="editor-picker"
							id="editor-picker"
							onChange={(event) => {
								this.setState((prevState) => {
									return {
										editor: event.target.value,
									}
								});
							}}
						>
							{this.editors.map((editorAlias, editorAliasIndex) => {
								return (
									<option value={editorAlias}>{editorAlias}</option>
								);
							})}
						</select>
					) : ''}
					
					{(this.state.editor === 'textarea') ? (
						<textarea
							id={'about'}
							className={'form-control'}
							value={model.about}
							onChange={this.inputChange}
							rows={10}
						/>
					) : ''}
					
					{(this.state.editor === 'custom') ? (
						<TextEditor
							text={model.about}
							onChange={(text) => {
								this.setState((prevState) => {
									return {
										about: text,
									}
								});
							}}
							hideControls={model.has_formulas ? 'field' : 'formula'}
						/>
					) : ''}
					
					{model.view_type_alias && model.view_type_alias.indexOf('completion_') >= 0 ? (
						<div>
							<small className={'form-text text-muted'}>
								{i18next.t("To make the input field inside of the text, please type the code like this")}
								<code>_right answer\</code>
								{/*<br/><code>_</code> - {i18next.t("открывающий символ")}*/}
								{/*<br/><code>\</code> - {i18next.t("закрывающий символ")}*/}
								<br/><code>{i18next.t("right answer")}</code> - {i18next.t("the expected content of the input field")}
								<br/>{i18next.t("Count of the underscore symbols regulates the width of the input field")}
								{/*<br/>{i18next.t("Ширина вычисляется как")} <var>{i18next.t("колчество подчёркиваний")} * 30px</var>.*/}
								<br/>{i18next.t("Example: input letters")}: с<code>_о\</code>бак<code>_а\</code>
								<br/>{i18next.t("Example: input words")}: мама
								с <code>___мылом\</code> мыла <code>___раму\</code>
							</small>
						</div>
					) : ''}
					{model.has_formulas ? formulaHelp : ''}
				</div>
				
				{/* редактор формул с предпросмотром */}
				{model.has_formulas ? (
					
					<div className={'form-group formula-editor-box'}>
						<label htmlFor={'formula'}>
							{i18next.t("Formula editor with preview")}
						</label>
						<Formula
							showEditor={true}
						/>
					</div>
				
				) : ''}
				
				{/* доп. контент (таблицы и тд) */}
				<div className="tables-editor">
					<label>{i18next.t("Additional text")}</label>
					<Editor
						id={'tinymce_' + Rand.id()}
						onInit={(evt, editor) => {
							this.tablesTinyEditorRef.current = editor;
						}}
						initialValue={model.tables}
						init={Config.tinyMceModalConfig}
						// init={Config.tinyMceMainConfig}
					/>
				</div>
				
				{/* свободный ввод // правильный ответ */}
				{['string'].indexOf(model.view_type_alias) >= 0 && (
					
					<div className={'form-group right-answer'}>
						
						<label htmlFor={'answer'}>{i18next.t("Right answer")}</label>
						
						{(['string'].indexOf(model.answers_type_alias) >= 0) && (
							<input
								type={'text'}
								name={'answer'}
								id={'answer'}
								className={'form-control'}
								onChange={this.inputChange}
								value={model.answer}
							/>
						)}
						
						{(['text', 'asciiMath_and_text'].indexOf(model.answers_type_alias) >= 0) && (
							<>
                                <textarea
									name={'answer'}
									id={'answer'}
									rows={5}
									className={'form-control'}
									onChange={this.inputChange}
									value={model.answer}
								></textarea>
								{model.has_formulas ? formulaHelp : ''}
							</>
						)}
						
						{(['int'].indexOf(model.answers_type_alias) >= 0) && (
							<input
								name="answer"
								id="answer"
								type={'number'}
								step={1}
								className={'form-control'}
								onChange={this.inputChange}
								value={model.answer}
							/>
						)}
						
						{(['float'].indexOf(model.answers_type_alias) >= 0) && (
							<input
								name="answer"
								id="answer"
								type={'number'}
								step={0.1}
								className={'form-control'}
								onChange={this.inputChange}
								value={model.answer}
							/>
						)}
						
						{(['date'].indexOf(model.answers_type_alias) >= 0) && (
							<input
								name="answer"
								id="answer"
								type={'date'}
								className={'form-control'}
								onChange={this.inputChange}
								value={model.answer}
							/>
						)}
						
						{(['asciiMath'].indexOf(model.answers_type_alias) >= 0) && (
							<>
								<Formula
									text={model.answer}
									showEditor={true}
									onChange={(text) => {
										this.setState((prevState) => {
											return {
												answer: text,
											}
										});
									}}
								/>
							</>
						)}
					
					</div>
				
				)}
				
				{/* варианты */}
				{['poll'].indexOf(model.view_type_alias) >= 0 &&
					
					<fieldset className={'poll-spec-fields'}>
						
						<legend>{i18next.t("Answer options")}</legend>
						
						<div className="form-group use_shuffle mt-2">
							<label htmlFor={'use_shuffle'}>
								<input
									id={'use_shuffle'}
									type="checkbox"
									checked={model.use_shuffle > 0}
									onChange={this.inputChange}
								/>&nbsp;{i18next.t("Shuffle answer options")}
							</label>
						</div>
						
						<div className="variants">
							
							{typeof model.variants === 'object' &&
								
								Object.getOwnPropertyNames(model.variants).map((alias, index) => {
									
									const logName = 'QuestionForm2.variant';
									const logAllow = 0;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									let variant = model.variants[alias];
									Logger.log(variant, 'variant', logAllow);
									
									Logger.groupEnd(logAllow);
									
									return alias !== 'tpl' ? <QuestionVariant
										alias={alias}
										variant={variant}
										onChange={this.variants.upd}
										onDelete={this.variants.del}
										num={index + 1}
										isFormula={model.has_formulas}
									/> : '';
									
								})
								
							}
							
							<div className="controls">
								<button type={'button'}
										className={'my-btn my-btn-primary'}
										onClick={(event) => {
											this.variants.add();
										}}
								>+ {i18next.t("Answer option")}</button>
							</div>
						
						</div>
					
					</fieldset>
					
				}
				
				{/* соответвия */}
				{['accordance'].indexOf(model.view_type_alias) >= 0 &&
					
					<fieldset className={'accordance-spec-fields mt-3'}>
						
						<legend>{i18next.t("Accordances")}</legend>
						
						{Object.getOwnPropertyNames(model.accordances).map((alias, index) => {
							
							let accordance = model.accordances[alias];
							
							return (
								<div className={'accordance mb-2'}>
									
									<div className="row">
										
										<div className="col-5">
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text">{index + 1}</div>
												</div>
												<input id={'left'}
													   type="text"
													   value={accordance.left}
													   className="form-control"
													   onChange={(event) => {
														   this.accordances.upd(event, alias);
													   }}
													   placeholder={i18next.t("What")}
												/>
											</div>
										</div>
										
										<div className="col-5">
											<input id={'right'}
												   type="text"
												   value={accordance.right}
												   className="form-control"
												   onChange={(event) => {
													   this.accordances.upd(event, alias);
												   }}
												   placeholder={i18next.t("To what")}
											/>
										</div>
										
										<div className="col-2">
											<div className="controls text-right">
												<button className="my-btn my-btn-danger"
														type={'button'}
														onClick={(event) => {
															this.accordances.del(alias);
														}}
												><Icon.X/></button>
											</div>
										</div>
									
									</div>
								
								</div>
							);
							
						})}
						
						<div className="controls">
							<button type={'button'}
									className={'my-btn my-btn-primary'}
									onClick={this.accordances.add}
							>+ {i18next.t("Accordance")}</button>
						</div>
					
					</fieldset>
					
				}
				
				{/* подстановка (таблица) */}
				{['completion'].indexOf(model.view_type_alias) >= 0 &&
					
					<div className={'completion-spec-fields'}>
						
						<fieldset className={'completion-accordances'}>
							
							<legend>{i18next.t("Accordances")}</legend>
							
							{Object.getOwnPropertyNames(model.accordances).map((alias, index) => {
								
								let accordance = model.accordances[alias];
								
								return (
									<div className={'mb-2'}>
										
										<div className="row">
											
											<div className="col-5">
												<div className="input-group">
													<div className="input-group-prepend">
														<div className="input-group-text">{index + 1}</div>
													</div>
													<input
														id={'left'}
														type="text"
														placeholder={i18next.t("Original text")}
														value={accordance.left}
														className="form-control"
														onChange={(event) => {
															this.accordances.upd(event, alias);
														}}
													/>
												</div>
											</div>
											
											<div className="col-5">
												<div className="input-group">
													<div className="input-group-prepend">
														<div className="input-group-text">{index + 1}</div>
													</div>
													<input
														id={'right'}
														type="text"
														placeholder={i18next.t("Result text")}
														value={accordance.right}
														className="form-control"
														onChange={(event) => {
															this.accordances.upd(event, alias);
														}}
													/>
												</div>
											</div>
											
											<div className="col-2">
												<div className="controls text-right">
													<button className="my-btn my-btn-danger"
															type={'button'}
															onClick={(event) => {
																this.accordances.del(alias);
															}}
													><Icon.X/></button>
												</div>
											</div>
										
										</div>
									
									</div>
								);
								
							})}
							
							<div className="controls">
								<button type={'button'}
										className={'my-btn my-btn-primary'}
										onClick={this.accordances.add}
								>+ {i18next.t("Accordance")}</button>
							</div>
						
						</fieldset>
					
					</div>
					
				}
				
				{/* подстановка (выбор из списка) */}
				{['completion_select'].indexOf(model.view_type_alias) >= 0 &&
					
					<div className={'completion-select-spec-fields'}>
						
						<div className="variants">
							
							{typeof model.variants === 'object' &&
								
								Object.getOwnPropertyNames(model.variants).map((alias, index) => {
									
									let variant = model.variants[alias];
									
									return alias !== 'tpl' ? <QuestionVariant
										alias={alias}
										variant={variant}
										onChange={this.variants.upd}
										onDelete={this.variants.del}
										num={index + 1}
										showPositionCol={true}
									/> : '';
									
								})
								
							}
							
							<div className="controls">
								<button type={'button'}
										className={'my-btn my-btn-primary'}
										onClick={(event) => {
											this.variants.add();
										}}
								>+ {i18next.t("Answer option")}</button>
							</div>
						
						</div>
					
					</div>
					
				}
				
				{/* сортировка */}
				{['order'].indexOf(model.view_type_alias) >= 0 &&
					
					<fieldset className={'accordance-spec-fields'}>
						
						<legend>{i18next.t("Content to sort")}</legend>
						
						{Object.getOwnPropertyNames(model.accordances).map((alias, index) => {
							
							let accordance = model.accordances[alias];
							
							return accordance !== 'tpl' ? (
								<div className={'test-question-accordance mb-2'} data-alias={alias}>
									<div className="input-group">
										<div className="input-group-prepend">
											<div className="input-group-text">{index + 1}</div>
										</div>
										<input id={'left'}
											   type="text"
											   value={accordance.left}
											   className="form-control"
											   onChange={(event) => {
												   this.accordances.upd(event, alias);
											   }}
										/>
										<div className="input-group-append">
											<button
												className="my-btn my-btn-danger"
												type={'button'}
												onClick={(event) => {
													this.accordances.del(alias);
												}}
											><Icon.X/></button>
										</div>
									</div>
								</div>
							) : '';
							
						})}
						
						<div className="controls">
							<button type={'button'}
									className={'my-btn my-btn-primary'}
									onClick={this.accordances.add}
							>+ {i18next.t("Content to sort item")}</button>
						</div>
					
					</fieldset>
					
				}
				
				<hr/>
				
				<fieldset className={'images'}>
					
					<legend>{i18next.t("Images")}</legend>
					
					{model && model.images && model.images.length > 0 && (
						<UploadsGrid
							uploads={model.images}
							afterUpd={this.uploads.afterUpd}
							showControls={true}
							preloader={this.props.preloader}
						/>
					)}
					
					<div className={'form-group'}>
						<input
							id={'images'}
							type={'file'}
							accept={'image/*'}
							ref={this.imageFileInputRef}
							multiple={true}
						/>
					</div>
				
				</fieldset>
				
				<hr/>
				
				<fieldset className={'sounds'}>
					
					<legend>{i18next.t("Audio")}</legend>
					
					{model && model.sounds && model.sounds.length > 0 && (
						<UploadsGrid
							uploads={model.sounds}
							afterUpd={this.uploads.afterUpd}
							showControls={true}
							preloader={this.props.preloader}
						/>
					)}
					
					<div className="form-group">
						<input
							id={'sounds'}
							type={'file'}
							accept={'audio/*'}
							ref={this.soundFileInputRef}
							multiple={true}
						/>
					</div>
				
				</fieldset>
				
				<hr/>
				
				<fieldset className={'different_files'}>
					
					<legend>{i18next.t("Documents")}</legend>
					
					{model && model.different_files && model.different_files.length > 0 && (
						<UploadsGrid
							uploads={model.different_files}
							afterUpd={this.uploads.afterUpd}
							showControls={true}
							preloader={this.props.preloader}
						/>
					)}
					
					<div className="form-group">
						<input
							id={'different_files'}
							type={'file'}
							ref={this.differentFilesInputRef}
							multiple={true}
							accept={Config.getAcceptedFilesMimes()}
						/>
					</div>
					
					<AcceptedFilesList/>
				
				</fieldset>
				
				<hr/>
				
				{(this.props.skins.length > 0) &&
					
					<fieldset className={'styles'}>
						
						<div className={'bg-styles table-responsive'}>
							
							<FoldableList
								name={i18next.t("Background style")}
							>
								
								<table className={'table table-bordered table-hover'}>
									
									<thead>
									
									<th>{i18next.t("Style of background")}</th>
									
									{this.props.skins.map(skin => {
										return (
											<th>{skin.name}</th>
										);
									})}
									
									</thead>
									
									<tbody>
									
									{styles.map(styleAlias => {
										
										let className = [
											'clickable',
										]
										
										if (styleAlias == currentBgStyleAlias) {
											className.push('table-success');
										}
										
										className = className.join(' ');
										
										return (
											
											<tr
												className={className}
												onClick={(event) => {
													model.bg_style_alias = styleAlias;
													this.setState((prevState) => {
														return {
															model: model,
															bg_style_alias: styleAlias,
														}
													});
												}}
											>
												
												<td>
													{styleAlias == 0 ? i18next.t("No") : styleAlias}
												</td>
												
												{this.props.skins.map(skin => {
													return (
														<td>
															<div className={'skin-' + skin.alias}>
																<div className={'style-preview bg-' + styleAlias}></div>
															</div>
														</td>
													);
												})}
											
											</tr>
										
										);
										
									})}
									
									</tbody>
								
								</table>
								
							</FoldableList>
						
						</div>
						
						<div className={'border-styles table-responsive mt-3'}>
							
							<FoldableList
								name={i18next.t("Border style")}
							>
								
								<table className={'table table-bordered table-hover'}>
									
									<thead>
									
									<th>{i18next.t("Style of borders")}</th>
									
									{this.props.skins.map(skin => {
										return (
											<th>{skin.alias}</th>
										);
									})}
									
									</thead>
									
									<tbody>
									
									{styles.map(styleAlias => {
										
										let className = [
											'clickable',
										]
										
										if (styleAlias == currentBorderStyleAlias) {
											className.push('table-success');
										}
										
										className = className.join(' ');
										
										return (
											
											<tr
												className={className}
												onClick={(event) => {
													model.border_style_alias = styleAlias;
													this.setState((prevState) => {
														return {
															model: model,
															border_style_alias: styleAlias,
														}
													});
												}}
											>
												
												<td>
													{styleAlias == 0 ? i18next.t("No") : styleAlias}
												</td>
												
												{this.props.skins.map(skin => {
													return (
														<td>
															<div className={'skin-' + skin.alias}>
																<div
																	className={'style-preview border-style-preview border-' + styleAlias}></div>
															</div>
														</td>
													);
												})}
											
											</tr>
										
										);
										
									})}
									
									</tbody>
								
								</table>
								
							</FoldableList>
						
						</div>
						
						<hr/>
					
					</fieldset>
					
				}
				
				<div className="controls">
					<div className="row">
						<div className="col">
							{this.props.cancel && (
								<button type={'button'} onClick={this.props.cancel} className={'my-btn my-btn-danger'}>
									{i18next.t("Cancel")}
								</button>
							)}
						</div>
						<div className="col text-right">
							<button type={'submit'}
									className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					</div>
				</div>
			
			</form>
		
		);
	}
	
}

QuestionForm2.propTypes = {
	afterSubmit: PropTypes.func.isRequired,
	model: PropTypes.instanceOf(Question),
	viewTypes: PropTypes.object,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
	materialId: PropTypes.any,
	materials: PropTypes.arrayOf(PropTypes.instanceOf(Material)),
	skins: PropTypes.array,
};

QuestionForm2.defaultProps = {
	viewTypes: Question.viewTypes,
	preloader: {},
	skins: [],
	materials: [],
};