import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";

export default class ListItem extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const logName = 'ListItem.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		let className = [
			'list-item',
		];
		
		let propsClassName = this.props.className;
		
		if (propsClassName) {
			propsClassName = Array.isArray(propsClassName) ? propsClassName : propsClassName.split(' ');
			className = className.concat(propsClassName);
		}
		
		if (this.props.active) {
			className.push('list-item-active');
		}
		
		className = className.join(' ');
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div
				className={className}
				title={this.props.title}
				onClick={this.props.onClick}
			>
				
				{this.props.progress > 0 && (
					<div className="progress-box">
						<div className="progress" style={{
							width: this.props.progress + '%',
						}}></div>
					</div>
				)}
				
				<div className="content">
					{this.props.children}
				</div>
				
			</div>
			
		);
		
	}
	
}

ListItem.propTypes = {
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	title: PropTypes.string,
	progress: PropTypes.any,
	active: PropTypes.any,
	onClick: PropTypes.func,
};

ListItem.defaultProps = {};