import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import Logger from "../../helpers/Logger";
import User from "../../models/User";
import Modal from "react-bootstrap/Modal";
import TrainerForm from "./TrainerForm";
import axios from "axios";
import Utils from "../../helpers/Utils";
import Config from "../../helpers/Config";
import Material from "../../models/Material";
import * as Icon from 'react-bootstrap-icons';

import "./TrainersPage.css";
import "./TrainerPreview.css";
import TrainerViewPage from "./TrainerViewPage";

export default class TrainersPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            trainers: [], // Material[]
            trainerToEdit: null,
            trainerToEditIndex: null,
            trainerFormModalIsOpen: false,
            currentTrainer: null,
            trainersShowDeleted: false,
        };
    }

    trainers = {

        load: () => {

            const logName = 'TrainersPage.load';
            const logAllow = 1;
            const logCollapsed = 0;

            Logger.groupStart(logName, logAllow, logCollapsed);

            if (this.props.preloader) {
                this.props.preloader.show();
            }

            let params = {
                'accessToken': Utils.getUserToken(),
                'filter[type_alias]': Material.TYPE_ALIAS_TRAINER_TEST,
                'sort': 'sort',
            };

            if (!this.state.trainersShowDeleted) {
                params['filter[is_del]'] = 0;
            }

            if (this.props.user.isAny(['student'])) {
                params['filter[is_public]'] = 1;
            }

            axios({
                method: 'get',
                url: Utils.apiUrl('materials'),
                data: {},
                params: params,
            }).then((response) => {

                const logName = 'TrainersPage.trainers.load.ajax.done';
                const logAllow = 1;
                const logCollapsed = 0;

                Logger.groupStart(logName, logAllow, logCollapsed);

                Logger.log(response, 'response', logAllow);

                let trainers = response.data.map(trainerData => new Material(trainerData));
                Logger.log(trainers, 'trainers', logAllow);

                this.setState((prevState) => {
                    return {
                        trainers: trainers,
                    }
                });

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Logger.groupEnd(logAllow);

            }).catch((error) => {

                Utils.axiosErrorAlert(error);

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

            });

            Logger.groupEnd(logAllow);

        },

        formModalToggle: () => {
            this.setState((prevState) => {
                return {
                    trainerFormModalIsOpen: !prevState.trainerFormModalIsOpen,
                }
            });
        },

        upd: (id, data, index) => {

            const logName = 'TrainersPage.trainers.upd';
            const logAllow = 1;
            const logCollapsed = 0;

            Logger.groupStart(logName, logAllow, logCollapsed);

            if (this.props.preloader) {
                this.props.preloader.show();
            }

            axios({
                method: 'put',
                url: Utils.apiUrl('materials') + '/' + id,
                data: data,
                params: {
                    'accessToken': Utils.getUserToken(),
                },
            }).then((response) => {

                const logName = 'TrainersPage.trainers.upd.ajax.done';
                const logAllow = 1;
                const logCollapsed = 0;

                Logger.groupStart(logName, logAllow, logCollapsed);

                Logger.log(response, 'response', logAllow);

                this.state.trainers[index] = new Material(response.data);

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Logger.groupEnd(logAllow);

            }).catch((error) => {

                Utils.axiosErrorAlert(error);

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

            });

            Logger.groupEnd(logAllow);

        },

        afterSubmit: (response, mode) => {

            const logName = 'TrainersPage.trainers.afterSubmit';
            const logAllow = 1;
            const logCollapsed = 0;

            Logger.groupStart(logName, logAllow, logCollapsed);

            Logger.log(response, 'response', logAllow);

            const trainer = new Material(response.data);

            const trainers = this.state.trainers;

            switch (mode) {

                case 'create':
                    trainers.push(trainer);
                    break;

                case 'update':
                    trainers[this.state.trainerToEditIndex] = trainer;
                    break;

            }

            this.setState((prevState) => {
                return {
                    trainerFormModalIsOpen: false,
                }
            });

            Logger.groupEnd(logAllow);

        },

        saveSort: (ids) => {

            const logName = 'TrainersPage.trainers.saveSort';
            const logAllow = 1;
            const logCollapsed = 0;

            Logger.groupStart(logName, logAllow, logCollapsed);

            if (this.props.preloader) {
                this.props.preloader.show();
            }

            axios({
                method: 'post',
                url: Utils.apiUrl('materials/save-sort'),
                data: {
                    ids: ids,
                },
                params: {
                    'accessToken': Utils.getUserToken(),
                },
            }).then((response) => {

                const logName = 'TrainersPage.trainers.saveSort.ajax.done';
                const logAllow = 1;
                const logCollapsed = 0;

                Logger.groupStart(logName, logAllow, logCollapsed);

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Logger.log(response, 'response', logAllow);

                Logger.groupEnd(logAllow);

            }).catch((error) => {

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Utils.axiosErrorAlert(error);

            });

            Logger.groupEnd(logAllow);

        },

        moveUp: (index) => {
            let trainers = this.state.trainers;
            Utils.arrayMoveUp(trainers, index);
            let ids = trainers.map(trainer => trainer.id);
            this.trainers.saveSort(ids);
        },

        moveDown: (index) => {
            let trainers = this.state.trainers;
            Utils.arrayMoveDown(trainers, index);
            let ids = trainers.map(trainer => trainer.id);
            this.trainers.saveSort(ids);
        },

        getOverallProgress: () => {

            const logName = 'TrainersPage.getOverallProgress';
            const logAllow = 1;
            const logCollapsed = 0;

            Logger.groupStart(logName, logAllow, logCollapsed);

            let out = 0;

            let wanted = 0;
            let gained = 0;

            let trainers = this.state.trainers;
            Logger.log(trainers, 'trainers', logAllow);

            trainers.forEach((trainer) => {
                wanted += trainer.max_score_value;
                gained += trainer.current_score_value
            });

            Logger.log(wanted, 'wanted', logAllow);
            Logger.log(gained, 'gained', logAllow);

            out = Math.ceil(gained / wanted * 100);

            Logger.groupEnd(logAllow);

            return out;

        },

    };
    
    componentDidMount() {
        this.trainers.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.trainersShowDeleted !== prevState.trainersShowDeleted) {
            this.trainers.load();
        }
    }
    
    render() {

        const logName = 'TrainersPage.render';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        Logger.log(this.props, 'this.props', logAllow);
        Logger.log(this.state, 'this.state', logAllow);

        const overallProgress = this.trainers.getOverallProgress();
        Logger.log(overallProgress, 'overallProgress', logAllow);

        Logger.groupEnd(logAllow);

        return (

            <div className={'TrainersPage'}>

               <div className="content-root">

                   {this.state.currentTrainer ? (

                       <TrainerViewPage
                           trainer={this.state.currentTrainer}
                           backBtnOnClick={() => {
                               this.setState((prevState) => {
                                   return {
                                       currentTrainer: null,
                                   }
                               });
                           }}
                           preloader={this.props.preloader}
                           user={this.props.user}
                           alert={this.props.alert}
                       />

                   ) : (

                       <div className={'trainers-list'}>

                           <div className="page-head-box nav-box">
                               <h2 className={'page-title'}>Studerus Game</h2>
                           </div>

                           <div className="top-panel">

                               <div className="row h-100 align-items-center">

                                   <div className="left-col col-6">

                                       <div className="overall-progress-box">

                                           <div className={'box overall-progress-value'}>
                                               <div className="inner">
                                                   <span>{overallProgress || 0}%</span>
                                               </div>
                                           </div>

                                           <div className={'overall-progress-label'}>
                                               {i18next.t("Overall progress")}
                                           </div>

                                       </div>

                                   </div>

                                   <div className="right-col col-6 text-right">

                                       <div className="appstore-link-box">
                                           <a className={'appstore appstore-link'} href={Config.trainersAppStoreUrl} target={'_blank'}>
                                               {/*<img src={appstoreVioletImg} alt="appstore link image"/>*/}
                                           </a>
                                       </div>

                                   </div>

                               </div>

                           </div>

                           <div className="list">

                               {(this.props.user.can('manageTrainers')) &&

                                   <div className="controls box-line">

                                       <button
                                           type={'button'}
                                           className={[
                                               'my-btn',
                                               // 'my-btn-sm',
                                           ].join(' ')}
                                           onClick={(event) => {
                                               this.setState((prevState) => {
                                                   return {
                                                       trainerToEdit: null,
                                                       trainerFormModalIsOpen: true,
                                                   }
                                               });
                                           }}
                                       >+ {i18next.t("Add trainer")}</button>

                                       {(this.props.user.can('showRemovedContent')) &&
                                           <>
                                               <label htmlFor={'trainersShowDeleted'}>
                                                   <input
                                                       id={'trainersShowDeleted'}
                                                       type={'checkbox'}
                                                       checked={this.state.trainersShowDeleted}
                                                       onChange={(event) => {
                                                           this.setState((prevState) => {
                                                               return {
                                                                   trainersShowDeleted: event.target.checked,
                                                               }
                                                           });
                                                       }}
                                                   /> {i18next.t("Show archived")}
                                               </label>
                                           </>
                                       }

                                   </div>

                               }

                               <div className="trainers row">

                                   {this.state.trainers.map((trainer, index) => {

                                       // if (this.props.user.is(['student']) && trainer.children.length < 1) {
                                       //     return '';
                                       // }

                                       let icon = trainer.getIcon();

                                       return (

                                           <div className="trainer-preview-box col-xl-4 col-lg-6 col-md-6">

                                               <div
                                                   className={[
                                                       'trainer-preview',
                                                       trainer.is_public == 1 ? 'trainer-public' : 'trainer-not-public',
                                                       trainer.is_del == 1 ? 'trainer-deleted' : 'trainer-not-deleted',
                                                       'align-items-center',
                                                   ].join(' ')}
                                                   onClick={(event) => {
                                                       this.setState((prevState) => {
                                                           return {
                                                               currentTrainer: trainer,
                                                           }
                                                       });
                                                   }}
                                               >

                                                   {(icon) ? (
                                                       <>
                                                           <div className="row align-items-center">
                                                               <div className={'icon-col col-6 col-md-5'}>
                                                                   <img src={icon.url} alt="icon" className={'icon'}/>
                                                               </div>
                                                               <div className={'name-col col-6 col-md-7'}>
                                                                   {trainer.name}
                                                               </div>
                                                           </div>
                                                       </>
                                                   ) : (
                                                       <>
                                                           <div className="name">
                                                               {trainer.name}
                                                           </div>
                                                       </>
                                                   )}


                                               </div>

                                               {(this.props.user.can('manageTrainers')) &&

                                                   <div className="controls trainer-controls trainer-bottom-controls">

                                                       <button
                                                           type={'button'}
                                                           className={[
                                                               'my-btn',
                                                               'my-btn-sm',
                                                               'edit-btn',
                                                           ].join(' ')}
                                                           onClick={(event) => {
                                                               this.setState((prevState) => {
                                                                   return {
                                                                       trainerToEdit: trainer,
                                                                       trainerToEditIndex: index,
                                                                       trainerFormModalIsOpen: true,
                                                                   }
                                                               });
                                                           }}
                                                           title={i18next.t("Edit")}
                                                       ><Icon.Pencil/></button>

                                                       {(this.state.trainers.length > 1) &&
                                                           <>
                                                               <button
                                                                   type={'button'}
                                                                   className={[
                                                                       'my-btn',
                                                                       'my-btn-sm',
                                                                       'move-up-btn',
                                                                   ].join(' ')}
                                                                   onClick={(event) => {
                                                                       this.trainers.moveUp(index);
                                                                   }}
                                                                   title={i18next.t("Move up")}
                                                               ><Icon.ArrowUp/></button>

                                                               <button
                                                                   type={'button'}
                                                                   className={[
                                                                       'my-btn',
                                                                       'my-btn-sm',
                                                                       'move-down-btn',
                                                                   ].join(' ')}
                                                                   onClick={(event) => {
                                                                       this.trainers.moveDown(index);
                                                                   }}
                                                                   title={i18next.t("Move down")}
                                                               ><Icon.ArrowDown/></button>
                                                           </>
                                                       }

                                                       {(trainer.is_del == 0) &&
                                                           <>
                                                               {trainer.is_public == 1 ? (
                                                                   <button
                                                                       type={'button'}
                                                                       className={[
                                                                           'my-btn',
                                                                           'my-btn-sm',
                                                                           'unpublic-btn',
                                                                       ].join(' ')}
                                                                       onClick={(event) => {
                                                                           this.trainers.upd(trainer.id, {is_public: 0}, index);
                                                                       }}
                                                                       title={i18next.t("Hide from students")}
                                                                   ><Icon.EyeSlash/></button>
                                                               ) : (
                                                                   <button
                                                                       type={'button'}
                                                                       className={[
                                                                           'my-btn',
                                                                           'my-btn-sm',
                                                                           'public-btn',
                                                                       ].join(' ')}
                                                                       onClick={(event) => {
                                                                           this.trainers.upd(trainer.id, {is_public: 1}, index);
                                                                       }}
                                                                       title={i18next.t("Show for students")}
                                                                   ><Icon.Eye/></button>
                                                               )}
                                                           </>
                                                       }

                                                       {trainer.is_del == 1 ? (
                                                           <button
                                                               type={'button'}
                                                               className={[
                                                                   'my-btn',
                                                                   'my-btn-sm',
                                                                   'undel-btn',
                                                               ].join(' ')}
                                                               onClick={(event) => {
                                                                   this.trainers.upd(trainer.id, {is_del: 0}, index);
                                                               }}
                                                               title={i18next.t("Return from archive")}
                                                           ><Icon.ArrowCounterclockwise/></button>
                                                       ) : (
                                                           <button
                                                               type={'button'}
                                                               className={[
                                                                   'my-btn',
                                                                   'my-btn-sm',
                                                                   'del-btn',
                                                               ].join(' ')}
                                                               onClick={(event) => {
                                                                   this.trainers.upd(trainer.id, {is_del: 1}, index);
                                                               }}
                                                               title={i18next.t("Move to archive")}
                                                           ><Icon.Trash/></button>
                                                       )}

                                                   </div>

                                               }

                                           </div>

                                       );

                                   })}

                               </div>

                           </div>

                       </div>

                   )}

               </div>

                <div className="modals">

                    <Modal
                        className={[
                            'trainer-form-modal',
                            'wide-modal',
                        ].join(' ')}
                        show={this.state.trainerFormModalIsOpen}
                        onHide={this.trainers.formModalToggle}
                        size={'lg'}
                    >

                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.trainerToEdit ? (
                                    i18next.t("Edit trainer #{{id}}", {
                                        id: this.state.trainerToEdit.id,
                                    })
                                ) : (
                                    i18next.t("Add trainer")
                                )}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <TrainerForm
                                model={this.state.trainerToEdit}
                                cancel={this.trainers.formModalToggle}
                                afterSubmit={this.trainers.afterSubmit}
                                preloader={this.props.preloader}
                                user={this.props.user}
                            />

                        </Modal.Body>

                    </Modal>

                </div>

            </div>

        );
    }
    
}

TrainersPage.propTypes = {
    user: PropTypes.instanceOf(User),
    preloader: PropTypes.object,
    alert: PropTypes.object,
};

TrainersPage.defaultProps = {};