import i18next from "i18next";

export default class Department {
	
	static types = [
		{
			'id': 1,
			'name': 'University',
		},
		{
			'id': 2,
			'name': 'Institute',
		},
		{
			'id': 3,
			'name': 'Faculty',
		},
		{
			'id': 4,
			'name': 'Chair',
		},
	];
	
	constructor(data = {}) {
		this.id = data.id;
		this.name = data.name;
		this.about = data.about;
		this.parent_id = data.parent_id;
		this.type_id = data.type_id;
		this.childen = data.childen || [];
		this.max_students = data.max_students;
		this.max_teachers = data.max_teachers;
		this.max_admins = data.max_admins;
	}
	
	getName = () => {
		// noinspection JSCheckFunctionSignatures
		return this.name || i18next.t("Department #{{id}}", {'id': this.id});
	};
	
	static flatList = (deps, excludedIds = [], out = [], level = 0, parent = null) => {
		deps.forEach(dep => {
			if (!excludedIds.includes(dep.id)) {
				dep.level = level;
				// if (parent) {
				//     dep.path = dep.path || [];
				//     dep.path.push(parent.name);
				// }
				out.push(dep);
				out = Department.flatList(dep.children, excludedIds, out, level + 1, parent = dep);
			}
		});
		return out;
	};
	
}