import './QuestionCardView.css';
import './QuestionCardViewFlip.css';

import React from 'react';
import PropTypes from 'prop-types';
import Question from "../../models/Question";
import Logger from '../../helpers/Logger';
import i18next from "i18next";
import ListItem from "./ListItem";
import Material from "../../models/Material";
import User from "../../models/User";

class QuestionCardView extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			isFlipped: false,
			isPlaying: false,
			editModalIsOpen: false,
		};
		
		this.playerRef = React.createRef();
		
	}
	
	player = {
		
		get: () => {
			return this.playerRef.current;
		},
		
		play: (url) => {
			
			let player = this.player.get();
			player.src = url;
			player.play();
			
			this.setState((prevState) => {
				return {
					isPlaying: true,
				}
			});
			
		},
		
		pause: () => {
			
			let player = this.player.get();
			player.pause();
			
			this.setState((prevState) => {
				return {
					isPlaying: false,
				}
			});
			
		},
		
	}
	
	cards = {
		
		flip: () => {
			
			this.playerRef.current.pause();
			
			this.setState((prevState) => {
				return {
					isFlipped: !prevState.isFlipped,
					isPlaying: false,
				}
			});
			
		},
		
		toggleIsPlaying: () => {
			this.setState((prevState) => {
				return {
					isPlaying: !prevState.isPlaying,
				}
			})
		},
		
		edit: () => {
			this.setState((prevState) => {
				return {
					editModalIsOpen: true,
				}
			});
		},
		
		del: () => {
			// todo del card
		}
		
	};
	
	componentDidMount() {
		
		let player = this.player.get();
		
		if (player) {
			
			player.addEventListener('ended', () => {
				this.setState((prevState) => {
					return {
						isPlaying: false,
					}
				});
			});
			
		}
		
	}
	
	componentDidUpdate() {}

	render() {
		
		const logName = 'QuestionCardView.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		let user = this.props.user;
		
		let card = this.props.card;
		
		let cssClassData = ['QuestionCardView'];
		
		if (this.state.isFlipped) {
			cssClassData.push('flipped');
		}
		
		if (this.state.isPlaying) {
			cssClassData.push('playing');
		}
		
		let frontSound = card ? card.sounds[0] : null;
		let backSound = card ? card.sounds[1] : null;
		
		let prevCardBtn = this.props.prevCard ? (
			<button onClick={() => {
				
				this.props.prevCard();
				
				this.setState((prevState) => {
					return {
						isFlipped: false,
						isPlaying: false,
					}
				});
				
			}} className={'nav-btn prev-card-btn'}></button>
		) : null;
		
		let nextCardBtn = this.props.nextCard ? (
			<button onClick={() => {
				
				this.props.nextCard();
				
				this.player.pause();
				
				this.setState((prevState) => {
					return {
						isFlipped: false,
					}
				});
				
			}} className={'nav-btn next-card-btn'}></button>
		) : null;
		
		let flipCardBtn = (
			<button
				className={'flip-btn'}
				onClick={this.cards.flip}
			>
				{(this.state.isFlipped) ? (
					<>
						<span className={'color-text'}>{i18next.t("Rotate")}</span>
						<img className={'eye opened-eye'} alt={'opened-eye'}/>
					</>
				) : (
					<>
						<span className={'regular-text'}>{i18next.t("Rotate")}</span>
						<img className={'eye closed-eye'} alt={'closed-eye'}/>
					</>
				)}
			</button>
		);
		
		let navControls = (
			
			<div className="card-nav-controls">
				
				<div className="row">
					
					<div className="col-3 nav-btn-col prev-btn-col">
						{prevCardBtn}
					</div>
					
					<div className="col-6 flip-btn-col">
						{flipCardBtn}
						{(user.can('debugInfo')) &&
							<div className="debug-info">#{card.id}</div>
						}
					</div>
					
					<div className="col-3 nav-btn-col next-btn-col">
						{nextCardBtn}
					</div>
					
				</div>
				
			</div>
			
		);
		
		let cardsCount = this.props.cardsCount;
		let progressView = null;
		
		if (cardsCount > 1) {
			
			let cardIndex = this.props.cardIndex;
			let cardNum = cardIndex + 1;
			let progress = cardNum / cardsCount * 100;
			
			progressView = (
				<ListItem progress={progress} className={'cards-group-progress'}>
					<div>{cardNum + ' / ' + cardsCount}</div>
				</ListItem>
			);
			
		}
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={cssClassData.join(' ')}>
				
				{(card) &&
					
					<>
						
						<audio
							className={'card-audio d-none'}
							ref={this.playerRef}
							controls={false}
						/>
						
						<div className={'flipper'}>
							
							<div className={'side front'}>
								
								{progressView}
								
								<div className={'card-text'}>
									{(card.card_front_text) ? (
										<>{card.card_front_text}</>
									) : (
										<>...</>
									)}
								</div>
								
								<div
									className={'media-box front-media-box' + (frontSound ? '' : ' no-sound')}
									onClick={() => {
										
										if (frontSound) {
											
											let player = this.playerRef.current;
											
											if (this.state.isPlaying) {
												player.pause();
											} else {
												player.src = frontSound.url;
												player.play();
											}
											
											this.cards.toggleIsPlaying();
										}
										
									}}
								></div>
								
								{navControls}
							
							</div>
							
							<div className={'side back'}>
								
								{progressView}
								
								<div className={'card-text'}>
									{(card.card_back_text) ? (
										<>{card.card_back_text}</>
									) : (
										<>...</>
									)}
								</div>
								
								<div
									className={'media-box back-media-box' + (backSound ? '' : ' no-sound')}
									onClick={() => {
										
										if (backSound) {
											
											let player = this.playerRef.current;
											
											if (this.state.isPlaying) {
												player.pause();
											} else {
												player.src = backSound.url;
												player.play();
											}
											
											this.cards.toggleIsPlaying();
										}
										
									}}
								></div>
									
								{navControls}
								
							</div>
							
						</div>
						
					</>
					
				}
				
			</div>
			
		);
		
	}
	
}

QuestionCardView.propTypes = {
	
	user: PropTypes.instanceOf(User).isRequired,
	
	card: PropTypes.instanceOf(Question),
	cardIndex: PropTypes.number,
	cardsCount: PropTypes.number,
	
	nextCard: PropTypes.func,
	prevCard: PropTypes.func,
	
	showNavControls: PropTypes.bool,
	
	preloader: PropTypes.object,
	
};

QuestionCardView.defaultProps = {
	card: null,
};

export default QuestionCardView;