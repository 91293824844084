import React from 'react'
import PropTypes from 'prop-types'
import i18next from "i18next";

import BackBtn from "./BackBtn";
import ListItem from "./ListItem";

class MaterialsPageNavPanelXs extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		const course = this.props.course
		const lesson = this.props.lesson
		const theme = this.props.theme
		const material = this.props.material
		
		const materialsManager = this.props.materialsManager
		const materials = materialsManager.get()
		
		return (
			
			<div className={'MaterialsPageNavPanelXs'}>
				
				<div className="nav-panel mobile-theme-nav-panel page-head-box d-md-none">
							
					<small className={'text-muted'}>
						<b>{i18next.t("Course")}</b>: {course.name}
						<br/><b>{i18next.t("Lesson")}</b>: {lesson.name}
						{/* <br/>{i18next.t("Theme")} {this.props.themeNum} */}
					</small>
					
					<h2>
						<BackBtn 
							onClick={this.props.back} 
							title={this.props.backBtnTitle}
						/> {this.props.theme.name}
					</h2>
					
					{material ? (
						
						<>
							
							<div className="list current-material-preview-box">
								
								<ListItem
									onClick={() => {
										
										// this.setState((prevState) => {
										// 	return {
										// 		material: null,
										// 	}
										// });
										
										materialsManager.setCurrent(null)
										
									}}
								>
									
									<div className="row align-items-center">
										
										<div className="col name-col">
											{material.name}
										</div>
										
										<div className="col-auto change-btn-col">
											<div className="change-btn">
												{i18next.t("Select")}
											</div>
										</div>
										
									</div>
									
								</ListItem>
								
							</div>
						
						</>
					
					) : (
						
						<>
							
							<div className="list materials-list materials-list-mobile">
								
								{materials.length > 0 ? (
									
									<>
										{materials.map((material, materialIndex) => {
											return (
												<ListItem
													onClick={() => {
														materialsManager.setCurrent(material)
													}}
												>{material.name}</ListItem>
											);
										})}
									</>
								
								) : (
									
									<>
										{i18next.t("Materials not found")}
									</>
								
								)}
							
							</div>
						
						</>
					
					)}
				
				</div>
				
			</div>
			
		)
		
	}
	
}

MaterialsPageNavPanelXs.propTypes = {
	
	preloader: PropTypes.object,
	
	course: PropTypes.object,
	theme: PropTypes.object,
	lesson: PropTypes.object,
	material: PropTypes.object,
	
	themeNum: PropTypes.any,
	
	back: PropTypes.func,
	backBtnTitle: PropTypes.string,
	
	materialsList: PropTypes.array,
	
}

MaterialsPageNavPanelXs.defaultProps = {
	
	materialsList: [],
	
}

export default MaterialsPageNavPanelXs