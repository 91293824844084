import './UserMaterialPage.css'

import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import User from "../../models/User";
import axios from "axios";
import Utils from "../../helpers/Utils";
import Material from "../../models/Material";
import Question from "../../models/Question";
import QuestionView from "./QuestionView";
import Logger from "../../helpers/Logger";
import Answer from "../../models/Answer";
import i18next from "i18next";
import {Link} from "react-router-dom";

class UserMaterialPage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			material: null,
			variant: null,
			answers: null,
			user: null,
			maxPoints: '???',
			studentPoints: '???',
		};
		
	}
	
	getUserId = () => {
		return this.props.match.params.userId;
	}
	
	getMaterialId = () => {
		return this.props.match.params.materialId;
	}
	
	// load material and answers
	loadData = () => {
		
		let userId = this.getUserId();
		let materialId = this.getMaterialId();
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('materials/answers'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				userId: userId,
				materialId: materialId,
			},
		}).then((response) => {
			
			const logName = 'UserMaterialPage.loadData.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let material = new Material(response.data.material);
			let variant = response.data.variant ? new Question(response.data.variant) : null;
			let answers = response.data.answers.map(x => new Answer(x));
			
			let questions = variant ? variant.children : material.getQuestions()
			Logger.log(questions, 'questions', logAllow)
			
			let maxPoints = 0
			let studentPoints = 0
			
			questions.forEach((question) => {
				
				Logger.log(question, 'question', logAllow)
				
				maxPoints += parseFloat(question.weight)
				
			})
			
			answers.forEach((answer) => {
				let answerPoints = answer.getStudentPoints()
				studentPoints += answerPoints
			})
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			this.setState((prevState) => {
				return {
					material: material,
					answers: answers,
					variant: variant,
					user: new User(response.data.user),
					maxPoints: maxPoints,
					studentPoints: studentPoints,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Utils.axiosErrorAlert(error);
			
		});
		
	}
	
	componentDidMount() {
		this.loadData();
	}
	
	render() {
		
		const logName = 'UserMaterialPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let material = this.state.material;
		Logger.log(material, 'material', logAllow);
		
		let variant = this.state.variant;
		
		// let questions = variant 
		// 	? variant.children
		// 	: material
		// 		? material.questions
		// 		: []
		
		let questions = material 
			// ? material.getQuestions(variant?.id)
			? variant
				? variant.children
				: material.getQuestions()
			: []
			
		// questions = questions || []
			
		Logger.log(questions, 'questions', logAllow);
		
		let answers = this.state.answers;
		Logger.log(answers, 'answers', logAllow);
		
		let stateUser = this.state.user;
		
		let stateUserGroupsView = stateUser ? stateUser.groups.map((groupModel) => {
			return groupModel.name;
		}).join(' | ') : '???';
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'UserMaterialPage'}>
				
				{/*Material #{this.getMaterialId()} by user #{this.getUserId()}*/}
				
				{(material) ? (
					<>
						<div className="material-info">
							
							<h1 className={'h2 user-info'}>
								<Link
									to={`/student/${stateUser.id}`}
									target={'_blank'}
								>
									{stateUser.getDisplayName()} <small className={'debug-info'}>#{stateUser.id}</small>
								</Link>
							</h1>
							
							<small className={'text-muted small'}>{i18next.t("Groups")}: {stateUserGroupsView}</small>
							
							<hr/>
							
							<div className="material-path text-muted small">
								<span>{material.course_name}</span>
								&nbsp;/ <span>{material.lesson_name}</span>
								&nbsp;/ <span>{material.theme_name}</span>
							</div>
							
							{/*<h1 className={'material-name h2'}>*/}
							{/*	{material.name}{variant ? ` | ${i18next.t("Variant")} ${variant.name}` : ''} <small className={'debug-info'}>#{material.id}</small>*/}
							{/*</h1>*/}
							
							<div className={'material-name h2'}>
								<Link
									to={'/courses'}
									onClick={(event) => {
										material.setAsTarget()
									}}
									target={'_blank'}
								>
									{material.name}{variant ? ` | ${i18next.t("Variant")} ${variant.name}` : ''} <small className={'debug-info'}>#{material.id}</small>
								</Link>
							</div>
							
						</div>
						
						<div className={`points`}>
							
							<div className={`max-points`}>
								{i18next.t("Max points")}: <b>{Math.round(this.state.maxPoints * 100) / 100}</b>
							</div>
							
							<div className={`student-points`}>
								{i18next.t("Student points")}: <b>{Math.round(this.state.studentPoints * 100) / 100}</b>
							</div>
							
						</div>
						
						<section className={'answers mt-3'}>
							
							{questions.map((questionModel, quetionIndex) => {
								
								// let questionData = JSON.parse(answerModel['question_data'])
								// let questionModel = new Question(questionData)
								// let questionNum = 
								
								let answerModel = questionModel.latest_answer || answers.find((answerData) => {
									return answerData.question_id == questionModel.id
								})
								
								// let answerModel = new Answer(answerModel)
								// questionModel.latest_answer = answerModel
								
								// const answer = questionModel.latest_answer
								// const path = answer?.path
								
								return (
									
									<div
										className={`question-box list`}
										data-question-id={questionModel.id}
									>
										
										<div
											className={`head bold`}
											title={`#${questionModel.id}`}
										>
											{i18next.t("Task")} {quetionIndex + 1} / {questions.length}
										</div>
										
										<QuestionView
											
											key={'question-' + questionModel.id}
											
											question={questionModel}
											// answers={[answerModel]}
											answer={answerModel}
											// course={path.course}
											// lesson={path.lesson}
											// theme={path.theme}
											preloader={this.props.preloader}
											afterCheck={(isRight, response) => {
												
												const logName = 'UserMaterialPage.afterCheck';
												const logAllow = 1;
												const logCollapsed = 0;
												
												Logger.groupStart(logName, logAllow, logCollapsed);
												
												let newAnswer = new Answer(response.data)
												
												let answers = this.state.answers
												
												let answerIndex = answers.findIndex(x => x.question_id == newAnswer.question_id)
												
												answers[answerIndex] = newAnswer
												
												questionModel.latest_answer = newAnswer
												
												material.questions[quetionIndex] = questionModel
												
												this.setState((prevState) => {
													return {
														material: material,
														answers: answers,
													}
												})
												
												Logger.groupEnd(logAllow);
												
											}}
											
											// mode={'demo'}
											// mode={answerModel ? 'check' : 'demo'}
											mode={'check'}
											
											user={this.props.user}
											
											showPath={false}
											showStudentInfo={false}
											showStateBorder={true}
											
										/>
										
									</div>
									
								);
								
							})}
						
						</section>
					</>
				) : (
					<>...</>
				)}
			
			</div>
		
		);
		
	}
	
}

UserMaterialPage.propTypes = {
	
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	
	user: PropTypes.instanceOf(User).isRequired,
	preloader: PropTypes.object,
	
};

UserMaterialPage.defaultProps = {};

export default withRouter(UserMaterialPage);