import "./MaterialView.css";

import React from 'react';
import PropTypes from 'prop-types';
import YouTubeHelper from "../../helpers/YouTubeHelper";
import UploadsList from "./UploadsList";
import Logger from "../../helpers/Logger";
import QuestionView from "./QuestionView";
import * as Icon from "react-bootstrap-icons";
import i18next from "i18next";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import Material from "../../models/Material";
import User from "../../models/User";
import PdfView from "./PdfView";
import Range from "../../helpers/Range";
import QuestionForm from "./QuestionForm";
import QuestionCardViewBulat from "./QuestionCardViewBulat";
import Question from "../../models/Question";
import MaterialForm from "./MaterialForm";
import Lesson from "../../models/Lesson";
import Course from "../../models/Course";
import Theme from "../../models/Theme";
import Modal from "react-bootstrap/Modal";
import QuestionCardForm from "./QuestionCardForm";
import QuestionGroupView from "./QuestionGroupView";
import QuestionGroupForm from "./QuestionGroupForm";
import QuestionCardView from "./QuestionCardView";
import QuestionForm2 from "./QuestionForm2";

export default class MaterialView extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			material: props.material,
			groups: [],
			editMode: false,
			currentQuestionIndex: 0,
			questionToEdit: null,
			questionFormModalIsOpen: false,
		};
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!Utils.isEqualObjects(this.props.material, this.state.material)) {
			this.setState((prevState) => {
				return {
					material: this.props.material,
				}
			});
		}
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	material = {
		
		get: () => {
			return this.props.material;
		},
		
		getGroups: () => {
			return this.props.material.questions.filter(x => x.isGroup());
		},
		
		addCard: () => {
			
			const logName = 'MaterialView.material.addCards';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.material.get();
			
			let newCard = new Question();
			
			newCard.view_type_alias = 'card';
			
			newCard.material_id = material.id;
			
			this.setState((prevState) => {
				return {
					questionToEdit: newCard,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addGroup: () => {
			
			const logName = 'MaterialView.material.addCards';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.props.material;
			
			let newGroup = new Question();
			
			newGroup.view_type_alias = 'group';
			
			let groups = material.getGroups();
			
			newGroup.name = i18next.t("Group") + ' ' + groups.length;
			
			this.setState((prevState) => {
				return {
					questionToEdit: newGroup,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	questions = {
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					questionFormModalIsOpen: !prevState.questionFormModalIsOpen,
				}
			});
		},
		
		afterSubmitSuccess: (response, materialId) => {
			
			const logName = 'MaterialView.questions.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let isCreate = this.state.questionToEdit === null;
			Logger.log(isCreate, 'isCreate', logAllow);
			
			let questionData = response.data;
			Logger.log(questionData, 'questionData', logAllow);
			
			let question = new Question(questionData);
			Logger.log(question, 'question', logAllow);
			
			let material = this.props.material;
			Logger.log(material, 'currentMaterial', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions', logAllow);
			
			if (isCreate) {
				// create
				questions.push(question);
				// questions.unshift(question);
			} else {
				// update
				if (materialId == material.id) {
					// если задание НЕ перемещёно в другой блок
					questions[this.state.questionToEditIndex] = question;
				} else {
					// если задание перемещёно в другой блок
					questions.splice(this.state.questionToEditIndex, 1);
				}
			}
			
			let questionIndex = this.state.questionToEditIndex || questions.length - 1;
			
			material.questions = questions;
			
			this.questions.saveSort(questions);
			
			this.setState((prevState) => {
				return {
					questionToEdit: null,
					questionFormModalIsOpen: false,
					currentQuestionIndex: questionIndex,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (bindId, questionIndex) => {
			
			const logName = 'MaterialView.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.props.material;
			let question = material.questions[questionIndex];
			
			Logger.log(bindId, 'bindId', logAllow);
			Logger.log(questionIndex, 'questionIndex', logAllow);
			
			if (!bindId) {
				window.alert(i18next.t("bind id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (!window.confirm(i18next.t("Hide task #{{id}}?", {id: question.id}))) {
				Logger.groupEnd(logAllow);
				return;
			}
			
			this.props.preloader.show();
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question-binds') + '/' + bindId,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				let questions = material.questions;
				
				questions.splice(questionIndex, 1);
				
				this.setState((prevState) => {
					return {
						currentMaterial: material,
					}
				});
				
				this.props.preloader.hide();
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		saveSort: (questions) => {
			
			const logName = 'MaterialView.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			Logger.log(questions, 'questions', logAllow);
			
			questions.forEach((question) => {
				// Logger.log(question, 'question', logAllow);
				if (question) {
					ids.push(question.id);
				}
			});
			
			const material = this.props.material;
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('test-question-binds/save-sort'),
				data: {
					material_id: material.id,
					questions_ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialView.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			const logName = 'MaterialView.questions.moveUp';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.material;
			Logger.log(material, 'material', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions [before]', logAllow);
			
			let newIndex = Utils.arrayMoveUp(questions, currentIndex);
			Logger.log(questions, 'questions [after]', logAllow);
			Logger.log(newIndex, 'newIndex', logAllow);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					material: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveDown: (currentIndex) => {
			
			const logName = 'MaterialView.questions.moveDown';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(currentIndex, 'currentIndex', logAllow);
			
			let material = this.state.material;
			Logger.log(material, 'material', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions [before]', logAllow);
			
			let newIndex = Utils.arrayMoveDown(questions, currentIndex);
			Logger.log(questions, 'questions [after]', logAllow);
			Logger.log(newIndex, 'newIndex', logAllow);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					material: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		edit: (question, questionIndex) => {
			
			if (question.view_type_alias == 'cards') {
				
				this.setState((prevState) => {
					return {
						questionCardToEdit: question,
						questionCardFormModalIsOpen: true,
						questionToEditIndex: questionIndex,
					}
				});
				
			} else {
				
				this.setState((prevState) => {
					return {
						questionToEdit: question,
						questionToEditIndex: questionIndex,
						questionFormModalIsOpen: true,
					}
				});
				
			}
			
		},
		
	};
	
	render() {
		
		const logName = 'MaterialView.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const user = this.props.user;
		
		// const material = this.props.material;
		const material = this.state.material;
		Logger.log(material, 'currentMaterial', logAllow);
		
		const questions = material.questions;
		
		const questionToEdit = this.state.questionToEdit;
		
		const materialControls = (
			
			<div className="list controls material-controls">
				
				{(user.can('printMaterials')) &&
					<button
						type={'button'}
						className={[
							'print-btn',
							'my-btn',
							'my-btn-sm',
						].join(' ')}
						onClick={() => {
							window.print();
						}}
					><Icon.Printer/> {i18next.t("Print PDF")}</button>
				}
				
				{(user.canAny(['manageMaterials', 'manageMaterialsAccess'])) &&
					<button
						type={'button'}
						className={[
							'my-btn',
							'my-btn-sm',
							'edit-material-btn',
							'material-edit-btn',
						].join(' ')}
						onClick={(event) => {
							this.setState((prevState) => {
								return {
									editMode: !prevState.editMode,
								}
							});
						}}
						// disabled={this.state.editMode}
					><Icon.Pencil/> {i18next.t("Edit")}</button>
				}
				
				{(user.can('manageMaterials')) &&
					<>
						{/*{this.state.materials.length > 1 && (
							<button
								type={'button'}
								className={[
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									this.materials.moveUp(this.props.materialIndex);
								}}
							><Icon.ArrowUp/> {i18next.t("Move up")}</button>
						)}*/}
						
						{/*{this.state.materials.length > 1 && (
							<button
								type={'button'}
								className={[
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									this.materials.moveDown(this.props.materialIndex);
								}}
							><Icon.ArrowDown/> {i18next.t("Move down")}</button>
						)}*/}
						
						{/*<button
							type={'button'}
							className={[
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								// event.stopPropagation();
								this.materials.upd(this.props.materialIndex, {
									is_public: 1,
								});
							}}
						><Icon.Trash/> {i18next.t("Hide")}</button>*/}
						
						<button
							type={'button'}
							className={[
								'add-task-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								
								let questionToEdit = new Question();
								
								let material = this.material.get();
								
								questionToEdit.material_id = material.id;
								
								questionToEdit.use_shuffle = 1;
								
								this.setState((prevState) => {
									return {
										questionToEdit: questionToEdit,
										questionFormModalIsOpen: true,
									}
								});
								
							}}
						>+ {i18next.t("Task")}</button>
						
						{(Utils.isLoc()) &&
							<>
								<button
									type={'button'}
									className={[
										'add-card-btn',
										'my-btn',
										'my-btn-sm',
									].join(' ')}
									onClick={(event) => {
										if (this.props.material) {
											this.material.addCard(material.id);
										}
									}}
								>+ {i18next.t("Card")}</button>
								
								<button
									type={'button'}
									className={[
										'add-group-btn',
										'my-btn',
										'my-btn-sm',
									].join(' ')}
									onClick={(event) => {
										this.material.addGroup();
									}}
									title={i18next.t("Add group of tasks")}
								>+ {i18next.t("Group")}</button>
							</>
						}
					
					</>
				}
			
			</div>
		
		);
		
		const materialPathData = [
			// this.props.lesson.name + ' [#' + this.props.lesson.id + ']',
			// this.props.theme.name + ' [#' + this.props.theme.id + ']',
		];
		
		if (material) {
			materialPathData.push(material.name + ' [#' + material.id + ']');
		}
		
		const materialPath = materialPathData.join(' › ');
		
		// const questionsViewMode = material && material.is_trainer == 1 ? 'slider' : 'list';
		const questionsViewMode = 'slider';
		
		const materialForm = (
			<MaterialForm
				model={material}
				// themeId={this.props.theme.id}
				cancel={() => {
					this.setState((prevState) => {
						return {
							editMode: false,
						}
					});
				}}
				afterCreate={null}
				afterUpdate={(response, model) => {
					
					const logName = 'MaterialView.afterUpdate';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					this.setState((prevState) => {
						return {
							editMode: false,
						}
					});
					
					if (this.props.afterUpdate) {
						this.props.afterUpdate(response, model);
					}
					
					Logger.groupEnd(logAllow);
					
				}}
				preloader={this.props.preloader}
				typeAlias={this.state.materialToEditTypeAlias}
				user={user}
			/>
		);
		
		let pdfs = [];
		let notPdfs = [];
		
		if (material) {
			const pdfData = Utils.splitPdfsAndNotPdfs(material.files);
			pdfs = pdfData.pdfs;
			notPdfs = pdfData.notPdfs;
		}
		
		let showMaterialControls = user.canAny(['printMaterials', 'manageMaterials', 'manageMaterialsAccess']);
		
		let questionsGroups = this.material.getGroups();
		
		// let viewableQuestions = material.getViewableQuestions();
		let viewableQuestions = material.questions;
		
		let currentQuestion = viewableQuestions[this.state.currentQuestionIndex];
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'MaterialView material-view'}>
				
				{showMaterialControls && materialControls}
				
				{(this.state.editMode) ? (
					
					<div className={'material-form-box list'}>
						{materialForm}
					</div>
				
				) : (
					
					<>
						
						{material.hasContent() && (
							
							<div className={'list body'}>
								
								{material.youtube_urls && (
									<div className="youtube-widget-box embed-responsive embed-responsive-16by9 mb-2">
										{YouTubeHelper.widget(material.youtube_urls)}
									</div>
								)}
								
								{material.about && (
									<div className="text" dangerouslySetInnerHTML={{__html: material.about}}></div>
								)}
								
								{pdfs.map((uploadBindData) => {
									return (
										<PdfView
											url={uploadBindData.url}
											// name={uploadBindData.original_name}
											// toolbar={true}
										/>
									);
								})}
								
								{material.images.length > 0 && (
									
									<div className={'images media-list row'}>
										{material.images.map((uploadBindData, index) => {
											return (
												<div className={'media-box img-box col'}>
													<a href={uploadBindData.url}
													   className={'img-link'}
													   target={'_blank'}
													   key={'img_' + index}
													>
														<img src={uploadBindData.preview_url}
															 alt="img"
															 onClick={(event) => {
																 // todo
															 }}
															 className={[
																 'clickable',
															 ].join(' ')}
														/>
													</a>
													<br/><small
													className={'upload-name text-muted'}>{uploadBindData.name}</small>
												</div>
											);
										})}
									</div>
								
								)}
								
								{material.sounds.length > 0 && (
									
									<div className={'sounds media-list row'}>
										{material.sounds.map((uploadData) => {
											return (
												<div className="col media-col sound-col">
													<div className="media-box sound-box">
														<audio
															src={uploadData.url}
															controls={true}
														/>
														<br/><small
														className={'upload-name text-muted'}>{uploadData.name}</small>
													</div>
												</div>
											);
										})}
									</div>
								
								)}
								
								{notPdfs.length > 0 && (
									<div className={'files media-list'}>
										<UploadsList uploads={material.files}/>
									</div>
								)}
							
							</div>
						
						)}
						
						{(material.questions.length > 1 && questionsViewMode === 'slider') &&
							
							<div className={'questions-nav-controls row my-3'}>
								
								<div className="col-sm-4 prev-btn-col">
									<button
										type={'button'}
										className={[
											'my-btn',
											'prev-question-btn',
										].join(' ')}
										onClick={(event) => {
											let currentIndex = this.state.currentQuestionIndex;
											let newIndex = Utils.arrayPrevIndex(viewableQuestions, currentIndex);
											this.setState((prevState) => {
												return {
													currentQuestionIndex: newIndex,
												}
											});
										}}
									><Icon.ChevronLeft/> {i18next.t("Previous page")}</button>
								</div>
								
								<div className="col-sm-4 goto-btn-col text-center">
									
									<Dropdown className={'goto-menu d-inline-block'}>
										
										<Dropdown.Toggle
											className={[
												'my-btn',
												'my-btn-default',
												'my-0',
												'mx-0',
											].join(' ')}
										>{i18next.t("Page")} {this.state.currentQuestionIndex + 1} / {viewableQuestions.length}</Dropdown.Toggle>
										
										<Dropdown.Menu>
											
											{Range.get(0, viewableQuestions.length - 1).map((newIndex) => {
												const currentQuestionIndex = this.state.currentQuestionIndex;
												const num = newIndex + 1;
												const isCurrent = newIndex == currentQuestionIndex;
												return (
													<Dropdown.Item
														onClick={(event) => {
															this.setState((prevState) => {
																return {
																	currentQuestionIndex: newIndex,
																}
															});
														}}
														disabled={isCurrent}
													>{num}</Dropdown.Item>
												);
											})}
										
										</Dropdown.Menu>
									
									</Dropdown>
								
								</div>
								
								<div className="col-sm-4 next-btn-col text-right">
									<button
										type={'button'}
										className={[
											'my-btn',
											'next-question-btn',
										].join(' ')}
										onClick={(event) => {
											let currentIndex = this.state.currentQuestionIndex;
											let newIndex = Utils.arrayNextIndex(viewableQuestions, currentIndex);
											this.setState((prevState) => {
												return {
													currentQuestionIndex: newIndex,
												}
											});
										}}
									>{i18next.t("Next page")} <Icon.ChevronRight/></button>
								</div>
							
							</div>
							
						}
						
						{material.hasQuestions() && (
							
							<div className={'questions'}>
								
								{[currentQuestion].map((question) => {
									
									const logName = 'MaterialView.questions.map';
									const logAllow = 0;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									Logger.log(question, 'question', logAllow);
									
									let questionIndex = this.state.currentQuestionIndex;
									Logger.log(questionIndex, 'questionIndex', logAllow);
									
									let latestAnswer = question.latest_answer;
									Logger.log(latestAnswer, 'latestAnswer', logAllow);
									
									let visible = true;
									
									// if (questionsViewMode === 'slider') {
									// 	visible = questionIndex == this.state.currentQuestionIndex;
									// }
									
									Logger.groupEnd(logAllow);
									
									return (
										
										<div
											key={'question-' + question.id + '-box'}
											className={[
												'question-box',
												'list-item',
												'bg-' + question.bg_style_alias,
												'border-' + question.border_style_alias,
												latestAnswer
													? material.isExam()
														? 'waiting'
														: latestAnswer.is_waiting
															? 'waiting'
															: latestAnswer.is_right
																? 'right-answer'
																: 'wrong-answer'
													: 'no-answer',
												visible ? 'd-block' : 'd-none',
												question.isGroup() ? 'question-group-box' : '',
											].join(' ')}
										>
											
											<div className={'question-name text-center font-weight-bold'}
												 title={'#' + question.id}>
												{i18next.t("Task")} {viewableQuestions.length > 1
												? i18next.t("{{num}} of {{count}}", {
													num: this.state.currentQuestionIndex + 1,
													count: viewableQuestions.length
												}) : this.state.currentQuestionIndex + 1}
											</div>
											
											<div className={'question-view-box'}>
												
												{(question.isGroup()) &&
													<QuestionGroupView
														group={question}
														material={material}
														user={this.props.user}
														preloader={this.props.preloader}
													/>
												}
												
												{(question.isCard()) &&
													<QuestionCardView
														card={question}
													/>
												}
												
												{(question.isRegular()) &&
													<QuestionView
														key={'question-' + question.id + '-view'}
														question={question}
														course={this.props.course}
														lesson={this.props.lesson}
														theme={this.props.theme}
														material_id={material.id}
														mode={user.is(['student']) ? 'pass' : 'demo'}
														user={user}
														path={materialPath}
														// num={questionIndex + 1}
														preloader={this.props.preloader}
														afterSendAnswer={(response, answer) => {
															question.latest_answer = answer;
															let questions = material.questions;
															questions[questionIndex] = question;
															this.setState((prevState) => {
																return {
																	currentMaterial: material,
																}
															});
														}}
														reanswers={!material.isExam()}
														showAnswerState={!material.isExam()}
														alert={this.props.alert}
														weightVisible={material.is_weight_visible == 1}
														questionsCount={material.questions.length}
													/>
												}
												
												{(user.can('manageMaterials') || user.can('manageHomework')) && (
													
													<>
														
														<hr/>
														
														<div className="controls bottom-controls">
															
															{(user.can('manageMaterials')) &&
																
																<>
																	
																	<button
																		type={'button'}
																		className={[
																			'question-edit-btn',
																			'my-btn',
																			'my-btn-sm',
																		].join(' ')}
																		onClick={(event) => {
																			this.questions.edit(question, questionIndex);
																		}}
																		title={'#' + question.id}
																	><Icon.Pencil/> {i18next.t("Edit")}
																	</button>
																	
																	{material.questions.length > 1 && (
																		
																		<>
																			
																			<button
																				type={'button'}
																				className={[
																					'question-move-up-btn',
																					'my-btn',
																					'my-btn-sm',
																				].join(' ')}
																				onClick={(event) => {
																					this.questions.moveUp(questionIndex);
																				}}
																			>
																				<Icon.ArrowUp/> {i18next.t("Move up")}
																			</button>
																			
																			<button
																				type={'button'}
																				className={[
																					'question-move-down-btn',
																					'my-btn',
																					'my-btn-sm',
																				].join(' ')}
																				onClick={(event) => {
																					this.questions.moveDown(questionIndex);
																				}}
																			>
																				<Icon.ArrowDown/> {i18next.t("Move down")}
																			</button>
																			
																			<Dropdown
																				className={'sort-menu d-inline-block question-set-position-menu'}
																			>
																				
																				<Dropdown.Toggle
																					className={[
																						// 'btn-unstyled',
																						'question-set-position-btn',
																						'my-btn',
																						'my-btn-sm',
																						'my-btn-default',
																						'my-0',
																					].join(' ')}
																				>
																					{i18next.t("Set position")}
																				</Dropdown.Toggle>
																				
																				<Dropdown.Menu>
																					
																					{Range.get(0, material.questions.length - 1).map((newIndex) => {
																						const num = newIndex + 1;
																						const isCurrent = newIndex == questionIndex;
																						return (
																							<Dropdown.Item
																								onClick={(event) => {
																									let questions = material.questions;
																									Utils.arrayMove(questions, questionIndex, newIndex);
																									material.questions = questions;
																									this.questions.saveSort(questions);
																									this.setState((prevState) => {
																										return {
																											currentQuestionIndex: newIndex,
																										}
																									});
																								}}
																								disabled={isCurrent}
																							>{num}</Dropdown.Item>
																						);
																					})}
																				
																				</Dropdown.Menu>
																			
																			</Dropdown>
																		
																		</>
																	
																	)}
																	
																	<button
																		type={'button'}
																		className={[
																			'hide-btn',
																			'my-btn',
																			'my-btn-sm',
																		].join(' ')}
																		onClick={(event) => {
																			this.questions.del(question.bind_id, questionIndex);
																		}}
																		disabled={!question.bind_id}
																		title={'bind: ' + question.bind_id}
																	><Icon.Trash/> {i18next.t("Hide")}</button>
																	
																	{this.state.groups.length > 0 && (
																		
																		<Dropdown
																			className={'homework-menu'}>
																			
																			<Dropdown.Toggle
																				className={[
																					// 'btn-unstyled',
																					'my-btn',
																					'my-btn-sm',
																					'my-btn-default',
																				].join(' ')}
																			>
																				{i18next.t("Set as homework")}
																			</Dropdown.Toggle>
																			
																			<Dropdown.Menu>
																				
																				{this.state.groups.length < 1 && (
																					<Dropdown.Item>
																						<div
																							className={'empty-msg'}>
																							{i18next.t("Groups not found")}.
																							<br/>
																							<br/>{i18next.t("Contact the administrator")}.
																						</div>
																					</Dropdown.Item>
																				)}
																				
																				{this.state.groups.map((group, groupIndex) => {
																					let questionState = question.states.filter(questionState => questionState.group_id == group.id)[0];
																					let title = questionState && questionState.is_active
																						? i18next.t("UNMARK as homework for this group")
																						: i18next.t("MARK as homework for this group")
																					;
																					let alertMsg = questionState && questionState.is_active
																						? i18next.t("Task '{{name}}' unmarked as homework for group {{group}}", {
																							name: question.name,
																							group: group.name,
																						})
																						: i18next.t("Task '{{name}}' marked as homework for group {{group}}", {
																							name: question.name,
																							group: group.name,
																						})
																					;
																					let showAlert = 0;
																					return (
																						<Dropdown.Item
																							onClick={(event) => {
																								
																								if (questionState) {
																									
																									// toggle existing state
																									
																									if (this.props.preloader) {
																										this.props.preloader.show();
																									}
																									
																									axios({
																										method: 'put',
																										url: Utils.apiUrl('test-question-state') + '/' + questionState.id,
																										data: {
																											is_active: questionState.is_active ? 0 : 1,
																										},
																										params: {
																											'accessToken': Utils.getUserToken(),
																										},
																									}).then((response) => {
																										
																										const logName = 'MaterialView.questionState.ajax.done';
																										const logAllow = 1;
																										const logCollapsed = 0;
																										
																										Logger.groupStart(logName, logAllow, logCollapsed);
																										
																										Logger.log(response, 'response', logAllow);
																										
																										let state = response.data;
																										let stateIndex = null;
																										question.states.forEach((currentState, currentStateIndex) => {
																											if (currentState.id == state.id) {
																												stateIndex = currentStateIndex;
																											}
																										});
																										question.states[stateIndex] = state;
																										
																										material.questions[questionIndex] = question;
																										
																										this.setState((prevState) => {
																											return {
																												currentMaterial: material,
																											}
																										});
																										
																										if (this.props.preloader) {
																											this.props.preloader.hide();
																										}
																										
																										if (showAlert) {
																											window.alert(alertMsg);
																										}
																										
																										Logger.groupEnd(logAllow);
																										
																									}).catch((error) => {
																										Utils.axiosErrorAlert(error);
																									});
																								} else {
																									
																									// create new state
																									
																									if (this.props.preloader) {
																										this.props.preloader.show();
																									}
																									
																									axios({
																										method: 'post',
																										url: Utils.apiUrl('test-question-state'),
																										data: {
																											group_id: group.id,
																											question_id: question.id,
																											material_id: material.id,
																											state_alias: 'homework',
																											is_active: 1,
																										},
																										params: {
																											'accessToken': Utils.getUserToken(),
																										},
																									}).then((response) => {
																										
																										const logName = 'MaterialView.questionState.ajax.done';
																										const logAllow = 1;
																										const logCollapsed = 0;
																										
																										Logger.groupStart(logName, logAllow, logCollapsed);
																										
																										Logger.log(response, 'response', logAllow);
																										
																										let state = response.data;
																										question.states.push(state);
																										
																										material.questions[questionIndex] = question;
																										
																										this.setState((prevState) => {
																											return {
																												currentMaterial: material,
																											}
																										});
																										
																										if (this.props.preloader) {
																											this.props.preloader.hide();
																										}
																										
																										if (showAlert) {
																											window.alert(alertMsg);
																										}
																										
																										Logger.groupEnd(logAllow);
																										
																									}).catch((error) => {
																										Utils.axiosErrorAlert(error);
																									});
																								}
																							}}
																							title={title}
																						>
																							{questionState && questionState.is_active == 1 ? (
																								<b>{group.name}</b>
																							) : (
																								group.name
																							)}
																						
																						</Dropdown.Item>
																					);
																				})}
																			
																			</Dropdown.Menu>
																		
																		</Dropdown>
																	
																	)}
																
																</>
																
															}
														
														</div>
													
													</>
												
												)}
												
											</div>
											
											{user.can('manageQuestions') && (
												
												<Dropdown className={'question-menu overlay-menu'}>
													
													<Dropdown.Toggle></Dropdown.Toggle>
													
													<Dropdown.Menu>
														
														<Dropdown.Item
															onClick={(event) => {
																this.setState((prevState) => {
																	return {
																		questionToEdit: question,
																		questionToEditIndex: questionIndex,
																		questionFormModalIsOpen: true,
																	}
																});
															}}
														>{i18next.t("Edit")}</Dropdown.Item>
														
														<Dropdown.Item
															onClick={(event) => {
																this.questions.moveUp(questionIndex);
															}}
														>{i18next.t("Move up")}</Dropdown.Item>
														
														<Dropdown.Item
															onClick={(event) => {
																this.questions.moveDown(questionIndex);
															}}
														>{i18next.t("Move down")}</Dropdown.Item>
														
														<Dropdown.Item
															onClick={(event) => {
																this.questions.del(question.bind_id, questionIndex);
															}}
														>{i18next.t("Hide")}</Dropdown.Item>
														
														{(user.can('copyMaterials')) &&
															<>
																<Dropdown.Item
																	onClick={(event) => {
																		// todo question copy to
																		let materialId = window.prompt('Material ID', material.id);
																		if (materialId) {
																			axios({
																				method: 'get',
																				url: Utils.apiUrl('questions/copy'),
																				data: {},
																				params: {
																					'accessToken': Utils.getUserToken(),
																					questionId: question.id,
																					materialId: materialId,
																				},
																			}).then((response) => {
																				
																				const logName = 'MaterialView.copy.ajax.done';
																				const logAllow = 1;
																				const logCollapsed = 0;
																				
																				Logger.groupStart(logName, logAllow, logCollapsed);
																				
																				Logger.log(response, 'response', logAllow);
																				
																				let copy = new Question(response.data);
																				Logger.log(copy, 'copy', logAllow);
																				
																				if (copy.material_id == material.id) {
																					material.questions.push(copy);
																					this.setState((prevState) => {
																						return {
																							currentMaterial: material,
																						}
																					});
																				}
																				
																				Logger.groupEnd(logAllow);
																				
																			}).catch((error) => {
																				console.log(error);
																				// window.alert(error.response.data.message);
																			});
																		}
																	}}
																>{i18next.t("Copy to")}...</Dropdown.Item>
																
																<Dropdown.Item
																	onClick={(event) => {
																		// todo question copy to
																		let toMaterialId = window.prompt('Material ID', material.id);
																		if (toMaterialId) {
																			axios({
																				method: 'get',
																				url: Utils.apiUrl('questions/move'),
																				data: {},
																				params: {
																					'accessToken': Utils.getUserToken(),
																					questionId: question.id,
																					fromMaterialId: material.id,
																					toMaterialId: toMaterialId,
																				},
																			}).then((response) => {
																				
																				const logName = 'MaterialView.move.ajax.done';
																				const logAllow = 1;
																				const logCollapsed = 0;
																				
																				Logger.groupStart(logName, logAllow, logCollapsed);
																				
																				Logger.log(response, 'response', logAllow);
																				
																				let questionIndex = material.questions.indexOf(question);
																				material.questions.splice(questionIndex, 1);
																				
																				this.setState((prevState) => {
																					return {
																						currentMaterial: material,
																					}
																				});
																				
																				Logger.groupEnd(logAllow);
																				
																			}).catch((error) => {
																				console.log(error);
																				// window.alert(error.response.data.message);
																			});
																		}
																	}}
																>{i18next.t("Move to")} ...</Dropdown.Item>
															</>
														}
													
													</Dropdown.Menu>
												
												</Dropdown>
											
											)}
										
										</div>
									
									);
									
								})}
							
							</div>
						
						)}
					
					</>
				
				)}
				
				<div className="modals">
					
					{(questionToEdit) &&
						
						<Modal
							className={[
								'question-form-modal',
								// 'wide-modal',
							].join(' ')}
							show={this.state.questionFormModalIsOpen}
							onHide={this.questions.formModalToggle}
							size={'xl'}
							keyboard={false}
							backdrop={'static'}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{questionToEdit.id ? (
										<>{i18next.t("Edit task #{{id}}", {id: questionToEdit.id})}</>
									) : (
										<>
											{(questionToEdit.isRegular()) &&
												<>{i18next.t("Add task")}</>
											}
											
											{(questionToEdit.isCard()) &&
												<>{i18next.t("Add card")}</>
											}
											
											{(questionToEdit.isGroup()) &&
												<>{i18next.t("Add group")}</>
											}
										</>
									)}
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
								
								{(questionToEdit) ? (
									
									<div className={'edit-task-form-box'}>
										
										{(questionToEdit.isRegular()) &&
											<QuestionForm2
												model={questionToEdit}
												materialId={material.id}
												afterSubmit={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={[material]}
												preloader={this.props.preloader}
												skins={this.props.skins}
											/>
										}
										
										{(questionToEdit.isGroup()) &&
											<QuestionGroupForm
												model={this.state.questionToEdit}
												materialId={material.id}
												afterSuccess={this.questions.afterSubmitSuccess}
												afterCardSuccess={() => {
													this.setState((prevState) => {
														return {
															questionToEdit: null,
															questionFormModalIsOpen: false,
														}
													});
												}}
												cancel={this.questions.formModalToggle}
												materials={[material]}
												preloader={this.props.preloader}
												skins={this.props.skins}
											/>
										}
										
										{(questionToEdit.isCard()) &&
											<QuestionCardForm
												model={this.state.questionToEdit}
												materialId={material.id}
												afterSubmit={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={[material]}
												preloader={this.props.preloader}
												// skins={this.props.skins}
												groups={questionsGroups}
											/>
										}
									
									</div>
								
								) : (
									
									<div className={'new-task-form-box'}>
										<QuestionForm2
											model={questionToEdit}
											materialId={material.id}
											afterSubmit={this.questions.afterSubmitSuccess}
											cancel={this.questions.formModalToggle}
											materials={[material]}
											preloader={this.props.preloader}
											skins={this.props.skins}
										/>
									</div>
								
								)}
							
							</Modal.Body>
						
						</Modal>
						
					}
				
				</div>
			
			</div>
		
		);
	}
	
}

MaterialView.propTypes = {
	
	material: PropTypes.instanceOf(Material).isRequired,
	user: PropTypes.instanceOf(User).isRequired,
	
	course: PropTypes.instanceOf(Course),
	lesson: PropTypes.instanceOf(Lesson),
	theme: PropTypes.instanceOf(Theme),
	
	afterCreate: PropTypes.func,
	afterUpdate: PropTypes.func,
	
	preloader: PropTypes.object,
	
	skins: PropTypes.array,
	
};

MaterialView.defaultProps = {
	skins: [],
};