import Group from "./Group";
import i18next from "i18next";
import Config from "../helpers/Config";

export default class User {
	
	static ownerRoleId = 2;
	static adminRoleId = 2;
	static studentRoleId = 5;
	static teacherRoleId = 6;
	
	static ownerRoleALias = 'owner';
	static adminRoleALias = 'admin';
	static studentRoleALias = 'student';
	static teacherRoleALias = 'teacher';
	
	static likeSearchAttrs = [
		'name',
		'surname',
		'patron',
		'email',
	];
	
	static roleNameEn = {
		[User.ownerRoleALias]: 'Owner',
		[User.adminRoleALias]: 'Admin',
		[User.teacherRoleALias]: 'Teacher',
		[User.studentRoleALias]: 'Student',
	}
	
	constructor(data = {}) {
		this.id = data.id;
		this.email = data.email;
		this.username = data.username;
		this.name = data.name;
		this.surname = data.surname;
		this.patron = data.patron;
		this.country_code = data.country_code;
		this.role_id = data.role_id;
		this.role_alias = data.role_alias;
		this.role_name = data.role_name;
		this.invite_password = data.invite_password;
		this.group_id = data.group_id;
		this.group_name = data.group_name;
		this.group = data.group ? new Group(data.group) : null;
		this.is_active = data.is_active;
		this.deps_ids = data.deps_ids || [];
		this.groups = data.groups ? data.groups.map(groupData => new Group(groupData)) : [];
		
		// << groups ids
		
		let groupsIdsArray = []
		
		if (data.groups_ids) {
			
			if (Array.isArray(data.groups_ids)) {
				
				groupsIdsArray = data.groups_ids
				
			} else {
				
				// groups_id seems to be object
				// so we make on array from it
				
				let groupsIdsObject = data.groups_ids
				
				Object.keys(groupsIdsObject).forEach((key) => {
					let groupId = groupsIdsObject[key]
					groupsIdsArray.push(groupId)
				})
				
			}
			
		}
		
		this.groups_ids = groupsIdsArray
		
		// >> groups ids
		
		this.available_roles = data.available_roles || [];
		this.status = data.status;
		this.created_at = data.created_at;
		this.restricted_course_access = data.restricted_course_access;
		this.courses_ids = data.courses_ids || [];
	}
	
	getDisplayName(short = 0) {
		
		if (this.name || this.surname) {
			
			let nameData = [];
			
			if (this.surname) {
				nameData.push(this.surname);
			}
			
			if (this.name) {
				nameData.push(short ? this.name.charAt(0) + '.' : this.name);
			}
			
			if (this.patron) {
				nameData.push(short ? this.patron.charAt(0) + '.' : this.patron);
			}
			
			return nameData.join(' ');
			
		}
		
		if (this.username) {
			return this.username;
		}
		
		return this.email;
		
	}
	
	is = (roleAlias) => {
		if (Array.isArray(roleAlias)) {
			return this.isAny(roleAlias);
		}
		return this.role_alias === roleAlias;
	}
	
	isAny = (rolesAliases = []) => {
		return rolesAliases.includes(this.role_alias);
	}
	
	can = (actionName) => {
		
		let roles = Config.permissions[actionName]
		
		return Array.isArray(roles) 
			? roles.includes(this.role_alias) 
			: roles === '*'
		
	}
	
	canAny = (actionsNamesArray) => {
		
		let out = true;
		
		actionsNamesArray.forEach((actionName) => {
			out = this.can(actionName);
		});
		
		return out;
		
	}
	
	static roles = [
		{
			id: 1,
			name: i18next.t("Админ+"),
		},
		{
			id: 2,
			name: i18next.t("Админ"),
		},
		{
			id: 5,
			name: i18next.t("Cтудент"),
		},
		{
			id: 6,
			name: i18next.t("Teacher"),
		},
	];
	
	getAvailableRolesListData = () => {
		let listData = {};
		this.available_roles.forEach(roleData => {
			listData[roleData.id] = roleData.name;
		});
		return listData;
	};
	
	getInviteText = () => {
		return [
			'Link: ' + Config.prodFrontendHost,
			'Login: ' + this.email,
			'Password: ' + (this.invite_password || ''),
		].join("\n");
	};
	
	copyInviteText = (showAlert=true, alertMsg=i18next.t("Приглашение скопировано в буфер обмена")) => {
		let inviteText = this.getInviteText();
		navigator.clipboard.writeText(inviteText).then(() => {
			if (showAlert) {
				window.alert(alertMsg + ":\n\n" + inviteText);
			}
		});
	};
	
	isStudent = () => {
		return this.role_alias == User.studentRoleALias;
	};
	
	isTeacher = () => {
		return this.role_alias == User.teacherRoleALias;
	};
	
	isRightGroup = (groupId) => {
		return this.groups_ids.includes(groupId.toString());
	}
	
	isAdmin = () => {
		return this.role_id == User.adminRoleId;
	};
	
}