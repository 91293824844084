import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";

export default class Stars extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {

        const logName = 'Stars.render';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        let data = [];

        let fullStar = <img src={this.props.fullStarImg} alt="full-star" className={'star full-star'}/>;
        let paleStar = <img src={this.props.paleStarImg} alt="pale-star" className={'star pale-star'}/>;

        let fullStarsCount = this.props.count;
        Logger.log(fullStarsCount, 'fullStarsCount', logAllow);

        let paleStarsCount = this.props.maxCount - this.props.count;
        Logger.log(paleStarsCount, 'paleStarsCount', logAllow);

        for (let i=0; i < fullStarsCount; i++) {
            data.push(fullStar);
        }

        for (let i=0; i < paleStarsCount; i++) {
            data.push(paleStar);
        }

        Logger.log(data, 'data', logAllow);

        Logger.groupEnd(logAllow);

        return (
            <span
                className={'stars'}
                style={{
                    whiteSpace: 'nowrap',
                }}
            >{data}</span>
        );
    }

}

Stars.propTypes = {
    count: PropTypes.number.isRequired,
    maxCount: PropTypes.number.isRequired,
    fullStarImg: PropTypes.any.isRequired,
    paleStarImg: PropTypes.any.isRequired,
};

Stars.defaultProps = {};