import './Discuss.css'

import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import axios from 'axios'
import * as Icon from 'react-bootstrap-icons';

import Utils from '../../helpers/Utils'
import Logger from '../../helpers/Logger'
import Msg from '../../models/Msg'

class Discuss extends React.Component {

	constructor(props) {
		
		super(props)
		
		const user = props.user
			
		this.state = {
			msgs: [],
			msgToEdit: new Msg({
				target_model_alias: props.targetModelAlias,
				target_model_id: props.targetModelId,
				target_user_id: user.isStudent() ? null : props.targetUserId,
			}),
		}
		
		if (Utils.isDev()) {
			
			// let fakeMsg = new Msg({
			// 	body: `for model ${this.props.targetModelAlias} #${this.props.targetModelId} to user #${this.props.targetUserId} from user #${this.props.user?.id}`,
			// 	created_at: '2023-10-18 01:52:00',
			// })
			
			// let fakeMsgs = Utils.fillArray(fakeMsg, 10)
			
			// this.state.msgToEdit = fakeMsg;
			
		}
		
		this.msgListBottomRef = React.createRef()
		
	}
	
	loadMsgs = () => {
		
		const logName = 'Discuss.loadMsgs'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		if (this.props.preloader.show) {
			this.props.preloader.show()
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('msg'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				'filter[target_user_id]': this.props.targetUserId,
				'filter[target_model_alias]': this.props.targetModelAlias,
				'filter[target_model_id]': this.props.targetModelId,
			},
		}).then((response) => {
			
			const logName = 'Discuss.loadMsgs.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			let rawMsgs = response.data
			
			let msgs = rawMsgs.map((msgData) => {
				return new Msg(msgData)
			})
			
			this.setState({
				msgs: msgs,
			})
			
			if (this.props.preloader.hide) {
				this.props.preloader.hide()
			}
			
			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			
			if (this.props.preloader.hide) {
				this.props.preloader.hide()
			}
			
			window.alert(axiosError)
			
		})
		
		Logger.groupEnd(logAllow)
		
	}
	
	msgForm = {
		
		handleInput: (event) => {
			
			let msg = this.state.msgToEdit
			
			let target = event.target
			
			let attr = target.getAttribute('name')
			
			let val = target.value
			
			msg[attr] = val
			
			this.setState({
				msgToEdit: msg,
			})
			
		},
		
		submit: (event) => {
			
			const logName = 'Discuss.msgForm.submit'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
		
			event.preventDefault();
			
			let msg = this.state.msgToEdit
			
			if (!msg.body) {
				return
			}

			msg.body = msg.body.trim()
			
			let method = msg.id ? 'put' : 'post'
			
			let url = msg.id ? Utils.apiUrl('msg') + '/' + msg.id : Utils.apiUrl('msg')
			Logger.log(url, 'url', logAllow)
			
			if (this.props.preloader.show) {
				this.props.preloader.show()
			}
			
			Logger.log(msg, 'msg', logAllow)
			
			axios({
				method: method,
				url: url,
				data: msg,
				params: {
					accessToken: Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'Discuss.msgForm.submit.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				Logger.log(response, 'response', logAllow)
				
				let newMsg = new Msg(response.data)
				
				let msgs = this.state.msgs
				
				msgs.unshift(newMsg)
				
				let msgToEdit = this.state.msgToEdit
				msgToEdit.body = ''
				
				this.setState({
					msgs: msgs,
					msgToEdit: msgToEdit,
				})
				
				this.scrollToBottomOfMsgList()
				
				if (this.props.preloader.hide) {
					this.props.preloader.hide()
				}
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				
				if (this.props.preloader.hide) {
					this.props.preloader.hide()
				}
				
				window.alert(axiosError)
				
			})
			
			Logger.groupEnd(logAllow)
			
		},
		
	}
	
	scrollToBottomOfMsgList = () => {
		
		const logName = 'Discuss.scrollToBottomOfMsgList'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)

		Logger.log(this.msgListBottomRef, 'this.msgListBottomRef', logAllow)

		// this.msgListBottomRef.current?.scrollIntoView({behavior: "instant"})
		
		Logger.groupEnd(logAllow)
		
	}
	
	componentDidMount() {
		
		const logName = 'Discuss.componentDidMount'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		this.loadMsgs()
		
		this.scrollToBottomOfMsgList()
		
		Logger.groupEnd(logAllow)
		
	}
	
	componentDidUpdate() {
		
		const logName = 'Discuss.componentDidUpdate'
		const logAllow = 0
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		this.scrollToBottomOfMsgList()
		
		Logger.groupEnd(logAllow)
		
	}

	render() {
		
		const logName = 'Discuss.render'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		Logger.log(this.state, 'this.state', logAllow)
		
		const user = this.props.user
		
		const msgToEdit = this.state.msgToEdit
		Logger.log(msgToEdit, 'msgToEdit', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<div className={'discuss'}>
				
				<div className={`msg-list`}>
					
					{(this.state.msgs.length < 1) ? (
						<div className={`empty-msg`}>
							{i18next.t('No messages yet')}
						</div>
					) : (
						<>
							{this.state.msgs.map((msg) => {
								
								let author = msg.created_by
							
								return (
									<div className={`msg conainer`}>
										
										<div className={`row`}>
											
											<div className={`col-auto img-col`}>
												
												<div className={`user-avatar`}></div>
												
											</div>
											
											<div className={`col text-col`}>
												
												<div className={`author msg-author bold`}>
													<span className={`name author-name`} title={`#${author?.id}`}>{author?.getDisplayName()}</span>
													<br/><small className={`text-muted`}>{author?.role_name}</small>
												</div>
												
												<div className={`body msg-body`}>
													{Utils.nl2br(msg.body)}
												</div>
												
												<div className={`created-at msg-created-at`}>
													<small>{msg.created_at}</small>
												</div>
												
											</div>
											
										</div>
										
									</div>
								)
								
							})}
							<div ref={this.msgListBottomRef} className={`msg-list-bottom`}></div>
						</>
					)}
					
				</div>
				
				<form className={`msg-form`} onSubmit={this.msgForm.submit}>
					
					<div className={`row`}>
						
						<div className={`col-md-9 col-xl-9`}>
							
							<div className={`box msg-text-field-box`}>
								
								<textarea
									id={'body'}
									name={'body'}
									rows={3}
									className={[
										'msg-text-field',
										'form-control',
										'my-form-control',
									].join(' ')}
									value={msgToEdit.body}
									onChange={this.msgForm.handleInput}
									onKeyDown={(event) => {
										
										if (event.ctrlKey) {
											
											// window.alert(event.code)
											
											switch (event.code) {
												
												case 'Enter':
													this.msgForm.submit(event)
													break;
												
												case 'KeyR':
													this.loadMsgs()
													break;
												
											}
											
										}
										
									}}
									required={true}
								></textarea>
								
								{/*
								<div className={`form-text text-muted small px-3`}>
									{i18next.t('Send message by keyboard')}: <kbd>Ctrl + Enter</kbd>
								</div>
								*/}
								
							</div>
							
						</div>
						
						<div className={`col-md-3 col-xl-3 controls-col`}>
							
							<div className={`send-btn-box`}>
								<button
									className={[
										'send-btn',
										'submit-btn',
										'my-btn',
										'my-btn-primary',
									].join(' ')}
									title={'Ctrl + Enter'}
								>{i18next.t('Send')}</button>
							</div>
							
							{/* <div className={`kbd-box text-center small d-none d-md-block`}>
								<kbd>Ctrl + Enter</kbd>
							</div> */}
							
							<div className={`reload-btn-box mt-2`}>
								<button
									type='button'
									className={[
										'reload-btn',
										'my-btn',
										'my-btn-sm',
									].join(' ')}
									title={'Ctrl + R'}
									onClick={(event) => {
										this.loadMsgs()
									}}
								><Icon.ArrowClockwise/></button>
							</div>
							
						</div>
						
					</div>
					
				</form>
				
			</div>
			
		)
		
	}
	
}

Discuss.propTypes = {
	preloader: PropTypes.object,
	targetUserId: PropTypes.object.isRequired,
	targetModelAlias: PropTypes.any,
	targetModelId: PropTypes.any,
	user: PropTypes.object,
}

Discuss.defaultProps = {}

export default Discuss