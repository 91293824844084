import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import DateHelper from "../../helpers/DateHelper";
import Department from "../../models/Department";
import User from "../../models/User";

export default class DepartmentForm extends React.Component {
	
	mode = 'create';
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Department();
		
		this.mode = model.id ? 'udpdate' : 'create';
		
		if (this.mode === 'create') {
			
			model.is_active = 1;
			
			if (Config.allowFormFake()) {
				model.name = '__TEST__ ' + DateHelper.dateTime();
			}
			
		}
		
		this.state = {
			model: model,
		};
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const target = event.target;
		const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
		const attr = target.getAttribute('id');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
	};
	
	submit = (event) => {
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		event.preventDefault();
		
		const form = event.currentTarget;
		
		const model = this.state.model;
		
		if (form.checkValidity()) {
			
			axios({
				method: model.id ? 'put' : 'post',
				url: Utils.apiUrl('department') + (model.id ? '/' + model.id : ''),
				data: model,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'DepartmentForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.afterSubmit) {
					this.props.afterSubmit(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		} else {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
		}
		
	};
	
	render() {
		
		const model = this.state.model;
		
		// const parentDepsData = [];
		// this.parentDepsData(this.props.parentDeps, parentDepsData);
		
		const parentDepsData = Department.flatList(this.props.parentDeps, [model.id]);
		
		let user = this.props.user;
		
		return (
			
			<form className={'DepartmentForm'} onSubmit={this.submit}>
				
				<label htmlFor={'is_active'}>
					<input
						id={'is_active'}
						type={'checkbox'}
						checked={model.is_active}
						onChange={this.inputChange}
					/> {i18next.t("Active")}
				</label>
				
				<hr/>
				
				<div className={'form-group name required'}>
					<label htmlFor={'name'}>{i18next.t("Title")}</label>
					<input
						id={'name'}
						name={'name'}
						type="text"
						className={'form-control'}
						value={model.name}
						onChange={this.inputChange}
						required={true}
					/>
				</div>
				
				<div className={'form-group type_id d-none'}>
					<label htmlFor={'type_id'}>
						{i18next.t("Type")}
					</label>
					<select
						id={'type_id'}
						name={'type_id'}
						className={'form-control'}
						value={model.type_id}
						onChange={this.inputChange}
						required={false}
					>
						<option value={''}>{i18next.t("None")}</option>
						{Department.types.map((type) => {
							return (
								<option value={type.id}>{i18next.t(type.name)}</option>
							);
						})}
					</select>
				</div>
				
				{(this.props.parentDeps) ? (
					<div className={'form-group parent_id' + (user.can('manageTopLevelDepartments') ? '' : ' required')}>
						<label htmlFor={'parent_id'}>
							{i18next.t("Parent department")}
						</label>
						<select
							id={'parent_id'}
							name={'parent_id'}
							className={'form-control'}
							value={model.parent_id}
							onChange={this.inputChange}
							required={!user.can('manageTopLevelDepartments')}
						>
							{(parentDepsData.length > 1) &&
								<option value={''}>{i18next.t("None")}</option>
							}
							{parentDepsData.map((dep) => {
								
								let prefix = [];
								for (let i = 0; i < dep.level; i++) {
									prefix.push('+');
								}
								prefix = prefix.join(' ');
								
								return (
									<option value={dep.id}>
										{prefix} {dep.name}
									</option>
								);
								
							})}
						</select>
					</div>
				) : ''}
				
				<div className="form-group about">
					<label htmlFor={'about'}>{i18next.t("About")}</label>
					<textarea
						id={'about'}
						className={'form-control'}
						value={model.about}
						onChange={this.inputChange}
						required={false}
						rows={5}
					/>
				</div>
				
				<div className={'form-group max_students'}>
					<label htmlFor={'max_students'}>{i18next.t("Students limit")}</label>
					<input
						id={'max_students'}
						name={'max_students'}
						type={'number'}
						className={'form-control'}
						value={model.max_students}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className={'form-group max_teachers'}>
					<label htmlFor={'max_teachers'}>{i18next.t("Teachers limit")}</label>
					<input
						id={'max_teachers'}
						name={'max_teachers'}
						type={'number'}
						className={'form-control'}
						value={model.max_teachers}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				{(user.can('manageAdmins')) &&
					<div className={'form-group max_admins'}>
						<label htmlFor={'max_admins'}>{i18next.t("Admins limit")}</label>
						<input
							id={'max_admins'}
							name={'max_admins'}
							type={'number'}
							className={'form-control'}
							value={model.max_admins}
							onChange={this.inputChange}
							placeholder={''}
							required={false}
						/>
					</div>
				}
				
				<div className="controls">
					
					<div className="row">
						
						<div className="col-6">
							{this.props.cancel && (
								<button
									type={'button'}
									className={'btn my-btn my-btn-danger'}
									onClick={(event) => {
										this.props.cancel();
									}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col-6 text-right">
							<button
								type={'submit'}
								className={'btn my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
		
		);
		
	}
	
}

DepartmentForm.propTypes = {
	model: PropTypes.instanceOf(Department),
	afterSubmit: PropTypes.func,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
	parentDeps: PropTypes.arrayOf(PropTypes.instanceOf(Department)),
	user: PropTypes.instanceOf(User).isRequired,
};

DepartmentForm.defaultProps = {
	model: null,
};