import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import axios from "axios";
import Utils from "../../helpers/Utils";
import Material from "../../models/Material";
import i18next from "i18next";
import UploadsGrid from "./UploadsGrid";
import Question from "../../models/Question";
import FormDataHelper from "../../helpers/FormDataHelper";
import DateHelper from "../../helpers/DateHelper";

// import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/theme-github";
import User from "../../models/User";

export default class TrainerForm extends React.Component {

    editors = [
        'cke',
        'textarea',
    ];

    mode = 'create';

    constructor(props) {

        super(props);

        let model = props.model ? {...props.model} : new Material();

        this.mode = props.model && props.model.id ? 'update' : 'create';

        if (this.props.themeId) {
            model.theme_id = this.props.themeId;
        }

        model.is_trainer = 1;
        model.type_alias = Material.TYPE_ALIAS_TRAINER_TEST;

        this.state = {
            model: model,
        };

        if (!props.model && Config.allowFormFake()) {
            this.state.model.name = i18next.t("Trainer") + ' ' + DateHelper.dateTime();
        }

        // << UPLOADS REFS

        this.uploadRefs = {};

        Material.uploadAttrs.forEach((attr) => {
            this.uploadRefs[attr] = React.createRef();
        });

        // >> UPLOADS REFS

    }

    inputChange = (event) => {

        const logName = 'TrainerForm.inputChange';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        const model = this.state.model;
        Logger.log(model, 'model', logAllow);

        const target = event.target;
        Logger.log(target, 'target', logAllow);

        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        Logger.log(value, 'value', logAllow);

        const attr = target.getAttribute('name');
        Logger.log(attr, 'attr', logAllow);

        model[attr] = value;

        this.setState({
            model: model,
        });

        Logger.groupEnd(logAllow);

    };

    getFormData = () => {

        const logName = 'TrainerForm.getFormData';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        const formData = new FormData();

        const model = this.state.model;
        Logger.log(model, 'model', logAllow);

        let modelProps = Object.getOwnPropertyNames(model);
        Logger.log(modelProps, 'modelProps', logAllow);

        modelProps.forEach((prop) => {
            if (Material.uploadAttrs.indexOf(prop) < 0 && !(model[prop] instanceof Function)) {
                formData.append(prop, model[prop]);
            }
        });

        let formDataValues = FormDataHelper.getValues(formData);
        Logger.log(formDataValues, 'formDataValues', logAllow);

        // files
        Object.getOwnPropertyNames(this.uploadRefs).forEach((prop) => {

            const ref = this.uploadRefs[prop];

            if (ref) {

                const refCurrent = ref.current;

                if (refCurrent) {

                    const files = refCurrent.files;
                    Logger.log(files, 'files', logAllow);

                    for (let i=0; i < files.length; i++) {
                        let file = files[i];
                        formData.append(prop + '[]', file);
                    }

                }

            }

        });

        let fromDataValues = FormDataHelper.getValues(formData);
        Logger.log(fromDataValues, 'fromDataValues', logAllow);

        Logger.groupEnd(logAllow);

        return formData;
    }

    submit = (event) => {

        const logName = 'TrainerForm.submit';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        Logger.log(this.state.model, 'this.state.model', logAllow);

        event.preventDefault();

        if (this.props.preloader) {
            this.props.preloader.show();
        }

        const form = event.currentTarget;

        if (form.checkValidity()) {

            // const data = this.state.model;
            const formData = this.getFormData();

            axios({
                method: this.mode == 'update' ? 'put' : 'post',
                url: Utils.apiUrl('materials' + (this.mode == 'update' ? '/' + this.props.model.id : '')),
                params: {
                    'accessToken': Utils.getUserToken(),
                },
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                },
                data: formData,
            }).then((response) => {

                const logName = 'TrainerForm.submit.ajax.done';
                const logAllow = 1;
                const logCollapsed = 0;

                Logger.groupStart(logName, logAllow, logCollapsed);

                Logger.log(response, 'response', logAllow);

                if (this.props.afterSubmit) {
                    this.props.afterSubmit(response, this.mode);
                }

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Logger.groupEnd(logAllow);

            }).catch((error) => {

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Utils.axiosErrorAlert(error);

            });

        }

        Logger.groupEnd(logAllow);

    };

    uploads = {

        del: (uploadBindId) => {

            const logName = 'QuestionForm.images.del';
            const logAllow = 1;
            const logCollapsed = 0;

            Logger.groupStart(logName, logAllow, logCollapsed);

            if (!window.confirm(i18next.t("Are you shure?"))) {
                return;
            }

            axios({
                method: 'put',
                url: Utils.apiUrl('upload-binds/'+uploadBindId),
                params: {
                    'accessToken': Utils.getUserToken(),
                },
                data: {
                    'active': 0,
                },
            }).then((response) => {

                const logName = 'QuestionForm.images.del';
                const logAllow = 1;
                const logCollapsed = 0;

                Logger.groupStart(logName, logAllow, logCollapsed);

                Logger.log(response, 'response', logAllow);

                let newUpload = response.data;
                let newModel = this.state.model;

                Question.uploadAttrs.forEach((attr) => {
                    newModel[attr].forEach((image, index) => {
                        if (image.id == uploadBindId) {
                            newModel.images[index] = newUpload;
                        }
                    });
                });

                this.setState((prevState) => {
                    return {
                        model: newModel,
                    }
                });

                Logger.groupEnd(logAllow);

            }).catch((error) => {
                // todo catch
            });

            Logger.groupEnd(logAllow);

        },

        afterUpd: (response, id, data) => {

            let newUpload = response.data;
            let newModel = this.state.model;

            Material.uploadAttrs.forEach((attr) => {
                newModel[attr].forEach((upload, index) => {
                    if (upload.id == id) {
                        newModel[attr][index] = newUpload;
                    }
                });
            });

            this.setState((prevState) => {
                return {
                    model: newModel,
                }
            });

        },

    };

    render() {

        const logName = 'TrainerForm.render';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        Logger.log(this.props, 'this.props', logAllow);
        Logger.log(this.state, 'this.state', logAllow);

        const model = this.state.model;

        Logger.groupEnd(logAllow);

        return (
            <form className={'TrainerForm'} onSubmit={this.submit}>

                <div className={'is_public__input-box'}>
                    <label htmlFor={'is_public'}>
                        <input
                            id={'is_public'}
                            name={'is_public'}
                            type={'checkbox'}
                            checked={model.is_public == 1}
                            onChange={this.inputChange}
                        /> {i18next.t("Published")}
                    </label>
                </div>

                <div className={'is_del__input-box'}>
                    <label htmlFor={'is_del'}>
                        <input
                            id={'is_del'}
                            name={'is_del'}
                            type={'checkbox'}
                            checked={model.is_del == 1}
                            onChange={this.inputChange}
                        /> {i18next.t("Archive")}
                    </label>
                </div>

                <hr/>

                <div className="form-group name required">
                    <label htmlFor={'name'}>{i18next.t("Title")}</label>
                    <input id={'name'}
                           name={'name'}
                           type="text"
                           className={'form-control'}
                           value={model.name || ''}
                           onChange={this.inputChange}
                           required={true}
                    />
                </div>

                <fieldset className={'icons'}>

                    <legend>{i18next.t("Icons")}</legend>

                    {model && model.icons.length > 0 && (
                        <UploadsGrid
                            uploads={model.icons}
                            afterUpd={this.uploads.afterUpd}
                            showControls={true}
                            preloader={this.props.preloader}
                        />
                    )}

                    <div className="form-group">
                        <input
                            id={'icons'}
                            type="file"
                            accept="icon/*"
                            ref={this.uploadRefs['icons']}
                            multiple={true}
                        />
                    </div>

                </fieldset>

                <hr/>

                <div className="controls">

                    <div className="row">

                        <div className="col">
                            {this.props.cancel && (
                                <button
                                    type={'button'}
                                    className={'my-btn my-btn-danger'}
                                    onClick={(event) => {
                                        this.props.cancel(event);
                                    }}
                                >{i18next.t("Cancel")}</button>
                            )}
                        </div>

                        <div className="col text-right">
                            <button
                                type={'submit'}
                                className={'my-btn my-btn-primary'}
                                onClick={(event) => {
                                    // todo
                                }}
                            >{i18next.t("Save")}</button>
                        </div>

                    </div>

                </div>

            </form>
        );

    }

}

TrainerForm.propTypes = {
    model: PropTypes.instanceOf(Material),
    typeAlias: PropTypes.string,
    themeId: PropTypes.any,
    afterSubmit: PropTypes.func,
    cancel: PropTypes.func,
    preloader: PropTypes.object,
    user: PropTypes.instanceOf(User),
};

TrainerForm.defaultProps = {
    model: null,
};