import i18next from "i18next";
import React from "react";
import Utils from "../helpers/Utils";
import Logger from "../helpers/Logger";
import Answer from "./Answer";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import Config from "../helpers/Config";

export default class Question {
	
	static jsonAttrs = [
		'variants', 
		'accordances', 
		'name_locales', 
		'about_locales',
		'right_answer_templates',
	];

	static likeSearchAttrs = [
		'name', 
		'about', 
		'variants', 
		'accordances', 
		'answer', 
		'created_at',
	];

	static uploadAttrs = [
		'images', 
		'sounds', 
		'different_files',
	];

	static viewTypes = {
		
		poll: "Choosing variants",
		order: "Sorting",
		accordance: "Accordances",
		string: "Free input",
		files: "Files",
		
		handwrite_ocr: "File with handwritten text + automatic recognition (OCR)",
		
		completion: "Completion (table)",
		completion_write: "Completion (free input)",
		completion_select: "Completion (select from list)",
		completion_cloud: "Completion (select from \"cloud\")",
		
	};

	static answersTypes = {
		date: "Date",
		files: "Files",
		float: "Float",
		asciiMath: "Formula",
		int: "Integer",
		text: "Multistring text",
		rich_text: "Rich text",
		string: "String",
		asciiMath_and_text: "Text with formulas",
	};

	static grades = [
		0,
		25,
		50,
		75,
		100,
	];
	
	// конструкция, определяющая место для подстановки поля ввода ответа
	static completionPlug = '_';
	
	static completionPlugRegex = /_+[ a-zA-Zа-яА-Я0-9ё\-?!«»'".,:;]*\\/gi;
	static completionPlugRegexWithGroups = /(_+)([ a-zA-Zа-яА-Я0-9ё\-?!«»'".,:;]*)\\/gi;
	
	// static completionPlugRegex = /_+.*\\/gi;
	// static completionPlugRegexWithGroups = /(_+)(.*)\\/gi;
	
	static completionInputMinSize = 30;
	
	static formulaRegex = /##[^#]*##/gi;
	static formulaHelp = (
		<small className={'form-text text-muted formula-help'}>
			You can place formulas inside the text, using <code>##</code> at the start and the end of formula
			and <a href={Config.asciiMathSyntaxUrl} target={'_blank'}>AsciiMath syntax</a> between them
			<br/>Example: <code>## sum_(i=1)^n i^3=((n(n+1))/2)^2 ##</code>
		</small>
	);
	
	constructor(data = {}) {
		
		const logName = 'Question.constructor';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(data, 'data', logAllow);
		
		this.id = parseInt(data.id);
		this.is_group = data.is_group || 0;
		
		this.group_items = data.group_items
			? data.group_items.map(x => new Question(x))
			: [];
		
		this.view_type_alias = data.view_type_alias || '';
		this.name = data.name || '';
		this.about = data.about || '';
		this.created_at = data.created_at || '';
		
		this.use_shuffle = parseInt(data.use_shuffle);
		this.use_comments = data.use_comments
		
		this.answer = data.answer || '';
		
		// ================================================
		// <<< ANSWER VARIANTS
		// ================================================
		
		// this.variants = data.variants === '[]' || data.variants === [] || data.variants === 'undefined'
		//     ? {}
		//     : (
		//         typeof data.variants === 'string'
		//             ? JSON.parse(data.variants)
		//             : data.variants
		//     );
		//
		// if (this.variants === null || this.variants === undefined) {
		//     this.variants = {};
		// }
		
		this.variants = {};
		
		if (typeof data.variants === 'string') {
			try {
				let x = JSON.parse(data.variants);
				if (x == []) {
					x = {};
				}
				this.variants = x;
			} catch (e) {
				Logger.log(e, 'e', logAllow);
			}
		} else {
			// this.variants = data.variants;
		}
		
		// ================================================
		// >>> ANSWER VARIANTS
		// ================================================
		
		// ================================================
		// <<< ACCORDANCES
		// ================================================
		
		// this.accordances = data.accordances === '[]' || data.accordances === [] || data.accordances === 'undefined'
		//     ? {}
		//     : (
		//         typeof data.accordances === 'string'
		//             ? JSON.parse(data.accordances)
		//             : data.accordances
		//     );
		//
		// if (this.accordances === null || this.variants === undefined) {
		//     this.accordances = {};
		// }
		
		this.accordances = {};
		
		if (typeof data.accordances === 'string') {
			try {
				let x = JSON.parse(data.accordances);
				if (x == []) {
					x = {};
				}
				this.accordances = x;
			} catch (e) {
				Logger.log(e, 'e', logAllow);
			}
		} else {
			// this.accordances = data.accordances;
		}
		
		// ================================================
		// >>> ACCORDANCES
		// ================================================
		
		// delete this.variants['tpl'];
		// delete this.accordances['tpl'];
		
		this.images = data.images || [];
		this.sounds = data.sounds || [];
		this.different_files = data.different_files || [];
		
		// this.variantsArray = Utils.objToArray(this.variants, true);
		// this.accordancesArray = Utils.objToArray(this.accordances, true);
		
		this.bind_id = data.bind_id;
		this.bind_sort = data.bind_sort;
		
		this.completion_type = data.completion_type;
		
		this.states = data.states ? data.states : [];
		
		// this.answers = data.answers
		//     ? data.answers.map(answerData => new Answer(answerData))
		//     : []
		// ;
		
		this.latest_answer = data.latest_answer && data.latest_answer instanceof Answer
			? this.latest_answer
			: (data.latest_answer
					? new Answer(data.latest_answer)
					: null
			)
		;
		
		this.need_manual_check = data.need_manual_check === undefined ? 0 : data.need_manual_check;
		
		this.files_as_answers = data.files_as_answers === undefined ? 0 : data.files_as_answers;
		this.files_as_answers_count = data.files_as_answers_count === undefined ? 0 : data.files_as_answers_count;
		this.has_formulas = data.has_formulas === undefined ? 0 : data.has_formulas;
		this.answers_type_alias = data.answers_type_alias === undefined ? 'string' : data.answers_type_alias;
		this.tables = data.tables === undefined ? '' : data.tables;
		this.weight = data.weight === undefined ? 1 : parseFloat(data.weight);
		this.is_weight_visible = data.is_weight_visible === undefined ? 0 : data.is_weight_visible;
		
		this.bg_style_alias = data.bg_style_alias || '0';
		this.border_style_alias = data.border_style_alias || '0';
		
		this.poll_mode = data.poll_mode || 'any';
		this.material_id = data.material_id;
		this.copy_of_id = data.copy_of_id;
		
		this.is_card = data.is_card;
		this.card_front_text = data.card_front_text;
		this.card_back_text = data.card_back_text;
		
		this.is_group = data.is_group;
		this.group_id = data.group_id;
		this.group_sort = data.group_sort;

		this.use_right_answer_templates = data.use_right_answer_templates;

		this.right_answer_templates = [];

		// answers templates
		// шаблоны ответов
		if (typeof data.right_answer_templates === 'string') {
			try {
				this.right_answer_templates = JSON.parse(data.right_answer_templates);
				this.right_answer_templates.sort((a, b) => {
					a = parseInt(a.grade)
					b = parseInt(b.grade)
					if (a == b) {
						return 0;
					} else {
						return a > b ? -1 : 1;
					}
				})
			} catch (rightAnswerTemplatesJsonParseError) {
				Logger.log(rightAnswerTemplatesJsonParseError, 'rightAnswerTemplates_jsonParseError', logAllow);
			}
		} else {
			if (Array.isArray(data.right_answer_templates)) {
				this.right_answer_templates = data.right_answer_templates;
			}
		}
		
		this.is_variant = data.is_variant;
		
		this.children = Array.isArray(data.children) ? data.children.map(data => new Question(data)) : [];
		
		Logger.groupEnd(logAllow);
		
	}
	
	getRightVariantsCount = () => {
		
		let count = 0;
		
		Object.getOwnPropertyNames(this.variants).forEach(key => {
			let variantData = this.variants[key];
			if (variantData.is_right) {
				count++;
			}
		});
		
		return count;
		
	}
	
	getTypeName = () => {
		return Question.viewTypes[this.view_type_alias];
	}
	
	hasImages = () => {
		return this.images.filter(uploadBind => uploadBind.active == 1).length > 0;
	};
	
	hasSounds = () => {
		return this.sounds.filter(uploadBind => uploadBind.active == 1).length > 0;
	};
	
	hasDifferentFiles = () => {
		return this.sounds.filter(uploadBind => uploadBind.active == 1).length > 0;
	};
	
	hasFiles1 = () => {
		return this.hasImages() || this.hasSounds() || this.hasDifferentFiles();
	};
	
	hasFiles = (attrs = ['images', 'sounds', 'different_files'], activeOnly = 1) => {
		
		let filesCount = 0;
		
		if (!Array.isArray(attrs)) {
			attrs = [attrs];
		}
		
		attrs.forEach(attr => {
			let files = this[attr];
			filesCount += activeOnly ? files.filter(uploadBind => uploadBind.active == 1).length : files.length;
		})
		
		return filesCount > 0;
		
	};
	
	getVariantsByPosition = (position, variantsArray) => {
		
		let out = [];
		
		if (variantsArray) {
			
			variantsArray.forEach((variant) => {
				if (variant.position == position) {
					out.push(variant);
				}
			});
			
		} else {
			
			Object.getOwnPropertyNames(this.variants).forEach((key) => {
				let variant = this.variants[key];
				if (variant.position == position) {
					variant.alias = key;
					out.push(variant);
				}
			});
			
		}
		
		return out;
		
	};
	
	getVariantsArray = (shuffle) => {
		
		let out = [];
		
		if (Array.isArray(this.variants)) {
			out = this.variants;
		} else {
			Object.getOwnPropertyNames(this.variants).forEach((alias) => {
				let variant = this.variants[alias];
				variant.alias = alias;
				out.push(variant);
			});
		}
		
		if (shuffle) {
			out = Utils.arrayShuffle(out);
		}
		
		return out;
		
	};
	
	getRightVariants = () => {
		
		let out = [];
		
		Object.getOwnPropertyNames(this.variants).forEach((alias) => {
			let variant = this.variants[alias];
			if (variant.is_right) {
				variant.alias = alias;
				out.push(variant);
			}
		});
		
		return out;
		
	};
	
	getAccordancesArray = (shuffle = 0) => {
		
		const logName = 'Question.getAccordancesArray';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this, 'this', logAllow);
		
		if (Array.isArray(this.accordances)) {
			Logger.groupEnd(logAllow);
			return this.accordances;
		}
		
		let index = 0;
		let out = [];
		
		Object.getOwnPropertyNames(this.accordances).forEach((alias) => {
			let accordance = this.accordances[alias];
			accordance.alias = alias;
			accordance.right_position = index;
			out.push(accordance);
		});
		
		if (shuffle) {
			out = Utils.arrayShuffle(out);
		}
		
		Logger.groupEnd(logAllow);
		
		return out;
		
	}
	
	hasRightAnswer = () => {
		return this.latest_answer && this.latest_answer.is_right;
	};
	
	static gridViewCols = (extraCols = {}) => {
		
		let cols = {
			
			id: {
				name: 'ID',
				width: '100px',
				filter: 'number',
			},
			
			view_type_alias: {
				name: i18next.t('Тип'),
				filter: Question.viewTypes,
				value: (question) => {
					return Question.viewTypes[question.view_type_alias];
				},
				style: {
					width: '150px',
				},
			},
			
			name: {
				name: i18next.t('Заголовок'),
				filter: 'text',
				style: {
					width: '250px',
				},
			},
			
			about: {
				name: i18next.t('Текст'),
				value: (question) => {
					// return question.about;
					return <pre>{question.about}</pre>;
				},
				filter: 'text',
			},
			
			answers: {
				name: i18next.t('Ответы'),
				value: (question) => {
					
					if (['poll'].indexOf(question.view_type_alias) >= 0) {
						
						let variants = question.variants;
						let aliases = Object.getOwnPropertyNames(variants);
						
						return aliases.length > 0 ? (
							<table className={'table-sm'}>
								{/*<thead>
                                    <th>{i18next.t("Вариант")}</th>
                                    <th>{i18next.t("Правильный")}</th>
                                </thead>*/}
								<tbody>
								{aliases.map((alias) => {
									let variant = variants[alias];
									return (
										<tr className={variant.is_right ? 'table-success' : 'table-danger'}>
											<td>{variant.name}</td>
											{/*<td>{variant.is_right ? i18next.t("Yes") : i18next.t("No")}</td>*/}
										</tr>
									);
									
								})}
								</tbody>
							</table>
						) : '';
						
					}
					
					if (['accordance', 'completion'].indexOf(question.view_type_alias) >= 0) {
						
						const logName = 'QuestionView.value';
						const logAllow = 1;
						const logCollapsed = 0;
						
						Logger.groupStart(logName, logAllow, logCollapsed);
						
						Logger.log(question, 'question', logAllow);
						
						let accordances = question.accordances;
						Logger.log(accordances, 'accordances', logAllow);
						
						let aliases = Object.getOwnPropertyNames(accordances);
						Logger.log(aliases, 'aliases', logAllow);
						
						Logger.groupEnd(logAllow);
						
						return aliases.length > 0 ? (
							<table className={'table-sm'}>
								{/*<thead>*/}
								{/*    <th>{i18next.t("Вариант")}</th>*/}
								{/*    <th>{i18next.t("Правильный")}</th>*/}
								{/*</thead>*/}
								<tbody>
								{aliases.map((alias) => {
									let accordance = accordances[alias];
									return (
										<tr>
											<td>{accordance.left}</td>
											<td>{accordance.right}</td>
										</tr>
									);
									
								})}
								</tbody>
							</table>
						) : '';
						
					}
					
					if (['order'].indexOf(question.view_type_alias) >= 0) {
						
						let accordances = question.accordances;
						let aliases = Object.getOwnPropertyNames(accordances);
						
						return aliases.length > 0 ? (
							<ol>
								{aliases.map((alias) => {
									let accordance = accordances[alias];
									return (
										<li>{accordance.left}</li>
									);
									
								})}
							</ol>
						) : '';
						
					}
					
					if (['string'].indexOf(question.view_type_alias) >= 0) {
						return question.answer;
					}
					
				}
			},
			
			created_at: {
				name: i18next.t("Created at"),
				value: (question) => {
					// return dateFormat(question.created_at, '');
					return new Date(question.created_at).toLocaleString();
				},
				filter: 'text',
			},
			
		};
		
		Object.getOwnPropertyNames(extraCols).forEach((colAlias) => {
			cols[colAlias] = extraCols[colAlias];
		});
		
		return cols;
		
	};
	
	static getCompletionAnswers = (text) => {
		
		// todo optimize
		
		const logName = 'QuestionView.getCompletionAnswers';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let answers = [];
		
		let data = [...text.matchAll(Question.completionPlugRegexWithGroups)];
		// let data = text.match(QuestionView.completionPlugRegex);
		Logger.log(data, 'data', logAllow);
		
		if (data) {
			data.forEach((dataItem, index) => {
				
				Logger.log(dataItem, 'dataItem', logAllow);
				
				let answer = {
					size: dataItem[1].length,
					text: dataItem[2],
				};
				
				answers.push(answer);
				
			});
		}
		
		Logger.log(answers, 'answers', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return answers;
		
	};
	
	// подготовить текст с AsiiMath формулами к рендерингу
	static renderableFormulas = (text, addBrs = 1, dynamic = false) => {
		
		const logName = 'Question.renderableFormulas';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let out = [];
		
		let strings = text.split("\n");
		
		let formulas = Question.getFormulas(text);
		Logger.log(formulas, 'formulas', logAllow);
		
		let formulaIndex = 0;
		
		strings.forEach((string) => {
			
			let stringData = string.split(Question.formulaRegex);
			Logger.log(stringData, 'stringData', logAllow);
			
			stringData.forEach((stringDataItem, stringIndex) => {
				
				// Logger.log(stringDataItem, 'stringDataItem', logAllow);
				
				out.push(
					<span
						dangerouslySetInnerHTML={{__html: stringDataItem}}
						// key={'string_' + stringIndex}
					></span>
				);
				
				let isNotLast = stringIndex < stringData.length - 1;
				let isEmpty = stringDataItem === '';
				let formulaText = formulas[formulaIndex];
				Logger.log(formulaText, 'formulaText', logAllow);
				
				if (isNotLast || (isNotLast && isEmpty)) {
					// let formula = formulaText;
					let formula = (
						<MathJax
							dynamic={dynamic}
							inline={true}
						>{"`" + formulaText + "`"}</MathJax>
					);
					out.push(formula);
					formulaIndex++;
				}
				
			});
			
			if (addBrs && stringData.length > 0) {
				out.push(<br/>);
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
		return (
			<MathJaxContext config={{loader: {load: ["input/asciimath"]}}}>
				{out}
			</MathJaxContext>
		);
		
	};
	
	static getFormulas = (text) => {
		
		const logName = 'QuestionView.getFormulas';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(text, 'text', logAllow);
		
		let formulas = [];
		
		let data = text.match(Question.formulaRegex);
		Logger.log(data, 'data', logAllow);
		
		if (data) {
			data.forEach((dataItem, index) => {
				// let formula = dataItem.substring(2, -2);
				let formula = dataItem.substring(2, dataItem.length - 2);
				Logger.log(formula, 'formula', logAllow);
				formulas.push(formula);
			});
		}
		
		Logger.log(formulas, 'formulas', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return formulas;
		
	};
	
	isCompletion = () => {
		return this.view_type_alias.indexOf('completion') >= 0;
	};
	
	hasTables = () => {
		return this.tables && this.tables !== 'null';
	};
	
	isCard = () => {
		return ['card', 'cards'].includes(this.view_type_alias);
	};
	
	isGroup = () => {
		return this.view_type_alias == 'group';
	};
	
	isRegular = () => {
		return ![
			'group',
			'card',
			'cards',
		].includes(this.view_type_alias)
	};
	
	isManualCheckAllowed = () => {
		return !['accordance', 'order', 'poll'].includes(this.view_type_alias);
	}
	
	isManualCheckRequired = () => {
		return ['files'].includes(this.view_type_alias)
	}

	static canUseRightAnswerTemplates = (answerTypeAlias) => {
		return !['rich_text', 'files'].includes(answerTypeAlias)
	}
	
}