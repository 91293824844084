import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import "./StudentHomePage.css";
import Calendar from "react-calendar";
import Logger from "../../../helpers/Logger";
import axios from "axios";
import Utils from "../../../helpers/Utils";
import Config from "../../../helpers/Config";
import Answer from "../../../models/Answer";
import Question from "../../../models/Question";
import QuestionView from "../../common/QuestionView";
import User from "../../../models/User";
import DateHelper from "../../../helpers/DateHelper";
import Sched from "../../../models/Sched";
import SchedsSmallTable from "../../common/SchedsSmallTable";
import { Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Material from '../../../models/Material';
import ListItem from '../../common/ListItem';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default class StudentHomePage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			notifications: [
				// 'Added a new test task on the topic “Знаки препинания”',
				// 'The teacher gave grades for the test work “Существительное”',
				// 'Tomorrow full-time lesson at 13.00',
				// 'The teacher gave grades for the test work “Глагол”',
			],
			
			scheds: [],
			
			homework: [],
			
			exams: [],
			
			currentDate: new Date(),
			
			// сообщения
			answeredQuestions: [],
			answeredQuestionsModalIsShown: false,
			
		};
		
	}
	
	// расписание
	scheds = {
		
		load: (date, afterSuccess) => {
			
			const logName = 'StudentHomePage.scheds.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const dateFormatted = DateHelper.formatDate({
				date: date,
				format: 'y-m-d',
			});
			Logger.log(dateFormatted, 'dateFormatted', logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('scheds'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[date]': dateFormatted,
					'filter[is_active]': 1,
					'sort': 'time',
				},
			}).then((response) => {
				
				const logName = 'StudentHomePage.scheds.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let scheds = response.data.map(schedData => new Sched(schedData));
				Logger.log(scheds, 'scheds', logAllow);
				
				this.setState((prevState) => {
					return {
						scheds: scheds,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (afterSuccess) {
					afterSuccess()
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide()
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	// домашка
	homework = {
		
		load: (afterSuccess) => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('questions/get-homework'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'StudentHomePage.homework.load';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let qustions = response.data.map((questionData, questionDataIndex) => {
					return new Question(questionData);
				});
				
				this.setState((prevState) => {
					return {
						homework: qustions,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (afterSuccess) {
					afterSuccess()
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				Utils.axiosErrorAlert(error);
				
				if (this.props.preloader) {
					// this.props.preloader.hide();
				}
				
			});
			
		},
		
	};
	
	// контрольные
	exams = {
		
		load: (afterSuccess) => {
			
			this.props.preloader?.show()
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials/exams'),
				params: {
					accessToken: Utils.getUserToken(),
				},
				data: {},
			}).then((response) => {
				
				const logName = 'StudentHomePage.exams.load.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				this.props.preloader?.hide()
				
				let exams = response.data.map(x => new Material(x))
				Logger.log(exams, 'exams', logAllow)
				
				this.setState({
					exams: exams,
				})
				
				if (afterSuccess) {
					afterSuccess(exams)
				}
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				
				const logName = 'StudentHomePage.exams.load.ajax.fail'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				this.props.preloader?.hide()
				
				// console.log(`axiosError = %o`, axiosError)
				// window.alert(axiosError)
				
				Logger.log(axiosError, 'axiosError', logAllow)
				
				Logger.log(axiosError.response.data, 'axiosError.response.data', logAllow)
				
				Logger.groupEnd(logAllow)
				
			})
			
		}
		
	}
	
	msg = {
		
		loadAnsweredQuestions: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show()
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('msg/answered-questions'),
				data: {},
				params: {
					accessToken: Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'StudentHomePage.msg.loadAnsweredQuestions.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				Logger.log(response, 'response', logAllow)
				
				let answeredQuestions = response.data.map(x => new Question(x))
				Logger.log(answeredQuestions, 'answeredQuestions', logAllow)
				
				this.setState({
					answeredQuestions: answeredQuestions,
				})
				
				if (this.props.preloader) {
					this.props.preloader.hide()
				}
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide()
				}
				
				console.log(`axiosError = %o`, axiosError)
				
				window.alert(axiosError)
				
			})
			
		},
		
	};
	
	componentDidMount() {
		
		this.scheds.load(this.state.currentDate, () => {
			this.homework.load(() => {
				this.exams.load(() => {
					this.msg.loadAnsweredQuestions();					
				})
			});
		});
		
	}
	
	render() {
		
		return (
			
			<div className={'student-home-page'}>
				
				<div className="row">
					
					<div className="col-xl-8 col-lg-7 col-md-6">
						
						<div className={`section-set top-left-section-set`}>
							
							<div className={`row`}>
								
								{/* <div className={`col-xl-8`}>
									
									<section className={`new-answers`}>
										
										<h2>{i18next.t("New answers")}</h2>
										
										<div className={`list`}>
											
											{(this.state.answeredQuestions.length > 0) ? (
												
												<>
												
													<div className={`list-item`}>
														<span className={`red-dot`}></span> {i18next.t('Answered questions')}
													</div>
												
												</>
												
											) : (
												
												<>
												
													<div className={`list-item`}>
														{i18next.t('No aswers yet')}
													</div>
												
												</>
												
											)}
											
										</div>
										
									</section>
									
								</div> */}
								
								<div className={`col`}>
									
									<section className="notifications">
									
										<h2>
											{i18next.t("Notifications")}
											&nbsp;<button
												className={'my-btn my-bt-sm'}
												onClick={this.msg.loadAnsweredQuestions}
											><Icon.ArrowCounterclockwise/></button>
										</h2>
										
										<div className="list">
											
											{(this.state.answeredQuestions.length > 0) && (
												
												<>
												
													<div 
														className={`list-item list-item-active`}
														onClick={(event) => {
															this.setState({
																answeredQuestionsModalIsShown: !this.state.answeredQuestionsModalIsShown,
															})
														}}
													>
														<span className={`red-dot`}></span>{i18next.t('Answered questions')}
														<sup>&nbsp;<small className={`text-muted`}>{this.state.answeredQuestions.length}</small></sup>
													</div>
												
												</>
												
											)}
											
											{(this.state.notifications.length > 0) ? (
												
												this.state.notifications.map((text) => {
													return (
														<div className="list-item list-item-active notification">
															{text}
														</div>
													);
												})
											
											) : (
												
												<>
												
													{(this.state.answeredQuestions.length < 1) && (
														<div className="list-item">
															{i18next.t("Notifications not found")}
														</div>
													)}
													
												</>
												
											)}
										
										</div>
									
									</section>
									
								</div>
								
							</div>
							
						</div>
						
						<section className={`exams`}>
							
							<h2>
								{i18next.t("Tests")}&nbsp;
								<button
									className={'my-btn my-bt-sm load-btn exams-load-btn'}
									onClick={() => {
										this.exams.load()
									}}
								><Icon.ArrowCounterclockwise/></button>
							</h2>
							
							{(this.state.exams.length > 0) ? (
								
								<div className={`list`}>
									
									{this.state.exams.map((exam) => {
										
										return (
											
											<Link
												to={'/courses'}
												className={`list-item list-item-active tdn`}
												onClick={(event) => {
													exam.setAsTarget()
												}}
											>
												
												<div className={`exam-name`}>
													<span className={`red-dot`}></span>{exam.name}
												</div>
												
												<div className={`exam-path small text-muted`}>
													{exam.getPath()}
												</div>
												
											</Link>
											
										)
										
									})}
									
								</div>
								
							) : (
								
								<div className={`list`}>
								
									<ListItem>{i18next.t("Tests not found")}</ListItem>
								
								</div>
								
							)}
							
						</section>
						
						<section className="checklist">
							
							<h2>
								{i18next.t("Homework")}&nbsp;
								<button
									className={'my-btn my-bt-sm reload-btn homework-reload-btn'}
									onClick={() => {
										this.homework.load()
									}}
								><Icon.ArrowCounterclockwise/></button>
							</h2>
							
							{this.state.homework.length > 0 ? (
								
								this.state.homework.map((question, questionInxdex) => {
									
									const logName = 'StudentHomePage.render.homework.map';
									const logAllow = 0;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									Logger.log(question, 'question', logAllow);
									
									Logger.groupEnd(logAllow);
									
									return (
										<div
											className={[
												'list-item',
												'question-box',
												question.latest_answer
													? (question.latest_answer.is_right ? 'right-answer' : 'wrong-answer')
													: '',
											].join(' ')}
											key={'homework-question_' + question.id}
										>
											<QuestionView
												question={question}
												user={this.props.user}
												answers={[]}
												mode={'pass'}
												preloader={this.props.preloader}
												afterSendAnswer={(response, answer) => {
													
													const logName = 'StudentHomePage.homework.QuestionView.afterSendAnswer'
													const logAllow = 1
													const logCollapse = 0
													
													Logger.groupStart(logName, logAllow, logCollapse)
													
													Logger.log(answer, 'answer', logAllow)
													
													question.latest_answer = answer
													
													let homework = this.state.homework
													
													let homeworkQuestionIndex = homework.findIndex(x => x.id == question.id)
													
													homework[homeworkQuestionIndex] = question
													
													this.setState((prevState) => {
														return {
															homework: homework,
														}
													});
													
													Logger.groupEnd(logAllow)
													
												}}
											/>
										</div>
									);
								})
							
							) : (
								
								<div className={'list'}>
									<div className={'list-item'}>
										{i18next.t("Homework not found")}
									</div>
								</div>
							
							)}
						
						</section>
					
					</div>
					
					<div className="col-xl-4 col-lg-5 col-md-6">
						
						<section className="calendar-box">
							
							<div className="h2">{i18next.t("Schedule")}</div>
							
							<div className="list">
								<Calendar
									value={this.state.currentDate}
									onChange={(date) => {
										this.setState((prevState) => {
											return {
												currentDate: date,
											}
										});
										this.scheds.load(date);
									}}
								/>
							</div>
						
						</section>
						
						<section className="sched">
							
							<div className="list">
								
								{this.state.scheds.length > 0 ? (
									
									<SchedsSmallTable
										scheds={this.state.scheds}
									/>
								
								) : (
									
									<div className={'list-item'}>
										{i18next.t("No schedule for selected date")}
									</div>
								
								)}
							
							</div>
						
						</section>
					
					</div>
				
				</div>
				
				<div className={`modals`}>
					
					{(this.state.answeredQuestionsModalIsShown) && (
						
						<>
						
							<Modal
								className={[
									'msg-modal',
									// 'wide-modal',
								].join(' ')}
								show={this.state.answeredQuestionsModalIsShown}
								onHide={() => {
									this.setState({
										answeredQuestionsModalIsShown: false,
									})
								}}
								size={'lg'}
								// keyboard={false}
								// backdrop={'static'}
							>
								
								<Modal.Header closeButton>
									<Modal.Title>
										{/* <span className={`red-dot`}></span> */}
										{i18next.t('Answered questions')}
									</Modal.Title>
								</Modal.Header>
								
								<Modal.Body>
									
									{this.state.answeredQuestions.map((question) => {
										
										return (
											
											<div className={`question-box`}>
												
												<QuestionView
													key={'question-' + question.id}
													question={question}
													mode={'demo'}
													preloader={this.props.preloader}
													user={this.props.user}
													showPath={true}
													showDiscuss={true}
													showClearBtn={false}
												/>
												
											</div>
											
										)
										
									})}
									
								</Modal.Body>
							
							</Modal>
						
						</>
						
					)}
					
				</div>
			
			</div>
		
		);
	}
	
}

StudentHomePage.propTypes = {
	user: PropTypes.instanceOf(User).isRequired,
	breadcrumbs: PropTypes.object,
	preloader: PropTypes.object,
};

StudentHomePage.defaultProps = {};