import './QuestionGroupView.css';

import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import User from "../../models/User";
import Question from "../../models/Question";
import Utils from "../../helpers/Utils";
import i18next from "i18next";
import Material from "../../models/Material";
import QuestionCardView from "./QuestionCardView";
import axios from "axios";
import Config from "../../helpers/Config";

class QuestionGroupView extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			currentCardIndex: 0,
		};
		
	}
	
	cards = {
		
		get: () => {
			let group = this.props.group;
			return group ? group.group_items : [];
		},
		
		showNext: (after) => {
			
			let cards = this.cards.get();
			let oldIndex = this.state.currentCardIndex;
			let newIndex = Utils.arrayNextIndex(cards, oldIndex);
			
			this.setState((prevState) => {
				return {
					currentCardIndex: newIndex,
					isFlipped: false,
					isPlaying: false,
				}
			});
			
			if (after) {
				after();
			}
			
		},
		
		showPrev: (after) => {
			
			let cards = this.cards.get();
			let oldIndex = this.state.currentCardIndex;
			let newIndex = Utils.arrayPrevIndex(cards, oldIndex);
			
			this.setState((prevState) => {
				return {
					currentCardIndex: newIndex,
				}
			});
			
			if (after) {
				after();
			}
			
		},
		
		hide: (card, cardIndex, useConfirm=1) => {
			
			const logName = 'QuestionGroupView.hide';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (useConfirm) {
				if (!window.confirm(i18next.t("Hide card") + ' "' + card.card_front_text + '"?')) {
					return;
				}
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('questions') + '/' + card.id,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'QuestionGroupView.cards.hide.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				this.setState((prevState) => {
					return {
						currentCardIndex: 0,
					}
				});
				
				if (this.props.afterHideSuccess) {
					this.props.afterHideSuccess(response, cardIndex);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};

	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const logName = 'QuestionGroupView.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const user = this.props.user;
		
		let group = this.props.group;
		
		let cards = this.cards.get();
		
		let cssClass = ['QuestionGroupView'];
		
		let currentCardIndex = this.state.currentCardIndex;
		
		let currentCard = cards[currentCardIndex];
		
		let cardsCount = cards.length;
		
		let emptyCardsCount = cardsCount > 4 ? 4 : cardsCount - 1;
		let stack = [];
		
		for (let x = 0; x < emptyCardsCount; x++) {
			
			let stackItem = (
				<div className={'stack-item'}/>
			);
			
			stack.push(stackItem);
			
		}
		
		if (emptyCardsCount > 0) {
			cssClass.push('has-stack');
		}
		
		let hasControls = this.props.editCard || this.props.hideCard;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={cssClass.join(' ')}>
				
				{(cards.length > 0) ? (
					
					<>
						
						<div className="stack">
							{stack}
						</div>
						
						<div className="main-card">
							
							<QuestionCardView
								card={currentCard}
								user={user}
								nextCard={cardsCount > 1 ? this.cards.showNext : null}
								prevCard={cardsCount > 1 ? this.cards.showPrev : null}
								cardIndex={currentCardIndex}
								cardsCount={cardsCount}
								preloader={this.props.preloader}
							/>
							
						</div>
						
					</>
					
				) : (
					
					<p className={'text-center'}>{i18next.t("Empty group")} "{group.name}"</p>
					
				)}
				
				{(user.can('manageMaterials') && currentCard) &&
					
					<div className={'card-controls'}>
						
						{(this.props.editCard) &&
							<button
								type={'button'}
								className={[
									'my-btn',
									'my-btn-default',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									this.props.editCard(currentCard, currentCardIndex);
								}}
							>{i18next.t("Edit card")}</button>
						}
						
						<button
							type={'button'}
							className={[
								'my-btn',
								'my-btn-default',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								this.cards.hide(currentCard, currentCardIndex);
							}}
						>{i18next.t("Hide card")}</button>
					
					</div>
					
				}
				
			</div>
		
		);
		
	}
	
}

QuestionGroupView.propTypes = {
	
	group: PropTypes.instanceOf(Question).isRequired,
	user: PropTypes.instanceOf(User).isRequired,
	
	editCard: PropTypes.func,
	afterHideSuccess: PropTypes.func,
	
	preloader: PropTypes.object,
	
};

QuestionGroupView.defaultProps = {};

export default QuestionGroupView;