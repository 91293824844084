export default class DateHelper {
	
	static dateDefaultFormat = "d.m.y";
	static dateInputFormat = "y-m-d";
	static timeDefaultFormat = "h:m";
	
	static formatDate = ({date = Date.now(), format = this.dateInputFormat}) => {
		
		date = new Date(date);
		
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear().toString();
		
		if (day < 10) {
			day = "0" + day;
		}
		
		if (month < 10) {
			month = "0" + month;
		}
		
		let formattedDate = format;
		
		formattedDate = formattedDate.replace(/d/, day);
		formattedDate = formattedDate.replace(/m/, month);
		formattedDate = formattedDate.replace(/y/, year);
		
		return formattedDate;
		
	};
	
	static formatTime = ({time = Date.now(), format = this.timeDefaultFormat}) => {
		
		time = new Date(time);
		
		let hours = time.getHours();
		let minutes = time.getMinutes();
		let seconds = time.getSeconds();
		
		hours = hours < 10 ? "0" + hours : hours;
		minutes = minutes < 10 ? "0" + minutes : minutes;
		seconds = seconds < 10 ? "0" + seconds : seconds;
		
		let formattedTime = format;
		
		formattedTime = formattedTime.replace(/h/, hours);
		formattedTime = formattedTime.replace(/m/, minutes);
		formattedTime = formattedTime.replace(/s/, seconds);
		
		return formattedTime;
		
	};
	
	static dateTime = (
		timestamp = Date.now(),
		dateFormat = DateHelper.dateDefaultFormat,
		timeFormat = "h:m:s",
		sep = " "
	) => {
		const dateFormatted = this.formatDate({
			date: timestamp,
			format: dateFormat,
		});
		
		const timeFormatted = this.formatTime({
			time: timestamp,
			format: timeFormat,
		});
		
		return dateFormatted + sep + timeFormatted;
	};
	
	static parsePythonDate = (pythonDateTimeString) => {
		// todo
	};
	
	static parsePythonTime = (pythonDateTimeString) => {
		// todo
	};
	
	static calcAge = (bornDate, targetDate = new Date()) => {
		
		bornDate = new Date(bornDate);
		targetDate = new Date(targetDate);
		
		let bornYear = bornDate.getFullYear();
		let targetYear = targetDate.getFullYear();
		
		let age = targetYear - bornYear;
		
		let bornMonth = bornDate.getMonth();
		let targetMonth = targetDate.getMonth();
		
		if (targetMonth < bornMonth) {
			age--;
		} else if (targetMonth == bornMonth) {
			let bornDay = bornDate.getDate();
			let targetDay = targetDate.getDate();
			
			if (targetDay < bornDay) {
				age--;
			}
		}
		
		return age;
		
	};
}
