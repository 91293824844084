import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "./ListItem";

export default class SkinTestPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {

        let buttons = [
            'default',
            'success',
            'danger',
            'warning',
        ];

        let listItems = [
            10,
            20,
            30,
        ];

        let question = {
            id: 42,
            name: '',
            about: `
                Как звали бабушку Будды?
            `,
        }

        return (
            <div className={'SkinTestPage'}>

                <h1>Skin test page</h1>

                <hr/>

                <div className="row">

                    <div className="col-12">

                        <div className="buttons">
                            {buttons.map(alias => {
                                return (
                                    <button className={'my-btn my-btn-' + alias}>
                                        {alias}
                                    </button>
                                );
                            })}
                        </div>

                        <div className="list mt-3">

                            {listItems.map(progress => {
                                return (
                                    <ListItem
                                        className={[
                                            'list-item-active',
                                        ].join(' ')}
                                        progress={progress}
                                    >
                                        <div className="content">List item with progress {progress}%</div>
                                    </ListItem>
                                );
                            })}

                        </div>

                    </div>

                </div>

            </div>
        );
    }

}

SkinTestPage.propTypes = {};

SkinTestPage.defaultProps = {};