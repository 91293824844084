import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import Question from "../../models/Question";
import Logger from "../../helpers/Logger";
import Utils from "../../helpers/Utils";
import axios from "axios";
import QuestionView from "./QuestionView";
import User from "../../models/User";

class QuestionPage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			question: null,
		};
		
	}
	
	getQuestionId = () => {
		return this.props.match.params.questionId;
	}
	
	load = () => {
		
		const logName = 'QuestionPage.load';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const questionId = this.getQuestionId();
		Logger.log(questionId, 'questionId', logAllow);
		
		if (questionId) {
			axios({
				method: 'get',
				// url: Utils.apiUrl('questions'),
				url: Utils.apiUrl('questions/' + questionId),
				data: {},
				params: {
					accessToken: Utils.getUserToken(),
					// id: questionId,
				},
			}).then((response) => {
				
				const logName = 'QuestionPage.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				let question = new Question(response.data);
				
				this.setState((prevState) => {
					return {
						question: question,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	componentDidMount() {
		this.load();
	}
	
	render() {
		
		const logName = 'QuestionPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);

		Logger.log(this.props, 'this.props', logAllow);
		
		const questionId = this.getQuestionId();
		const question = this.state.question;
		const user = this.props.user;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'QuestionPage'}>
				
				<h1>Question #{questionId}</h1>
				
				{(question) &&
					<div className={'question-box list'}>
						<QuestionView
							question={question}
							user={user}
							preloader={this.props.preloader}
						/>
					</div>
				}
			
			</div>
		
		);
		
	}
	
}

QuestionPage.propTypes = {
	
	questionsId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	question: PropTypes.instanceOf(Question),
	
	user: PropTypes.instanceOf(User).isRequired,
	
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	
	preloader: PropTypes.object,
	
};

QuestionPage.defaultProps = {};

export default withRouter(QuestionPage);
