import React from 'react';
import PropTypes from 'prop-types';
import Range from "../../helpers/Range";
import Rand from "../../helpers/Rand";
import "./Pager.css";
import Pagination from "react-bootstrap/Pagination";
import Logger from "../../helpers/Logger";


export default class Pager extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	firstPage = () => {
		this.props.pageClick(0);
	};
	
	lastPage = () => {
		const pagesCount = Math.ceil(this.props.pagesCount);
		let newPageIndex = this.props.pagesCount > 1 ? pagesCount - 1 : this.props.firstPageIndex;
		this.props.pageClick(newPageIndex);
	};
	
	nextPage = () => {
		
		const logName = 'Pager.nextPage'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let pagesCount = Math.ceil(parseFloat(this.props.pagesCount));
		Logger.log(pagesCount, 'pagesCount', logAllow)
		
		Logger.log(this.props.currentPageIndex, 'this.props.currentPageIndex', logAllow)
		
		let currentPageIndex = parseInt(this.props.currentPageIndex);
		Logger.log(currentPageIndex, 'currentPageIndex', logAllow)
		
		let newPageIndex = (currentPageIndex + 1) % pagesCount;
		Logger.log(newPageIndex, 'newPageIndex', logAllow)
		
		this.props.pageClick(newPageIndex);
		
		Logger.groupEnd(logAllow)
		
	};
	
	prevPage = () => {
		const pagesCount = Math.ceil(this.props.pagesCount);
		let currentPageIndex = parseInt(this.props.currentPageIndex);
		let newPageIndex = currentPageIndex > this.props.firstPageIndex ? currentPageIndex - 1 : pagesCount - 1;
		this.props.pageClick(newPageIndex);
	};
	
	render() {
		
		const logName = 'Pager.render';
		const logAllow = 1;
		const logCollapsed = 1;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const currentPageIndex = this.props.currentPageIndex;
		const pagesCount = Math.ceil(this.props.pagesCount);
		Logger.log(pagesCount, 'pagesCount', logAllow);
		
		let first = <Pagination.Item key={'first-page'} onClick={this.firstPage}>&laquo;</Pagination.Item>;
		let last = <Pagination.Item key={'last-page'} onClick={this.lastPage}>&raquo;</Pagination.Item>;
		let prev = <Pagination.Item key={'prev-page'} onClick={this.prevPage}>&lt;</Pagination.Item>;
		let next = <Pagination.Item key={'next-page'} onClick={this.nextPage}>&gt;</Pagination.Item>;
		
		let range = Range.get(this.props.firstPageIndex, pagesCount - 1);
		
		let pages = range.map((pageIndex) => {
			return (
				<Pagination.Item
					key={'page-'+pageIndex}
					active={pageIndex === currentPageIndex}
					onClick={() => {
						this.props.pageClick(pageIndex);
					}}
				>{pageIndex + 1}</Pagination.Item>
			);
		});
		
		if (this.props.useDropdown) {
			pages = (
				<select
					className={'form-control'}
					value={currentPageIndex}
					onChange={(event) => {
						let pageIndex = parseInt(event.target.value);
						this.props.pageClick(pageIndex);
					}}
				>
					{range.map((pageIndex) => {
						return (
							<option
								key={'page-'+pageIndex}
								value={pageIndex}
							>{pageIndex + 1} / {pagesCount}</option>
						);
					})}
				</select>
			);
		}
		
		let out = [];
		
		switch (this.props.controlsConfig) {
			
			case 'start':
				
				if (this.props.showFirstLastControls) {
					out.push(
						<div className="col-auto first-last-col">
							<Pagination key={Rand.id()} className={'pager-part'} aria-label="pagination">
								{first}{last}
							</Pagination>
						</div>
					);
				}
				
				if (this.props.showNextPrevControls) {
					out.push(
						<div className="col-auto prev-next-col">
							<Pagination key={Rand.id()} className={'pager-part'} aria-label="pagination">
								{prev}{next}
							</Pagination>
						</div>
					);
				}
				
				out.push(
					<div className="col-auto pages-col text-right">
						<Pagination key={Rand.id()} className={'pager-part'} aria-label="pagination">
							{pages}
						</Pagination>
					</div>
				);
				
				break;
			
			case 'edge':
				
				if (this.props.showFirstLastControls) {
					out.push(first);
				}
				
				if (this.props.showNextPrevControls) {
					out.push(prev);
				}
				
				out.push(pages);
				
				if (this.props.showNextPrevControls) {
					out.push(next);
				}
				
				if (this.props.showFirstLastControls) {
					out.push(last);
				}
				
				out = (
					<div className="col pages-col">
						<Pagination>
							<nav className={'pagination'}>
								{out}
							</nav>
						</Pagination>
					</div>
				);
				
				break;
			
			default:
				
				out = (
					<div className="col">
						<Pagination>
							<nav className={'pagination'}>
								{pages}
							</nav>
						</Pagination>
					</div>
				);
			
		}
		
		Logger.groupEnd(logAllow);
		
		return this.props.pagesCount > 1 ? (
			<div className={'pager'}>
				<div className="row">
					{out}
				</div>
			</div>
		) : '';
		
	}
	
}

Pager.propTypes = {
	
	firstPageIndex: PropTypes.number,
	currentPageIndex: PropTypes.number,
	pagesCount: PropTypes.number,
	pageClick: PropTypes.func,
	
	size: PropTypes.string,
	
	showNextPrevControls: PropTypes.bool,
	showFirstLastControls: PropTypes.bool,
	
	controlsConfig: PropTypes.oneOf([
		'start',
		'edge',
	]),
	
	useDropdown: PropTypes.bool,
	
};

Pager.defaultProps = {
	firstPageIndex: 0,
	currentPageIndex: 0,
	pagesCount: 1,
	size: 'md',
	showNextPrevControls: true,
	showFirstLastControls: false,
	controlsConfig: 'edge',
	pageClick: (pageIndex) => {
		alert('page ' + (pageIndex + 1));
	},
	useDropdown: true,
};