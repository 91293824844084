import React from 'react'
import PropTypes from 'prop-types'
import Question from '../../models/Question'
import i18next from 'i18next'
import Formula from './Formula'
import * as Icon from 'react-bootstrap-icons'
import QuestionFormAnswerField from './QuestionFormAnswerField';

import './QuestionFormAnswerTemplateView.css'

class QuestionFormAnswerTemplateFormView extends React.Component {

	grades = [
		0,
		25,
		50,
		75,
		100,
	]

	constructor(props) {
		super(props)
		this.state = {
			//model: props.model,
		}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {

		let rightAnswerTemplate = this.props.rightAnswerTemplate
		let answerTypeAlias = this.props.answerTypeAlias

		let onChange = (event) => {
			this.props.onChange(event, rightAnswerTemplate)
		}

		let formulaEditorWithPreview = (
			<div className={'form-group formula-editor-box'}>
				<label htmlFor={'formula'}>
					{i18next.t("Formula editor with preview")}
				</label>
				<Formula
					showEditor={true}
				/>
			</div>
		)
		
		return (
			
			<div className={'QuestionFormAnswerTemplateView'}>
				
				<div className={`row`}>

					<div className={`content-col col`}>

						<div className={`form-group required`}>

							<label>{i18next.t("Answer content")}</label>

							<QuestionFormAnswerField
								questionViewTypeAlias={this.props.questionViewTypeAlias}
								answerTypeAlias={this.props.answerTypeAlias}
								onChange={(val) => {
									rightAnswerTemplate.content = val
									onChange(null, rightAnswerTemplate)
								}}
								value={rightAnswerTemplate.content}
								required={true}
							/>

						</div>

					</div>

					<div className={`grade-col col-auto`}>

						<div className={`form-group required`}>

							<label>{i18next.t("Grade")}</label>

							<select
								id={'grade'}
								name={'grade'}
								className={`form-control`}
								onChange={onChange}
								value={rightAnswerTemplate.grade}
								required={true}
							>
								<option value={null}></option>
								{this.grades.map((grade) => {
									return (
										<option value={grade}>
											{grade}%
										</option>
									)
								})}
							</select>

						</div>

					</div>

					<div className={`controls-col col-auto`}>
						<button 
							type={'button'}
							className={'my-btn my-btn-danger del-btn '}
							onClick={(event) => {
								this.props.onDelete(event, rightAnswerTemplate);
							}}
							title={i18next.t("Delete")}
						>
							<Icon.X/>
						</button>
					</div>

				</div>
				
			</div>
			
		)
		
	}
	
}

QuestionFormAnswerTemplateFormView.propTypes = {
	// rightAnswerTemplate: PropTypes.instanceOf(QuestionRightAnswerTemplateView).isRequired,
	rightAnswerTemplate: PropTypes.object,
	questionViewTypeAlias: PropTypes.string,
	answerTypeAlias: PropTypes.string,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
}

QuestionFormAnswerTemplateFormView.defaultProps = {}

export default QuestionFormAnswerTemplateFormView