import React from 'react';
import PropTypes from 'prop-types';
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import Logger from "../../helpers/Logger";
import Formatter from "../../helpers/Formatter";
import "./GridView.css";
import i18next from "i18next";
import * as Icon from 'react-bootstrap-icons';

/**
 * COL CONFIG: {alias: func(model) | {name:string, value:func(model)|string, type:'bool'|'date'|null, cssClass:string, style:{}, width:number}}
 */
export default class GridView extends React.Component {
	
	static emptyPlug = '-';
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			filters: this.props.filtersValues,
		};
		
		// Object.getOwnPropertyNames(this.props.filtersValues).forEach((filterAlias) => {
		// 	this.state.filters[filterAlias] = this.props.filtersValues[filterAlias];
		// });
		
	}
	
	filterInputChange = (event, filterAlias) => {
		
		let field = event.target;
		let fieldValue = field.value;
		let filters = this.state.filters;
		
		filters[filterAlias] = fieldValue;
		
		if (fieldValue === '') {
			delete filters[filterAlias];
		}
		
		this.setState((prevState) => {
			return {
				filters: filters,
			}
		});
		
	}
	
	applyFilters = () => {
		if (this.props.filtersHandler) {
			this.props.filtersHandler(this.state.filters);
		}
	}
	
	filtersHandler = (event, filterAlias, waitForEnterKey = this.props.filterWaitForEnterKey) => {
		
		// event.stopPropagation();
		// event.preventDefault();
		
		const logName = 'Table.filtersHandler';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(waitForEnterKey, 'waitForEnterKey', logAllow);
		
		let filters = this.state.filters;
		Logger.log(filters, 'filters old', logAllow);
		
		let filter = event.target;
		let filterValue = filter.value;
		
		if (filterValue === '') {
			delete filters[filterAlias];
		} else {
			filters[filterAlias] = filterValue;
		}
		
		// if (Array.isArray(filterAlias)) {
		//     filterAlias.forEach((filterAlias) => {
		//         filters[filterAlias] = filterValue;
		//     });
		// } else {
		//     filters[filterAlias] = filterValue;
		// }
		
		Logger.log(filters, 'filters new', logAllow);
		
		this.setState((prevState) => {
			return {
				filters: filters,
			}
		});
		
		if (waitForEnterKey && event.keyCode !== Utils.keyCodes.enter) {
			Logger.groupEnd(logAllow);
			return false;
		}
		
		if (this.props.filtersHandler) {
			this.props.filtersHandler(filters, filterAlias);
		}
		
		Logger.groupEnd(logAllow);
		
		return false;
		
	};
	
	filterClear = (event, filterAlias) => {
		
		let filters = this.state.filters;
		
		delete filters[filterAlias];
		
		this.setState((prevState) => {
			return {
				filters: filters,
			}
		});
		
		if (this.props.filtersHandler) {
			this.props.filtersHandler(filters, filterAlias);
		}
		
		// return false;
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if (!Utils.isEqualObjects(this.state.filters, this.props.filtersValues)) {
			this.setState((prevState) => {
				return {
					filters: this.props.filtersValues,
				}
			});
		}
		
		if (!Utils.isEqualObjects(this.state.filters, prevState.filters)) {
			this.props.filtersHandler(this.state.filters);
		}
		
	}
	
	render() {
		
		const logName = 'GridView.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props.filtersValues, 'this.props.filtersValues', logAllow);
		Logger.log(this.state.filters, 'this.state.filters', logAllow);
		
		let colsData = this.props.cols;
		let colsAliases = Object.getOwnPropertyNames(colsData);
		let hasFilters = false;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className="GridView">
				
				<div className="table-responsive">
					
					<table className={'table table-striped table-hover table-bordered mb-0' + (this.props.className ? ' ' + this.props.className : '')}>
						
						<thead>
							
							<tr className={'headers'}>
								
								{colsAliases.map((colAlias) => {
									
									let colData = colsData[colAlias];
									
									if (colData) {
										
										if (colData.visible !== undefined && !colData.visible) {
											return null;
										}
										
										let colName = null;
										
										if (typeof colData === 'object') {
											colName = colData.name;
										} else if (typeof colData === 'string') {
											colName = colData;
										} else if (typeof colData === 'function') {
											colName = '';
										}
										
										// if (!hasFilters && colData.filter) {
										if (!hasFilters && (colData.filterAlias || colData.filter)) {
											hasFilters = true;
										}
										
										return (
											<th className={colAlias}
												key={colAlias + '-th'}
												title={colData.title}
											>{colName === undefined ? colAlias : colName}</th>
										);
										
									}
									
								})}
							
							</tr>
							
							{hasFilters &&
								
								<tr className={'filters'} key={'filters'}>
									
									{colsAliases.map(colAlias => {
										
										let colData = colsData[colAlias];
										
										if (colData) {
											
											if (colData.visible !== undefined && !colData.visible) {
												return null;
											}
											
											let name = colData.name;
											let filterAlias = colData.filterAlias || (colData.filter ? colAlias : null);
											let filterData = colData.filter;
											// let waitForEnter = (typeof filterData === 'string' && ['date'].indexOf(filterData) < 0) || typeof filterData === 'object';
											// let waitForEnter = typeof filterData !== 'object';
											
											return (
												
												<th key={`${colAlias}_filter_cell`} className={colAlias}>
													
													{filterAlias &&
														
														<div key={`${colAlias}_filter_box`}>
															
															{colData.type === 'bool' ?
																
																<select
																	className={'form-control'}
																	// ref={colData.filterRef}
																	// value={this.props.filtersValues[colAlias]}
																	value={this.state.filters[colAlias]}
																	onChange={(event) => {
																		// event.preventDefault();
																		this.filtersHandler(event, colAlias, false);
																		// this.filterInputChange(event, filterAlias);
																	}}
																	title={i18next.t("Search") + ': ' + name}
																>
																	<option value=""></option>
																	<option value="1">{i18next.t("Yes")}</option>
																	<option value="0">{i18next.t("No")}</option>
																</select>
																
																: typeof colData.filter === 'object' ?
																	
																	<select
																		className={'form-control'}
																		value={this.state.filters[colAlias]}
																		onChange={(event) => {
																			this.filtersHandler(event, colAlias, false);
																			// this.filterInputChange(event, filterAlias);
																		}}
																		title={i18next.t("Search") + ': ' + name}
																	>
																		<option value=""></option>
																		{Object.getOwnPropertyNames(colData.filter).map((alias) => {
																			return (
																				<option
																					key={`${alias}-option`}
																					value={alias}
																				>{colData.filter[alias]}</option>
																			);
																		})}
																	</select>
																	
																	:
																	
																	<div className={'input-box'}>
																		
																		<input
																			key={`${colAlias}_filter`}
																			name={`${colAlias}_filter`}
																			className={'form-control filter-input'}
																			placeholder={name}
																			type={typeof filterData === 'string' ? filterData : 'text'}
																			value={this.state.filters[filterAlias] || ''}
																			onKeyUp={(event) => {
																				// event.stopPropagation();
																				// this.filtersHandler(event, filterAlias, this.props.filterWaitForEnterKey);
																				// this.filterInputChange(event, filterAlias);
																			}}
																			onKeyDown={(event) => {
																				
																				const logName = 'GridView.filter.onKeyDown';
																				const logAllow = 1;
																				const logCollapsed = 0;
																				
																				Logger.groupStart(logName, logAllow, logCollapsed);
																				
																				Logger.log(event.key, 'event.key', logAllow);
																				
																				event.stopPropagation();
																				
																				if (event.key == 'Enter') {
																					event.preventDefault();
																					this.applyFilters();
																				}
																				
																				// this.filtersHandler(event, filterAlias, this.props.filterWaitForEnterKey);
																				
																				Logger.groupEnd(logAllow);
																				
																			}}
																			onChange={(event) => {
																				// event.preventDefault();
																				// event.stopPropagation();
																				// this.filtersHandler(event, filterAlias, true);
																				// this.filtersHandler(event, filterAlias, this.props.filterWaitForEnterKey);
																				// this.filterInputChange(event, filterAlias);
																				this.filterInputChange(event, filterAlias);
																				return false;
																			}}
																			title={i18next.t("Search") + ': ' + name}
																		/>
																		
																		{/*<div className="clear-btn"
																			 title={'Очистить поле'}
																			 onClick={(event) => {
																				 this.filterClear(event, filterAlias);
																			 }}
																		></div>*/}
																		
																		{(this.props.showFiltersControls) &&
																			<div className={'filter-controls mt-1'}>
																				
																				<button
																					type={'button'}
																					className={[
																						'apply-btn',
																						'btn',
																						'btn-sm',
																						'btn-light',
																					].join(' ')}
																					title={i18next.t("Apply")}
																					onClick={(event) => {
																						// this.filtersHandler(event, filterAlias, false);
																						this.applyFilters();
																					}}
																				><Icon.Check/></button>
																				
																				<button
																					type={'button'}
																					className={[
																						'clear-btn-2',
																						'btn',
																						'btn-sm',
																						'btn-light',
																						'ml-1',
																					].join(' ')}
																					title={i18next.t("Clear")}
																					onClick={(event) => {
																						this.filterClear(event, filterAlias);
																					}}
																				><Icon.X/></button>
																			
																			</div>
																		}
																		
																		
																	</div>
																
															}
														
														</div>
														
													}
												
												</th>
												
											);
											
										}
										
									})}
								
								</tr>
							}
							
						</thead>
						
						<tbody>
							
							{this.props.content.map((contentItem, index) => {
								
								let title = '';
								
								if (this.props.rowTitleMaker) {
									title = this.props.rowTitleMaker(contentItem);
								}
								
								return (
									
									<tr key={'tr-' + index}
										data-content-item-id={contentItem['id']}
										className={[
											this.props.rowClassNameMaker ? this.props.rowClassNameMaker(contentItem) : '',
											this.props.rowOnClick ? 'clickable' : '',
										].join(' ')}
										onClick={(event) => {
											event.stopPropagation();
											if (this.props.rowOnClick) {
												this.props.rowOnClick(contentItem, event);
											}
										}}
										title={title}
									>
										
										{colsAliases.map((colAlias) => {
											
											let colData = colsData[colAlias];
											
											if (colData) {
												
												if (colData.visible !== undefined && !colData.visible) {
													return null;
												}
												
												let colContent = '';
												
												if (typeof colData === 'function') {
													
													colContent = colData(contentItem);
													
												} else {
													
													colContent = contentItem[colAlias];
													
													let valueProcessor = colData.value;
													
													if (valueProcessor) {
														colContent = valueProcessor(contentItem, index);
													} else {
														if (colData.type === 'date') {
															let format = colData.format || 'd.m.y';
															colContent = colContent ? Formatter.date(colContent, format) : Config.emptyValuePlug;
														} else if (colData.type === 'bool') {
															colContent = colContent === undefined ? Config.emptyValuePlug : (colContent == 1 ? 'Да' : 'Нет');
														}
													}
													
												}
												
												return (
													<td
														key={colAlias + '-td'}
														className={colAlias}
														width={colData.width}
														style={colData.style}
													>
														{/*<div className={colData.cssClass}>{colContent}</div>*/}
														{colContent}
													</td>
												);
												
											}
											
										})}
									
									</tr>
								
								);
								
							})}
							
						</tbody>
					
					</table>
				
				</div>
			
			</div>
		
		);
	}
	
}

GridView.propTypes = {
	cols: PropTypes.object.isRequired,
	content: PropTypes.array.isRequired,
	filtersValues: PropTypes.object,
	filtersHandler: PropTypes.func,
	filterWaitForEnterKey: PropTypes.bool,
	rowTitleMaker: PropTypes.func,
	rowClassNameMaker: PropTypes.func,
	rowOnClick: PropTypes.func,
	className: PropTypes.string,
	showFiltersControls: PropTypes.bool,
};

GridView.defaultProps = {
	filtersValues: {},
	filterWaitForEnterKey: false,
};