import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import Question from "../../models/Question";
import FormDataHelper from "../../helpers/FormDataHelper";
import UploadsGrid from "./UploadsGrid";
import DateHelper from "../../helpers/DateHelper";

export default class QuestionCardForm extends React.Component {
	
	modeCreate = 1;
	modeUpdate = 0;
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Question();
		
		if (model.id) {
			this.modeCreate = 0;
			this.modeUpdate = 1;
		}
		
		this.state = {
			model: model,
		};
		
		model.view_type_alias = 'card';
		model.need_manual_check = 0;
		model.use_shuffle = 0;
		model.material_id = this.props.materialId;
		
		this.uploadRefs = {};
		
		Question.uploadAttrs.forEach((attr) => {
			this.uploadRefs[attr] = React.createRef();
		});
		
		if (!model.id && Config.allowFormFake()) {
			model.card_front_text = 'front ' + DateHelper.dateTime();
			model.card_back_text = 'back ' + DateHelper.dateTime();
		}
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const attr = target.getAttribute('name');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
	};
	
	submit = (event) => {
		
		event.preventDefault();
		
		const logName = 'QuestionCardForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const form = event.currentTarget;
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		if (form.checkValidity()) {
			
			const formData = FormDataHelper.get(model, [], this.uploadRefs);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: this.modeUpdate ? 'put' : 'post',
				url: Utils.apiUrl('questions') + (model.id ? '/' + model.id : ''),
				data: formData,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'QuestionCardForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.afterSuccess) {
					this.props.afterSuccess(response, model);
				}
				
				// review bulat // WTF?! ^^^^^ there is already function above
				// review bulat // where is null check?
				// this.props.afterSubmit(response, this.state.model.material_id);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				const logName = 'QuestionCardForm.submit.ajax.error';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(error, 'error', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (this.props.afterError) {
					this.props.afterError(error);
				}
				
				if (Utils.isDev(Config)) {
					window.alert(error);
				}
				
				Logger.groupEnd(logAllow);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	render() {
		
		// todo bulat 2022-12-25
		
		const logName = 'QuestionCardForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<form className={'QuestionCardForm'} onSubmit={this.submit}>
				
				{(this.props.useGroupSelector) &&
					<div className={'form-group group_id required'}>
						<label htmlFor={'group_id required'}>{i18next.t("Group")}</label>
						<select
							id={'group_id'}
							name={'group_id'}
							className={'form-control'}
							onChange={this.inputChange}
							value={model.group_id}
							required={true}
						>
							<option value="">{i18next.t("Select from list")}</option>
							{this.props.groups.map((group) => {
								return (
									<option value={group.id}>{group.name}</option>
								);
							})}
						</select>
					</div>
				}
				
				<div className={'form-group card_front_text'}>
					<label htmlFor={'card_front_text'}>{i18next.t("Front text")}</label>
					<input
						id={'card_front_text'}
						name={'card_front_text'}
						type={'text'}
						className={'form-control'}
						value={model.card_front_text}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className={'form-group card_back_text'}>
					<label htmlFor={'card_back_text'}>{i18next.t("Back text")}</label>
					<input
						id={'card_back_text'}
						name={'card_back_text'}
						type={'text'}
						className={'form-control'}
						value={model.card_back_text}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<fieldset className={'sounds'}>
					
					<legend>{i18next.t("Audio")}</legend>
					
					{(model && model.sounds && model.sounds.length > 0) && (
						<UploadsGrid
							uploads={model.sounds}
							showControls={true}
							preloader={this.props.preloader}
							// afterUpd={this.props.afterUploadUpdSuccess}
							afterUpd={this.props.afterUploadUpdSuccess}
						/>
					)}
					
					{(model.sounds.length < 2) &&
						<div className="form-group">
							<input
								id={'sounds'}
								type={'file'}
								accept={'audio/*'}
								ref={this.uploadRefs['sounds']}
								multiple={true}
							/>
						</div>
					}
					
				</fieldset>
				
				<div className="controls">
					
					<hr/>
					
					<div className="row">
						
						<div className="col">
							{this.props.cancel && (
								<button
									type={'button'}
									className={'my-btn my-btn-danger'}
									onClick={(event) => {
										this.props.cancel(event);
									}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col text-right">
							<button
								type={'submit'}
								className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
		
		);
		
	}
	
}

QuestionCardForm.propTypes = {
	
	model: PropTypes.instanceOf(Question),
	groups: PropTypes.arrayOf(PropTypes.instanceOf(Question)),
	materialId: PropTypes.number.isRequired,
	
	useGroupSelector: PropTypes.bool,
	
	afterSuccess: PropTypes.func,
	afterError: PropTypes.func,
	afterUploadUpdSuccess: PropTypes.func,
	
	cancel: PropTypes.func,
	
	preloader: PropTypes.object,
	
};

QuestionCardForm.defaultProps = {
	model: null,
	groups: [],
	useGroupSelector: true,
};