import React from 'react';
import PropTypes from 'prop-types';

export default class TeamsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {
        return (
            <div className={'TeamsPage'}>
               <h1>Microsoft Teams</h1>
            </div>
        );
    }

}

TeamsPage.propTypes = {};

TeamsPage.defaultProps = {};