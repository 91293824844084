import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import Logger from "../../helpers/Logger";
import Group from "../../models/Group";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import buttonAdd from '../../img/buttonAdd.png';
import Modal from "react-bootstrap/Modal";
import SchedForm from "./SchedForm";
import Sched from "../../models/Sched";
import DateHelper from "../../helpers/DateHelper";
import User from "../../models/User";

// todo optimize scheds loading !!!

export default class CalendarPage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			scheds: [],
			teachers: [],
			groups: [],
			
			currentGroup: null,
			currentSched: null,
			currentSchedIndex: null,
			
			schedFormModalIsOpen: false,
			
			currentDate: new Date(),
			
		};
		
	}
	
	groups = {
		
		load: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('groups'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'name',
					'filter[is_active]': 1,
				},
			}).then((response) => {
				
				const logName = 'CalendarPage.groups.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let groups = [];
				
				response.data.forEach((groupData) => {
					let group = new Group(groupData);
					groups.push(group);
				});
				
				// todo sort by group name
				// groups.sort();
				
				this.setState((prevState) => {
					return {
						groups: groups,
					}
				});
				
				this.scheds.load();
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
	};
	
	scheds = {
		
		load: (date) => {
			
			const logName = 'CalendarPage.scheds.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const dateFormatted = DateHelper.formatDate({
				date: date,
				format: 'y-m-d',
			});
			Logger.log(dateFormatted, 'dateFormatted', logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('scheds'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[date]': dateFormatted,
					'filter[is_active]': 1,
					'sort': 'time, group.name',
				},
			}).then((response) => {
				
				const logName = 'CalendarPage.scheds.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let scheds = [];
				
				response.data.forEach((schedData) => {
					let sched = new Sched(schedData);
					scheds.push(sched);
				});
				
				Logger.log(scheds, 'scheds', logAllow);
				
				this.setState((prevState) => {
					return {
						scheds: scheds,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					schedFormModalIsOpen: !prevState.schedFormModalIsOpen,
				}
			});
		},
		
		add: () => {
			this.setState((prevState) => {
				return {
					currentSched: null,
				}
			});
			this.scheds.formModalToggle();
		},
		
	};
	
	teachers = {
		
		load: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('users'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[role_id]': 6,
					'sort': 'surname, name, patron',
					'per-page': 999,
				},
			}).then((response) => {
				
				const logName = 'CalendarPage.teachers.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let teachers = response.data.map(data => new User(data));
				
				this.setState((prevState) => {
					return {
						teachers: teachers,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		}
		
	};
	
	componentDidMount() {
		this.groups.load();
		// this.scheds.load(this.state.currentDate);
		this.teachers.load();
	}
	
	render() {
		
		const logName = 'CalendarPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state.scheds, 'this.state.scheds', logAllow);
		
		let groups = Sched.collectGroups(this.state.scheds);
		Logger.log(groups, 'groups', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'CalendarPage'}>
				
				<div className="content-root row">
					
					<div className="col-xl-8 col-md-7 col-sm-6 scheds-list-col">
						
						<div className="scheds-box">
							
							<div className="h2">
								{i18next.t("Schedule")}
								{this.state.currentGroup && (
									<span>: {this.state.currentGroup.name}</span>
								)}
							</div>
							
							<div className="list">
								
								<div className={'scheds table-responsive'}>
									
									<table className={[
										'table',
										'table-hover',
									].join(' ')}>
										
										<thead>
										<tr>
											<th>{i18next.t("Date")}</th>
											<th>{i18next.t("Time")}</th>
											<th>{i18next.t("Group")}</th>
											{!this.props.user.isAny(['teacher']) && (
												<th>{i18next.t("Teacher")}</th>
											)}
											<th>{i18next.t("Place")}</th>
											<th>{i18next.t("Theme")}</th>
											<th>{i18next.t("Link")}</th>
										</tr>
										</thead>
										
										<tbody>
										
										{this.state.scheds.map((sched, schedIndex) => {
											
											const logName = 'CalendarPage.render.scheds.map';
											const logAllow = 0;
											const logCollapsed = 0;
											
											Logger.groupStart(logName, logAllow, logCollapsed);
											
											Logger.log(sched, 'sched', logAllow);
											
											// if (!sched.is_active) {
											//     Logger.groupEnd(logAllow);
											//     return;
											// }
											
											let currentGroup = this.state.currentGroup;
											Logger.log(currentGroup, 'currentGroup', logAllow);
											
											if (currentGroup && currentGroup.id != sched.group_id) {
												Logger.groupEnd(logAllow);
												return;
											}
											
											let teacher = sched.teacher;
											
											// let allowEdit = true;
											let allowEdit = this.props.user.isAny(['owner', 'admin'])
												|| this.props.user.id == sched.created_by;
											
											Logger.groupEnd(logAllow);
											
											return (
												
												<tr className={'sched clickable'}
													onClick={(event) => {
													
														if (!allowEdit) {
															window.alert(i18next.t("Sorry, you can't edit this item"));
															return;
														}
													
														this.setState((prevState) => {
															return {
																currentSched: sched,
																currentSchedIndex: schedIndex,
																schedFormModalIsOpen: true,
															}
														});
													
													}}
													data-sched-id={sched.id}
												>
													
													<td className={'date'} title={'#' + sched.id}>
														{DateHelper.formatDate({
															date: sched.date,
															format: 'd.m.y',
														})}
													</td>
													
													<td className={'time'}>
														{sched.timeWithoutSeconds()}
													</td>
													
													<td className={'currentGroup'}>
														<span title={'#' + sched.group_id}>{sched.group_name}</span>
													</td>
													
													{!this.props.user.isAny(['teacher']) && (
														<td className={'teacher'}
															title={teacher ? '#' + teacher.id : ''}>
															{teacher ? teacher.getDisplayName() : '-'}
														</td>
													)}
													
													<td className={'place'}>
														{sched.place}
													</td>
													
													<td className={'theme'}>
														{sched.name}
													</td>
													
													<td className={'link'}>
														{sched.url && (
															<a href={sched.url}
															   target={'_blank'}
															   onClick={(event) => {
																   event.stopPropagation();
															   }}
															   className={[
																   'my-btn',
																   'my-btn-sm',
															   ].join(' ')}
															>{i18next.t("Open")}</a>
														)}
													</td>
												
												</tr>
											
											);
											
										})}
										
										</tbody>
									
									</table>
								
								</div>
								
								{this.props.user.can('manageSched') && (
									<div className="bottom-controls">
										<img className={'add-btn clickable'}
											 src={buttonAdd}
											 alt={'add-btn'}
											 onClick={(event) => {
												 this.scheds.add();
											 }}
										/>
									</div>
								)}
							
							</div>
						
						</div>
					
					</div>
					
					<div className="col-xl-4 col-md-5 col-sm-6 calendar-col">
						
						<div className="h2">{i18next.t("Calendar")}</div>
						
						<div className="list calendar-box">
							<Calendar
								value={this.state.currentDate}
								locale={this.props.langCode}
								onChange={(date) => {
									
									const logName = 'CalendarPage.calendar.onChange';
									const logAllow = 1;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									Logger.log(date, 'date', logAllow);
									
									this.setState((prevState) => {
										return {
											// scheds: [],
											currentGroup: null,
											currentDate: date,
										}
									});
									
									// const group = this.state.currentGroup;
									// Logger.log(group, 'group', logAllow);
									
									// const groupId = group ? group.id : null;
									// Logger.log(groupId, 'groupId', logAllow);
									
									this.scheds.load(date);
									
									Logger.groupEnd(logAllow);
									
								}}
							/>
						</div>
						
						<div className="list groups">
							
							<div className={[
								'group',
								'list-item',
								'list-item-active',
								this.state.currentGroup ? '' : 'list-item-current',
							].join(' ')}
								 onClick={(event) => {
									 this.setState((prevState) => {
										 return {
											 currentGroup: null,
										 }
									 });
								 }}
							>
								{i18next.t("All groups")}
							</div>
							
							{groups.map((group, groupIndex) => {
								return (
									<div className={[
										'group',
										'list-item',
										'list-item-active',
										this.state.currentGroup ?
											(this.state.currentGroup.id == group.id ? 'list-item-current' : '') : '',
									].join(' ')}
										 onClick={(event) => {
											 this.setState((prevState) => {
												 return {
													 currentGroup: group,
												 }
											 });
										 }}
									>{groupIndex + 1}. {group.name}</div>
								);
							})}
						
						</div>
					
					</div>
				
				</div>
				
				<div className="modals">
					
					<Modal className={'sched-form-modal'}
						   show={this.state.schedFormModalIsOpen}
						   onHide={this.scheds.formModalToggle}
						   size={'lg'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.currentSched ? (
									<span>{i18next.t("Edit schedule item #{{id}}",
										{id: this.state.currentSched.id})}</span>
								) : (
									<span>{i18next.t("Add lesson")}</span>
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							
							<SchedForm
								model={this.state.currentSched}
								groups={this.state.groups}
								currentGroup={this.state.currentGroup}
								afterSubmit={(response) => {
									
									let sched = new Sched(response.data);
									let scheds = this.state.scheds;
									
									let currentDate = DateHelper.formatDate({date: new Date(), format: 'y-m-d'});
									Logger.log(currentDate, 'currentDate', logAllow);
									Logger.log(sched.date, 'sched.date', logAllow);
									
									if (sched.is_active == 0) {
										
										// delete
										
										scheds.splice(this.state.currentSchedIndex, 1);
										
									} else if (sched.date == currentDate) {
										
										if (this.state.currentSched) {
											
											// update
											
											scheds[this.state.currentSchedIndex] = sched;
											
										} else {
											
											// create
											
											scheds.push(sched);
											
										}
										
									} else {
										
										let date = new Date(sched.date);
										
										this.setState((prevState) => {
											return {
												currentDate: date,
											}
										});
										
										this.scheds.load(date);
										
									}
									
									this.setState((prevState) => {
										return {
											scheds: scheds,
											schedFormModalIsOpen: false,
										}
									});
									
								}}
								user={this.props.user}
								teachers={this.state.teachers}
								preloader={this.props.preloader}
								date={this.state.currentDate}
							/>
						
						</Modal.Body>
					
					</Modal>
				
				</div>
			
			</div>
		
		);
		
	}
	
}

CalendarPage.propTypes = {
	user: PropTypes.instanceOf(User).isRequired,
	langCode: PropTypes.string,
	breadcrumbs: PropTypes.object,
	preloader: PropTypes.object,
};

CalendarPage.defaultProps = {};