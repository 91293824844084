import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';
import "./TextEditor.css";
import i18next from "i18next";
import Logger from "../../helpers/Logger";

export default class TextEditor extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            // text: this.props.text,

            // undo / redo
            history: [this.props.text],
            historyIndex: 0,

            // lastSelectionStart: null,
            // lastSelectionEnd: null,
            // lastControl: null,

        };

        this.textFieldRef = React.createRef();

    }

    componentDidMount() {
        // todo ajax
    }

    controlsData = {

        // __tpl: {
        //     label: null,
        //     title: i18next.t(""),
        //     icon: <Icon./>,
        //     onClick: () => {}
        // },

        bold: {
            label: null,
            title: i18next.t("Bold"),
            icon: <Icon.TypeBold/>,
            onClick: () => {

                let newText = this.editTextSelection((selection) => {
                    let trimmedSelection = selection.trim();
                    let spacesCount = selection.length - trimmedSelection.length;
                    return '<b>' + trimmedSelection + '</b>' + (spacesCount > 0 ? ' ' : '');
                })

                this.setText(newText);
                this.focus();
                this.history.add(newText);

            }
        },

        italic: {
            label: null,
            title: i18next.t("Italic"),
            icon: <Icon.TypeItalic/>,
            onClick: () => {

                let newText = this.editTextSelection((selection) => {
                    let trimmedSelection = selection.trim();
                    let spacesCount = selection.length - trimmedSelection.length;
                    return '<i>' + trimmedSelection + '</i>' + (spacesCount > 0 ? ' ' : '');
                })

                this.setText(newText);
                this.focus();
                this.history.add(newText);

            }
        },

        underline: {
            label: null,
            title: i18next.t("Underline"),
            icon: <Icon.TypeUnderline/>,
            onClick: () => {

                let newText = this.editTextSelection((selection) => {
                    let trimmedSelection = selection.trim();
                    let spacesCount = selection.length - trimmedSelection.length;
                    return '<u>' + trimmedSelection + '</u>' + (spacesCount > 0 ? ' ' : '');
                })

                this.setText(newText);
                this.focus();
                this.history.add(newText);

            }
        },

        strike: {
            label: null,
            title: i18next.t("Linethrough"),
            icon: <Icon.TypeStrikethrough/>,
            onClick: () => {

                let newText = this.editTextSelection((selection) => {
                    let trimmedSelection = selection.trim();
                    let spacesCount = selection.length - trimmedSelection.length;
                    return '<s>' + trimmedSelection + '</s>' + (spacesCount > 0 ? ' ' : '');
                })

                this.setText(newText);
                this.focus();
                this.history.add(newText);

            }
        },

        sub: {
            label: null,
            title: i18next.t("Нижний регистр"),
            icon: <Icon.ArrowDownRightSquare/>,
            onClick: () => {

                let newText = this.editTextSelection((selection) => {
                    let trimmedSelection = selection.trim();
                    let spacesCount = selection.length - trimmedSelection.length;
                    return '<sub>' + trimmedSelection + '</sub>' + (spacesCount > 0 ? ' ' : '');
                })

                this.setText(newText);
                this.focus();
                this.history.add(newText);

            }
        },

        sup: {
            label: null,
            title: i18next.t(" Верхний регистр"),
            icon: <Icon.ArrowUpRightSquare/>,
            onClick: () => {

                let newText = this.editTextSelection((selection) => {
                    let trimmedSelection = selection.trim();
                    let spacesCount = selection.length - trimmedSelection.length;
                    return '<sup>' + trimmedSelection + '</sup>' + (spacesCount > 0 ? ' ' : '');
                })

                this.setText(newText);
                this.focus();
                this.history.add(newText);

            }
        },

        field: {
            label: i18next.t("Поле ввода"),
            title: i18next.t("Поле ввода"),
            icon: <Icon.InputCursorText/>,
            onClick: () => {}
        },

        formula: {
            label: i18next.t("Формула"),
            title: i18next.t("Формула"),
            icon: <Icon.Calculator/>,
            onClick: () => {}
        },

        undo: {
            // label: i18next.t("Cancel"),
            title: i18next.t("Отменить последнее действие"),
            icon: <Icon.ArrowCounterclockwise/>,
            onClick: () => {
                this.undo();
                this.focus();
            },
            disabled: () => {
                return !this.canUndo();
            },
        },

        redo: {
            // label: i18next.t("Вернуть"),
            title: i18next.t("Вернуть последнее действие"),
            icon: <Icon.ArrowClockwise/>,
            onClick: () => {
                this.redo();
                this.focus();
            },
            disabled: () => {
                return !this.canRedo();
            },
        },

    };

    history = {

        add: (text) => {
            let history = this.state.history;
            // let historyIndex = this.state.historyIndex;
            // history.splice(historyIndex + 1);
            history.push(text);
            this.setState((prevState) => {
                return {
                    history: history,
                    // historyIndex: prevState.historyIndex + 1,
                    historyIndex: history.length - 1,
                }
            });
        },

    };

    getText = () => {
        return this.textFieldRef.current.value;
    }

    editTextSelection = (editor) => {

        const logName = 'TextEditor.editTextSelection';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        let field = this.textFieldRef.current;

        let text = field.value;

        let selectionStart = field.selectionStart;
        Logger.log(selectionStart, 'selectionStart', logAllow);

        let selectionEnd = field.selectionEnd;
        Logger.log(selectionEnd, 'selectionEnd', logAllow);

        let selected = text.substring(selectionStart, selectionEnd);
        Logger.log(selected, 'selected', logAllow);

        let beforeSelected = text.substring(0, selectionStart);
        let afterSelected = text.substring(selectionEnd, text.length);

        let newText = beforeSelected + editor(selected) + afterSelected;

        Logger.groupEnd(logAllow);

        return newText;

    }

    setText = (text) => {
        let field = this.textFieldRef.current;
        field.value = text;
        if (this.props.onChange) {
            this.props.onChange(text);
        }
        // this.history.add(text);
        // field.focus();
    }

    focus = () => {
        this.textFieldRef.current.focus();
    }

    canUndo = () => {
        return this.state.history.length > 0 && this.state.historyIndex > 0;
    }

    undo = () => {
        if (this.canUndo()) {
            let historyIndex = this.state.historyIndex - 1;
            let history = this.state.history;
            let text = history[historyIndex];
            this.setText(text);
            this.setState((prevState) => {
                return {
                    historyIndex: historyIndex,
                }
            });
        }
    };

    canRedo = () => {
        let history = this.state.history;
        let historyIndex = this.state.historyIndex;
        return history.length > 1 && historyIndex < history.length - 1;
    }

    redo = () => {
        if (this.canRedo()) {
            let historyIndex = this.state.historyIndex + 1;
            let history = this.state.history;
            let text = history[historyIndex];
            this.setText(text);
            this.setState((prevState) => {
                return {
                    historyIndex: historyIndex,
                }
            });
        }
    };

    onChange = () => {

        const logName = 'TextEditor.onChange';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        let text = this.textFieldRef.current.value;
        Logger.log(text, 'text', logAllow);

        // this.history.add(text);

        if (this.props.onChange) {
            this.props.onChange(text);
        }

        Logger.groupEnd(logAllow);

    };

    render() {

        const logName = 'TextEditor.render';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        // Logger.log(this.props, 'this.props', logAllow);
        // Logger.log(this.state, 'this.state', logAllow);

        Logger.log(this.state.history, 'this.state.history', logAllow);
        Logger.log(this.state.historyIndex, 'this.state.historyIndex', logAllow);

        Logger.groupEnd(logAllow);

        return (
            <div className={'TextEditor'}>

                <div className="controls">
                   {this.props.controls.map((controlsGroup) => {
                       return (
                           <div className={'btn-group'}>
                               {controlsGroup.map((controlAlias) => {

                                   const logName = 'TextEditor.render.control';
                                   const logAllow = 0;
                                   const logCollapsed = 0;

                                   Logger.groupStart(logName, logAllow, logCollapsed);

                                   Logger.log(controlAlias, 'controlAlias', logAllow);

                                   if (this.props.hideControls.indexOf(controlAlias) >= 0) {
                                       return;
                                   }

                                   let defaults = this.controlsData[controlAlias] || {};
                                   Logger.log(defaults, 'defaults', logAllow);

                                   let icon = defaults.icon;
                                   // let label = defaults.label;
                                   let label = defaults.label || (!icon ? controlAlias : null);
                                   let title = defaults.title;
                                   let onCLick = defaults.onClick;
                                   let disabled = defaults.disabled ? defaults.disabled() : this.props.disabled;

                                   Logger.groupEnd(logAllow);

                                   return (
                                       <button
                                           type={'button'}
                                           className={[
                                               'btn',
                                               'btn-light',
                                           ].join(' ')}
                                           onClick={onCLick}
                                           title={title}
                                           disabled={disabled}
                                       >{icon}{label ? (icon ? ' ' : '') + label : ''}</button>
                                   );

                               })}
                           </div>
                       );
                   })}
               </div>

                <div className="text-box">
                    {(this.props.rows > 1) ? (
                        <textarea
                            ref={this.textFieldRef}
                            className={'form-control'}
                            rows={this.props.rows}
                            onChange={this.onChange}
                            value={this.props.text}
                            disabled={this.props.disabled}
                        ></textarea>
                    ) : (
                        <input
                            type={'text'}
                            ref={this.textFieldRef}
                            className={'form-control'}
                            onChange={this.onChange}
                            value={this.props.text}
                            disabled={this.props.disabled}
                        />
                    )}

                </div>

            </div>
        );
    }

}

TextEditor.propTypes = {
    text: PropTypes.string,
    rows: PropTypes.number,
    onChange: PropTypes.func,
    controls: PropTypes.array,
    hideControls: PropTypes.array,
    disabled: PropTypes.bool,
};

TextEditor.defaultProps = {
    controls: [
        [
            'bold',
            'italic',
            'underline',
            'strike',
            'sub',
            'sup',
        ],
        // [
        //     'field',
        //     'formula',
        // ],
        // [
        //     'undo',
        //     'redo',
        // ],
    ],
    controlsOnClick: {
        bold: () => {
            let field = this.textFieldRef.current;
            let text = field.value;
            let newText = '<b>' + text + '</b>';
            field.value = newText;
        },
    },
    controlsIcons: {
        bold: <Icon.TypeBold/>,
        italic: <Icon.TypeItalic/>,
        underline: <Icon.TypeUnderline/>,
        strike: <Icon.TypeStrikethrough/>,
        field: <Icon.InputCursorText/>,
        formula: <Icon.Calculator/>,
    },
    controlsLabels: {
        field: i18next.t("Поле ввода"),
        formula: i18next.t("Формула"),
    },
    controlsTitles: {
        bold: i18next.t("Bold"),
        italic: i18next.t("Курсив"),
        underline: i18next.t("Подчеркивание"),
        strike: i18next.t("Зачёркивание"),
        field: i18next.t("Поле ввода"),
        formula: i18next.t("Формула"),
    },
    rows: 10,
    hideControls: [],
    disabled: false,
};