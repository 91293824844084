import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import axios from "axios";
import Utils from "../../helpers/Utils";
import i18next from "i18next";
import Lesson from "../../models/Lesson";
import FormDataHelper from "../../helpers/FormDataHelper";

export default class LessonForm extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Lesson();
		
		model.course_id = this.props.courseId;
		
		this.state = {
			model: model,
		};
		
		if (!props.model && Config.allowFormFake()) {
			model.name = '__TEST__ ' + new Date().toLocaleString();
		}
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const attr = target.getAttribute('id');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
	};
	
	submit = (event) => {
		
		event.preventDefault();
		
		const form = event.currentTarget;
		const model = this.state.model;
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let formData = FormDataHelper.get(model, ['copy_of_id', 'is_active']);
			
			axios({
				method: model.id ? 'put' : 'post',
				url: Utils.apiUrl('lessons') + (model.id ? '/' + model.id : ''),
				// data: model,
				data: formData,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'LessonForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.model) {
					this.props.afterUpdate(response);
				} else {
					this.props.afterCreate(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
	};
	
	render() {
		
		const logName = 'LessonForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			<form className={'LessonForm'} onSubmit={this.submit}>
				
				<div className="form-group course_id required d-none">
					<label htmlFor={'course_id'}>{i18next.t("Course")}</label>
					<input id={'course_id'}
						   type="text"
						   className={'form-control'}
						   value={model.course_id || this.props.courseId}
						   onChange={this.inputChange}
						   required={true}
					/>
				</div>
				
				<div className="form-group name required">
					<label htmlFor={'name'}>{i18next.t("Title")}</label>
					<input id={'name'}
						   type="text"
						   className={'form-control'}
						   value={model.name}
						   onChange={this.inputChange}
						   required={true}
						   autoFocus={true}
					/>
				</div>
				
				<div className="form-group about">
					<label htmlFor={'about'}>{i18next.t("About")}</label>
					<textarea id={'about'}
							  className={'form-control'}
							  value={model.about}
							  rows={5}
							  onChange={this.inputChange}
					/>
				</div>
				
				{/*<div className="form-group sort">
                    <label htmlFor={'sort'}>{i18next.t("Сортировка")}</label>
                    <input id={'sort'}
                           type="text"
                           className={'form-control'}
                           value={model.sort}
                           onChange={this.inputChange}
                    />
                </div>*/}
				
				<div className="controls">
					<div className="row">
						<div className="col">
							{this.props.cancel && (
								<button type={'button'}
										className={'my-btn my-btn-danger'}
										onClick={this.props.cancel}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						<div className="col text-right">
							<button type={'submit'}
									className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					</div>
				</div>
			
			</form>
		);
		
	}
	
}

LessonForm.propTypes = {
	model: PropTypes.instanceOf(Lesson),
	afterCreate: PropTypes.func,
	afterUpdate: PropTypes.func,
	cancel: PropTypes.func,
	courseId: PropTypes.any,
	preloader: PropTypes.object,
};

LessonForm.defaultProps = {
	model: null,
};