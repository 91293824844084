import React from "react";

export default class YouTubeHelper {
	
	static URL_SHORT_START = 'https://youtu.be';
	static URL_LONG_START = 'https://www.youtube.com';
	static URL_EMBED_START = 'https://www.youtube.com/embed';
	
	static isShortUrl = (url) => {
		return url.includes(YouTubeHelper.URL_SHORT_START);
	}
	
	static isLongUrl = (url) => {
		return url.includes(YouTubeHelper.URL_LONG_START);
	}
	
	static isEmbedUrl = (url) => {
		return url.includes(YouTubeHelper.URL_EMBED_START);
	}
	
	static videoId = (url) => {
		
		let urlData = url.split('?');
		
		if (YouTubeHelper.isShortUrl(url) || YouTubeHelper.isEmbedUrl(url)) {
			urlData = url.split('/');
			return urlData.pop();
		}
		
		let videoId = null;
		
		let urlQuery = urlData[1];
		
		if (urlQuery) {
			let pairs = urlQuery.split('&');
			pairs.forEach((pair) => {
				let pairData = pair.split('=');
				let key = pairData[0];
				if (key === 'v') {
					videoId = pairData[1];
				}
			});
		}
		
		return videoId;
		
	};
	
	static previewImageUrl = (videoId, num = 0) => {
		return `https://img.youtube.com/vi/{videoId}/{num}.jpg`;
	};
	
	static widget = (url, width = 300, height = 150, ratio = '16:9') => {
		
		// todo !!! validate url
		
		// const ratioData = ratio.split(':');
		//
		// const ratioWidth = ratioData[0];
		// const ratioHeight = ratioData[1];
		//
		// if (width && !height) {
		//     height = width * ratioHeight / ratioWidth;
		// }
		//
		// if (!width && height) {
		//     height = height * ratioWidth / ratioHeight;
		// }
		
		const videoId = YouTubeHelper.videoId(url);
		
		return (
			<iframe
				className={'embed-responsive-item'}
				src={YouTubeHelper.URL_EMBED_START + '/' + videoId}
				width={width}
				height={height}
				frameBorder={0}
				allowFullScreen
				// allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			></iframe>
		);
	}
	
}