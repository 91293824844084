import React from 'react';
import PropTypes, {array} from 'prop-types';
import Material from "../../models/Material";
import BackBtn from "./BackBtn";
import i18next from "i18next";
import axios from "axios";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import Logger from "../../helpers/Logger";
import User from "../../models/User";
import Modal from "react-bootstrap/Modal";
import TrainerQuestionForm from "./TrainerQuestionForm";
import Question from "../../models/Question";
import * as Icon from 'react-bootstrap-icons';
import "./TrainerViewPage.css";
import TrainerQuestionView from "./TrainerQuestionView";
import clearIcon from '../../img/refresh.png';
import Answer from "../../models/Answer";

// import grayStar from "../../img/star-gray.png"
// import blueStar from "../../img/star-blue.png"

import grayStar from "../../img/star-gray.svg"
import blueStar from "../../img/star-blue.svg"

import Stars from "./Stars";

export default class TrainerViewPage extends React.Component {
	
	constructor(props) {
		
		const logName = 'TrainerViewPage.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		super(props);
		
		const trainer = props.trainer;
		
		this.state = {
			trainer: trainer,
			currentLevelIndex: trainer.children.length > 0 ? 0 : null,
			questionToEdit: null,
			questionToEditIndex: null,
			questionFormModalIsOpen: false,
			currentQuestionIndex: 0,
			audio: new Audio(),
			audioId: null,
			// congratsModalIsOpen: true,
		};
		
		Logger.groupEnd(logAllow);
		
	}
	
	levels = {
		
		load: () => {
			
			const logName = 'TrainerViewPage.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let trainer = this.state.trainer;
			Logger.log(trainer, 'trainer', logAllow);
			
			// Logger.groupEnd(logAllow);
			// return;
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[parent_id]': trainer.id,
					'filter[is_del]': 0,
					'sort': 'sort',
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.levels.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let levels = response.data.map((levelData) => {
					return new Material(levelData);
				});
				
				trainer.children = levels;
				
				this.setState((prevState) => {
					return {
						currentLevelIndex: 0,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				// window.alert(error);
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					trainerLevelFormModalIsOpen: prevState.trainerLevelFormModalIsOpen,
				}
			});
		},
		
		add: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials'),
				data: {
					type_alias: Material.TYPE_ALIAS_TRAINER_TEST_PART,
					parent_id: this.props.trainer.id,
					is_public: 1,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.levels.add';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let level = new Material(response.data);
				Logger.log(level, 'level', logAllow);
				
				this.state.trainer.children.push(level);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		},
		
		saveSort: (ids) => {
			
			const logName = 'TrainerViewPage.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.levels.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		saveScore: (level) => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/save-score'),
				data: {
					material_id: level.id,
					score_value: level.getScoreValue(),
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.level.completed.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				level.latest_score = response.data;
				
				if (level.latest_score.value > level.biggest_score.value) {
					level.biggest_score = level.latest_score;
				}
				
				this.setState((prevState) => {
					return {
						congratsModalIsOpen: true,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				window.alert(error);
				
			});
			
		},
		
		moveUp: (index) => {
			
			const logName = 'TrainerViewPage.levels.moveUp';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(index, 'index', logAllow);
			
			let trainer = this.state.trainer;
			
			let levels = trainer.children;
			
			let newIndex = Utils.arrayMoveUp(levels, index);
			Logger.log(newIndex, 'newIndex', logAllow);
			
			this.setState((prevState) => {
				return {
					currentLevelIndex: newIndex,
				}
			});
			
			let ids = levels.map(level => level.id);
			
			this.levels.saveSort(ids);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveDown: (index) => {
			
			const logName = 'TrainerViewPage.levels.moveDown';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(index, 'index', logAllow);
			
			let trainer = this.state.trainer;
			
			let levels = trainer.children;
			
			let newIndex = Utils.arrayMoveDown(levels, index);
			Logger.log(newIndex, 'newIndex', logAllow);
			
			this.setState((prevState) => {
				return {
					currentLevelIndex: newIndex,
				}
			});
			
			let ids = levels.map(level => level.id);
			
			this.levels.saveSort(ids);
			
			Logger.groupEnd(logAllow);
			
		},
		
		clear: (levelIndex) => {
			
			const logName = 'TrainerViewPage.levels.clear';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let trainer = this.state.trainer;
			let levels = trainer.children;
			let level = levels[levelIndex];
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/clear-trainer-level'),
				data: {
					material_id: level.id,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.levelPreview.clearBtn.click.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				level.questions.forEach((question, questionIndex) => {
					question.latest_answer = null;
					// levels[levelIndex].questions[questionIndex].latest_answer = null;
				});
				
				// todo fix xxx // костыль, чтобы вызвать ререндеринг TrainerQuestionView
				
				this.setState((prevState) => {
					return {
						currentQuestionIndex: null,
					}
				});
				
				window.setTimeout(() => {
					this.setState((prevState) => {
						return {
							currentQuestionIndex: 0,
						}
					});
				}, 50);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				// this.forceUpdate();
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				window.alert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		clear_new1: (levelIndex) => {
			
			const logName = 'TrainerViewPage.levels.clear';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let trainer = this.state.trainer;
			let levels = trainer.children;
			let level = levels[levelIndex];
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/clear-trainer-level'),
				data: {
					material_id: level.id,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.levelPreview.clearBtn.click.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let level = new Material(response.data);
				this.state.trainer.children[this.state.currentLevelIndex] = level;
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				// this.forceUpdate();
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				window.alert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		upd: (id, data, index) => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('materials') + '/' + id,
				data: data,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TrainerViewPage.levels.upd.axaj.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let level = new Material(response.data);
				
				let trainer = this.state.trainer;
				
				trainer.children[index] = level;
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				window.alert(error);
				
			});
			
		},
		
		next: () => {
			
			let levels = this.state.trainer.children;
			let nextLevelIndex = Utils.arrayNextIndex(levels, this.state.currentLevelIndex);
			
			// todo xxx
			
			this.setState((prevState) => {
				return {
					congratsModalIsOpen: false,
					currentQuestionIndex: null,
				}
			});
			
			window.setTimeout(() => {
				this.setState((prevState) => {
					return {
						currentQuestionIndex: 0,
						currentLevelIndex: nextLevelIndex,
					}
				});
			}, 50);
			
		},
		
		prev: () => {
			
			let levels = this.state.trainer.children;
			let prevLevelIndex = Utils.arrayPrevIndex(levels, this.state.currentLevelIndex);
			
			// todo xxx
			
			this.setState((prevState) => {
				return {
					congratsModalIsOpen: false,
					currentQuestionIndex: null,
				}
			});
			
			window.setTimeout(() => {
				this.setState((prevState) => {
					return {
						currentQuestionIndex: 0,
						currentLevelIndex: prevLevelIndex,
					}
				});
			}, 50);
			
		},
		
	};
	
	questions = {
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					questionFormModalIsOpen: !prevState.questionFormModalIsOpen,
				}
			});
		},
		
		upd: (id, data) => {
			
			const logName = 'TrainerViewPage.upd';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question') + '/' + id,
				data: data,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
			}).catch((error) => {
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (bindId, questionIndex) => {
			
			let trainer = this.state.trainer;
			let question = trainer.questions[questionIndex];
			
			if (!window.confirm(i18next.t("Archive task {{name}} уровня {{level_num}}?",
				{
					name: questionIndex + 1,
					level_num: this.state.currentLevelIndex + 1
				}))) {
				return;
			}
			
			this.props.preloader.show();
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question-binds') + '/' + bindId,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				let level = this.state.trainer.children[this.state.currentLevelIndex];
				let questions = level.questions;
				questions.splice(questionIndex, 1);
				
				let newQuestionIndex = Utils.arrayNextIndex(questions, questionIndex);
				
				this.setState((prevState) => {
					return {
						currentQuestionIndex: newQuestionIndex,
					}
				});
				
				this.props.preloader.hide();
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
		},
		
		saveSort: (materialId, questionsIds) => {
			
			const logName = 'TrainerViewPage.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('test-question-binds/save-sort'),
				data: {
					material_id: materialId,
					questions_ids: questionsIds,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'UploadsGrid.saveSort';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		next: () => {
			let level = this.state.trainer.children[this.state.currentLevelIndex];
			let questions = level.questions;
			let newIndex = Utils.arrayNextIndex(questions, this.state.currentQuestionIndex);
			this.state.audio.pause();
			this.setState((prevState) => {
				return {
					currentQuestionIndex: newIndex,
				}
			});
		},
		
		prev: () => {
			let level = this.state.trainer.children[this.state.currentLevelIndex];
			let questions = level.questions;
			let newIndex = Utils.arrayPrevIndex(questions, this.state.currentQuestionIndex);
			this.state.audio.pause();
			this.setState((prevState) => {
				return {
					currentQuestionIndex: newIndex,
				}
			});
		},
		
	};
	
	audio = {
		
		toggle: (id, url) => {
			
			let audio = this.state.audio;
			
			if (id == this.state.audioId) {
				if (audio.paused) {
					audio.load();
					audio.play();
				} else {
					audio.pause();
				}
			} else {
				audio.pause();
				audio.src = url;
				audio.load();
				audio.play();
			}
			
			this.setState((prevState) => {
				return {
					audioId: id,
				}
			});
			
		},
		
	};
	
	componentDidMount() {
		
		const logName = 'TrainerViewPage.componentDidMount';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let levels = this.state.trainer.children;
		
		Logger.log(levels, 'this.state.trainer.children', logAllow);
		Logger.log(levels == [], 'levels == []', logAllow);
		
		if (levels.length < 1) {
			this.levels.load();
		}
		
		Logger.groupEnd(logAllow);
		
	}
	
	render() {
		
		const logName = 'TrainerViewPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		// Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const trainer = this.state.trainer;
		Logger.log(trainer, 'trainer', logAllow);
		
		let levels = trainer.children;
		
		if (this.props.user.isAny(['student'])) {
			levels = levels.filter(level => level.questions.length > 0);
		}
		
		const levelData = this.state.currentLevelIndex !== null ? levels[this.state.currentLevelIndex] : null;
		Logger.log(levelData, 'levelData', logAllow);
		
		const level = levelData ? (levelData instanceof Material ? levelData : new Material(levelData)) : null;
		Logger.log(level, 'level', logAllow);
		
		const addLevelBtn = (
			<button
				type={'button'}
				className={[
					'my-btn',
					// 'my-btn-wide',
				].join(' ')}
				onClick={(event) => {
					this.levels.add();
				}}
			>+ {i18next.t("Level")}</button>
		);
		
		const latestScore = level ? level.latest_score : {
			value: 0,
		};
		
		const currentScoreValue = level ? level.getScoreValue() : 0;
		const currentStarsCount = level ? level.getStarsCount(currentScoreValue) : 0;
		const currentRank = level ? level.getRank(currentStarsCount) : '';
		const isRecord = level ? currentScoreValue > level.biggest_score.value : false;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'TrainerViewPage'}>
				
				<div className="content-root">
					
					<div className="row">
						
						<div
							className={[
								'questions-col',
								'col-xl-8',
								'col-lg-7',
								'col-md-6',
								// 'order-1',
								// 'order-md-0',
							].join(' ')}
						>
							
							{(this.state.currentLevelIndex !== null) && (
								
								<>
									
									<section className="page-head-box">
										
										<div className={'row'}>
											
											{(this.props.backBtnOnClick) &&
												<div
													className="col-auto back-btn-col align-self-center d-none d-md-block">
													<BackBtn
														onClick={this.props.backBtnOnClick}
													/>
												</div>
											}
											
											<div className="col-auto back-btn-col align-self-center d-md-none">
												<BackBtn
													onClick={() => {
														this.setState((prevState) => {
															return {
																currentLevelIndex: null,
															}
														});
													}}
												/>
											</div>
											
											<div className="col title-col align-self-center">
												<h2 className={'page-title'}>
													{trainer.name}
													{this.state.currentLevelIndex !== null
														?
														<span>&nbsp;/&nbsp;{i18next.t("Level")}&nbsp;{this.state.currentLevelIndex + 1}</span>
														: ''
													}
												</h2>
											</div>
										
										</div>
									
									</section>
									
									<section className="page-body-box">
										
										<div className="list">
											
											{(levels.length > 0) ? (
												
												<>
													
													{(this.props.user.can('manageMaterials')) &&
														<>
															<div className="controls top-controls levels-controls">
																
																<button
																	type={'button'}
																	className={[
																		'my-btn',
																		'add-question-btn',
																	].join(' ')}
																	onClick={(event) => {
																		this.setState((prevState) => {
																			return {
																				questionToEdit: null,
																				questionFormModalIsOpen: true,
																			}
																		});
																	}}
																>+ {i18next.t("Task")}</button>
															
															</div>
															
															<hr/>
														</>
													}
													
													{(this.state.currentQuestionIndex !== null && level && level.questions) &&
														<>
															<div className="questions">
																
																{level.questions.map((questionData, questionIndex) => {
																	
																	if (this.state.currentQuestionIndex !== questionIndex) {
																		return '';
																	}
																	
																	let question = questionData instanceof Question
																		? questionData : new Question(questionData);
																	
																	Logger.log(question, 'question', logAllow);
																	
																	// игнорируем удалённые
																	if (question.is_active == 0) {
																		return '';
																	}
																	
																	return (
																		
																		<div
																			className={[
																				'question-box',
																			].join(' ')}
																		>
																			
																			<TrainerQuestionView
																				material_id={level.id}
																				question={question}
																				user={this.props.user}
																				preloader={this.props.preloader}
																				questionIndex={questionIndex}
																				questionsCount={level.questions.length}
																				nextBtnOnClick={this.questions.next}
																				prevBtnOnClick={this.questions.prev}
																				// play={this.audio.play}
																				// pause={this.audio.pause}
																				audioToggle={this.audio.toggle}
																				audio={this.state.audio}
																				audioId={this.state.audioId}
																				alert={this.props.alert}
																				afterSendAnswer={(response, answer) => {
																					
																					const logName = 'TrainerViewPage.afterSendAnswer';
																					const logAllow = 1;
																					const logCollapsed = 0;
																					
																					Logger.groupStart(logName, logAllow, logCollapsed);
																					
																					question.latest_answer = answer;
																					
																					let isCompleted = level.isCompleted();
																					Logger.log(isCompleted, 'isCompleted', logAllow);
																					
																					if (isCompleted) {
																						
																						this.levels.saveScore(level);
																						
																					} else {
																						
																						if (answer.is_right == 1) {
																							window.setTimeout(() => {
																								this.questions.next();
																							}, 500);
																						}
																						
																					}
																					
																					Logger.groupEnd(logAllow);
																					
																				}}
																				showAnswerState={true}
																			/>
																			
																			{(this.props.user.can('manageMaterials')) &&
																				<>
																					<div className="controls">
																						
																						<button
																							type={'button'}
																							className={[
																								'my-btn',
																								'my-btn-sm',
																								'question-edit-btn',
																							].join(' ')}
																							onClick={(event) => {
																								this.setState((prevState) => {
																									return {
																										questionToEdit: question,
																										questionToEditIndex: questionIndex,
																										questionFormModalIsOpen: true,
																									}
																								});
																							}}
																							title={i18next.t("Edit")}
																						><Icon.Pencil/></button>
																						
																						{(level.questions.length > 0) &&
																							<>
																								
																								<button
																									type={'button'}
																									className={[
																										'my-btn',
																										'my-btn-sm',
																										'question-move-up-btn',
																									].join(' ')}
																									title={i18next.t("Move up")}
																									onClick={(event) => {
																										
																										let array = level.questions;
																										let newIndex = Utils.arrayMoveUp(array, questionIndex);
																										
																										this.setState((prevState) => {
																											return {
																												currentQuestionIndex: newIndex,
																											}
																										});
																										
																										let questionsIds = array.map(question => question.id);
																										Logger.log(questionsIds, 'questionsIds', logAllow);
																										
																										this.questions.saveSort(level.id, questionsIds);
																										
																									}}
																								><Icon.ArrowUp/>
																								</button>
																								
																								<button
																									type={'button'}
																									className={[
																										'my-btn',
																										'my-btn-sm',
																										'question-move-down-btn',
																									].join(' ')}
																									title={i18next.t("Move down")}
																									onClick={(event) => {
																										
																										let array = level.questions;
																										let newIndex = Utils.arrayMoveDown(array, questionIndex);
																										
																										this.setState((prevState) => {
																											return {
																												currentQuestionIndex: newIndex,
																											}
																										});
																										
																										let questionsIds = array.map(question => question.id);
																										Logger.log(questionsIds, 'questionsIds', logAllow);
																										
																										this.questions.saveSort(level.id, questionsIds);
																										
																									}}
																								><Icon.ArrowDown/>
																								</button>
																							
																							</>
																						}
																						
																						<button
																							type={'button'}
																							className={[
																								'my-btn',
																								'my-btn-sm',
																								'question-del-btn',
																							].join(' ')}
																							onClick={(event) => {
																								this.questions.del(question.bind_id, questionIndex);
																							}}
																							title={i18next.t("Move to archive")}
																						><Icon.Trash/></button>
																					
																					</div>
																				</>
																			}
																		
																		</div>
																	
																	);
																})}
															
															</div>
														</>
													}
												
												</>
											
											) : (
												
												<div
													className="msg no-level-msg">{i18next.t("Level not selected")}</div>
											
											)}
										
										</div>
									
									</section>
								
								</>
							
							)}
						
						</div>
						
						<div
							className={[
								'levels-col',
								'col-xl-4',
								'col-lg-5',
								'col-md-6',
								// this.state.currentLevelIndex !== null ? 'd-none d-md-block' : '',
								// 'order-0',
								// 'order-md-1',
							].join(' ')}
						>
							
							<section className="page-head-box">
								
								<div className="row">
									
									<div className="col-6">
										<h2 className="page-title">
											{i18next.t("Levels")}
										</h2>
									</div>
									
									<div className="col-6 text-right">
										
										<div className="controls d-none d-md-block">
											
											<button
												type={'button'}
												className={[
													'my-btn',
													'my-btn-sm',
													'prev-level-btn',
												].join(' ')}
												onClick={this.levels.prev}
											><Icon.ArrowLeftShort/></button>
											
											<button
												type={'button'}
												className={[
													'my-btn',
													'my-btn-sm',
													'next-level-btn',
												].join(' ')}
												onClick={this.levels.next}
											><Icon.ArrowRightShort/></button>
										
										</div>
									
									</div>
								
								</div>
							
							</section>
							
							<section className="page-body-box">
								
								<div className="list levels-list">
									
									{(this.props.user.can('manageTrainerLevels')) &&
										<>
											<div className="controls top-controls levels-controls">
												{addLevelBtn}
											</div>
											<hr/>
										</>
									}
									
									{levels.map((level, levelIndex) => {
										
										if (level.is_del == 1) {
											return '';
										}
										
										let num = levelIndex + 1;
										
										return this.props.user.isAny(['owner', 'admin']) || level.questions.length > 0 ? (
											
											<div
												key={'level_' + level.id}
												className={[
													'level-preview',
													'list-item',
													'list-item-active',
													levelIndex === this.state.currentLevelIndex
														? 'list-item-current'
														: 'list-item-not-current',
													level.is_public == 1
														? 'level-preview-public'
														: 'level-preview-not-public',
												].join(' ')}
												onClick={(event) => {
													
													// todo xxx
													
													this.setState((prevState) => {
														return {
															currentQuestionIndex: null,
														}
													});
													
													window.setTimeout(() => {
														
														this.setState((prevState) => {
															return {
																currentQuestionIndex: 0,
																currentLevelIndex: levelIndex,
															}
														});
														
													}, 50);
													
												}}
											>
												
												<div className="row">
													
													<div className="left-col col-4 align-self-center">
														
														<div className="name">
															{i18next.t("Level")} {num}
														</div>
														
														{this.props.user.can('debugInfo') && (
															<div className={'debug-info'}>#{level.id}</div>
														)}
													
													</div>
													
													<div className="right-col col-8 text-right align-self-center">
														
														{(this.props.user.can('applyAnswers')) &&
															
															<>

                                                                <span className="score-value score-badge">
                                                                    {level.latest_score.value}
                                                                </span>
																
																<span className="score-stars">
                                                                    <Stars
																		count={level.getStarsCount(level.latest_score.value)}
																		maxCount={3}
																		fullStarImg={blueStar}
																		paleStarImg={grayStar}
																	/>
                                                                </span>
																
																<button
																	type={'button'}
																	className={[
																		'clear-level-btn',
																		'my-btn',
																		'my-btn-sm',
																	].join(' ')}
																	onClick={() => {
																		this.levels.clear(levelIndex);
																	}}
																	// onClick={(event) => {
																	//
																	//     const logName = 'TrainerViewPage.levelPreview.clearBtn.click';
																	//     const logAllow = 1;
																	//     const logCollapsed = 0;
																	//
																	//     Logger.groupStart(logName, logAllow, logCollapsed);
																	//
																	//     axios({
																	//         method: 'post',
																	//         url: Utils.apiUrl('materials/clear-trainer-level'),
																	//         data: {
																	//             material_id: level.id,
																	//         },
																	//         params: {
																	//             'accessToken': Utils.getUserToken(),
																	//         },
																	//     }).then((response) => {
																	//
																	//         const logName = 'TrainerViewPage.levelPreview.clearBtn.click.ajax.done';
																	//         const logAllow = 1;
																	//         const logCollapsed = 0;
																	//
																	//         Logger.groupStart(logName, logAllow, logCollapsed);
																	//
																	//         Logger.log(response, 'response', logAllow);
																	//
																	//         level.questions.forEach((question, questionIndex) => {
																	//             // question.latest_answer = null;
																	//             levels[levelIndex].questions[questionIndex].latest_answer = null;
																	//         });
																	//
																	//         this.setState((prevState) => {
																	//             return {
																	//                 currentQuestionIndex: 0,
																	//             }
																	//         });
																	//
																	//         Logger.groupEnd(logAllow);
																	//
																	//     }).catch((error) => {
																	//         window.alert(error);
																	//     });
																	//
																	//     Logger.groupEnd(logAllow);
																	//
																	// }}
																	title={i18next.t("Пройти заново")}
																><Icon.ArrowClockwise/></button>
															
															</>
															
														}
													
													</div>
												
												</div>
												
												{(this.props.user.can('manageMaterials')) &&
													
													<div className="controls">
														
														{(levels.length > 1) &&
															<>
																<button
																	type={'button'}
																	className={[
																		'my-btn',
																		'my-btn-sm',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.levels.moveUp(levelIndex);
																	}}
																><Icon.ArrowUp/></button>
																
																<button
																	type={'button'}
																	className={[
																		'my-btn',
																		'my-btn-sm',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.levels.moveDown(levelIndex);
																	}}
																><Icon.ArrowDown/></button>
																
																{level.is_public == 1 ? (
																	<>
																		<button
																			type={'button'}
																			className={[
																				'my-btn',
																				'my-btn-sm',
																				'level-unpublic-btn',
																			].join(' ')}
																			onClick={(event) => {
																				event.stopPropagation();
																				this.levels.upd(level.id, {
																					is_public: 0,
																				}, levelIndex);
																			}}
																			title={i18next.t("Скрыть от студентов")}
																		><Icon.EyeSlash/></button>
																	</>
																) : (
																	<>
																		<button
																			type={'button'}
																			className={[
																				'my-btn',
																				'my-btn-sm',
																				'level-public-btn',
																			].join(' ')}
																			onClick={(event) => {
																				event.stopPropagation();
																				this.levels.upd(level.id, {
																					is_public: 1,
																				}, levelIndex);
																			}}
																			title={i18next.t("Опубликовать для студентов")}
																		><Icon.Eye/></button>
																	</>
																)}
																
																{(this.props.user.can('removeContent')) &&
																	<>
																		{level.is_del == 1 ? (
																			<>
																				<button
																					type={'button'}
																					className={[
																						'my-btn',
																						'my-btn-sm',
																						'level-undel-btn',
																					].join(' ')}
																					onClick={(event) => {
																						event.stopPropagation();
																						this.levels.upd(level.id, {
																							is_del: 0,
																						}, levelIndex);
																					}}
																					title={i18next.t("Восстановить из корзины")}
																				><Icon.ArrowCounterclockwise/></button>
																			</>
																		) : (
																			<>
																				<button
																					type={'button'}
																					className={[
																						'my-btn',
																						'my-btn-sm',
																						'level-del-btn',
																					].join(' ')}
																					onClick={(event) => {
																						event.stopPropagation();
																						if (!window.confirm(i18next.t("Are you shure?"))) {
																							return;
																						}
																						this.levels.upd(level.id, {
																							is_del: 1,
																						}, levelIndex);
																					}}
																					title={i18next.t("Удалить в корзину (обратимо)")}
																				><Icon.Trash/></button>
																			</>
																		)}
																	</>
																}
															
															</>
														}
													
													</div>
													
												}
											
											</div>
										) : '';
									})}
								
								</div>
							
							</section>
						
						</div>
					
					</div>
				
				</div>
				
				<div className="modals">
					
					<Modal
						className={'question-form-modal'}
						show={this.state.questionFormModalIsOpen}
						onHide={this.questions.formModalToggle}
						size={'lg'}
						keyboard={false}
						backdrop={'static'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.questionToEdit ? (
									<>{i18next.t("Edit task #{{id}}", {'id': this.state.questionToEdit.id})}</>
								) : (
									<>{i18next.t("Add task")}</>
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							<TrainerQuestionForm
								model={this.state.questionToEdit}
								materialId={level ? level.id : null}
								materials={levels}
								afterSubmit={(respose, mode) => {
									
									let question = new Question(respose.data);
									
									if (mode === 'create') {
										level.questions.push(question);
									} else {
										level.questions[this.state.questionToEditIndex] = question;
									}
									
									this.setState((prevState) => {
										return {
											questionFormModalIsOpen: false,
											// currentQuestionIndex: level.questions.length - 1,
										}
									});
									
								}}
								preloader={this.props.preloader}
							/>
						</Modal.Body>
					
					</Modal>
					
					<Modal
						className={'congrats-modal'}
						show={this.state.congratsModalIsOpen}
						onHide={() => {
							this.setState((prevState) => {
								return {
									congratsModalIsOpen: !prevState.congratsModalIsOpen,
								}
							});
						}}
						size={'lg'}
					>
						
						{/*<Modal.Header closeButton>
                            <Modal.Title>

                            </Modal.Title>
                        </Modal.Header>*/}
						
						<Modal.Body>
							
							<div className="stars-box">
								<Stars
									count={currentStarsCount}
									maxCount={3}
									fullStarImg={blueStar}
									paleStarImg={grayStar}
								/>
							</div>
							
							<div className="rank">
								{currentRank}
							</div>
							
							{(isRecord) &&
								<div className="new-record">
									{i18next.t("Новый рекорд")}
								</div>
							}
							
							<div className="score-box">
								<div className="score-value">
									{currentScoreValue}
								</div>
							</div>
							
							<div className="controls">
								
								<div className="row">
									
									<div className="col-4">
										<button
											type={'button'}
											className={[
												'my-btn',
												// 'btn-primary',
											].join(' ')}
											onClick={(event) => {
												this.setState((prevState) => {
													return {
														congratsModalIsOpen: false,
													}
												});
											}}
										><Icon.X/> {i18next.t("Закрыть")}</button>
									</div>
									
									<div className="col-4">
										<button
											type={'button'}
											className={[
												'my-btn',
												// 'btn-primary',
											].join(' ')}
											onClick={(event) => {
												
												this.levels.clear(this.state.currentLevelIndex);
												
												this.setState((prevState) => {
													return {
														congratsModalIsOpen: false,
													}
												});
												
											}}
										><Icon.ArrowClockwise/> {i18next.t("Повторить")}</button>
									</div>
									
									<div className="col-4">
										<button
											type={'button'}
											className={[
												'my-btn',
												// 'btn-primary',
											].join(' ')}
											onClick={this.levels.next}
										>{i18next.t("След. уровень")} <Icon.ArrowRightShort/></button>
									</div>
								
								</div>
							
							</div>
						
						</Modal.Body>
					
					</Modal>
				
				</div>
			
			</div>
		
		);
	}
	
}

TrainerViewPage.propTypes = {
	trainer: PropTypes.instanceOf(Material).isRequired,
	backBtnOnClick: PropTypes.func,
	preloader: PropTypes.object,
	alert: PropTypes.object,
	user: PropTypes.instanceOf(User).isRequired,
};

TrainerViewPage.defaultProps = {};