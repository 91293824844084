export default class VideoSource {
	
	baseUrls
	countryName
	
	name
	about
	
	sampleVideoUrl
	sampleVideoId
	
	icon
	iconUrl
	
	extractVideoId
	makeWidget
	
	constructor ({
		
		baseUrls,
		name,
		countryName,
		
		sampleVideoUrl,
		sampleVideoId, 
		
		about,
		
		icon,
		iconUrl,
		
		extractVideoId,
		makeWidget,
		
	} = {}) {
		this.baseUrls = baseUrls
		this.sampleVideoUrl = sampleVideoUrl
		this.sampleVideoId = sampleVideoId
		this.name = name
		this.about = about
		this.countryName = countryName
		this.icon = icon
		this.iconUrl = iconUrl
		this.extractVideoId = extractVideoId
		this.makeWidget = makeWidget
	}
	
}