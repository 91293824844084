import './UserNotAnsweredQuestions.css'

import React from 'react'
import PropTypes from 'prop-types'
import Utils from '../../helpers/Utils'
import axios from 'axios'
import Logger from '../../helpers/Logger'
import Question from '../../models/Question'
import QuestionView from './QuestionView'

class UserMsgQuestions extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			questions: [],
		}
	}
	
	load = () => {
		
		if (this.props.preloader.show) {
			this.props.preloader.show()
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('msg/not-answered-questions'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				targetUserId: this.props.targetUserId,
			},
		}).then((response) => {
			
			const logName = 'UserMsgQuestions.load.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			Logger.log(response, 'response', logAllow)
			
			let questions = response.data.map(x => new Question(x))
			
			this.setState({
				questions: questions,
			})
			
			if (this.props.preloader.hide) {
				this.props.preloader.hide()
			}
			
			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			
			if (this.props.preloader.hide) {
				this.props.preloader.hide()
			}
			
			window.alert(axiosError)
			
		})
		
	}
	
	componentDidMount() {
		
		this.load()
		
	}

	render() {
		
		return (
			
			<div className={'UserMsgQuestions'}>
				
				{this.state.questions.map((question) => {
					
					return (
						
						<div className={`question-box`}>
							
							<QuestionView
								question={question}
								preloader={this.props.preloader}
								key={'question-' + question.id}
								mode={'demo'}
								user={this.props.user}
								showPath={true}
								showDiscuss={true}
								discussTargetUserId={this.props.targetUserId}
								showClearBtn={false}
							/>
							
						</div>
						
					)
					
				})}
				
			</div>
			
		)
		
	}
	
}

UserMsgQuestions.propTypes = {
	preloader: PropTypes.object,
	targetUserId: PropTypes.number,
	user: PropTypes.object,
}

UserMsgQuestions.defaultProps = {}

export default UserMsgQuestions