import React from 'react';
import PropTypes from 'prop-types';
import {Editor} from "@tinymce/tinymce-react";
import Rand from "../../helpers/Rand";
import Config from "../../helpers/Config";

export default class EditorsPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		return (
			
			<div className={'EditorsPage'}>
				
				<div className="row">
					
					<div className="col-6">
						
						<Editor
							id={'tinymce_' + Rand.id()}
							init={Config.tinyMceMainConfig}
						/>
						
					</div>
					
				</div>
			
			</div>
		
		);
		
	}
	
}

EditorsPage.propTypes = {
	preloader: PropTypes.object,
};

EditorsPage.defaultProps = {};