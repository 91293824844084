import React from 'react';
import "./BootstrapBreakpointIndicator.css";

export default class BootstrapBreakpointIndicator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={'BootstrapBreakpointIndicator'}>
                <div className={'item d-block   d-sm-none   d-md-none   d-lg-none   d-xl-none'}>xs</div>
                <div className={'item d-none    d-sm-block  d-md-none   d-lg-none   d-xl-none'}>sm</div>
                <div className={'item d-none    d-sm-none   d-md-block  d-lg-none   d-xl-none'}>md</div>
                <div className={'item d-none    d-sm-none   d-md-none   d-lg-block  d-xl-none'}>lg</div>
                <div className={'item d-none    d-sm-none   d-md-none   d-lg-none   d-xl-block'}>xl</div>
            </div>
        );
    }

}

BootstrapBreakpointIndicator.propTypes = {};

BootstrapBreakpointIndicator.defaultProps = {};