import React from 'react';
import PropTypes from 'prop-types';

export default class UploadName extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {
        const uploadBindData = this.props.uploadBindData;
        return (
            <span className={'UploadName'}>
                {this.props.useCustomFileName ? (
                    uploadBindData.name || uploadBindData.original_name_only
                ) : (
                    uploadBindData.original_name_only
                )}
                &nbsp;<span className="file-ext-badge badge badge-primary">{uploadBindData.original_ext}</span>
            </span>
        );
    }

}

UploadName.propTypes = {
    uploadBindData: PropTypes.object.isRequired,
    useCustomFileName: PropTypes.bool,
};

UploadName.defaultProps = {
    useCustomFileName: true,
};