import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";

import "./AnkaraLoginPage.css";
import studerusTextLogo from '../../../img/studerus-text-logo.svg';
import kfuLogoMicro from '../../../img/kfu-logo-micro.png';
import Config from "../../../helpers/Config";
import LoginForm from "../../common/LoginForm";
import LangMenu from "../../common/LangMenu";

export default class AnkaraLoginPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		let currentLangData = this.props.langs[i18next.language];
		
		return (
			
			<div
				className={[
					'LoginPage',
					// 'h-100',
					// 'align-self-center',
				].join(' ')}
			>
				
				{/*<div className="mobile-logo d-md-none">
                    <img src={} alt=""/>
                </div>*/}
				
				<div
					className={[
						'row',
						'h-100',
						'align-items-center',
					].join(' ')}
				>
					
					<div
						className={[
							
							'logo-col',
							
							'col-xl-4',
							'offset-xl-1',
							
							'col-sm-4',
							'offset-sm-1',
							
							'align-self-center',
							'text-center',
							
							// 'd-none',
							// 'd-md-block',
						
						].join(' ')}
					>
						<a className={'logo-link institute-logo-link ankara-logo-link'} href={Config.ankaraUrl} target={'_blank'}></a>
						<img src={studerusTextLogo} alt="studerus_text_logo" className="studerus-logo"/>
						<div className="powered-by">
							{i18next.t("Powered by")}&nbsp;
							<a
								href={Config.kfuUrl}
								target={'_blank'}
							><img src={kfuLogoMicro} alt={'kfu-logo'}/></a>
						</div>
					</div>
					
					<div
						className={[
							
							'form-col',
							
							'col-xl-4',
							'offset-xl-2',
							
							'col-sm-5',
							'offset-sm-1',
							
							'align-self-center',
						
						].join(' ')}
					>
						
						<div className="list login-form-box">
							<LoginForm submit={this.props.login}/>
						</div>
						
						<div className="lang-menu-box">
							<LangMenu
								langs={this.props.langs}
								changeLang={this.props.changeLang}
								currentLangAlias={this.props.currentLangAlias}
							/>
						</div>
					
					</div>
				
				</div>
			
			</div>
		);
	}
	
}

AnkaraLoginPage.propTypes = {
	login: PropTypes.func.isRequired,
	langs: PropTypes.object.isRequired,
	changeLang: PropTypes.func.isRequired,
	currentLangAlias: PropTypes.string.isRequired,
};

AnkaraLoginPage.defaultProps = {};