import User from "./User";
import Group from "./Group";

export default class Sched {
    
    constructor(data = {}) {

        this.id = data.id;
        this.date = data.date;
        this.time = data.time;
        this.name = data.name || '';
        this.group_id = data.group_id;
        this.group_name = data.group_name;
        this.url = data.url;

        this.timestamp = data.timestamp;
        this.place = data.place;
        this.type_id = data.type_id;
        this.theme_id = data.theme_id;
        this.about = data.about || '';
        this.created_by = data.created_by;

        this.teacher_id = data.teacher_id;
        this.teacher = data.teacher ? new User(data.teacher) : null;

        this.group = data.group ? new Group(data.group) : null;
        this.is_active = data.is_active;

    }

    timeWithoutSeconds = () => {
        let data = this.time.split(':');
        return data[0] + ':' + data[1];
    };

    static collectGroups = (schedsArray) => {

        let groups = [];

        schedsArray.forEach((sched) => {
            let group = sched.group;
            if (group && groups.filter(group2 => group.id == group2.id).length === 0) {
                groups.push(group);
            }
        });

        groups.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return groups;

    };
    
}