export default class Range {
	
	// src: https://stackoverflow.com/questions/8273047/javascript-function-similar-to-python-range
	static get(start, stop, step = 1) {
		
		if (typeof stop == 'undefined') {
			// one param defined
			stop = start;
			start = 0;
		}
		
		if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
			return [];
		}
		
		const out = [];
		
		for (let i = start; step > 0 ? i <= stop : i >= stop; i += step) {
			out.push(i);
		}
		
		return out;
		
	};
	
}