import "./MaterialsPage.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import {withRouter} from "react-router";
import Theme from "../../models/Theme";
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import axios from "axios";
import Utils from "../../helpers/Utils";
import Material from "../../models/Material";
import Question from "../../models/Question";
import User from "../../models/User";
import * as Icon from 'react-bootstrap-icons';
import Group from "../../models/Group";
import Lesson from "../../models/Lesson";
import BackBtn from "./BackBtn";
import Course from "../../models/Course";
import ListItem from "./ListItem";
import MaterialView from "./MaterialView";
import {Link} from "react-router-dom";


class MaterialsPage2 extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			materialFormModalIsOpen: false,
			materialTheoryFormModalIsOpen: false,
			materialTestFormModalIsOpen: false,
			
			currentMaterial: null,
			currentMaterialIndex: 0,
			
			materials: [],
			materialsActiveOnly: true,
			
			materialsShowDel: false,
			materialsShowDraft: this.props.user.can('seeMaterialsDrafts'),
			
			materialToEdit: null,
			materialToEditIndex: null,
			materialToEditTypeAlias: null,
			
			questionFormModalIsOpen: false,
			questionToEdit: null,
			questionToEditIndex: null,
			currentQuestionIndex: null,
			
			imgViewerIsOpen: false,
			imgViewerCurrentIndex: null,
			imgViewerUrls: [],
			
			homeworks: [],
			currentHomework: null,
			currentHomeworkIndex: null,
			homeworkToEdit: null,
			homeworkToEditIndex: null,
			homeworkFormModalIsOpen: false,
			
			groups: [],
			// questionsStates: [], // менки домашних заданий
			
			useQuestionEditModal: true,
			
			questionCardToEdit: null,
			questionCardFormModalIsOpen: false,
			
			questionGroupFormModalIsOpen: false,
			
		};
		
	}
	
	materials = {
		
		loadAll: (
			materialsShowDel = this.state.materialsShowDel,
			materialsShowDraft = this.state.materialsShowDraft
		) => {
			
			const logName = 'MaterialsPage2.materials.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			this.setState((prevState) => {
				return {
					materials: [],
					currentMaterial: null,
					// setCurrentMaterialIndex: null,
				}
			});
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'sort': 'sort',
				'filter[theme_id]': this.props.theme.id,
				'per-page': 50,
			};
			
			if (!materialsShowDel) {
				params['filter[is_del]'] = 0;
			}
			
			if (!materialsShowDraft) {
				params['filter[is_public]'] = 1;
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials'),
				data: {},
				params: params,
			}).then((response) => {
				
				const logName = 'MaterialsPage2.materials.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let materials = response.data.map(materialData => new Material(materialData));
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				let currentMaterial = materials[this.state.currentMaterialIndex];
				
				this.setState((prevState) => {
					return {
						currentMaterial: currentMaterial,
						currentQuestionIndex: 0,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		loadList: (
			materialsShowDel = this.state.materialsShowDel,
			materialsShowDraft = this.state.materialsShowDraft
		) => {
			
			const logName = 'MaterialsPage2.materials.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			this.setState((prevState) => {
				return {
					materials: [],
					currentMaterial: null,
					// setCurrentMaterialIndex: null,
				}
			});
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'themeId': this.props.theme.id,
				'drafts': materialsShowDraft ? 1 : 0,
				'archived': materialsShowDel ? 1 : 0,
			};
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials/list'),
				data: {},
				params: params,
			}).then((response) => {
				
				const logName = 'MaterialsPage2.materials.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let materials = response.data.map(materialData => new Material(materialData));
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				let currentMaterialIndex = this.state.currentMaterialIndex || localStorage.getItem(Config.coursesCurrentThemeIndexKey);
				
				if (currentMaterialIndex !== null) {
					
					let currentMaterial = materials[currentMaterialIndex];
					
					if (currentMaterial) {
						
						currentMaterial.has_details = 0;
						
						this.materials.setCurrentWithLoad(currentMaterial, currentMaterialIndex);
						
					}
					
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		loadOne: (materialId, afterSuccess) => {
			
			const logName = 'MaterialsPage2.materials.loadOne';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!materialId) {
				window.alert(i18next.t("matrial id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials') + '/' + materialId,
				data: {},
				params: {
					accessToken: Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage2.materials.loadOne.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				console.log('error.response = %o', error.response);
				
				if (error.response && error.response.data) {
					window.alert(error.response.data.message);
				} else {
					window.alert(error.message);
				}
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterCreate: (response) => {
			
			const logName = 'MaterialsPage2.materials.afterCreate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = new Material(response.data);
			let materials = this.state.materials;
			materials.push(material);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: material,
					materialFormModalIsOpen: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpdate: (response) => {
			
			const logName = 'MaterialsPage2.materials.afterUpdate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			const oldMaterial = this.state.currentMaterial;
			Logger.log(oldMaterial, 'oldMaterial', logAllow);
			
			const newMaterial = new Material(response.data);
			Logger.log(newMaterial, 'material', logAllow);
			
			const materials = this.state.materials;
			
			materials[this.state.materialToEditIndex] = newMaterial;
			
			this.setState((prevState) => {
				return {
					currentMaterial: newMaterial,
					materials: materials,
				}
			});
			
			// if (oldMaterial) {
			// 	this.setState((prevState) => {
			// 		return {
			// 			currentMaterial: newMaterial,
			// 		}
			// 	});
			// }
			
			Logger.groupEnd(logAllow);
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					materialFormModalIsOpen: !prevState.materialFormModalIsOpen,
				}
			});
		},
		
		// сохранить текущий порядок элементов
		saveSort: (materials) => {
			
			const logName = 'MaterialsPage2.materials.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			materials.forEach((material) => {
				ids.push(material.id);
			});
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage2.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (materialIndex) => {
			
			let newMaterialIndex;
			
			if (materialIndex > 0) {
				newMaterialIndex = materialIndex - 1;
			} else {
				newMaterialIndex = this.state.materials.length - 1;
			}
			
			let materials = this.state.materials;
			Utils.arrayMove(materials, materialIndex, newMaterialIndex);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterialIndex: newMaterialIndex,
				}
			});
			
			this.materials.saveSort(materials);
			
		},
		
		moveDown: (materialIndex) => {
			
			let newMaterialIndex;
			
			if (materialIndex === this.state.materials.length - 1) {
				newMaterialIndex = 0;
			} else {
				newMaterialIndex = materialIndex + 1;
			}
			
			let materials = this.state.materials;
			Utils.arrayMove(materials, materialIndex, newMaterialIndex);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterialIndex: newMaterialIndex,
				}
			});
			
			this.materials.saveSort(materials);
			
		},
		
		upd: (materialIndex, data, confirmMsg) => {
			
			const logName = 'MaterialsPage2.upd';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.log(materialIndex, 'materialIndex', logAllow);
			Logger.log(data, 'data', logAllow);
			
			let material = this.state.materials[materialIndex];
			Logger.log(material, 'material', logAllow);
			
			if (!material) {
				return;
			}
			
			if (confirmMsg) {
				if (!window.confirm(confirmMsg)) {
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					return;
				}
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('materials') + '/' + material.id,
				data: data,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage2.materials.upd';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let material = new Material(response.data);
				
				let materials = this.state.materials;
				
				materials[materialIndex] = material;
				
				if ((material.is_del == 1 && this.state.materialsShowDel == 0) || (material.is_public == 0 && this.state.materialsShowDraft == 0)) {
					materials.splice(materialIndex, 1);
				}
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addCard: () => {
			
			const logName = 'MaterialsPage2.materials.addCards';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			this.setState((prevState) => {
				return {
					questionCardToEdit: null,
					questionCardFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addGroup: () => {
			this.setState((prevState) => {
				return {
					questionGroupFormModalIsOpen: true,
				}
			});
		},
		
		setCurrent: (material, materialIndex) => {
			
			let materials = this.state.materials;
			
			materials[materialIndex] = material;
			
			localStorage.setItem(Config.coursesCurrentMaterialIdKey, material.id);
			localStorage.setItem(Config.coursesCurrentMaterialIndexKey, materialIndex);
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentMaterialIndex: materialIndex,
					currentQuestionIndex: 0,
					materialToEdit: null,
					materials: materials,
				}
			});
			
		},
		
		setCurrentWithLoad: (material, materialIndex) => {
			
			if (material.has_details == 1) {
				
				this.materials.setCurrent(material, materialIndex);
				
			} else {
				
				this.materials.loadOne(material.id, (response) => {
					
					let material = new Material(response.data);
					
					material.has_details = 1;
					
					this.materials.setCurrent(material, materialIndex);
					
				});
				
			}
			
		},
		
	};
	
	questions = {
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					questionFormModalIsOpen: !prevState.questionFormModalIsOpen,
				}
			});
		},
		
		afterSubmit: (response, materialId) => {
			
			const logName = 'MaterialsPage2.questions.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let isCreate = this.state.questionToEdit === null;
			Logger.log(isCreate, 'isCreate', logAllow);
			
			let questionData = response.data;
			Logger.log(questionData, 'questionData', logAllow);
			
			let question = new Question(questionData);
			Logger.log(question, 'question', logAllow);
			
			let currentMaterial = this.state.currentMaterial;
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			
			let questions = currentMaterial.questions;
			Logger.log(questions, 'questions', logAllow);
			
			let materials = this.state.materials;
			
			if (isCreate) {
				// create
				questions.push(question);
				// questions.unshift(question);
			} else {
				// update
				if (materialId == currentMaterial.id) {
					// если задание НЕ перемещёно в другой блок
					questions[this.state.questionToEditIndex] = question;
				} else {
					// если задание перемещёно в другой блок
					questions.splice(this.state.questionToEditIndex, 1);
					let targetMaterial = null;
					let targetMaterialIndex = null;
					materials.forEach((material, materialIndex) => {
						if (material.id == materialId) {
							targetMaterial = material;
							targetMaterialIndex = materialIndex;
						}
					});
					targetMaterial.questions.push(question);
					materials[targetMaterialIndex] = targetMaterial;
				}
			}
			
			currentMaterial.questions = questions;
			
			materials[this.state.currentMaterialIndex] = currentMaterial;
			
			this.questions.saveSort(questions);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: currentMaterial,
					questionToEdit: null,
					questionFormModalIsOpen: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (bindId, questionIndex) => {
			
			const logName = 'MaterialsPage2.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			let question = material.questions[questionIndex];
			
			Logger.log(bindId, 'bindId', logAllow);
			Logger.log(questionIndex, 'questionIndex', logAllow);
			
			if (!bindId) {
				window.alert(i18next.t("bind id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (!window.confirm(i18next.t("Hide task #{{id}}?", {id: question.id}))) {
				Logger.groupEnd(logAllow);
				return;
			}
			
			this.props.preloader.show();
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question-binds') + '/' + bindId,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				let material = this.state.currentMaterial;
				let questions = material.questions;
				questions.splice(questionIndex, 1);
				
				this.setState((prevState) => {
					return {
						currentMaterial: material,
					}
				});
				
				this.props.preloader.hide();
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		saveSort: (questions) => {
			
			const logName = 'MaterialsPage2.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			Logger.log(questions, 'questions', logAllow);
			
			questions.forEach((question) => {
				// Logger.log(question, 'question', logAllow);
				if (question) {
					ids.push(question.id);
				}
			});
			
			axios({
				method: 'post',
				url: Utils.apiUrl('test-question-binds/save-sort'),
				data: {
					material_id: this.state.currentMaterial.id,
					questions_ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage2.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			const logName = 'MaterialsPage2.questions.moveUp';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			let questions = material.questions;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = questions.length - 1;
			}
			
			Logger.log(questions, 'questions (before move)', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			Logger.log(questions, 'questions (after move)', logAllow);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveDown: (currentIndex) => {
			
			const logName = 'MaterialsPage2.moveDown';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.log(currentIndex, 'currentIndex', logAllow);
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			Logger.log(material, 'material', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions', logAllow);
			
			if (currentIndex === questions.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			Logger.log(newIndex, 'newIndex', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		edit: (question, questionIndex) => {
			
			if (question.view_type_alias == 'cards') {
				
				this.setState((prevState) => {
					return {
						questionCardToEdit: question,
						questionCardFormModalIsOpen: true,
						questionToEditIndex: questionIndex,
					}
				});
				
			} else {
				
				this.setState((prevState) => {
					return {
						questionToEdit: question,
						questionToEditIndex: questionIndex,
						questionFormModalIsOpen: true,
					}
				});
				
			}
			
		},
		
	};
	
	homework = {
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					homeworkFormModalIsOpen: !prevState.homeworkFormModalIsOpen,
				}
			});
		},
		
	};
	
	groups = {
		
		load: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('groups'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'name',
					'filter[is_active]': 1,
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage2.groups.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let groups = [];
				
				response.data.forEach((groupData) => {
					let group = new Group(groupData);
					groups.push(group);
				});
				
				this.setState((prevState) => {
					return {
						groups: groups,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.theme !== prevProps.theme
			|| this.state.materialsShowDel !== prevState.materialsShowDel
			|| this.state.materialsShowDraft !== prevState.materialsShowDraft
		) {
			this.materials.loadList();
		}
	}
	
	componentDidMount() {
		
		this.materials.loadList();
		
		if (this.props.user.isAny('teacher')) {
			this.groups.load();
		}
		
	}
	
	render() {
		
		const logName = 'MaterialsPage2.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const user = this.props.user;
		
		const currentMaterial = this.state.currentMaterial;
		Logger.log(currentMaterial, 'currentMaterial', logAllow);
		
		let imgUrls = [];
		
		if (this.state.currentMaterial) {
			imgUrls = this.state.currentMaterial.images.map((imgUploadBindData) => {
				return imgUploadBindData.url;
			});
		}
		
		const backBtnTitle = i18next.t("Back to courses, lessons and themes");
		const backBtn = <BackBtn onClick={this.props.back} title={backBtnTitle}/>;
		
		// title
		
		const currentThemeNum = this.props.currentThemeIndex + 1;
		const themesCount = this.props.themes.length;
		const themeNum = i18next.t("{{num}} of {{count}}", {
			'num': currentThemeNum,
			'count': themesCount,
		});
		
		const pageTitleData = [
			this.props.theme.name,
		];
		
		if (currentMaterial) {
			pageTitleData.push(currentMaterial.name);
		}
		
		const pageTitle = pageTitleData.join(' › ');
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'MaterialsPage'}>
				
				<div className="content-root row">
					
					<section className="current-material-col col-xl-9 col-lg-8 col-sm-8 order-2 order-md-1">
						
						{/* md+ theme nav panel */}
						<div className="nav-panel theme-nav-panel page-head-box d-none d-md-block">
							
							<div className={'row'}>
								
								<div className="col-auto back-btn-col align-self-center">
									{backBtn}
								</div>
								
								<div className={'title-col col align-self-center'}>
									<small className={'text-muted'}>
										{this.props.course.name} &bull; {this.props.lesson.name} &bull; {i18next.t("Theme")} {themeNum}
									</small>
									<h2>{pageTitle}</h2>
								</div>
								
								<div
									className={'controls-col col-12 col-md-auto text-center text-md-right mt-3 mt-md-0'}>
									
									{(themesCount > 1) &&
										<>
											<button
												type={'button'}
												className={[
													'prev-theme-btn',
													'my-btn',
													'my-btn-sm',
													// 'btn-primary',
												].join(' ')}
												onClick={this.props.showPrevTheme}
												title={[
													i18next.t("Previous theme"),
													this.props.prevTheme.name,
													(this.props.prevThemeIndex + 1) + ' ' + i18next.t("of") + ' ' + this.props.themes.length,
												].join(' | ')}
											><Icon.ArrowLeft/></button>
											
											<button
												type={'button'}
												className={[
													'next-theme-btn',
													'my-btn',
													'my-btn-sm',
													// 'btn-primary',
												].join(' ')}
												onClick={this.props.showNextTheme}
												title={[
													i18next.t("Next theme"),
													this.props.nextTheme.name,
													(this.props.nextThemeIndex + 1) + ' ' + i18next.t("of") + ' ' + this.props.themes.length,
												].join(' | ')}
											><Icon.ArrowRight/></button>
										</>
									}
									
									<button
										type={'button'}
										className={[
											'close-theme-btn',
											'my-btn',
											'my-btn-sm',
											// 'btn-primary',
										].join(' ')}
										onClick={this.props.back}
										title={backBtnTitle}
									><Icon.X/></button>
									
									<button
										type={'button'}
										className={[
											'reload-theme-btn',
											'my-btn',
											'my-btn-sm',
											// 'btn-primary',
										].join(' ')}
										onClick={() => {
											this.materials.loadList(0, 0);
										}}
										title={i18next.t("Reload")}
									><Icon.ArrowCounterclockwise/></button>
								
								</div>
							
							</div>
						
						</div>
						
						{/* sm- theme nav panel */}
						<div className="nav-panel mobile-theme-nav-panel page-head-box d-md-none">
							
							<small className={'text-muted'}>
								<b>{i18next.t("Course")}</b>: {this.props.course.name}
								&nbsp;&bull; <b>{i18next.t("Lesson")}</b>: {this.props.lesson.name}
								&nbsp;&bull; {i18next.t("Theme")} {themeNum}
							</small>
							
							<h2>{backBtn} {this.props.theme.name}</h2>
							
							{currentMaterial ? (
								<>
									<div className="list current-material-preview-box">
										<ListItem
											onClick={() => {
												this.setState((prevState) => {
													return {
														currentMaterial: null,
													}
												});
											}}
										>
											<div className="row align-items-center">
												<div className="col-8 name-col">
													{currentMaterial.name}
												</div>
												<div className="col-4 change-btn-col">
													<div className="change-btn">
														{i18next.t("Select")}
													</div>
												</div>
											</div>
										</ListItem>
									</div>
								</>
							) : (
								<>
									<div className="list materials-list">
										
										{this.state.materials.length > 0 ? (
											<>
												{this.state.materials.map((material, materialIndex) => {
													
													const logName = 'MaterialsPage2.materials.map';
													const logAllow = 1;
													const logCollapsed = 0;
													
													Logger.groupStart(logName, logAllow, logCollapsed);
													
													// Logger.log(material, 'material', logAllow);
													Logger.log(material.name, 'material.name', logAllow);
													
													if (user.isAny(['student'])) {
														
														let hasAccess = material.hasAccess(user.groups_ids);
														
														if (!hasAccess) {
															Logger.groupEnd(logAllow);
															return;
														}
														
													}
													
													Logger.groupEnd(logAllow);
													
													return (
														<ListItem
															onClick={() => {
																this.materials.setCurrentWithLoad(material, materialIndex);
															}}
														>{material.name}</ListItem>
													);
												})}
											</>
										) : (
											<>
												{i18next.t("Materials not found")}
											</>
										)}
									
									</div>
								</>
							)}
						
						</div>
						
						{this.state.currentMaterial && (
							
							<MaterialView
								material={this.state.currentMaterial}
								user={user}
								preloader={this.props.preloader}
								theme={this.props.theme}
								afterCreate={this.materials.afterCreate}
								afterUpdate={this.materials.afterUpdate}
							/>
						
						)}
					
					</section>
					
					<section
						className="materials-list-col col-xl-3 col-lg-4 col-sm-4 order-1 order-md-2 d-none d-md-block">
						
						<div className="materials-list-box">
							
							<div className="page-head-box">
								<h2>{i18next.t("Materials")}</h2>
							</div>
							
							<div className="list materials-list materials-list-desktop">
								
								{this.props.user.can('manageMaterials') && (
									<div className="controls top-controls materials-controls materials-top-controls">
										
										<button type={'button'}
												className={[
													'add-material-btn',
													'my-btn',
													// 'my-btn-sm',
													'my-btn-wide',
												].join(' ')}
												onClick={(event) => {
													this.setState((prevState) => {
														return {
															materialToEdit: null,
															materialToEditIndex: null,
															materialToEditTypeAlias: Material.TYPE_ALIAS_THEORY,
															materialFormModalIsOpen: true,
														}
													});
												}}
											// title={i18next.t("Add material")}
										>+ {i18next.t("Add material")}</button>
									
									</div>
								)}
								
								{this.props.user.can('showRemovedContent') && (
									<div className={'controls show-removed-controls text-center'}>
										<label htmlFor={'show-removed'}>
											<input
												id={'show-removed'}
												type={'checkbox'}
												checked={this.state.materialsShowDel}
												onChange={(event) => {
													this.setState((prevState) => {
														return {
															materialsShowDel: event.target.checked,
														}
													});
													// this.materials.load();
												}}
											/> {i18next.t("Archive")}
										</label>
									</div>
								)}
								
								{(this.props.user.can('seeMaterialsDrafts')) &&
									<div className={'controls show-draft-controls text-center'}>
										<label htmlFor={'show-not-public'}>
											<input
												id={'show-not-public'}
												type={'checkbox'}
												checked={this.state.materialsShowDraft}
												onChange={(event) => {
													this.setState((prevState) => {
														return {
															materialsShowDraft: event.target.checked,
														}
													});
													// this.materials.load();
												}}
											/> {i18next.t("Drafts")}
										</label>
									</div>
								}
								
								{(this.state.materials.length > 0) ? (
									
									<div className={'body materials-list-body'}>
										
										{this.state.materials.map((material, materialIndex) => {
											
											if (user.isAny(['student'])) {
												
												let hasAccess = material.hasAccess(user.groups_ids);
												
												if (!hasAccess) {
													return;
												}
												
											}
											
											let progress = material.isExam() ? 0 : material.getProgress();
											// let progress = material.getProgress();
											
											let classNameData = [
												'material-preview',
												'list-item',
												'list-item-active',
											];
											
											if (currentMaterial && material.id === currentMaterial.id) {
												classNameData.push('list-item-current');
											}
											
											if (material.is_del == 1) {
												classNameData.push('list-item-archived');
											}
											
											if (material.is_exam == 1) {
												classNameData.push('is_exam');
											}
											
											if (material.is_trainer == 1) {
												classNameData.push('is_trainer');
											}
											
											if (!material.is_public == 1) {
												classNameData.push('list-item-draft');
											}
											
											let materialDebugInfo = [
												'#' + material.id,
												'created_at: ' + material.created_at,
												// 'created_by: ' + material.created_by,
											];
											
											if (material.copy_of_id) {
												materialDebugInfo.push('copy of #' + material.copy_of_id);
											}
											
											return (
												
												<div
													className={classNameData.join(' ')}
													onClick={(event) => {
														this.materials.setCurrentWithLoad(material, materialIndex);
													}}
													// title={progress + '%'}
													// data-type-alias={material.type_alias}
													data-type-alias={material.isExam() ? 'exam' : ''}
													data-id={material.id}
												>
													{progress > 0 && (
														<div className="progress-box">
															<div className="progress" style={{
																width: progress + '%',
															}}></div>
														</div>
													)}
													
													<div className={'content'}>
														<div className={'title'}>{material.name}</div>
														{this.props.user.can('debugInfo') && (
															<div
																className={'debug-info'}>{materialDebugInfo.join(' | ')}</div>
														)}
													</div>
													
													{this.props.user.can('manageMaterials') && (
														
														<div
															className="controls material-preview-controls bottom-controls">
															
															{/*<button
                                                                type={'button'}
                                                                className={[
                                                                    'edit-btn',
                                                                    'my-btn',
                                                                    'my-btn-sm',
                                                                    // 'btn-primary',
                                                                ].join(' ')}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    this.setState((prevState) => {
                                                                        return {
                                                                            materialToEdit: material,
                                                                            materialToEditIndex: materialIndex,
                                                                            // materialToEditTypeAlias: material.type_alias,
                                                                            // materialFormModalIsOpen: true,
                                                                            currentMaterial: material,
                                                                        }
                                                                    });
                                                                }}
                                                            ><Icon.Pencil/></button>*/}
															
															{this.state.materials.length > 1 && (
																<button
																	type={'button'}
																	className={[
																		'move-up-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.moveUp(materialIndex);
																	}}
																><Icon.ArrowUp/></button>
															)}
															
															{this.state.materials.length > 1 && (
																<button
																	type={'button'}
																	className={[
																		'move-down-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.moveDown(materialIndex);
																	}}
																><Icon.ArrowDown/></button>
															)}
															
															{material.is_public == 0 && material.is_del == 0 && (
																<button
																	type={'button'}
																	className={[
																		'public-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_public: 1,
																		});
																	}}
																	title={i18next.t("Show for students")}
																><Icon.Eye/></button>
															)}
															
															{material.is_public == 1 && material.is_del == 0 && (
																<button
																	type={'button'}
																	className={[
																		'unpublic-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_public: 0,
																		});
																	}}
																	title={i18next.t("Hide for students")}
																><Icon.EyeSlash/></button>
															)}
															
															{material.is_del == 0 && (
																<button
																	type={'button'}
																	className={[
																		'del-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_del: 1,
																		}, i18next.t("Are you shure?"));
																	}}
																	title={i18next.t("Move to archive")}
																><Icon.Trash/></button>
															)}
															
															{material.is_del == 1 && (
																<button
																	type={'button'}
																	className={[
																		'undel-material-btn',
																		'my-btn',
																		'my-btn-sm',
																		// 'btn-primary',
																	].join(' ')}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.materials.upd(materialIndex, {
																			is_del: 0,
																		});
																	}}
																	title={i18next.t("Return from archive")}
																><Icon.ArrowCounterclockwise/></button>
															)}
															
															<button
																type={'button'}
																className={[
																	'copy-material-btn',
																	'my-btn',
																	'my-btn-sm',
																].join(' ')}
																onClick={(event) => {
																	event.stopPropagation();
																	let themeId = window.prompt(i18next.t("Theme ID"), material.theme_id);
																	if (themeId) {
																		if (this.props.preloader) {
																			this.props.preloader.show();
																		}
																		axios({
																			method: 'post',
																			url: Utils.apiUrl('materials/copy-to'),
																			data: {},
																			params: {
																				'accessToken': Utils.getUserToken(),
																				materialId: material.id,
																				themeId: themeId,
																			},
																		}).then((response) => {
																			
																			const logName = 'MaterialsPage2.copyMaterial';
																			const logAllow = 1;
																			const logCollapsed = 0;
																			
																			Logger.groupStart(logName, logAllow, logCollapsed);
																			
																			Logger.log(response, 'response', logAllow);
																			
																			if (themeId == material.theme_id) {
																				let copy = new Material(response.data);
																				let materials = this.state.materials;
																				materials.push(copy);
																				materials.sort(Utils.defaultModelSort);
																				this.setState((prevState) => {
																					return {
																						materials: materials,
																					}
																				});
																			}
																			
																			if (this.props.preloader) {
																				this.props.preloader.hide();
																			}
																			
																			Logger.groupEnd(logAllow);
																			
																		}).catch((error) => {
																			if (this.props.preloader) {
																				this.props.preloader.hide();
																			}
																			console.log('error.response = %o', error.response);
																			if (error.response && error.response.data) {
																				window.alert(error.response.data.message);
																			} else {
																				window.alert(error.message);
																			}
																		});
																	}
																}}
																title={i18next.t("Copy to") + '...'}
															><Icon.ClipboardCheck/></button>
															
															<button
																type={'button'}
																className={[
																	'show-material-page-btn',
																	'my-btn',
																	'my-btn-sm',
																].join(' ')}
																onClick={(event) => {
																	event.stopPropagation();
																}}
																title={i18next.t("Material page")}
															>
																<Link
																	to={'/material/' + material.id}
																	target={'_blank'}
																><Icon.ArrowUpRightCircleFill/></Link>
															</button>
															
														</div>
													
													)}
												
												</div>
											
											);
											
										})}
									
									</div>
								
								) : (
									
									<div className={'text-center'}>{i18next.t("Materials not found")}</div>
								
								)}
							
							</div>
						
						</div>
					
					</section>
				
				</div>
				
				<div className="modals"></div>
			
			</div>
		
		);
		
	}
	
}

MaterialsPage2.propTypes = {
	course: PropTypes.instanceOf(Course).isRequired,
	theme: PropTypes.instanceOf(Theme).isRequired,
	lesson: PropTypes.instanceOf(Lesson).isRequired,
	preloader: PropTypes.object,
	user: PropTypes.instanceOf(User),
	themes: PropTypes.arrayOf(PropTypes.instanceOf(Theme)),
	back: PropTypes.func,
	showPrevTheme: PropTypes.func,
	showNextTheme: PropTypes.func,
	prevTheme: PropTypes.instanceOf(Theme),
	prevThemeIndex: PropTypes.number,
	nextTheme: PropTypes.instanceOf(Theme),
	nextThemeIndex: PropTypes.number,
	currentThemeIndex: PropTypes.number,
	alert: PropTypes.object,
	skins: PropTypes.array,
};

MaterialsPage2.defaultProps = {
	skins: [],
};

export default withRouter(MaterialsPage2);