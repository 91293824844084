import React from 'react';
import PropTypes from 'prop-types';
import Sched from "../../models/Sched";
import i18next from "i18next";

export default class SchedsSmallTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {

        return (

            <div className={'SchedsSmallTable table-responsive'}>

                <table
                    className={[
                        'table',
                        'table-hover',
                    ].join(' ')}
                >

                    <tbody>

                        {this.props.scheds.map((sched) => {

                            return (

                                <tr key={'sched_' + sched.id}>

                                    <td className={'time-cell'}>
                                        <b>{sched.timeWithoutSeconds()}</b>
                                    </td>

                                    <td className={'info-cell'}>

                                        <div className="theme">
                                            {sched.url ? (
                                                <div className={'link-box'}>
                                                    <a className={[
                                                        // 'link',
                                                        // 'my-btn',
                                                        // 'my-btn-sm',
                                                    ].join(' ')}
                                                       href={sched.url}
                                                       target={'_blank'}
                                                       // title={sched.url}
                                                    >{sched.name}</a>
                                                </div>
                                            ) : (
                                                sched.name
                                            )}
                                        </div>

                                        <small>
                                            <span className={'group'}
                                                  title={i18next.t("Group")}
                                            >{sched.group_name}</span>
                                            {sched.place && (
                                                <span className={'place'}
                                                      title={i18next.t("Place")}
                                                >&nbsp;&bull;&nbsp;{sched.place}</span>
                                            )}
                                            {sched.teacher && (
                                                <span className={'teacher'}
                                                      title={i18next.t("Teacher")}
                                                >&nbsp;&bull;&nbsp;{sched.teacher.getDisplayName(1)}</span>
                                            )}
                                        </small>

                                    </td>

                                    {/*<td className={'link-cell'}>
                                        {sched.url && (
                                            <a className={[
                                                'link',
                                                'my-btn',
                                                'my-btn-sm',
                                            ].join(' ')}
                                               href={sched.url}
                                               target={'_blank'}
                                            >{i18next.t("Link")}</a>
                                        )}
                                    </td>*/}

                                </tr>

                            );

                        })}

                    </tbody>

                </table>

            </div>

        );

    }

}

SchedsSmallTable.propTypes = {
    scheds: PropTypes.arrayOf(PropTypes.instanceOf(Sched)).isRequired,
};

SchedsSmallTable.defaultProps = {};