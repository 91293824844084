import DateHelper from "./DateHelper";

export default class Rand {

	static get digits() {
		return '0123456789';
	}

	static get en() {
		return 'abcdefjhijklmnopqrstuvwxyz';
	}

	static get ru() {
		return 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя';
	}

	static get specs() {
		return '-_';
	}

	static int(min = 0, max = 0) { // goodman
		return Math.floor(Math.random()*(max - min + 1)) + min;
	};

	static string(alphabet = Rand.en, len = 5, norepeat = false) {
		let result = '';
		for(let i=0; i<len; i++){
			let randCharIndex = this.int(0, alphabet.length - 1);
			let randChar = alphabet.charAt(randCharIndex);
			if (norepeat) {
				alphabet = alphabet.replace(randChar,'');
			}
			result += randChar;
		}
		return result;
	};

	static email(prefix='test_', domain=null) {
		// let seed = this.string(this.en,this.int(5,9));
		let seed = Date.now();
		domain = domain || this.string(this.en,5) + '.' + this.string(this.en,3);
		return prefix + seed + '@' + domain;
	};

	static phone() {
		return '+'+this.string(this.digits,11);
	};

	static fromArray(array) {
		const index = this.int(0, array.length - 1);
		return array[index];
	}

	static day = (min=1, max=28, addZero=true) => {

		let day = this.int(min, max);

		if (addZero && day < 10) {
			day = '0' + day;
		}

		return day;

	};

	static month = (min=1, max=12, addZero=true) => {

		let month = this.int(min, max);

		if (addZero && month < 10) {
			month = '0' + month;
		}

		return month;

	};

	static date = ({format='y-m-d', minYear=1950, maxYear=2000, minMoth=1, maxMonth=12, minDay=1, maxDay=28, asText=true}) => {

		let year = this.int(minYear, maxYear);
		let month = this.month(minMoth, maxMonth, true);
		let day = this.day(minDay, maxDay, true);

		let date = format;

		date = date.replace(/d/, day);
		date = date.replace(/m/, month);
		date = date.replace(/y/, year);

		return date;
	};

	static time(addSeconds = false) {

		let hours = Rand.int(0,23);
		let minutes = Rand.int(0,59);
		let seconds = Rand.int(0,59);

		if (hours < 10) {
			hours = '0' + hours;
		}

		if (minutes < 10) {
			minutes = '0' + minutes;
		}

		if (seconds < 10) {
			seconds = '0' + seconds;
		}

		return hours + ':' + minutes + (addSeconds ? ':' + seconds : '');

	}

	static getNames(lang='ru') {
		const names = {
			ru: [
				'Александр',
				'Алексей',
				'Андрей',
				'Артемий',
				'Дмитрий',
				'Константин',
				'Леонид',
				'Михаил',
				'Николай',
				'Олег',
				'Сергей',
			]
		};
		return names[lang];
	}

	static getSurnames(lang='ru') {
		const surnames = {
			ru: [
				'Андреев',
				'Михайлов',
				'Иванов',
				'Петров',
				'Орлов',
			]
		};
		return surnames[lang];
	}

	static getPatrons(lang='ru') {
		const patrons = {
			ru: [
				'Алексеевич',
				'Андреевич',
				'Дмитриевич',
				'Игоревич',
				'Михайлович',
				'Николаевич',
				'Олегович',
				'Семёнович',
				'Сергеевич',
				'Станиславович',
				'Степанович',
			]
		};
		return patrons[lang];
	}

	static name(lang='ru') {
		return Rand.fromArray(Rand.getNames(lang));
	}

	static surname(lang='ru') {
		return Rand.fromArray(Rand.getSurnames(lang));
	}

	static patron(lang='ru') {
		return Rand.fromArray(Rand.getPatrons(lang))
	}

	static fullName(lang='ru', short=false) {

		let name = Rand.name(lang);
		let surname = Rand.surname(lang);
		let patron = Rand.patron(lang);

		if (short) {
			name = name.charAt(0) + '.';
			patron = patron.charAt(0) + '.';
		}

		return surname + ' ' + name + ' ' + patron;

	}

	static snils() {
		let triplet1 = Rand.string(Rand.digits, 3);
		let triplet2 = Rand.string(Rand.digits, 3);
		let triplet3 = Rand.string(Rand.digits, 3);
		let postfix = Rand.string(Rand.digits, 2);
		return `${triplet1}-${triplet2}-${triplet3} ${postfix}`;
	}

	static id (n=10) {
		return Rand.string(Rand.en + Rand.digits, n);
	}

	static inputValue (text=null) {
		return (text ? text + ' ' : '') + '__TEST__ ' + DateHelper.formatDate({}) + ' ' + DateHelper.formatTime({format: 'h:m:s'});
	}

	static password = (length=10) => {

		let password = '';

		let alphabet = Rand.en + Rand.digits + Rand.en.toUpperCase();

		let minRandPos = 0;
		let maxRandPos = alphabet.length - 1;

		for (let i=0; i++; i<length) {
			let randPos = Rand.int(minRandPos, maxRandPos);
			let char = alphabet.charAt(randPos);
			password += char;
		}

		return password;

	};

}