import i18next from "i18next";
import Theme from "./Theme";

export default class Lesson {
	
	constructor(data = {}) {
		this.id = data.id;
		this.name = data.name;
		this.about = data.about;
		this.course_id = data.course_id;
		// this.course = data.course ? new Course(data.course) : null;
		this.is_active = data.is_active;
		this.sort = data.sort;
		this.progress = data.progress || 0;
		this.copy_of_id = data.copy_of_id;
		this.created_at = data.created_at;
		this.themes = data.themes ? data.themes.map(x => new Theme(x)) : [];
	}
	
	getName = () => {
		return this.name ? this.name : i18next.t("Lesson #") + this.id;
	};
	
}