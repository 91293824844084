import React from 'react';
import PropTypes from 'prop-types';
import {Trans} from "react-i18next";
import * as Icon from 'react-bootstrap-icons';
import i18next from "i18next";
import Logger from "../../helpers/Logger";

import './QuestionVariant.css';

export default class QuestionVariant extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			text: this.props.variant.name,
		};
	}
	
	render() {
		
		const logName = 'QuestionVariant.render'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		Logger.log(this.props, 'this.props', logAllow)
		Logger.log(this.props.variant, 'this.props.variant', logAllow)
		
		// let formulas = Question.getFormulas(this.props.variant.name)
		// let formulasRenderData = Question.renderableFormulas(this.props.variant.name)
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<div
				className={'QuestionVariant'}
				data-alias={this.props.alias}
				key={'test-question-variant_' + this.props.alias}
			>
				
				<div className="row">
					
					{this.props.showPositionCol && (
						
						<div className="position col-2">
							
							<div className="form-group">
								<div className="input-group">
									{/*<div className="input-group-prepend">
										<div className="input-group-text">{this.props.num}</div>
									</div>*/}
									<input
										type={'number'}
										id={'position'}
										name={'position'}
										min={1}
										step={1}
										className={'form-control'}
										value={this.props.variant.position}
										onChange={(event) => {
											this.props.onChange(event, this.props.alias);
										}}
										title={i18next.t("Position in text")}
										required={true}
										placeholder={i18next.t("Position")}
									/>
								</div>
							</div>
						
						</div>
					
					)}
					
					<div
						className={[
							'name-col',
							'col',
							// this.props.showPositionCol ? 'col-8 col-xl-9' : 'col-10 col-xl-11',
							// this.props.showPositionCol ? 'col' : 'col',
						].join(' ')}
					>
						
						<div className="form-group mb-0">
							<div className="input-group">
								<div className="input-group-prepend">
									<div className="input-group-text">{this.props.num}</div>
								</div>
								<input
									name={'name'}
									type={'text'}
									className={'form-control'}
									value={this.props.variant.name}
									onChange={(event) => {
										this.props.onChange(event, this.props.alias);
									}}
								/>
							</div>
						</div>
						
						<div className="form-group">
							<label>
								<input
									name={'is_right'}
									type={'checkbox'}
									checked={this.props.variant.is_right}
									onChange={(event) => {
										this.props.onChange(event, this.props.alias);
									}}
								/> <Trans>Верный</Trans>
							</label>
							{/*<MathJaxContext config={{loader:{load:["input/asciimath"]}}}>
								<MathJax dynamic>{"`"+this.props.variant.name+"`"}</MathJax>
							</MathJaxContext>*/}
							{/*{(formulas.length > 0) &&
								<div className={'formulas'}>
									{Question.renderableFormulas(this.props.variant.name)}
								</div>
							}*/}
						</div>
					
					</div>
					
					<div className={`rate-col col-auto`}>
						<div className="form-group mb-0">
							<div className="input-group">
								<div className="input-group-prepend">
									<div className="input-group-text">%</div>
								</div>
								<input
									name={'percent'}
									type={'number'}
									min={-100}
									max={100}
									step={0.01}
									className={'form-control'}
									value={this.props.variant.percent}
									onChange={(event) => {
										this.props.onChange(event, this.props.alias);
									}}
								/>
							</div>
						</div>
					</div>
					
					<div className={`controls-col col-auto`}>
						<div className="controls text-right">
							<button
								type={'button'}
								className={'my-btn my-btn-danger del-btn del-question-variant-btn'}
								onClick={(event) => {
									this.props.onDelete(event, this.props.alias, this.props.variant);
								}}
								title={i18next.t("Delete")}
							>
								<Icon.X/>
							</button>
						</div>
					</div>
				
				</div>
			
			</div>
		);
	}
	
}

QuestionVariant.propTypes = {
	alias: PropTypes.string.isRequired,
	variant: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	num: PropTypes.number.isRequired,
	showPositionCol: PropTypes.bool,
	isFormula: PropTypes.bool,
};

QuestionVariant.defaultProps = {
	// name: '',
	// isRigth: false,
	// position: 0,
};