import React from 'react'
import PropTypes from 'prop-types'
import Material from "../../models/Material"
import User from "../../models/User"
import Logger from "../../helpers/Logger"
import i18next from "i18next"
import MaterialControls from "./MaterialControls"
import MaterialForm from "./MaterialForm"
import Utils from "../../helpers/Utils"
import Modal from "react-bootstrap/Modal"
import QuestionForm from "./QuestionForm"
import QuestionGroupForm from "./QuestionGroupForm"
import QuestionCardForm from "./QuestionCardForm"
import Course from "../../models/Course"
import Theme from "../../models/Theme"
import Lesson from "../../models/Lesson"
import DateHelper from "../../helpers/DateHelper"
import VideoLinkHelper from "../../helpers/VideoLinkHelper"
import YouTubeHelper from "../../helpers/YouTubeHelper"
import * as Icon from 'react-bootstrap-icons'
import MaterialsPageQuestionBox from "./MaterialsPageQuestionBox";
import Dropdown from "react-bootstrap/Dropdown";
import UploadsList from "./UploadsList";
import PdfView from "./PdfView";
import Question from "../../models/Question";
import axios from "axios";
import Config from "../../helpers/Config";
import Range from "../../helpers/Range";

class MaterialView2 extends React.Component {

	constructor(props) {
		
		super(props)
		
		this.state = {
			editMode: 0,
			currentVariant: null,
			materialFormModalIsOpen: 0,
			currentQuestionIndex: 0,
		}
		
	}
	
	questions = {
		
		get: () => {
			
			let questions = []
			
			const material = this.props.material
			const currentVariant = this.state.currentVariant
			
			if (material) {
				
				if (currentVariant) {
					
					questions = currentVariant.children
					
				} else {
					
					questions = material.is_dynamic == 1
						? material.dynamic
							? material.dynamic.questions
							: material.questions
						: material.questions
					
				}
				
			}
			
			return questions
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					questionFormModalIsOpen: !prevState.questionFormModalIsOpen,
				}
			});
		},
		
		setMaterialAndVariant: (question, oldMaterialId, newMaterialId, variantId) => {
			// todo
		},
		
		setVariant: (question, materialId, variantId) => {
			
			const logName = 'MaterialsPage.questions.setVariant'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			let questionIndex = null
			
			let materials = this.state.materials
			
			if (Array.isArray(materials)) {
				
				let materialIndex = materials.findIndex((material) => {
					return material.id == materialId
				})
				
				let material = materials[materialIndex]
				Logger.log(material, 'material', logAllow)
				
				if (material) {
					
					// удаляем из общего списка
					
					// let materialQuestions = material.questions
					
					questionIndex = material.questions.findIndex((question) => {
						return question.id == question.id
					})
					
					if (questionIndex >= 0) {
						material.questions.splice(questionIndex, 1)
					}
					
					// material.questions = materialQuestions
					// materials[materialIndex] = material
					
					// дабавляем в варианты
					
					let variants = material.variants
					
					if (Array.isArray(variants)) {
						
						let variantIndex = variants.findIndex((variant) => {
							return variant.id == variantId
						})
						
						let variant = variants[variantIndex]
						
						if (variant) {
							
							let variantQuestions = variant.children
							
							if (Array.isArray(variantQuestions)) {
								
								let variantQuestionIndex = variantQuestions.findIndex(x => x.id == question.id)
								Logger.log(variantQuestionIndex, 'variantQuestionIndex', logAllow)
								
								if (variantQuestionIndex >= 0) {
									// if (0) {
									
									variantQuestions[variantQuestionIndex] = question
									
								} else {
									
									variantQuestions.push(question)
									
									variantQuestionIndex = variantQuestions.length - 1
									
								}
								
								variant.children = variantQuestions
								
								variants[variantIndex] = variant
								
								material.variants = variants
								
								questionIndex = variantQuestionIndex
								
							}
							
							materials[materialIndex] = material
							
							this.setState({
								materials: materials,
								currentVariant: variant,
								currentQuestionIdex: questionIndex,
							})
							
						}
						
					}
					
				}
				
			} else {
				
				console.error('materials is not array')
				
			}
			
			Logger.groupEnd(logAllow)
			
			return questionIndex;
			
		},
		
		afterSubmitSuccess: (response, materialId, variantId) => {
			
			const logName = 'MaterialsPage.questions.afterSubmitSuccess';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let currentMaterial = this.state.currentMaterial;
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			
			let currentMaterialQuestions = currentMaterial.questions;
			Logger.log(currentMaterialQuestions, 'questions', logAllow);
			
			let questionData = response.data;
			Logger.log(questionData, 'questionData', logAllow);
			
			let editedQuestion = new Question(questionData);
			Logger.log(editedQuestion, 'question', logAllow);
			
			let isNewQuestion = currentMaterialQuestions.filter(x => x.id == editedQuestion.id).length < 1;
			Logger.log(isNewQuestion, 'isCreated', logAllow);
			
			let currentQuestionIndex = this.state.questionToEditIndex;
			
			let materials = this.state.materials;
			
			if (editedQuestion.isCard()) {
				
				let groupIndex = currentMaterialQuestions.findIndex(x => x.id == editedQuestion.group_id);
				Logger.log(groupIndex, 'groupIndex', logAllow);
				
				currentQuestionIndex = groupIndex;
				
				if (groupIndex >= 0) {
					
					let group = currentMaterialQuestions[groupIndex];
					Logger.log(group, 'group', logAllow);
					
					if (group) {
						
						let cards = group.group_items;
						
						let cardIndex = cards.findIndex(x => x.id == editedQuestion.id);
						Logger.log(cardIndex, 'cardIndex', logAllow);
						
						if (cardIndex >= 0) {
							cards[cardIndex] = editedQuestion;
						} else {
							cards.push(editedQuestion);
						}
						
						group.group_items = cards;
						
					}
					
					currentMaterialQuestions[groupIndex] = group;
					
				}
				
			} else {
				
				// если это НЕ карточка
				
				// определяем целевой материал
				
				let targetMaterial = currentMaterial
				let targetMaterialIndex = this.state.currentMaterialIndex
				
				if (materialId) {
					
					targetMaterialIndex = materials.findIndex((material) => {
						return material.id == materialId
					})
					
					targetMaterial = materials[targetMaterialIndex]
					
				}
				
				if (currentMaterial.id == targetMaterial.id) {
					
					// если МАТЕРИАЛ прежний
					
					if (isNewQuestion) {
						
						// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое
						
						if (variantId) {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							currentQuestionIndex = this.questions.setVariant(editedQuestion, materialId, variantId)
							
							// this.setState({
							// 	currentVariant: ,
							// })
							
						} else {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое + ВАРИАНТ не задан
							
							// добавляем в общий список заданий текущего материала
							
							currentMaterial.questions.push(editedQuestion)
							
							currentQuestionIndex = currentMaterial.questions.length - 1
							
							this.setState({
								currentVariant: null,
							})
							
						}
						
					} else {
						
						// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое
						
						// ищем задание в вариантах - и удаляем оттуда
						
						targetMaterial.variants.forEach((variant) => {
							
							let variantQuestionIndex = variant.children.findIndex((question) => {
								return question.id == editedQuestion.id
							})
							
							if (variantQuestionIndex >= 0) {
								variant.children.splice(variantQuestionIndex, 1)
							}
							
						})
						
						if (variantId) {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое + ВАРИАНТ задан
							
							// помещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое + ВАРИАНТ не задан
							
							// заменяем это задание внутри текущего материала
							
							let editedQuestionIndex = currentMaterial.questions.findIndex((question) => {
								return question.id == editedQuestion.id
							})
							
							currentMaterial.questions[editedQuestionIndex] = editedQuestion
							
						}
						
					}
					
				} else {
					
					// если МАТЕРИАЛ изменился
					
					if (isNewQuestion) {
						
						// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое
						
						if (variantId) {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое + ВАРИАНТ не задан
							
							// добавляем в общий список заданий заданного варианта
							
							targetMaterial.questions.push(editedQuestion)
							
						}
						
						// переключаемся на этот материал
						currentMaterial = targetMaterial
						
					} else {
						
						// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое
						
						// удаляем задание из текущего материала
						
						let editedQuestionIndex = currentMaterial.questions.findIndex((question) => {
							return question.id == editedQuestion.id
						})
						
						currentMaterial.questions.splice(editedQuestionIndex, 1)
						
						currentQuestionIndex = 0
						
						if (variantId) {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое + ВАРИАНТ не задан
							
							// перемещаем в другой материал
							
							targetMaterial.questions.push(editedQuestion)
							
						}
						
					}
					
				}
				
			}
			
			currentMaterial.questions = currentMaterialQuestions;
			
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			materials[this.state.currentMaterialIndex] = currentMaterial;
			
			this.questions.saveSort(currentMaterialQuestions);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: currentMaterial,
					questionToEdit: null,
					questionFormModalIsOpen: false,
					currentQuestionIndex: currentQuestionIndex,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (bindId, questionIndex) => {
			
			const logName = 'MaterialsPage.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			let question = material.questions[questionIndex];
			let questions = material.questions;
			
			Logger.log(bindId, 'bindId', logAllow);
			Logger.log(questionIndex, 'questionIndex', logAllow);
			
			if (!bindId) {
				window.alert(i18next.t("bind id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (!window.confirm(i18next.t("Hide task #{{id}}?", {id: question.id}))) {
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question-binds') + '/' + bindId,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				this.props.preloader.hide();
				
				questions.splice(questionIndex, 1);
				
				let currentQuestionIndex = this.state.currentQuestionIndex;
				
				if (currentQuestionIndex > questions.length - 1) {
					currentQuestionIndex = questions.length - 1;
				}
				
				this.setState((prevState) => {
					return {
						currentMaterial: material,
						currentQuestionIndex: currentQuestionIndex,
					}
				});
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		saveSort: (questions, afterSuccess) => {
			
			const logName = 'MaterialsPage.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			Logger.log(questions, 'questions', logAllow);
			
			questions.forEach((question) => {
				// Logger.log(question, 'question', logAllow);
				if (question) {
					ids.push(question.id);
				}
			});
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('test-question-binds/save-sort'),
				data: {
					material_id: this.state.currentMaterial.id,
					questions_ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			const logName = 'MaterialsPage.questions.moveUp';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			let questions = material.questions;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = questions.length - 1;
			}
			
			Logger.log(questions, 'questions (before move)', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			Logger.log(questions, 'questions (after move)', logAllow);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveDown: (currentIndex) => {
			
			const logName = 'MaterialsPage.moveDown';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.log(currentIndex, 'currentIndex', logAllow);
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			Logger.log(material, 'material', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions', logAllow);
			
			if (currentIndex === questions.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			Logger.log(newIndex, 'newIndex', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveToPosition: (oldIndex, newIndex) => {
			
			let questions = currentMaterial.questions;
			
			Utils.arrayMove(questions, oldIndex, newIndex);
			
			const currentMaterial = this.state.currentMaterial
			
			currentMaterial.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: currentMaterial,
					questionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
		},
		
		edit: (question, questionIndex) => {
			
			const logName = 'MaterialsPage.questions.edit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(question, 'question', logAllow);
			
			this.setState((prevState) => {
				return {
					questionToEdit: question,
					questionToEditIndex: questionIndex,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		goto: (questionIndex) => {
			
			let currentMaterial = this.state.currentMaterial;
			
			if (currentMaterial) {
				
				Config.setQuestionIndex(currentMaterial.id, questionIndex);
				
				this.setState((prevState) => {
					return {
						currentQuestionIndex: questionIndex,
					}
				});
				
			}
			
		},
		
		prev: () => {
			// let currentMaterial = this.state.currentMaterial
			let questions = this.questions.get()
			let currentIndex = this.state.currentQuestionIndex
			let newIndex = Utils.arrayPrevIndex(questions, currentIndex)
			this.questions.goto(newIndex)
		},
		
		next: () => {
			// let currentMaterial = this.state.currentMaterial;
			let questions = this.questions.get()
			let currentIndex = this.state.currentQuestionIndex
			let newIndex = Utils.arrayNextIndex(questions, currentIndex)
			this.questions.goto(newIndex)
		},
		
		copyToMaterial: (question) => {
			
			const logName = 'MaterialsPage.questions.copy'
			const logAllow = 1
			const logCollapsed = 0
			
			Logger.groupStart(logName, logAllow, logCollapsed)
			
			let currentMaterial = this.state.currentMaterial
			Logger.log(currentMaterial.id, 'currentMaterial.id', logAllow);
			
			let materialId = window.prompt('Copy question to Material ID', currentMaterial.id);
			
			if (materialId) {
				
				axios({
					method: 'get',
					url: Utils.apiUrl('questions/copy'),
					data: {},
					params: {
						accessToken: Utils.getUserToken(),
						questionId: question.id,
						materialId: materialId,
					},
				}).then((response) => {
					
					const logName = 'MaterialsPage.copy.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					let copy = new Question(response.data);
					Logger.log(copy, 'copy', logAllow);
					Logger.log(copy.material_id, 'copy.material_id', logAllow);
					
					if (copy.material_id == currentMaterial.id) {
						
						currentMaterial.questions.push(copy);
						
						this.setState((prevState) => {
							return {
								currentMaterial: currentMaterial,
								currentQuestionIndex: currentMaterial.questions.length - 1,
							}
						});
						
					}
					
					window.alert(i18next.t("Copied successfully"));
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					console.log(error);
					// window.alert(error.response.data.message);
				});
				
			}
			
			Logger.groupEnd(logAllow)
			
		},
		
		moveToMaterial: (question) => {
			
			let toMaterialId = window.prompt('Material ID', currentMaterial.id);
			
			let currentMaterial = this.state.currentMaterial
			
			if (toMaterialId) {
				axios({
					method: 'get',
					url: Utils.apiUrl('questions/move'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						questionId: question.id,
						fromMaterialId: currentMaterial.id,
						toMaterialId: toMaterialId,
					},
				}).then((response) => {
					
					const logName = 'MaterialsPage.move.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					let questionIndex = currentMaterial.questions.indexOf(question);
					currentMaterial.questions.splice(questionIndex, 1);
					
					this.setState((prevState) => {
						return {
							currentMaterial: currentMaterial,
						}
					});
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					console.log(error);
					// window.alert(error.response.data.message);
				});
			}
			
		},
		
		disableUnwantedAnswerTemplates: (question, questionIndex) => {
			
			const currentMaterial = this.state.currentMaterial
			
			this.props.preloader?.show()
			
			axios({
				method: 'post',
				url: Utils.apiUrl('questions/disable-unwanted-use-answer-templates'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'questionId': question.id,
				},
			}).then((response) => {
				
				const logName = 'disableUnwantedUseAnswerTemplates.ajax.done'
				const logAllow = 1
				const logCollapsed = 0
				
				Logger.groupStart(logName, logAllow, logCollapsed)
				
				Logger.log(response, 'response', logAllow)
				
				this.props.preloader?.hide()
				
				let updatedQuestion = new Question(response.data)
				
				currentMaterial.questions[questionIndex] = updatedQuestion
				
				this.setState((prevState) => {
					return {
						currentMaterial: currentMaterial,
					}
				})
				
				Logger.groupEnd(logAllow)
				
			}).catch((error) => {
				
				this.props.preloader?.hide()
				
				console.log(error)
				window.alert(error)
				
			})
			
		},
		
		setAsHomework: (question, questionIndex) => {
			
			// todo
			
		}
		
	}
	
	componentDidMount() {
		// ajax
	}
	
	render() {
		
		const logName = 'MaterialView2.render'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		const user = this.props.user;
		
		// const userIsOwner = user.is(['owner'])
		// Logger.log(userIsOwner, 'userIsOwner', logAllow)
		
		const currentMaterial = this.props.material
		Logger.log(currentMaterial, 'currentMaterial', logAllow)
		
		const course = this.props.course
		const theme = this.props.theme
		const lesson = this.props.lesson
		
		let currentMaterialGroupsBindsWithLimitedAccess = currentMaterial ? currentMaterial.getGroupsBindsWithLimitedAccess(user.groups_ids) : []
		Logger.log(currentMaterialGroupsBindsWithLimitedAccess, 'currentMaterialGroupsBindsWithLimitedAccess', logAllow)
		
		const currentVariant = this.state.currentVariant
		Logger.log(currentVariant, 'currentVariant', logAllow)
		
		if (currentMaterial) {
			Logger.log(currentMaterial.name, 'currentMaterial.name', logAllow);
			Logger.log(currentMaterial.id, 'currentMaterial.id', logAllow);
		}
		
		// const answerForm = question.about.replaceAll('___', '<b>INPUT</b>');
		
		// const backBtnTitle = i18next.t("Back to courses, lessons and themes");
		// const backBtn = <BackBtn onClick={this.props.back} title={backBtnTitle}/>;
		
		// material path
		
		const materialPathData = [
			this.props.lesson.name + ' [#' + this.props.lesson.id + ']',
			this.props.theme.name + ' [#' + this.props.theme.id + ']',
		];
		
		if (currentMaterial) {
			materialPathData.push(currentMaterial.name + ' [#' + currentMaterial.id + ']');
		}
		
		const materialPath = materialPathData.join(' › ');
		
		const materialControls = (
			
			<MaterialControls
				preloader={this.props.preloader}
				user={this.props.user}
				materialsManager={this.materials}
				currentMaterialIndex={this.state.currentMaterialIndex}
			/>
			
		);
		
		const questionsViewMode = 'slider';
		Logger.log(questionsViewMode, 'questionsViewMode', logAllow);
		
		const materialForm = (
			<MaterialForm
				model={currentMaterial}
				course={course}
				themeId={theme.id}
				// themes={this.props.themes}
				cancel={() => {
					this.setState((prevState) => {
						return {
							materialFormModalIsOpen: 0,
						}
					});
				}}
				afterCreate={this.props.afterCreateSuccess}
				afterUpdate={this.props.afterUpdateSuccess}
				preloader={this.props.preloader}
				user={user}
			/>
		);
		
		let pdfs = [];
		let notPdfs = [];
		
		if (currentMaterial) {
			const pdfData = Utils.splitPdfsAndNotPdfs(currentMaterial.files);
			pdfs = pdfData.pdfs;
			notPdfs = pdfData.notPdfs;
		}
		
		let questionToEdit = this.state.questionToEdit;
		
		let questions = this.questions.get()
		Logger.log(questions, 'questions', logAllow);
		
		let currentQuestionIndex = this.state.currentQuestionIndex;
		Logger.log(currentQuestionIndex, 'currentQuestionIndex [raw]', logAllow);
		
		if (currentQuestionIndex >= questions.length) {
			currentQuestionIndex = 0;
		}
		
		Logger.log(currentQuestionIndex, 'currentQuestionIndex [final]', logAllow);
		
		let currentQuestion = questions[currentQuestionIndex];
		Logger.log(currentQuestion, 'currentQuestion', logAllow);
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<div className={'MaterialView2'}>
				
				<div 
					className={'material-view'} 
					data-current-question-id={currentQuestion?.id}
				>
					
					{/* material controls */}
					{(
						user.can('printMaterials')
						|| user.can('manageMaterials')
						|| user.can('manageMaterialsAccess')
					) && materialControls}
					
					{/* material form */}
					{(this.state.materialToEdit) && (
						<div className={'list body'}>
							{materialForm}
						</div>
					)}
					
					{/* material dynamic DEBUG info */}
					{(Utils.isLoc() && currentMaterial.is_dynamic == 1 && user.isStudent()) && (
						<>
							<div className={`dynamic-debug-info text-center`}>
								<code>currentMaterial.dynamic.id = {currentMaterial?.dynamic?.id}</code>
							</div>
						</>
					)}
					
					{/* media content */}
					{(!this.state.materialToEdit && currentMaterial.hasContent()) && (
						
						<div className={'list body'}>
							
							{currentMaterial.youtube_urls && (
								<div className="youtube-widget-box embed-responsive embed-responsive-16by9 mb-2">
									{YouTubeHelper.widget(currentMaterial.youtube_urls)}
								</div>
							)}
							
							{(Array.isArray(currentMaterial.video_links) && currentMaterial.video_links.length > 0) && (
								
								<div className={`video-links`}>
									
									<div className={`section-header`}>{i18next.t('Video')}</div>
									
									<div className={`video-cards`}>
										
										<div className={`row`}>
											
											{currentMaterial.video_links.map((videoLinkData) => {
												
												let colSize = 12
												
												let videoUrl = videoLinkData.url
													
												let videoLinkView = (
													<div className={`video-link-box`}>
														<span className={`d-inline-block mr-2`}><Icon.CameraVideo/></span>
														<a href={videoUrl} target={'_blank'} className={``}>
															{VideoLinkHelper.urlToName(videoUrl)}
														</a>
													</div>
												)
												
												let videoWidget = VideoLinkHelper.makeWidget(videoUrl)
												
												return (
													
													<>
													
														<div className={`col-md-${colSize}`}>
															
															<div className={`card video-card mb-3`}>
																
																<div className={`card-header`}>
																	{videoLinkView}
																</div>
																
																<div className={`video-widget-box embed-responsive embed-responsive-16by9 preloader-bg`}>
																	{videoWidget}
																</div>
																
															</div>
															
														</div>
													
													</>
												
												)
												
											})}
											
										</div>
										
									</div>
									
								</div>
								
							)}
							
							{currentMaterial.about && (
								<div className="text question-text" dangerouslySetInnerHTML={{__html: currentMaterial.about}}></div>
							)}
							
							{pdfs.map((uploadBindData) => {
								return (
									<PdfView
										url={uploadBindData.url}
										// url={uploadBindData.url_with_original_name}
										name={i18next.t("Download") + ': ' + uploadBindData.original_name}
										nameUrl={uploadBindData.url_with_original_name}
										// toolbar={true}
									/>
								);
							})}
							
							{currentMaterial.images.length > 0 && (
								
								<div className={'images media-list row'}>
									
									{currentMaterial.images.map((uploadBindData, index) => {
										
										return (
											
											<div className={'media-box img-box col'}>
												
												<a href={uploadBindData.url}
													className={'img-link'}
													target={'_blank'}
													key={'img_' + index}
												>
													<img src={uploadBindData.preview_url}
															alt="img"
															onClick={(event) => {
																// todo
															}}
															className={[
																'clickable',
															].join(' ')}
													/>
												</a>
												
												<br/>
												
												<small className={'upload-name text-muted'}>{uploadBindData.name}</small>
												
											</div>
											
										)
										
									})}
									
								</div>
							
							)}
							
							{currentMaterial.sounds.length > 0 && (
								
								<div className={'sounds media-list row'}>
									{currentMaterial.sounds.map((uploadData) => {
										return (
											<div className="col media-col sound-col">
												<div className="media-box sound-box">
													<audio
														src={uploadData.url}
														controls={true}
													/>
													<br/><small
													className={'upload-name text-muted'}>{uploadData.name}</small>
												</div>
											</div>
										);
									})}
								</div>
							
							)}
							
							{currentMaterial.videos.length > 0 && (
								
								<div className={'videos media-list video-list'}>
									{currentMaterial.videos.map((uploadData) => {
										return (
											<div className="media-box video-box">
												<video
													src={uploadData.url}
													controls={true}
												/>
												<br/><small className={'upload-name text-muted'}>{uploadData.name || uploadData.original_name_only}</small>
											</div>
										);
									})}
								</div>
							
							)}
							
							{notPdfs.length > 0 && (
								<div className={'files media-list mt-3'}>
									<div className={`head bold`}>
										{i18next.t('Files')}
									</div>
									<UploadsList uploads={notPdfs}/>
								</div>
							)}
						
						</div>
					
					)}
					
					{/* variants panel */}
					{(currentMaterial.variants.length > 0) && (
						
						<div className={`variants-controls`}>
							
							{(currentMaterial.questions.length > 0 && !user.isStudent()) && (
								<button
									className={[
										'my-btn',
										// 'my-btn-active',
									].join()}
									disabled={!currentVariant}
									onClick={(event) => {
										this.materials.setVariant(null)
									}}
								>{i18next.t('Out of variants')}</button>
							)}
							
							{currentMaterial.variants.map((variant, variantIndex) => {
								
								let debugInfo = user.can('debugInfo') 
									? `TestQuestion #${variant.id}` 
									: ''
								
								return (
									<div className={`variant-box`}>
										
										<button
											className={[
												'variant-set-btn',
												'my-btn',
											].join(' ')}
											onClick={(event) => {
												this.materials.setVariant(variant)
											}}
											disabled={currentVariant && currentVariant.id == variant.id}
											title={debugInfo}
											data-variant-question-id={variant.id}
										>{i18next.t('Variant')} {user.isStudent() ? variant.name : variantIndex + 1}</button>
										
										<br/>
										
										{(user.can('manageMaterials')) && (
											<button
												className={[
													'variant-del-btn',
													'del-variant-btn',
													'my-btn',
													'my-btn-xs',
													'my-btn-danger',
												].join(' ')}
												onClick={(event) => {
													this.materials.delVariant(variant.id)
												}}
												title={debugInfo}
											>{i18next.t('Delete')}</button>
										)}
										
									</div>
								)
							})}
							
						</div>
						
					)}
					
					{/* limited access warning */}
					{(currentMaterialGroupsBindsWithLimitedAccess.length > 0) && (
						
						<div className={`limited-access-warning-box`}>
							
							<div className={`limited-access-warning list-item orange-box`}>
								
								<b>{i18next.t("Limited access")}</b>
								
								<div className={`groups-binds access-list my-2`}>
													
									{currentMaterialGroupsBindsWithLimitedAccess.map((materialGroupBind) => {
										
										const logName = 'material.groups_binds.map'
										const logAllow = 0
										const logCollapse = 0
										
										Logger.groupStart(logName, logAllow, logCollapse)
										
										Logger.log(materialGroupBind, 'materialGroupBind', logAllow)
										
										// отображать студенту только те доступы, который относятся к его группам
										if (user.isStudent() && !user.groups_ids.includes(materialGroupBind.group_id)) {
											return ''
										}
										
										// let dateTimeFormat = 'DD.MM.YY hh:mm'
										
										// let accessStartDateTime = moment(materialGroupBind.access_start_unixtime * 1000).format(dateTimeFormat)
										
										// let accessEndDateTime = materialGroupBind.access_end_unixtime
										// 	? moment(materialGroupBind.access_end_unixtime * 1000).format(dateTimeFormat)
										// 	: i18next.t("Not restricted")
										
										let accessStartDateTime =
											
											DateHelper.formatDate({
												date: materialGroupBind.access_start_unixtime * 1000,
												format: 'd.m.y',
											})
											
											+ ' ' + 
											
											DateHelper.formatTime({
												time: materialGroupBind.access_start_unixtime * 1000,
												format: 'h:m',
											})
											
										// let accessEndDateTime = materialGroupBind.access_end
										// 	? materialGroupBind.access_end
										// 	: i18next.t("Not restricted")
										
										let accessEndDateTime =
											
											(materialGroupBind.access_end_date != materialGroupBind.access_start_date 
												
												? DateHelper.formatDate({
													date: materialGroupBind.access_end_unixtime * 1000,
													format: 'd.m.y',
												})
												
												: '')
											
											+ ' ' + 
											
											DateHelper.formatTime({
												time: materialGroupBind.access_end_unixtime * 1000,
												format: 'h:m',
											})
										
										Logger.groupEnd(logAllow)
										
										return (
											
											<div className={`group-bind access-list-item my-2`}>
												{i18next.t("Group")} {materialGroupBind.group_name} : {accessStartDateTime} – <b>{accessEndDateTime}</b>
											</div>
											
										)
										
									})}
									
								</div>
								
							</div>
							
						</div>
						
					)}
					
					{/* questions nav controls */}
					{(questions.length > 1 && questionsViewMode === 'slider') && (
						
						<div className={'questions-nav-controls row my-3'}>
							
							<div className="col-auto col-md-4 prev-btn-col">
								<button
									type={'button'}
									className={[
										'my-btn',
										'prev-question-btn',
									].join(' ')}
									onClick={(event) => {
										this.questions.prev();
									}}
								><Icon.ChevronLeft/> <span className={'btn-text d-none d-sm-inline'}>{i18next.t("Prev. page")}</span></button>
							</div>
							
							<div className="col col-md-4 goto-btn-col text-center">
								
								<Dropdown
									className={'goto-menu d-inline-block'}
								>
									
									<Dropdown.Toggle
										className={[
											'my-btn',
											'my-btn-default',
											'my-0',
											'mx-0',
										].join(' ')}
									>{i18next.t("Page")} {currentQuestionIndex + 1} / {questions.length}</Dropdown.Toggle>
									
									<Dropdown.Menu>
										
										{Range.get(0, questions.length - 1).map((newIndex) => {
											const num = newIndex + 1;
											const isCurrent = newIndex == currentQuestionIndex;
											return (
												<Dropdown.Item
													onClick={(event) => {
														this.questions.goto(newIndex)
													}}
													disabled={isCurrent}
												>{num}</Dropdown.Item>
											);
										})}
									
									</Dropdown.Menu>
								
								</Dropdown>
								
							</div>
							
							<div className="col-auto col-md-4 next-btn-col text-right">
								<button
									type={'button'}
									className={[
										'my-btn',
										'next-question-btn',
									].join(' ')}
									onClick={(event) => {
										this.questions.next();
									}}
								><span className={'btn-text d-none d-sm-inline'}>{i18next.t("Next page")}</span> <Icon.ChevronRight/></button>
							</div>
						
						</div>
						
					)}
					
					{/* questions list */}
					{currentQuestion && (
						
						<div className={'questions'}>
							
							{[currentQuestion].map((question) => {
								
								return (
									
									<MaterialsPageQuestionBox
										preloader={this.props.preloader}
										user={user}
										material={currentMaterial}
										question={question}
										questionIndex={currentQuestionIndex}
										questionsManager={this.questions}
										materialPath={materialPath}
										groups={this.state.groups}
									/>
									
								);
								
							})}
						
						</div>
					
					)}
					
					{/*{materialControls}*/}
				
				</div>
				
				<div className="modals">
					
					<Modal
						className={[
							'material-form-modal',
							'wide-modal',
						].join(' ')}
						show={this.state.materialFormModalIsOpen}
						onHide={() => {
							this.setState((prevState) => {
								return {
									materialFormModalIsOpen: 0,
								}
							})
						}}
						size={'lg'}
						keyboard={false}
						backdrop={'static'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{i18next.t("Edit material #{{id}}", {id: currentMaterial.id})}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							{materialForm}
						</Modal.Body>
						
					</Modal>
					
					{this.state.useQuestionEditModal && this.state.currentMaterial && (
						
						<Modal
							className={[
								'question-form-modal',
								// 'wide-modal',
							].join(' ')}
							show={this.state.questionFormModalIsOpen}
							onHide={this.questions.formModalToggle}
							size={'xl'}
							keyboard={false}
							backdrop={'static'}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{questionToEdit && questionToEdit.id ? (
										<div>
											{i18next.t("Edit task / card / group #{{id}}", {id: questionToEdit.id})}
										</div>
									) : (
										<div>
											{i18next.t("Add task / card / group")}
										</div>
									)}
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
								
								{(questionToEdit) ? (
									
									<>
										
										{(questionToEdit.isRegular()) &&
											<QuestionForm
												model={this.state.questionToEdit}
												materialId={this.state.currentMaterial?.id}
												variantId={this.state.currentVariant?.id}
												afterSubmit={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={this.state.materials}
												preloader={this.props.preloader}
												skins={this.props.skins}
											/>
										}
										
										{(questionToEdit.isGroup()) &&
											<QuestionGroupForm
												model={this.state.questionToEdit}
												groups={currentMaterial.getGroups()}
												questions={this.state.questionToEdit?.group_items}
												materialId={this.state.currentMaterial?.id}
												afterSuccess={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={this.state.materials}
												preloader={this.props.preloader}
												skins={this.props.skins}
											/>
										}
										
										{(questionToEdit.isCard()) &&
											<QuestionCardForm
												model={this.state.questionToEdit}
												materialId={this.state.currentMaterial?.id}
												afterSuccess={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={this.state.materials}
												preloader={this.props.preloader}
												skins={this.props.skins}
												groups={currentMaterial.getGroups()}
											/>
										}
									
									</>
								
								) : (
									
									<>
										<QuestionForm
											model={this.state.questionToEdit}
											materialId={this.state.currentMaterial?.id}
											afterSubmit={this.questions.afterSubmitSuccess}
											cancel={this.questions.formModalToggle}
											materials={this.state.materials}
											preloader={this.props.preloader}
											skins={this.props.skins}
										/>
									</>
								
								)}
							
							</Modal.Body>
						
						</Modal>
					
					)}
				
				</div>
				
			</div>
			
		)
		
	}
	
}

MaterialView2.propTypes = {
	
	preloader: PropTypes.object,
	user: PropTypes.instanceOf(User).isRequired,
	
	material: PropTypes.instanceOf(Material).isRequired,
	
	course: PropTypes.instanceOf(Course),
	theme: PropTypes.instanceOf(Theme),
	lesson: PropTypes.instanceOf(Lesson),
	
	afterCreateSuccess: PropTypes.func,
	afterUpdateSuccess: PropTypes.func,
	
}

MaterialView2.defaultProps = {}

export default MaterialView2