import React from 'react'
import './ChatPage.css';

export default class ChatPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messageToSend: null,
            messagesContext: [],
            inputDisabled: false,
        };
        this.chatSubmit = this.chatSubmit.bind(this); 
        this.handleInputChange = this.handleInputChange.bind(this); 
        // this.send = this.send.bind(this);
    }

    chatSubmit(event) {
        event.preventDefault();

        const newMessage = {
            alias: 'User',
            type: 1,
            message: this.state.messageToSend,
            datetime: Math.floor(Date.now() / 1000)
        }
        this.setState((prevState) => ({
            messagesContext: [...prevState.messagesContext, newMessage],
            messageToSend: '',
            inputDisabled: true,
        }))
        
    }

    handleInputChange(event) {
        this.setState({ messageToSend: event.target.value });
    }

    // send() {
    //     return;
    // }
    
    render(){
        return(
            <div className = "chat">
                <div className = "chat-window">
                    {this.state.messagesContext.map((context, index)=>{
                        if(context['type'] == 0) {
                            return(
                                <div key = {index}className = "message">
                                    <div className = "from">
                                        {context.alias}
                                    </div>
                                    <div className = "text">
                                        {context.message}
                                    </div>
                                    <div className ="where">
                                        {new Date(context.datetime * 1000).toLocaleString()}
                                    </div>
                                </div>
                            )
                        }

                        else{
                            return(
                                <div key = {index}className = "message-out">
                                    <div className = "from">
                                        {context.alias}
                                    </div>
                                    <div className = "text">
                                        {context.message}
                                    </div>
                                    <div className ="when">
                                        {new Date(context.datetime * 1000).toLocaleString()}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className = "chat-input-block">
                    <form onSubmit = {this.chatSubmit}>
                        <input 
                            onChange = {this.handleInputChange} 
                            value = {this.state.messageToSend} 
                            //disabled = {this.state.inputDisabled} 
                            type = "textarea" 
                            required>

                        </input>
                        <button type = "submit">Отправить</button>
                    </form>
                </div>
            </div>
        
        );
    }
}