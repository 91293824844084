import Logger from "../helpers/Logger";
import User from "./User";
import Course from "./Course";

export default class Group {
	
	constructor(data = {}) {
		
		this.id = parseInt(data.id);
		this.name = data.name || '';
		this.about = data.about || '';
		this.sort = data.sort;
		
		this.students = data.students || [];
		
		this.teachers = data.teachers ? data.teachers.map(teacherData => new User(teacherData)) : [];
		this.teachers_ids = this.getTeachersIds();
		
		this.is_active = data.is_active;
		
		// подразделения
		this.deps_ids = data.deps_ids || [];
		this.deps = data.deps || [];
		
		// курсы
		this.courses = Array.isArray(data.courses) ? data.courses.map(x => new Course(x)) : []
		this.courses_ids = Array.isArray(data.courses) ? data.courses.map(x => x.id) : [];
		this.restricted_course_access = data.restricted_course_access
		
	};
	
	getTeachersIds = () => {
		return this.teachers.map(teacher => teacher.id);
	};
	
	static collectTeachers = (groupsArray) => {
		let teachers = [];
		groupsArray.forEach((group) => {
			group.teachers.forEach((teacher) => {
				// только уникальные значения
				if (teachers.filter(teacher2 => teacher.id == teacher2.id).length === 0) {
					teachers.push(teacher);
				}
			});
			// teachers = teachers.concat(group.teachers);
		});
		return teachers;
	};
	
	getName = (addDeps = 1) => {
		
		let data = [
			this.name,
		];
		
		if (addDeps) {
			let depsNames = this.deps.map(dep => dep.name);
			data.push(depsNames.join(', '));
		}
		
		return data.join(' | ');
		
	};
	
}