import Logger from "./Logger";

export default class FormDataHelper {
	
	static getValues = (formData) => {
		
		let values = [];
		
		let iterator = formData.entries();
		
		for (let pair of iterator) {
			values.push(pair);
		}
		
		return values;
		
	};
	
	static get = (model, excludedAttrs=[], uploadRefs=null, after, jsonAttrs = []) => {
		
		const logName = 'FormDataHelper.get';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(model, 'model', logAllow);
		
		let formData = new FormData();
		let uploadAttrs = [];
		
		// FILES
		
		if (uploadRefs) {
			
			Object.keys(uploadRefs).forEach((uploadAttr) => {
				
				const ref = uploadRefs[uploadAttr];
				
				uploadAttrs.push(uploadAttr);
				
				if (ref) {
					
					const field = ref.current;
					
					if (field) {
						
						const files = field.files;
						Logger.log(files, 'files', logAllow);
						
						for (let i = 0; i < files.length; i++) {
							let file = files[i];
							formData.append(uploadAttr + '[]', file);
						}
						
					}
					
				}
				
			});
			
		}
		
		// NOT FILES
		
		let attrs = Object.getOwnPropertyNames(model);
		Logger.log(attrs, 'attrs', logAllow);
		
		attrs.forEach((attr) => {
			
			let allow =
				!excludedAttrs.includes(attr)
				&& !uploadAttrs.includes(attr)
				&& !(model[attr] instanceof Function);
			
			if (allow) {
				
				let val = model[attr];
				
				if (val !== undefined && val !== null) {
					formData.append(attr, val);
				}
				
			}
			
		});
		
		// json attrs
		jsonAttrs.forEach((jsonAttr) => {
			let rawVal = model[jsonAttr]
			let stringVal = JSON.stringify(rawVal)
			formData.append(jsonAttr, stringVal)
		})
		
		if (after) {
			formData = after(formData);
		}
		
		if (logAllow) {
			let fromDataValues = FormDataHelper.getValues(formData);
			Logger.log(fromDataValues, 'fromDataValues', logAllow);
		}
		
		Logger.groupEnd(logAllow);
		
		return formData;
	};
	
}