import User from "./User";
import i18next from "i18next";
import Lesson from "./Lesson";

export default class Course {
	
	constructor(data = {}) {
		this.id = data.id;
		this.name = data.name;
		this.about = data.about || '';
		this.is_active = data.is_active;
		this.sort = data.sort;
		this.progress = data.progress || 0;
		this.department_id = data.department_id;
		this.department_name = data.department_name;
		this.created_by = data.created_by ? new User(data.created_by) : null;
		this.created_at = data.created_at;
		this.videos_urls = data.videos_urls || '';
		this.imgs = data.imgs || [];
		this.docs = data.docs || [];
		this.copy_of_id = data.copy_of_id || null;
		this.lessons = data.lessons ? data.lessons.map(x => new Lesson(x)) : [];
	}
	
	static uploadAttrs = [
		'imgs',
		'docs',
	];
	
	getName = () => {
		return this.name ? this.name : i18next.t("Course") + ' ' + i18next.t("#") + this.id;
	};
	
	hasPreviewInfo = () => {
		return this.about || this.videos_urls || this.imgs.length > 0 || this.docs.length > 0;
	};
	
}