import React from 'react'
import PropTypes from 'prop-types'
import i18next from "i18next"
import axios from "axios"
import Dropdown from "react-bootstrap/Dropdown"
import * as Icon from 'react-bootstrap-icons'

import Utils from "../../helpers/Utils"
import Logger from "../../helpers/Logger"

import User from "../../models/User"
import QuestionGroupView from "./QuestionGroupView"
import Question from "../../models/Question"
import QuestionCardView from "./QuestionCardView"
import QuestionView from "./QuestionView";
import Material from "../../models/Material"
import Range from "../../helpers/Range"

class MaterialsPageQuestionBox extends React.Component {
	
	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const logName = 'MaterialsPageQuestionBox.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let question = this.props.question
		Logger.log(question, 'question', logAllow);
		
		let questionIndex = this.props.questionIndex;
		Logger.log(questionIndex, 'questionIndex', logAllow)
		
		let latestAnswer = question.latest_answer;
		Logger.log(latestAnswer, 'latestAnswer', logAllow);
		
		const questionsManager = this.props.questionsManager
		
		let questions = questionsManager.get()
		
		const user = this.props.user
		
		const currentMaterial = this.props.material
		
		const groups = this.props.groups
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'MaterialsPageQuestionBox'}>
				
				<div
					key={'question-' + question.id + '-box'}
					className={[
						'question-box',
						'list-item',
						'bg-' + question.bg_style_alias,
						'border-' + question.border_style_alias,
						latestAnswer
							? currentMaterial.isExam()
								? 'waiting'
								: latestAnswer.is_waiting
									? 'waiting'
									: latestAnswer.is_right
										? 'right-answer'
										: 'wrong-answer'
							: 'no-answer',
						question.isGroup() ? 'question-group-box' : '',
					].join(' ')}
				>
					
					<div
						className={'question-name text-center font-weight-bold'}
						title={'#' + question.id}
					>
						{i18next.t("Task")}&nbsp;
						{questions.length > 1
							? i18next.t("{{num}} of {{count}}", {
								num: questionIndex + 1,
								count: questions.length
							})
							: questionIndex + 1}
					</div>
					
					<>
						
						{(question.isGroup()) &&
							<QuestionGroupView
								group={question}
								user={user}
								questions={question.group_items}
								currentMaterial={currentMaterial}
								preloader={this.props.preloader}
								afterSubmit={questionsManager.afterSubmitSuccess}
								editCard={(card, cardIndex) => {
									questionsManager.edit(card);
									// questionsManager.editCard(card, cardIndex);
								}}
								afterHideSuccess={(response, cardIndex) => {
									
									let currentGroup = question;
									
									let cards = currentGroup.group_items;
									
									// currentGroup.group_items.splice(cardIndex, 1);
									cards.splice(cardIndex, 1);
									
									currentMaterial.questions[questionIndex] = currentGroup;
									
									this.setState((prevState) => {
										return {
											currentMaterial: currentMaterial,
										}
									});
									
								}}
							/>
						}
						
						{(question.isCard()) &&
							<QuestionCardView
								card={question}
								user={user}
							/>
						}
						
						{(question.isRegular()) &&
							<QuestionView
								key={'question-' + question.id + '-view'}
								question={question}
								course={this.props.course}
								lesson={this.props.lesson}
								theme={this.props.theme}
								material_id={currentMaterial.id}
								// mode={user.is(['teacher', 'admin', 'owner']) ? 'demo' : 'pass'}
								mode={'pass'}
								user={user}
								path={this.props.materialPath}
								// num={questionIndex + 1}
								preloader={this.props.preloader}
								afterSendAnswer={(response, answer) => {
									question.latest_answer = answer;
									let questions = currentMaterial.questions;
									questions[questionIndex] = question;
									this.setState((prevState) => {
										return {
											currentMaterial: currentMaterial,
										}
									});
								}}
								reanswers={!currentMaterial.isExam()}
								showAnswerState={!currentMaterial.isExam()}
								alert={this.props.alert}
								weightVisible={currentMaterial.is_weight_visible == 1}
								questionsCount={currentMaterial.questions.length}
								// showDiscuss={true}
								checkAnswerBtnText={
									currentMaterial.is_exam == 1
										? i18next.t("Save")
										: i18next.t("Check")
								}
							/>
						}
						
						{(user.canAny(['manageMaterials', 'manageHomework'])) && (
							
							<>
								
								<hr/>
								
								<div className="controls bottom-controls question-bottom-controls">
									
									{(user.can('manageMaterials')) &&
										
										<>
											
											<button
												type={'button'}
												className={[
													'question-edit-btn',
													'my-btn',
													'my-btn-sm',
												].join(' ')}
												onClick={(event) => {
													questionsManager.edit(question, questionIndex);
												}}
												title={'#' + question.id}
											><Icon.Pencil/> {i18next.t("Edit")}
											</button>
											
											{questions.length > 1 && (
												
												<>
													
													<button
														type={'button'}
														className={[
															'question-move-up-btn',
															'my-btn',
															'my-btn-sm',
														].join(' ')}
														onClick={(event) => {
															questionsManager.moveUp(questionIndex);
														}}
													>
														<Icon.ArrowUp/> {i18next.t("Move up")}
													</button>
													
													<button
														type={'button'}
														className={[
															'question-move-down-btn',
															'my-btn',
															'my-btn-sm',
														].join(' ')}
														onClick={(event) => {
															questionsManager.moveDown(questionIndex);
														}}
													>
														<Icon.ArrowDown/> {i18next.t("Move down")}
													</button>
													
													<Dropdown
														className={'sort-menu d-inline-block question-set-position-menu'}
													>
														
														<Dropdown.Toggle
															className={[
																// 'btn-unstyled',
																'question-set-position-btn',
																'my-btn',
																'my-btn-sm',
																'my-btn-default',
																'my-0',
															].join(' ')}
														>
															{i18next.t("Set position")}
														</Dropdown.Toggle>
														
														<Dropdown.Menu>
															
															{Range.get(0, currentMaterial.questions.length - 1).map((newIndex) => {
																
																const num = newIndex + 1;
																
																const isCurrent = newIndex == questionIndex;
																
																return (
																	<Dropdown.Item
																		onClick={(event) => {
																			questionsManager.moveToPosition(questionIndex, newIndex)
																		}}
																		disabled={isCurrent}
																	>{num}</Dropdown.Item>
																);
																
															})}
														
														</Dropdown.Menu>
													
													</Dropdown>
												
												</>
											
											)}
											
											<button
												type={'button'}
												className={[
													'hide-question-btn',
													'del-question-btn',
													'remove-question-btn',
													'my-btn',
													'my-btn-sm',
												].join(' ')}
												onClick={(event) => {
													questionsManager.del(question.bind_id, questionIndex);
												}}
												disabled={!question.bind_id}
												title={'bind: ' + question.bind_id}
											><Icon.Trash/> {i18next.t("Hide")}</button>
											
											{(user.is(['owner'])) && (
												
												<>
													
													<Dropdown className={'etc-menu'}>
														
														<Dropdown.Toggle
															className={[
																'etc-menu',
																'my-btn',
																'my-btn-sm',
																'my-btn-default',
															].join(' ')}
														>
															{i18next.t("etc")}
														</Dropdown.Toggle>
														
														<Dropdown.Menu>
															
															<Dropdown.Item
																title={i18next.t("disable unwanted answer templates")}
																onClick={(event) => {
																	questionsManager.disableUnwantedAnswerTemplates(
																		question,
																		questionIndex,
																	)
																}}
															>{i18next.t("disable unwanted answer templates")}</Dropdown.Item>
														
														</Dropdown.Menu>
													
													</Dropdown>
												
												</>
											
											)}
										
										</>
										
									}
									
									<Dropdown className={'homework-menu'}>
										
										<Dropdown.Toggle
											className={[
												// 'btn-unstyled',
												'my-btn',
												'my-btn-sm',
												'my-btn-default',
											].join(' ')}
										>
											{i18next.t("Set as homework")}
										</Dropdown.Toggle>
										
										<Dropdown.Menu>
											
											{(groups.length < 1) && (
												<Dropdown.Item>
													<div className={'empty-msg'}>...</div>
												</Dropdown.Item>
											)}
											
											{groups.map((group, groupIndex) => {
												
												let questionState = question.states.filter(questionState => questionState.group_id == group.id)[0];
												
												let title = questionState && questionState.is_active
													? i18next.t("UNMARK as homework for this group")
													: i18next.t("MARK as homework for this group")
												
												let alertMsg = questionState && questionState.is_active
													? i18next.t("Task '{{name}}' unmarked as homework for group {{group}}", {
														name: question.name,
														group: group.name,
													})
													: i18next.t("Task '{{name}}' marked as homework for group {{group}}", {
														name: question.name,
														group: group.name,
													})
												
												let showAlert = 0;
												
												return (
													
													<Dropdown.Item
														onClick={(event) => {
															
															if (questionState) {
																
																// toggle existing state
																
																this.props.preloader?.show();
																
																axios({
																	method: 'put',
																	url: Utils.apiUrl('test-question-state') + '/' + questionState.id,
																	data: {
																		is_active: questionState.is_active ? 0 : 1,
																	},
																	params: {
																		'accessToken': Utils.getUserToken(),
																	},
																}).then((response) => {
																	
																	const logName = 'MaterialsPage.questionState.ajax.done';
																	const logAllow = 1;
																	const logCollapsed = 0;
																	
																	Logger.groupStart(logName, logAllow, logCollapsed);
																	
																	Logger.log(response, 'response', logAllow);
																	
																	let state = response.data;
																	let stateIndex = null;
																	question.states.forEach((currentState, currentStateIndex) => {
																		if (currentState.id == state.id) {
																			stateIndex = currentStateIndex;
																		}
																	});
																	question.states[stateIndex] = state;
																	
																	currentMaterial.questions[questionIndex] = question;
																	
																	this.setState((prevState) => {
																		return {
																			currentMaterial: currentMaterial,
																		}
																	});
																	
																	if (this.props.preloader) {
																		this.props.preloader.hide();
																	}
																	
																	if (showAlert) {
																		window.alert(alertMsg);
																	}
																	
																	Logger.groupEnd(logAllow);
																	
																}).catch((error) => {
																	Utils.axiosErrorAlert(error);
																});
																
															} else {
																
																// create new state
																
																if (this.props.preloader) {
																	this.props.preloader.show();
																}
																
																axios({
																	method: 'post',
																	url: Utils.apiUrl('test-question-state'),
																	data: {
																		group_id: group.id,
																		question_id: question.id,
																		material_id: currentMaterial.id,
																		state_alias: 'homework',
																		is_active: 1,
																	},
																	params: {
																		'accessToken': Utils.getUserToken(),
																	},
																}).then((response) => {
																	
																	const logName = 'MaterialsPage.questionState.ajax.done';
																	const logAllow = 1;
																	const logCollapsed = 0;
																	
																	Logger.groupStart(logName, logAllow, logCollapsed);
																	
																	Logger.log(response, 'response', logAllow);
																	
																	let state = response.data;
																	question.states.push(state);
																	
																	currentMaterial.questions[questionIndex] = question;
																	
																	this.setState((prevState) => {
																		return {
																			currentMaterial: currentMaterial,
																		}
																	});
																	
																	if (this.props.preloader) {
																		this.props.preloader.hide();
																	}
																	
																	if (showAlert) {
																		window.alert(alertMsg);
																	}
																	
																	Logger.groupEnd(logAllow);
																	
																}).catch((error) => {
																	Utils.axiosErrorAlert(error);
																});
															}
														}}
														title={title}
													>
														{questionState && questionState.is_active == 1 ? (
															<b>{group.name}</b>
														) : (
															group.name
														)}
													
													</Dropdown.Item>
												);
											})}
										
										</Dropdown.Menu>
									
									</Dropdown>
								
								</div>
							
							</>
						
						)}
					
					</>
					
					{user.can('manageQuestions') && (
						
						<Dropdown className={'question-menu overlay-menu'}>
							
							<Dropdown.Toggle></Dropdown.Toggle>
							
							<Dropdown.Menu>
								
								<Dropdown.Item
									onClick={(event) => {
										questionsManager.edit(question, questionIndex)
									}}
								>{i18next.t("Edit")}</Dropdown.Item>
								
								<Dropdown.Item
									onClick={(event) => {
										questionsManager.moveUp(questionIndex);
									}}
								>{i18next.t("Move up")}</Dropdown.Item>
								
								<Dropdown.Item
									onClick={(event) => {
										questionsManager.moveDown(questionIndex);
									}}
								>{i18next.t("Move down")}</Dropdown.Item>
								
								<Dropdown.Item
									onClick={(event) => {
										questionsManager.del(question.bind_id, questionIndex);
									}}
								>{i18next.t("Hide")}</Dropdown.Item>
								
								{(user.can('copyMaterials')) &&
									<>
										<Dropdown.Item
											onClick={(event) => {
												questionsManager.copyToMaterial(question)
											}}
										>{i18next.t("Copy to")}...</Dropdown.Item>
										
										<Dropdown.Item
											onClick={(event) => {
												questionsManager.moveToMaterial(question)
											}}
										>{i18next.t("Move to")} ...</Dropdown.Item>
									</>
								}
							
							</Dropdown.Menu>
						
						</Dropdown>
					
					)}
				
				</div>
			
			</div>
		
		)
		
	}
	
}

MaterialsPageQuestionBox.propTypes = {
	
	preloader: PropTypes.object,
	
	user: PropTypes.instanceOf(User).isRequired,
	
	material: PropTypes.instanceOf(Material).isRequired,
	
	question: PropTypes.instanceOf(Question).isRequired,
	questionIndex: PropTypes.number.isRequired,
	
	questionsManager: PropTypes.object.isRequired,
	
	materialPath: PropTypes.string,
	
	groups: PropTypes.array,
	
}

MaterialsPageQuestionBox.defaultProps = {
	
	groups: [],
	
}

export default MaterialsPageQuestionBox