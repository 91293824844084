import './QuestionManager.css'

import axios from 'axios'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import Logger from '../../helpers/Logger'
import Utils from '../../helpers/Utils'
import Course from '../../models/Course'
import Question from '../../models/Question'
import CourseTree from './CourseTree'
import FoldableList from './FoldableList'
import Pager from './Pager'
import * as Icon from 'react-bootstrap-icons'
import QuestionView from "./QuestionView";

class QuestionManager extends React.Component {

	constructor(props) {
		
		super(props)
		
		this.state = {
			
			courses: props.courses,
			coursesAreLoading: 0,
			coursesPagesCount: 1,
			coursesPageSize: 5,
			coursesPageNum: 1,
			courseNameToSearch: '',
			
			selectedCoursesIds: [],
			selectedLessonsIds: [],
			selectedThemesIds: [],
			
			questions: [],
			questionsPageNum: 1,
			questionsPageSize: 5,
			questionsPagesCount: null,
			
		}
		
		this.courseNameToSearchFieldRef = React.createRef()
		
	}
	
	courses = {
		
		search: {
			
			clear: () => {
				
				this.setState({
					courseNameToSearch: null,
				})
				
				this.courseNameToSearchFieldRef.current.value = ''
				
			}
			
		}
		
	}
	
	loadCourses = (
		pageNum = this.state.coursesPageNum, 
		pageSize = this.state.coursesPageSize,
		params = {},
	) => {
		
		const logName = 'QuestionManager.loadCourses'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		Logger.log(pageNum, 'pageNum', logAllow)
		Logger.log(pageSize, 'pageSize', logAllow)
		
		this.props.preloader?.show()
		
		this.setState({
			coursesAreLoading: 1,
		})
		
		params = {
			
			...params,
				
			'accessToken': Utils.getUserToken(),
			
			'per-page': pageSize,
			'page': pageNum,
			'sort': 'name',
			
			'use-filters': true,
			'filter[is_active]': 1,
			
		}
		
		let searchName = this.state.courseNameToSearch;
		
		if (searchName) {
			params['filter[name][like]'] = searchName
		}
		
		Logger.log(params, 'params', logAllow)
		
		axios({
			method: 'get',
			url: Utils.apiUrl('courses'),
			params: params,
			data: {},
		}).then((response) => {
			
			const logName = 'QuestionManager.loadCourses.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			Logger.log(response, 'response', logAllow)
			
			let courses = response.data.map(x => new Course(x))
			Logger.log(courses, 'courses', logAllow)
			
			let pagesCount = parseInt(response.headers['x-pagination-page-count'])
			Logger.log(pagesCount, 'pagesCount', logAllow)
			
			this.props.preloader?.hide()
			
			this.setState({
				courses: courses,
				coursesAreLoading: 0,
				coursesPageNum: pageNum,
				coursesPagesCount: pagesCount,
			})

			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			
			console.log(`axiosError = %o`, axiosError)
			
			window.alert(axiosError)
			
		})

		Logger.groupEnd(logAllow)
		
	}
	
	apiTest = () => {
		
		axios({
			method: 'get',
			url: Utils.apiUrl('question-manager/test'),
			params: {
				accessToken: Utils.getUserToken(),
			},
			data: {},
		}).then((response) => {
			
			const logName = 'QuestionManager.apiTest.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			Logger.log(response.data, 'response.data', logAllow)
			
			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			
			console.log(`axiosError = %o`, axiosError)
			//window.alert(axiosError)
			
		})
		
	}
	
	loadQuestions = () => {
		
		this.props.preloader?.show()
		
		axios({
			method: 'get',
			url: Utils.apiUrl('question-manager/get-questions'),
			params: {
				accessToken: Utils.getUserToken(),
			},
			data: {},
		}).then((response) => {
			
			const logName = 'QuestionManager.loadQuestions.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			this.props.preloader?.hide()
			
			Logger.log(response.data, 'response.data', logAllow)
			
			let questions = response.data.map((questionData) => {
				return new Question(questionData)
			})
			
			this.setState((prevState) => {
				return {
					questions: questions,
				}
			})
			
			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			
			this.props.preloader?.hide()
			
			console.log(`axiosError = %o`, axiosError)
			//window.alert(axiosError)
			
		})
		
	}
	
	componentDidUpdate(prevProps, prevState) {
		
		if (prevState.courseNameToSearch !== this.state.courseNameToSearch) {
			this.loadCourses(1, this.state.coursesPageSize)
		}
		
	}
	
	componentDidMount() {
		
		let courses = this.state.courses
		
		if (courses.length < 1) {
			
			this.loadCourses(
				this.state.coursesPageNum,
				this.state.coursesPageSize,
				{
					// 'filter[name][like]': 'хим',
				}
			)
			
		}
		
		// this.apiTest()
		this.loadQuestions()
		
	}

	render() {
		
		const logName = 'QuestionManager.render'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		Logger.log(this.state, 'this.state', logAllow)
		Logger.log(this.props, 'this.props', logAllow)
		
		let courses = this.state.courses
		Logger.log(courses, 'courses', logAllow)
		
		Logger.log(this.state.coursesPageNum, 'this.state.coursesPageNum', logAllow)
		
		let questions = this.state.questions
		Logger.log(questions, 'questions', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<div className={'QuestionManager'}>
				
				<div className={`row`}>
					
					<div className={`filter-col col col-lg-4 col-xl-4`}>
						
						<div className={`filter material-filter`}>
							
							<FoldableList
								folded={false}
								name={i18next.t("Materials")}
							>
								
								<div className={`search-box mb-3`}>
									
									<div className={`input-group`}>
										
										<div className={`input-group-prepend`}>
											<button
												type={'button'}
												className={`btn btn-outline-secondary`}
												onClick={(event) => {
													this.courses.search.clear()
												}}
											><Icon.X/></button>
										</div>
										
										<input
											ref={this.courseNameToSearchFieldRef}
											className={[
												'search-field',
												'form-control',
											].join(' ')}
											// disabled={this.state.coursesAreLoading}
											type={'text'}
											placeholder={i18next.t('Search courses by title')}
											onChange={(event) => {
												
												// this.setState({
												// 	courseNameToSearch: event.target.value,
												// })
												
											}}
											onKeyDown={(event) => {
												
												if (event.key == 'Escape') {
													this.courses.search.clear()
													return
												}
												
												if (event.key !== 'Enter') {
													return
												}
												
												this.setState({
													courseNameToSearch: event.target.value,
												})
												
											}}
										/>
										
										<div className={`input-group-append`}>
											<button
												type={'button'}
												className={`btn btn-outline-secondary`}
												onClick={(event) => {
													
													this.setState({
														courseNameToSearch: this.courseNameToSearchFieldRef.current.value,
													})
													
												}}
											><Icon.Search/></button>
										</div>
										
									</div>
									
								</div>
								
								<div className={`pagination-box mb-3`}>
									<Pager
										controlsConfig={'start'}
										pagesCount={this.state.coursesPagesCount}
										pageClick={(newPageIndex) => {
											
											const logName = 'QuestionManager.courses.pager.pageClick'
											const logAllow = 1
											const logCollapse = 0
											
											Logger.groupStart(logName, logAllow, logCollapse)
											
											Logger.log(newPageIndex, 'newPageIndex', logAllow)
											
											let newPageNum = newPageIndex + 1
											
											this.loadCourses(newPageNum)
											
											Logger.groupEnd(logAllow)
											
										}}
										currentPageIndex={this.state.coursesPageNum - 1}
									/>
								</div>
								
								{(courses.length > 0) ? (
									
									<>
										{courses.map((course, courseIndex) => {
											
											const logName = 'QuestionManager.courses.map'
											const logAllow = 0
											const logCollapse = 0
											
											Logger.groupStart(logName, logAllow, logCollapse)
											
											Logger.log(course.id, 'course.id', logAllow)
											
											let selectedCoursesIds = this.state.selectedCoursesIds
											Logger.log(selectedCoursesIds, 'selectedCoursesIds', logAllow)
											
											let courseIdIndex = selectedCoursesIds.findIndex(x => x == course.id)
											Logger.log(courseIdIndex, 'courseIdIndex', logAllow)
											
											let isSelected = courseIdIndex >= 0
											Logger.log(isSelected, 'isSelected', logAllow)
											
											let courseExtraClassName = []
											
											if (isSelected) {
												courseExtraClassName.push('selected')
											}
											
											let courseHead = (
												<>
													<label>
														<input
															type={'checkbox'}
															checked={isSelected}
															onChange={(event) => {
																
																const logName = `QuestionManager.CourseTree.course_${course.id}.filterChbx.onChange`
																const logAllow = 1
																const logCollapse = 0
																
																Logger.groupStart(logName, logAllow, logCollapse)
																
																let chbx = event.target
																Logger.log(chbx, 'chbx', logAllow)
																
																let isChecked = chbx.checked
																Logger.log(isChecked, 'isChecked', logAllow)
																
																if (isChecked) {
																	selectedCoursesIds.push(course.id)
																} else {
																	selectedCoursesIds.splice(courseIdIndex, 1)
																}
																
																this.setState({
																	selectedCoursesIds: selectedCoursesIds,
																})
																
																Logger.groupEnd(logAllow)
																
															}}
														/> {i18next.t("Filter by")}
													</label>
												</>
											)
											
											Logger.groupEnd(logAllow)
											
											return (
												<CourseTree
													key={`course-${course.id}`}
													course={course}
													preloader={this.props.preloader}
													courseExtraClassName={courseExtraClassName}
													courseHead={null}
													useMaterials={true}
												/>
											)
											
										})}
									</>
									
								) : (
									
									<>
										{(this.state.coursesAreLoading) ? (
											<>
												{i18next.t("Loading")}...
											</>
										) : (
											<>
												<div className={`alert alert-warning`}>
													{i18next.t("Courses not found")}
												</div>
											</>
										)}
										
									</>
									
								)}
								
							</FoldableList>
							
						</div>
						
						<br/>
						
						<div className={`filter view-type-filter`}>
							
							<FoldableList
								folded={false}
								name={i18next.t('Task types')}
							>
								
								{Object.keys(Question.viewTypes).map((viewTypeAlias) => {
									
									let viewTypeName = Question.viewTypes[viewTypeAlias]
									
									return (
										<label className={`d-block`}>
											<input type={'checkbox'} />
											&nbsp;<span className={`text`}>{i18next.t(viewTypeName)}</span>
										</label>
									)
									
								})}
								
							</FoldableList>
							
						</div>
						
					</div>
					
					<div className={`content-col col`}>
						
						<div className={`search-box`}>
							{/* поисковая строка (поиск по тексту задания) */}
							<input className={`form-control`} placeholder={i18next.t('Search in task text')} />
						</div>
						
						<hr/>
						
						<div className={`content`}>
							
							{this.state.questions.map((question) => {
								
								return (
									<>
										<QuestionView
											question={question}
											user={null}
										/>
									</>
								)
								
							})}
						
						</div>
						
					</div>
					
				</div>
				
			</div>
			
		)
		
	}
	
}

QuestionManager.propTypes = {
	preloader: PropTypes.object,
	courses: PropTypes.arrayOf(PropTypes.instanceOf(Course)),
	alert: PropTypes.object,
}

QuestionManager.defaultProps = {
	courses: [],
}

export default QuestionManager