import "./CoursesPage.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Config from "../../helpers/Config";
import Logger from "../../helpers/Logger";
import Utils from "../../helpers/Utils";

import Course from "../../models/Course";
import Lesson from "../../models/Lesson";
import Theme from "../../models/Theme";
import User from "../../models/User";

import CourseForm from "./CourseForm";
import LessonForm from "./LessonForm";
import ThemeForm from "./ThemeForm";

import FoldableList from "./FoldableList";
import ListItem from "./ListItem";
import BackBtn from "./BackBtn";

import YouTubeHelper from "../../helpers/YouTubeHelper";
import UploadsList from "./UploadsList";

import infoIconBlue from "../../img/info-blue.svg"
import Department from "../../models/Department";
import Tree from "./Tree";

import MaterialsPage from "./MaterialsPage";
import MaterialsPage2 from "./MaterialsPage2";
import MaterialsPage3 from "./MaterialsPage3";

export default class CoursesPage extends React.Component {
	
	constructor(props) {
		
		super(props)
		
		this.state = {
			
			courses: [],
			lessons: [],
			themes: [],
			materials: [],
			
			currentCourse: null,
			currentCourseIndex: null,
			
			courseToEdit: null,
			courseToEditIndex: null,
			coursePreviewModalIsOpen: false,
			coursePreviewId: null,
			
			coursesShowArchived: null,
			
			currentLesson: null,
			currentLessonIndex: null,
			
			lessonToEdit: null,
			lessonToEditIndex: null,
			
			currentTheme: null,
			currentThemeIndex: null,
			
			themeToEdit: null,
			themeToEditIndex: null,
			
			currentMaterial: null,
			currentMaterialIndex: null,
			
			courseFormModalIsOpen: false,
			lessonFormModalIsOpen: false,
			themeFormModalIsOpen: false,
			
			// departments
			deps: [],
			currentDep: null,
			currentDepId: null,
			
			// useMaterialsPage3:  Utils.isLoc(),
			useMaterialsPage3: 0,
			// useMaterialsPage3: 1,
			
		}
		
	}
	
	deps = {
		
		load: (afterSuccess) => {
			
			const logName = 'CoursesPage.deps.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('department'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'name',
					// 'filter[type_id][0]': 2,
					// 'filter[type_id][1]': 3,
				},
			}).then((response) => {
				
				const logName = 'CoursesPage.deps.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 1;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let deps = response.data;
				
				this.setState((prevState) => {
					return {
						deps: deps,
					}
				});
				
				let storedDepartmentId = localStorage.getItem(Config.coursesCurrentDepartmentIdKey);
				
				if (storedDepartmentId) {
					
					this.deps.setCurrentById(storedDepartmentId, this.state.showArchivedCourses, 0);
					
				} else {
					
					let currentDep = deps[0];
					
					if (currentDep) {
						
						this.setState((prevState) => {
							return {
								currentDep: currentDep,
							}
						});
						
						this.courses.load(currentDep.id);
						
					}
					
				}
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrent: (dep, showArchivedCourses, clearCurrentCourseLessonTheme) => {
			
			const logName = 'CoursesPage.dep.setCurrent';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(dep, 'dep', logAllow);
			
			if (!dep) {
				return;
			}
			
			localStorage.setItem(Config.coursesCurrentDepartmentIdKey, dep.id);
			
			if (clearCurrentCourseLessonTheme) {
				localStorage.removeItem(Config.coursesCurrentCourseIdKey);
				localStorage.removeItem(Config.coursesCurrentLessonIdKey);
				localStorage.removeItem(Config.coursesCurrentThemeIdKey);
				localStorage.removeItem(Config.coursesCurrentThemeIndexKey);
			}
			
			this.setState((prevState) => {
				return {
					courses: [],
					lessons: [],
					themes: [],
					currentCourse: null,
					currentLesson: null,
					currentMaterial: null,
					currentDep: dep,
				}
			});
			
			this.courses.load(dep.id, showArchivedCourses);
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrentById: (depId, showArchivedCourses, clearCurrentCourseLessonTheme) => {
			
			const logName = 'CoursesPage.setCurrentById';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let dep = this.deps.getById(depId);
			Logger.log(dep, 'dep', logAllow);
			
			this.deps.setCurrent(dep, showArchivedCourses, clearCurrentCourseLessonTheme);
			
			Logger.groupEnd(logAllow);
			
		},
		
		getById: (id) => {
			
			const logName = 'CoursesPage.getById';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(id, 'id', logAllow);
			
			let deps = this.state.deps;
			Logger.log(deps, 'deps', logAllow);
			
			let depsFlatList = Department.flatList(deps);
			Logger.log(depsFlatList, 'depsFlatList', logAllow);
			
			let foundDep = depsFlatList.filter(dep => dep.id == id)[0];
			Logger.log(foundDep, 'foundDep', logAllow);
			
			Logger.groupEnd(logAllow);
			
			return foundDep;
			
		},
		
		getCurrent: () => {
			return this.deps.getById(this.state.currentDepId);
		},
		
	};
	
	courses = {
		
		disableUnwantedUseAnswerTemplates: (courseId) => {
			
			if (!courseId) {
				window.alert("course id not provided")
				return
			}
			
			if (!window.confirm(i18next.t("Shure?"))) {
				return
			}
			
			this.props.preloader?.show()
			
			axios({
				method: 'post',
				url: Utils.apiUrl('courses/disable-unwanted-use-answer-templates'),
				params: {
					accessToken: Utils.getUserToken(),
					courseId: courseId,
				},
				data: {},
			}).then((response) => {
				
				const logName = 'CoursesPage.courses.disableUnwantedUseAnswerTemplates.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				let touchedQuestionsIds = response.data
				Logger.log(touchedQuestionsIds, 'touchedQuestionsIds', logAllow)
				
				let touchedQuestionsCount = Array.isArray(touchedQuestionsIds) ? touchedQuestionsIds.length : '???'
				Logger.log(touchedQuestionsCount, 'touchedQuestionsCount', logAllow)
				
				window.alert(i18next.t("Questions touched") + ': ' + touchedQuestionsCount)
				
				this.props.preloader?.hide()
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				
				const logName = 'CoursesPage.courses.disableUnwantedUseAnswerTemplates.ajax.fail'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				console.log(`axiosError = %o`, axiosError)
				window.alert(axiosError)
				
				this.props.preloader?.hide()
				
				Logger.groupEnd(logAllow)
				
			})
			
		},
		
		load: (depId, showArchived=this.state.coursesShowArchived, afterSuccess) => {
			
			const logName = 'CoursesPage.courses.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'sort': 'name',
				'use-filters': true,
				// 'filter[is_active]': 1,
				'filter[department_id]': depId,
				'per-page': 999,
			};
			
			if (!showArchived) {
				params['filter[is_active]'] = 1;
			}
			
			Logger.log(params, 'params', logAllow);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('courses'),
				data: {},
				params: params,
			}).then((response) => {
				
				const logName = 'CoursesPage.courses.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 1;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (response.data) {
					
					// let courses = [];
					
					// response.data.forEach((corseData) => {
					//     let course = new Course(corseData);
					//     courses.push(course);
					// });
					
					let courses = this.state.courses;
					let newCourses = response.data.map(courseData => new Course(courseData));
					courses = courses.concat(newCourses);
					
					let savedCourseId = localStorage.getItem(Config.coursesCurrentCourseIdKey);
					Logger.log(savedCourseId, 'savedCourseId', logAllow);
					
					let savedCourse = null;
					
					if (savedCourseId) {
						
						savedCourse = courses.filter(course => course.id == savedCourseId)[0];
						Logger.log(savedCourse, 'currentCourse', logAllow);
						
						if (savedCourse) {
							this.lessons.load(savedCourse.id)
						}
						
					}
					
					this.setState((prevState) => {
						return {
							courses: courses,
							currentCourse: savedCourse,
						}
					});
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					if (afterSuccess) {
						afterSuccess(response, courses);
					}
					
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		add: () => {
			
			this.setState((prevState) => {
				return {
					courseToEdit: null,
					courseToEditIndex: null,
					courseFormModalIsOpen: true,
				}
			});
			
		},
		
		del: (course, courseIndex) => {
			
			const msg = i18next.t("Hide course \"{{course_name}}\"?", {
				course_name: course.getName(),
			});
			
			if (!window.confirm(msg)) {
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('courses') + '/' + course.id,
				data: {
					is_active: 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				let courses = this.state.courses;
				courses.splice(courseIndex, 1);
				
				this.setState((prevState) => {
					return {
						courses: courses,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
		afterCreate: (response) => {
			
			const logName = 'CoursesPage.courses.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			if (response.data) {
				
				let course = new Course(response.data);
				Logger.log(course, 'course', logAllow);
				
				let courses = this.state.courses;
				courses.push(course);
				courses.sort(Utils.defaultModelSort);
				
				this.setState((prevState) => {
					return {
						courses: courses,
						currentCourse: course,
						courseFormModalIsOpen: false,
						lessons: [],
						themes: [],
					}
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpdate: (response) => {
			
			const logName = 'CoursesPage.cources.afterUpdate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (response.data) {
				
				const updatedCourse = new Course(response.data);
				
				let courses = this.state.courses;
				
				courses[this.state.courseToEditIndex] = updatedCourse;
				
				courses.sort(Utils.defaultModelSort);
				
				this.setState((prevState) => {
					return {
						courses: courses,
						courseFormModalIsOpen: false,
					}
				});
				
				// this.courses.load();
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		toggleFormModal: () => {
			this.setState((prevState) => {
				return {
					courseFormModalIsOpen: !prevState.courseFormModalIsOpen,
				}
			});
		},
		
		saveSort: (courses) => {
			
			const logName = 'CoursesPage.courses.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			courses.forEach((course) => {
				ids.push(course.id);
			});
			
			axios({
				method: 'post',
				url: Utils.apiUrl('courses/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'CoursesPage.courses.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (courseIndex) => {
			
			let newCourseIndex;
			
			if (courseIndex > 0) {
				newCourseIndex = courseIndex - 1;
			} else {
				newCourseIndex = this.state.courses.length - 1;
			}
			
			let courses = this.state.courses;
			Utils.arrayMove(courses, courseIndex, newCourseIndex);
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			this.courses.saveSort(courses);
			
		},
		
		moveDown: (courseIndex) => {
			
			let newCourseIndex;
			
			if (courseIndex === this.state.courses.length - 1) {
				newCourseIndex = 0;
			} else {
				newCourseIndex = courseIndex + 1;
			}
			
			let courses = this.state.courses;
			Utils.arrayMove(courses, courseIndex, newCourseIndex);
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			this.courses.saveSort(courses);
			
		},
		
		togglePreviewModal: () => {
			this.setState((prevState) => {
				return {
					coursePreviewModalIsOpen: !prevState.coursePreviewModalIsOpen,
				}
			});
		},
		
		copy: (originalCourse) => {
			
			const logName = 'CoursesPage.courses.copy';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(originalCourse, 'originalCourse', logAllow);
			
			let departmentId = window.prompt(i18next.t("Department ID"), originalCourse.department_id);
			Logger.log(departmentId, 'courseId', logAllow);
			
			if (departmentId) {
				
				if (this.props.preloader) {
					this.props.preloader.show();
				}
				
				axios({
					method: 'post',
					url: Utils.apiUrl('courses/copy-to'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						courseId: originalCourse.id,
						departmentId: departmentId,
					},
				}).then((response) => {
					
					const logName = 'DepartmentsPage.courses.copy.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					if (response.data) {
						
						let courseCopy = new Course(response.data);
						
						let courses = this.state.courses;
						
						courses.push(courseCopy);
						
						this.setState((prevState) => {
							return {
								courses: courses,
							}
						});
						
					}
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					console.log('error.response [' + typeof error.response + '] = %o', error.response);
					
					if (error.response && error.response.data) {
						window.alert(error.response.data.message);
					} else {
						window.alert(error.message);
					}
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		move: (courseId, courseIndex) => {
			
			const logName = 'CoursesPage.courses.move';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let depId = window.prompt(i18next.t("Department ID"));
			Logger.log(depId, 'depId', logAllow);
			
			if (depId && depId != this.state.currentDepId) {
				
				if (this.props.preloader) {
					this.props.preloader.show();
				}
				
				axios({
					method: 'post',
					url: Utils.apiUrl('courses/move-to'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						courseId: courseId,
						departmentId: depId,
					},
				}).then((response) => {
					
					const logName = 'CoursesPage.courses.move.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					let courses = this.state.courses;
					courses.splice(courseIndex, 1);
					
					this.setState((prevState) => {
						return {
							courses: courses,
						}
					});
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					console.log('error.response = %o', error.response);
					
					if (error.response && error.response.data) {
						window.alert(error.response.data.message);
					} else {
						window.alert(error.message);
					}
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrent: (course, clearLessonAndTheme = false) => {
			
			this.setState((prevState) => {
				return {
					currentCourse: course,
					currentLesson: null,
					currentTheme: null,
					lessons: [],
					themes: [],
				}
			});
			
			this.lessons.load(course.id);
			
			localStorage.setItem(Config.coursesCurrentCourseIdKey, course.id);
			
			localStorage.removeItem(Config.coursesCurrentLessonIdKey);
			localStorage.removeItem(Config.coursesCurrentThemeIdKey);
			localStorage.removeItem(Config.coursesCurrentThemeIndexKey);
			
			if (clearLessonAndTheme) {
				// localStorage.removeItem(Config.coursesCurrentLessonIdKey);
				// localStorage.removeItem(Config.coursesCurrentThemeIdKey);
			}
			
		},
		
	};
	
	lessons = {
		
		load: (courseId) => {
			
			const logName = 'CoursesPage.load';
			const logAllow = 1;
			const logCollapsed = 1;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(courseId, 'courseId', logAllow);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.groupEnd(logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('lessons'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'sort',
					'use-filters': true,
					'filter[is_active]': 1,
					'filter[course_id]': courseId,
					'per-page': 999,
				},
			}).then((response) => {
				
				const logName = 'CoursesPage.lessons.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 1;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (response.data) {
					
					let lessons = this.state.lessons;
					
					response.data.forEach((modelData) => {
						let lesson = new Lesson(modelData);
						lessons.push(lesson);
					});
					
					this.setState((prevState) => {
						return {
							lessons: lessons,
						}
					});
					
					let storedLessonId = localStorage.getItem(Config.coursesCurrentLessonIdKey);
					Logger.log(storedLessonId, 'storedLessonId', logAllow);
					
					if (storedLessonId) {
						this.lessons.setCurrent(storedLessonId);
					}
					
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		},
		
		add: () => {
			
			this.setState((prevState) => {
				return {
					lessonToEdit: null,
					lessonToEditIndex: null,
					lessonFormModalIsOpen: true,
				}
			});
			
		},
		
		del: (lesson, lessonIndex) => {
			
			const msg = i18next.t("Hide lesson \"{{lesson_name}}\"?", {
				lesson_name: lesson.getName(),
			});
			
			if (!window.confirm(msg)) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('lessons') + '/' + lesson.id,
				data: {
					is_active: 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				let lessons = this.state.lessons;
				lessons.splice(lessonIndex, 1);
				
				this.setState((prevState) => {
					return {
						lessons: lessons,
					}
				});
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
		afterCreate: (response) => {
			
			const logName = 'CoursesPage.lessons.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			if (response.data) {
				
				let lesson = new Lesson(response.data);
				Logger.log(lesson, 'lesson', logAllow);
				
				let lessons = this.state.lessons;
				lessons.push(lesson);
				
				this.setState((prevState) => {
					return {
						lessons: lessons,
						currentLesson: lesson,
						lessonFormModalIsOpen: false,
						themes: [],
					}
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpdate: (response) => {
			
			const logName = 'CoursesPage.cources.afterUpdate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (response.data) {
				const updatedLesson = new Lesson(response.data);
				let lessons = this.state.lessons;
				lessons[this.state.lessonToEditIndex] = updatedLesson;
				this.setState((prevState) => {
					return {
						lessons: lessons,
						lessonFormModalIsOpen: false,
					}
				});
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		toggleFormModal: () => {
			this.setState((prevState) => {
				return {
					lessonFormModalIsOpen: !prevState.lessonFormModalIsOpen,
				}
			});
		},
		
		saveSort: (lessons) => {
			
			const logName = 'CoursesPage.lessons.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			lessons.forEach((lesson) => {
				ids.push(lesson.id);
			});
			
			axios({
				method: 'post',
				url: Utils.apiUrl('lessons/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'CoursesPage.lessons.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			let newIndex;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = this.state.lessons.length - 1;
			}
			
			let sorted = this.state.lessons;
			Utils.arrayMove(sorted, currentIndex, newIndex);
			
			this.setState((prevState) => {
				return {
					lessons: sorted,
				}
			});
			
			this.lessons.saveSort(sorted);
			
		},
		
		moveDown: (currentIndex) => {
			
			let newIndex;
			
			if (currentIndex === this.state.lessons.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			let sorted = this.state.lessons;
			Utils.arrayMove(sorted, currentIndex, newIndex);
			
			this.setState((prevState) => {
				return {
					lessons: sorted,
				}
			});
			
			this.lessons.saveSort(sorted);
			
		},
		
		copy: (originalLesson) => {
			
			const logName = 'CoursesPage.lessons.copy';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(originalLesson, 'originalLesson', logAllow);
			
			let courseId = window.prompt(i18next.t("Course ID"), originalLesson.course_id);
			Logger.log(courseId, 'lessonId', logAllow);
			
			if (courseId) {
				
				if (this.props.preloader) {
					this.props.preloader.show();
				}
				
				axios({
					method: 'post',
					url: Utils.apiUrl('lessons/copy-to'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						lessonId: originalLesson.id,
						courseId: courseId,
					},
				}).then((response) => {
					
					const logName = 'CoursesPage.lessons.copy.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					if (response.data) {
						
						if (courseId == this.state.currentCourse.id) {
							
							let lessonCopy = new Lesson(response.data);
							
							let lessons = this.state.lessons;
							
							lessons.push(lessonCopy);
							
							this.setState((prevState) => {
								return {
									lessons: lessons,
								}
							});
							
						}
						
					}
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					console.log('error.response [' + typeof error.response + '] = %o', error.response);
					
					if (error.response && error.response.data) {
						window.alert(error.response.data.message);
					} else {
						window.alert(error.message);
					}
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrent: (lessonId, clearStoredThemeId = false) => {
			
			const logName = 'CoursesPage.lessons.setCurrent';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(lessonId, 'lessonId', logAllow);
			
			let lessons = this.state.lessons;
			Logger.log(lessons, 'lessons', logAllow);
			
			let lesson = lessons.filter(lesson => lesson.id == lessonId)[0];
			Logger.log(lesson, 'lesson', logAllow);
			
			if (lesson) {
				
				localStorage.setItem(Config.coursesCurrentLessonIdKey, lesson.id);
				
				this.setState((prevState) => {
					return {
						currentLessonId: lessonId,
						currentLesson: lesson,
						currentTheme: null,
						themes: [],
					}
				});
				
				this.themes.load(lesson.id);
				
				// if (clearStoredThemeId) {
				// 	localStorage.removeItem(Config.coursesCurrentThemeIdKey);
				// }
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	themes = {
		
		load: (lessonId) => {
			
			const logName = 'CoursesPage.themes.load';
			const logAllow = 1;
			const logCollapsed = 1;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(lessonId, 'lessonId', logAllow);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.groupEnd(logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('themes'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'sort',
					'use-filters': true,
					'filter[is_active]': 1,
					'filter[lesson_id]': lessonId,
					'per-page': 999,
				},
			}).then((response) => {
				
				const logName = 'CoursePage.themes.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 1;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				if (response.data) {
					
					let themes = this.state.themes;
					
					response.data.forEach((modelData) => {
						let theme = new Theme(modelData);
						themes.push(theme);
					});
					
					this.setState((prevState) => {
						return {
							themes: themes,
						}
					});
					
					/*
					let currentThemeIndex = localStorage.getItem(Config.coursesCurrentThemeIndexKey);
					
					if (currentThemeIndex !== null) {
						
						let currentTheme = themes[currentThemeIndex];
						
						this.setState((prevState) => {
							return {
								currentTheme: currentTheme,
							}
						});
						
					}
					*/
					
					let currentThemeId = localStorage.getItem(Config.coursesCurrentThemeIdKey)
					
					if (currentThemeId) {
						
						let currentTheme = themes.find(x => x.id == currentThemeId);
						
						this.setState((prevState) => {
							return {
								currentTheme: currentTheme,
							}
						});
						
					}
					
					
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		},
		
		add: () => {
			
			this.setState((prevState) => {
				return {
					themeToEdit: null,
					themeToEditIndex: null,
					themeFormModalIsOpen: true,
				}
			});
			
		},
		
		del: (theme, themeIndex) => {
			
			const msg = i18next.t("Hide theme \"{{theme_name}}\"?", {
				theme_name: theme.getName(),
			});
			
			if (!window.confirm(msg)) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('themes') + '/' + theme.id,
				data: {
					is_active: 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				let themes = this.state.themes;
				themes.splice(themeIndex, 1);
				
				this.setState((prevState) => {
					return {
						themes: themes,
					}
				});
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
		afterCreate: (response) => {
			
			const logName = 'themesPage.themes.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			if (response.data) {
				
				let theme = new Theme(response.data);
				Logger.log(theme, 'theme', logAllow);
				
				let themes = this.state.themes;
				themes.push(theme);
				
				this.setState((prevState) => {
					return {
						themes: themes,
						themeFormModalIsOpen: false,
					}
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpdate: (response) => {
			
			const logName = 'themesPage.cources.afterUpdate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (response.data) {
				const updatedTheme = new Theme(response.data);
				let themes = this.state.themes;
				themes[this.state.themeToEditIndex] = updatedTheme;
				this.setState((prevState) => {
					return {
						themes: themes,
						themeToEdit: updatedTheme,
						themeFormModalIsOpen: false,
					}
				});
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		toggleFormModal: () => {
			this.setState((prevState) => {
				return {
					themeFormModalIsOpen: !prevState.themeFormModalIsOpen,
				}
			});
		},
		
		saveSort: (themes) => {
			
			const logName = 'CoursesPage.themes.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			themes.forEach((theme) => {
				ids.push(theme.id);
			});
			
			axios({
				method: 'post',
				url: Utils.apiUrl('themes/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'CoursesPage.themes.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			let newIndex;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = this.state.themes.length - 1;
			}
			
			let sorted = this.state.themes;
			Utils.arrayMove(sorted, currentIndex, newIndex);
			
			this.setState((prevState) => {
				return {
					themes: sorted,
				}
			});
			
			this.themes.saveSort(sorted);
			
		},
		
		moveDown: (currentIndex) => {
			
			let newIndex;
			
			if (currentIndex === this.state.themes.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			let sorted = this.state.themes;
			Utils.arrayMove(sorted, currentIndex, newIndex);
			
			this.setState((prevState) => {
				return {
					themes: sorted,
				}
			});
			
			this.themes.saveSort(sorted);
			
		},
		
		getPrev: (currentIndex = this.state.currentThemeIndex) => {
			let themes = this.state.themes;
			let maxThemeIndex = themes.length - 1;
			let prevThemeIndex = currentIndex > 0 ? currentIndex - 1 : maxThemeIndex;
			let prevTheme = themes[prevThemeIndex];
			return {
				theme: prevTheme,
				index: prevThemeIndex,
			};
		},
		
		getNext: (currentIndex = this.state.currentThemeIndex) => {
			let themes = this.state.themes;
			let maxThemeIndex = themes.length - 1;
			let nextThemeIndex = currentIndex < maxThemeIndex ? currentIndex + 1 : 0;
			let nextTheme = themes[nextThemeIndex];
			return {
				theme: nextTheme,
				index: nextThemeIndex,
			};
		},
		
		showPrev: (event) => {
			let data = this.themes.getPrev();
			this.setState((prevState) => {
				return {
					currentTheme: data.theme,
					currentThemeIndex: data.index,
				}
			});
		},
		
		showNext: (event) => {
			let data = this.themes.getNext();
			this.setState((nextState) => {
				return {
					currentTheme: data.theme,
					currentThemeIndex: data.index,
				}
			});
		},
		
		copy: (originalTheme) => {
			
			const logName = 'CoursesPage.themes.copy';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(originalTheme, 'originalTheme', logAllow);
			
			let lessonId = window.prompt(i18next.t("Lesson ID"), originalTheme.lesson_id);
			Logger.log(lessonId, 'lessonId', logAllow);
			
			if (lessonId) {
				
				if (this.props.preloader) {
					this.props.preloader.show();
				}
				
				axios({
					method: 'post',
					url: Utils.apiUrl('themes/copy-to'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						themeId: originalTheme.id,
						lessonId: lessonId,
					},
				}).then((response) => {
					
					const logName = 'CoursesPage.themes.copy.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					if (response.data) {
						
						let themeCopy = new Theme(response.data);
						
						let themes = this.state.themes;
						
						themes.push(themeCopy);
						
						this.setState((prevState) => {
							return {
								themes: themes,
							}
						});
						
					}
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					console.log('error.response = %o', error.response);
					
					if (error.response && error.response.data) {
						window.alert(error.response.data.message);
					} else {
						window.alert(error.message);
					}
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	componentDidMount() {
		
		this.deps.load();
		
	}
	
	render() {
		
		const logName = 'CoursesPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state, 'this.state', logAllow);
		
		let user = this.props.user;
		
		let prevThemeData = this.themes.getPrev();
		let nextThemeData = this.themes.getNext();
		
		let dep = this.state.currentDep;
		let course = this.state.currentCourse;
		let lesson = this.state.currentLesson;
		let theme = this.state.currentTheme;
		
		let deps = Department.flatList(this.state.deps);
		Logger.log(deps, 'deps', logAllow);
		
		let courses = this.state.courses.filter(course => course.department_id == this.state.currentDep.id);
		let lessons = course ? this.state.lessons.filter(lesson => lesson.course_id == course.id) : [];
		// let themes = lesson ? this.state.themes.filter(theme => theme.lesson_id == lesson.id) : [];
		
		let courseToPreview = courses.filter(course => course.id == this.state.coursePreviewId)[0];
		
		Logger.groupEnd(logAllow);
		
		// todo use routing
		
		return (
			
			<div className={'CoursesPage'}>
				
				<section className="mobile-nav-box d-md-none">
					
					{course ? (
						
						/* превью курса + выбор урока и темы */
						
						<>
							
							{(!theme) &&
								
								<>
									
									{/* превью курса */}
									<section className={'course-preview mobile-course-preview'}>
										
										<div className="page-head-box">
											<h2>
												<BackBtn onClick={() => {
													// back to course picker
													this.setState((prevState) => {
														return {
															currentCourse: null,
														}
													});
												}}/>&nbsp;{i18next.t("Courses")}
											</h2>
										</div>
										
										<div className="list mobile-course-preview">
											<ListItem
												onClick={() => {
													// back to course picker
													this.setState((prevState) => {
														return {
															currentCourse: null,
														}
													});
												}}
											>
												<div className="row align-items-center">
													<div className="col-8 name-col">
														{course.name}
													</div>
													<div className="col-4 change-btn-col">
														<div className="change-btn">
															{i18next.t("Select")}
														</div>
													</div>
												</div>
											</ListItem>
										</div>
									
									</section>
									
									{/* выбор урока и темы */}
									<section className={'theme-picker mobile-theme-picker'}>
										
										<div className="page-head-box">
											<h2>{i18next.t("Lessons and themes")}</h2>
										</div>
										
										<div className="list lessons-list">
											
											{lessons.length > 0 &&
												
												<>
													
													{lessons.map((lesson, lessonIndex) => {
														
														let themes = this.state.themes.filter(theme => theme.lesson_id == lesson.id);
														
														return (
															
															<FoldableList
																// name={<><b>{i18next.t("Lesson")} {lessonIndex + 1}</b>. {lesson.name}</>}
																name={<>
																	<small
																		className={'text-muted'}>{i18next.t("Lesson")}</small>
																	<div>{lessonIndex + 1}. {lesson.name}</div>
																</>}
																onUnfold={() => {
																	if (themes.length < 1) {
																		this.themes.load(lesson.id);
																	}
																}}
															>
																{themes.length > 0 ? (
																	<>
																		{themes.map((theme, themeIndex) => {
																			return (
																				<ListItem
																					active={true}
																					onClick={() => {
																						this.setState((prevState) => {
																							return {
																								currentLesson: lesson,
																								currentTheme: theme,
																							}
																						});
																					}}
																				>
																					<small
																						className={'text-muted'}>{i18next.t("Theme")}</small>
																					<div>{themeIndex + 1}. {theme.name}</div>
																				</ListItem>
																			);
																		})}
																	</>
																) : (
																	<>{i18next.t("Themes not found")}</>
																)}
															</FoldableList>
														
														);
														
													})}
												
												</>
												
											}
										
										</div>
									
									</section>
								
								</>
								
							}
						
						</>
					
					) : (
						
						/* выбор курса */
						
						<>
							
							<div className="page-head-box">
								<h2>{i18next.t("Courses")}</h2>
							</div>
							
							<div className="list course-picker mobile-course-picker">
								
								{deps.map((dep, depIndex) => {
									
									const logName = 'CoursesPage.deps.map';
									const logAllow = 0;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									const depCourses = this.state.courses.filter(course => course.department_id == dep.id);
									
									Logger.groupEnd(logAllow);
									
									return (
										
										<FoldableList
											// name={<>{depIndex + 1}. {dep.name}</>}
											name={<>
												{/*<small className={'text-muted'}>{i18next.t("Department")}</small>*/}
												<div>{depIndex + 1}. {dep.name}</div>
											</>}
											folded={true}
											onToggle={() => {
												if (depCourses.length < 1) {
													this.courses.load(dep.id);
												}
											}}
										>
											
											{depCourses.length > 0 ? (
												
												depCourses.map((course, courseIndex) => {
													
													const logName = 'CoursesPage.depCourses.map';
													const logAllow = 0;
													const logCollapsed = 0;
													
													Logger.groupStart(logName, logAllow, logCollapsed);
													
													let lessons = this.state.lessons.filter(lesson => lesson.course_id == course.id);
													
													Logger.groupEnd(logAllow);
													
													return (
														<ListItem
															onClick={() => {
																
																this.setState((prevState) => {
																	return {
																		currentCourse: course,
																	}
																});
																
																// load lessons
																if (lessons.length < 1) {
																	this.lessons.load(course.id);
																}
																
															}}
														>
															{/*<small className={'text-muted'}>{i18next.t("Course")}</small>*/}
															<div>{courseIndex + 1}. {course.name}</div>
														</ListItem>
													);
													
												})
											
											) : (
												<span className={'empty-msg'}>
                                                    {i18next.t("Courses not found")}
                                                </span>
											)}
										
										</FoldableList>
									
									);
									
								})}
							
							</div>
						
						</>
					
					)}
				
				</section>
				
				{this.state.currentTheme ? (
					
					<>
						
						{(this.state.useMaterialsPage3) ? (
							
							<MaterialsPage3
								
								course={this.state.currentCourse}
								lesson={this.state.currentLesson}
								theme={this.state.currentTheme}
								themes={this.state.themes}
								
								user={this.props.user}
								
								back={() => {
									
									localStorage.removeItem(Config.coursesCurrentThemeIndexKey);
									
									this.setState((prevState) => {
										return {
											currentTheme: null,
											currentThemeIndex: null,
										}
									});
									
								}}
								
								showPrevTheme={this.themes.showPrev}
								showNextTheme={this.themes.showNext}
								
								prevTheme={prevThemeData.theme}
								prevThemeIndex={prevThemeData.index}
								
								nextTheme={nextThemeData.theme}
								nextThemeIndex={nextThemeData.index}
								
								currentThemeIndex={this.state.currentThemeIndex}
								
								skins={this.props.skins}
								
								alert={this.props.alert}
								preloader={this.props.preloader}
								
							/>
							
						) : (
							
							<MaterialsPage
								
								course={this.state.currentCourse}
								lesson={this.state.currentLesson}
								theme={this.state.currentTheme}
								themes={this.state.themes}
								
								user={this.props.user}
								back={() => {
									
									localStorage.removeItem(Config.coursesCurrentThemeIndexKey);
									localStorage.removeItem(Config.coursesCurrentThemeIdKey);
									
									this.setState((prevState) => {
										return {
											currentTheme: null,
											currentThemeIndex: null,
										}
									});
									
								}}
								
								showPrevTheme={this.themes.showPrev}
								showNextTheme={this.themes.showNext}
								
								prevTheme={prevThemeData.theme}
								prevThemeIndex={prevThemeData.index}
								
								nextTheme={nextThemeData.theme}
								nextThemeIndex={nextThemeData.index}
								
								currentThemeIndex={this.state.currentThemeIndex}
								
								skins={this.props.skins}
								
								alert={this.props.alert}
								preloader={this.props.preloader}
								
							/>
							
						)}
						
					</>
				
				) : (
					
					<div className="full-courses-list-box d-none d-md-block">
						
						<div className="row">
							
							<section className="col-lg-4 courses-col">
								
								<div className="page-head-box">
									<h2>{i18next.t("Courses")}</h2>
								</div>
								
								<div className="list courses-list old-courses-list">
									
									{(user.can('showArchivedCourses')) &&
										
										<div className="controls courses-list-controls">
											
											<label htmlFor={'courses-show-archived'}>
												<input
													id={'courses-show-archived'}
													type={'checkbox'}
													checked={this.state.coursesShowArchived}
													onChange={(event) => {
														
														const logName = 'CoursesPage.coursesShowArchived.toggle';
														const logAllow = 1;
														const logCollapsed = 0;
														
														Logger.groupStart(logName, logAllow, logCollapsed);
														
														let showArchivedCourses = event.target.checked;
														Logger.log(showArchivedCourses, 'val', logAllow);
														
														this.setState((prevState) => {
															return {
																coursesShowArchived: showArchivedCourses,
															}
														});
														
														// this.courses.load(this.state.currentDepId);
														
														// let currentDep = this.deps.getById(this.state.currentDepId);
														let currentDep = this.state.currentDep;
														Logger.log(currentDep, 'currentDep', logAllow);
														
														this.deps.setCurrent(currentDep, showArchivedCourses);
														
														Logger.groupEnd(logAllow);
														
													}}
												/> {i18next.t("Show inactive")}
											</label>
											
											<hr/>
										
										</div>
										
									}
									
									<div className={'form-group deps-list'}>
										
										{/* поразделения */}
										
										{this.state.deps.length > 0 ? (
											<>
												<Tree
													data={this.state.deps}
													itemViewer={(dep) => {
														return (
															<div>
																{/*{(!dep.parent_id || dep.children.length > 0) ? (*/}
																{(false) ? (
																	<>
																		<b>{dep.name}</b>
																		<small
																			className={'debug-info'}>#{dep.id}</small>
																	</>
																) : (
																	<label>
																		<input
																			type={'radio'}
																			name={'dep_id'}
																			value={dep.id}
																			checked={this.state.currentDep === dep}
																			onChange={(event) => {
																				this.deps.setCurrent(dep, this.state.showArchivedCourses, 1);
																			}}
																		/> {dep.name} <small className={'debug-info'}>#{dep.id}</small>
																	</label>
																)}
															</div>
														);
													}}
												/>
											</>
										) : (
											<>{i18next.t("Departments not found")}</>
										)}
									
									</div>
									
									{this.props.user.can('manageCourses') && (
										
										<div className="controls courses-list-controls">
											
											{this.state.currentDep && (
												<div
													className={'list-item list-item-active add-btn'}
													onClick={(event) => {
														this.courses.add();
													}}
												><span className="content">+ {i18next.t("Add course")}</span></div>
											)}
										
										</div>
									
									)}
									
									<div className="body">
										
										{courses.length > 0 ? (
											
											<>
												
												{courses.map((course, courseIndex) => {
													
													let createdBy = course.created_by;
													
													let courseDebugData = [
														'#' + course.id,
														// 'progress: ' + course.progress,
														'created_by: ' + (createdBy ? createdBy.getDisplayName(1) + ' #' + createdBy.id : '???'),
														'created_at: ' + course.created_at,
													];
													
													if (course.copy_of_id) {
														courseDebugData.push('copy of #' + course.copy_of_id);
													}
													
													let classNameData = [
														'course',
														'course-preview',
														'course-list-item',
														'list-item',
														'list-item-active',
													];
													
													if (user.can('manageCourses')) {
														classNameData = classNameData.concat([
															'has-menu',
															'has-burger-menu',
														])
													}
													
													let currentCourse = this.state.currentCourse;
													
													let isCurrent = currentCourse && course.id == currentCourse.id;
													
													if (isCurrent) {
														classNameData.push('list-item-current')
													}
													
													let hasPreviewInfo = course.hasPreviewInfo();
													
													if (hasPreviewInfo) {
														classNameData.push('has-info-btn');
													}
													
													if (course.is_active == 0) {
														classNameData.push('list-item-archived');
													}
													
													return (
														
														<div
															key={`course-${course.id}-preview`}
															className={classNameData.join(' ')}
															data-course-id={course.id}
															onClick={(event) => {
																this.courses.setCurrent(course, true);
															}}
															// title={'#' + course.id}
															data-debug={courseDebugData.join(' | ')}
														>
															
															<div className="progress-box">
																<div className="progress" style={{
																	width: course.progress + '%',
																}}></div>
															</div>
															
															<div className="content">
																
																<div className={'text'}>
																	
																	{course.getName()}
																	
																	{user.can('debugInfo') && (
																		<div className={'debug-info'}>{courseDebugData.join(' | ')}</div>
																	)}
																	
																</div>
															
															</div>
															
															<div className="controls">
																
																{(course.hasPreviewInfo()) &&
																	// todo use button tag
																	<img
																		className={[
																			'info-btn',
																		].join(' ')}
																		src={infoIconBlue}
																		alt={'info'}
																		onClick={(event) => {
																			event.stopPropagation();
																			this.setState((prevState) => {
																				return {
																					coursePreviewId: course.id,
																					coursePreviewModalIsOpen: true,
																				}
																			});
																		}}
																	/>
																}
																
																{this.props.user.can('manageCourses') && (
																	
																	<DropdownButton
																		className={[
																			'overlay-menu',
																			'course-menu',
																		].join(' ')}
																		menuAlign="right"
																		onClick={(event) => {
																			event.stopPropagation();
																		}}
																		title={''}
																	>
																		
																		<Dropdown.Item
																			eventKey="update"
																			onClick={(event) => {
																				
																				event.stopPropagation();
																				
																				this.setState((prevState) => {
																					return {
																						courseToEdit: course,
																						courseToEditIndex: courseIndex,
																						courseFormModalIsOpen: true,
																					}
																				});
																				
																			}}
																		>{i18next.t("Edit")}</Dropdown.Item>
																		
																		<Dropdown.Item
																			eventKey="delete"
																			onClick={(event) => {
																				event.stopPropagation();
																				this.courses.del(course, courseIndex);
																			}}
																		>{i18next.t("Hide")}</Dropdown.Item>
																		
																		{(user.can('moveCourses')) &&
																			<>
																				<Dropdown.Item
																					className={'course-move-up-btn'}
																					eventKey="up"
																					onClick={(event) => {
																						event.stopPropagation();
																						this.courses.moveUp(courseIndex);
																					}}
																				>{i18next.t("Move up")}</Dropdown.Item>
																				
																				<Dropdown.Item
																					className={'course-move-down-btn'}
																					eventKey="down"
																					onClick={(event) => {
																						event.stopPropagation();
																						this.courses.moveDown(courseIndex);
																					}}
																				>{i18next.t("Move down")}</Dropdown.Item>
																			</>
																		}
																		
																		{(user.can('copyMaterials')) &&
																			<>
																				<Dropdown.Item
																					className={'course-copy-btn'}
																					onClick={(event) => {
																						this.courses.copy(course);
																					}}
																				>{i18next.t("Copy to")}...</Dropdown.Item>
																				
																				<Dropdown.Item
																					className={'course-move-btn'}
																					onClick={(event) => {
																						this.courses.move(course.id, courseIndex);
																					}}
																				>{i18next.t("Move to")}...</Dropdown.Item>
																			</>
																		}
																		
																		{(user.can('disableUnwantedUseAnswerTemplates')) &&
																			<>
																				<Dropdown.Item
																					className={'course-copy-btn'}
																					onClick={(event) => {
																						this.courses.disableUnwantedUseAnswerTemplates(course.id);
																					}}
																					title={i18next.t("Disable unwanted using of answer templates")}
																				>{i18next.t("Dsbl unwntd usg of answr tpls")}</Dropdown.Item>
																			</>
																		}
																	
																	</DropdownButton>
																
																)}
															
															</div>
														
														</div>
													
													);
													
												})}
											
											</>
										
										) : (
											
											<div
												className={[
													'course',
													'course-list-item',
													'list-item',
													'has-menu',
													'has-burger-menu',
												].join(' ')}
											>{i18next.t("Courses not found")}</div>
										
										)}
									
									</div>
								
								</div>
							
							</section>
							
							<section className="col-lg-4 lessons-col">
								
								<div className="page-head-box">
									<h2>{i18next.t("Lessons")}</h2>
								</div>
								
								{this.state.currentCourse ? (
									
									<div className={'list lessons-list'}>
										
										{this.props.user.can('manageLessons') && (
											
											<div className="controls">
												
												<div
													className={'list-item list-item-active add-btn'}
													onClick={(event) => {
														this.lessons.add();
													}}
												><span className="content">+ {i18next.t("Add lesson")}</span></div>
											
											</div>
										
										)}
										
										{this.state.lessons.length > 0 ? (
											
											<div className="body">
												
												{this.state.lessons.map((lesson, lessonIndex) => {
													
													let classNameData = [
														'lesson-preview',
														'lesson-list-item',
														'list-item',
														'list-item-active',
														'menu-box',
														'has-menu',
														'has-burger-menu',
													];
													
													if (this.state.currentLesson && lesson.id == this.state.currentLesson.id) {
														classNameData.push('list-item-current');
													}
													
													let lessonDebugData = [
														'#' + lesson.id,
														// 'progress: ' + lesson.progress,
														'created_at: ' + lesson.created_at,
													];
													
													if (lesson.copy_of_id) {
														lessonDebugData.push('copy of #' + lesson.copy_of_id);
													}
													
													return (
														
														<div
															className={classNameData.join(' ')}
															data-lesson-id={lesson.id}
															onClick={(event) => {
																this.lessons.setCurrent(lesson.id);
															}}
															data-debug={lessonDebugData.join(' | ')}
														>
															
															<div className="progress-box">
																<div className="progress" style={{
																	width: lesson.progress + '%',
																}}></div>
															</div>
															
															<div className="content">
																<div
																	className={'lesson-name'}>{lessonIndex + 1}. {lesson.getName()}</div>
																{this.props.user.can('debugInfo') && (
																	<div
																		className={'debug-info'}>{lessonDebugData.join(' | ')}</div>
																)}
															</div>
															
															{this.props.user.can('manageLessons') && (
																
																<DropdownButton
																	className={[
																		'lesson-menu',
																		'overlay-menu',
																	].join(' ')}
																	menuAlign="right"
																	onClick={(event) => {
																		event.stopPropagation();
																	}}
																	title={''}
																>
																	
																	<Dropdown.Item
																		className={'lesson-edit-btn'}
																		eventKey={'update'}
																		onClick={(event) => {
																			this.setState((prevState) => {
																				return {
																					lessonToEdit: lesson,
																					lessonToEditIndex: lessonIndex,
																					lessonFormModalIsOpen: true,
																				}
																			});
																		}}
																	>{i18next.t("Edit")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		className={'lesson-hide-btn'}
																		eventKey={'remove'}
																		onClick={(event) => {
																			this.lessons.del(lesson, lessonIndex);
																		}}
																	>{i18next.t("Hide")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		className={'lesson-move-up-btn'}
																		eventKey={'up'}
																		onClick={(event) => {
																			event.stopPropagation();
																			this.lessons.moveUp(lessonIndex);
																		}}
																	>{i18next.t("Move up")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		className={'lesson-move-down-btn'}
																		eventKey={'down'}
																		onClick={(event) => {
																			event.stopPropagation();
																			this.lessons.moveDown(lessonIndex);
																		}}
																	>{i18next.t("Move down")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		className={'lesson-copy-btn'}
																		eventKey={'copy'}
																		onClick={(event) => {
																			event.stopPropagation();
																			this.lessons.copy(lesson);
																		}}
																	>{i18next.t("Copy to")}...</Dropdown.Item>
																
																</DropdownButton>
															
															)}
														
														</div>
													
													);
													
												})}
											
											</div>
										
										) : (
											
											<div className="body">
												
												<div
													className={[
														'lesson-preview',
														'list-item',
														'menu-box',
													].join(' ')}
												>{i18next.t("Lessons not found")}</div>
											
											</div>
										
										)}
									
									</div>
								
								) : (
									
									<div className={'list lessons-list'}>
										
										<div className="controls">
											
											<div className="list-item list-item-disabled">
												<div className="content">
													{i18next.t("Select course")}
												</div>
											</div>
										
										</div>
									
									</div>
								
								)}
							
							</section>
							
							<section className="col-lg-4 themes-col">
								
								<div className="page-head-box">
									<h2>{i18next.t("Themes")}</h2>
								</div>
								
								{this.state.currentLesson ? (
									
									<div className={'list themes-list'}>
										
										{this.props.user.can('manageThemes') && (
											
											<div className="controls">
												
												<div className={'list-item list-item-active add-btn'}
													 onClick={(event) => {
														 this.themes.add();
													 }}
												><span className="content">+ {i18next.t("Add theme")}</span></div>
											
											</div>
										
										)}
										
										{this.state.themes.length > 0 && (
											
											<div className="body">
												
												{this.state.themes.map((theme, themeIndex) => {
													
													let themeDebugData = [
														'#' + theme.id,
														// 'progress: ' + theme.progress,
														'creared at: ' + theme.created_at,
													];
													
													if (theme.copy_of_id) {
														themeDebugData.push('copy of #' + theme.copy_of_id);
													}
													
													return (
														
														<div
															// to={'/courses/theme/' + theme.id}
															className={[
																'theme-preview',
																'list-item',
																'list-item-active',
																'menu-box',
																'has-menu',
																this.state.currentTheme && theme.id == this.state.currentTheme.id ? 'list-item-current' : '',
															].join(' ')}
															data-theme-id={theme.id}
															onClick={(event) => {
																
																// todo breadcrumbs
																// if (this.props.breadcrumbs) {
																// }
																
																localStorage.setItem(Config.coursesCurrentThemeIndexKey, themeIndex);
																localStorage.setItem(Config.coursesCurrentThemeIdKey, theme.id);
																
																this.setState((prevState) => {
																	return {
																		currentTheme: theme,
																		currentThemeIndex: themeIndex,
																	}
																});
																
															}}
															data-debug={themeDebugData}
														>
															
															<div className="progress-box">
																<div className="progress" style={{
																	width: theme.progress + '%',
																}}></div>
															</div>
															
															<div className="content">
																<div
																	className={'theme-name'}>{themeIndex + 1}. {theme.getName()}</div>
																{this.props.user.can('debugInfo') && (
																	<div
																		className="debug-info">{themeDebugData.join(' | ')}</div>
																)}
															</div>
															
															{this.props.user.can('manageThemes') && (
																
																<DropdownButton
																	className={[
																		'overlay-menu',
																	].join(' ')}
																	menuAlign="right"
																	onClick={(event) => {
																		event.stopPropagation();
																	}}
																	title={''}
																>
																	
																	<Dropdown.Item
																		eventKey="update"
																		onClick={(event) => {
																			this.setState((prevState) => {
																				return {
																					themeToEdit: theme,
																					themeToEditIndex: themeIndex,
																					themeFormModalIsOpen: true,
																				}
																			});
																		}}
																	>{i18next.t("Edit")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		eventKey="delete"
																		onClick={(event) => {
																			this.themes.del(theme, themeIndex);
																		}}
																	>{i18next.t("Hide")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		eventKey="up"
																		onClick={(event) => {
																			event.stopPropagation();
																			this.themes.moveUp(themeIndex);
																		}}
																	>{i18next.t("Move up")}</Dropdown.Item>
																	
																	<Dropdown.Item
																		eventKey="down"
																		onClick={(event) => {
																			event.stopPropagation();
																			this.themes.moveDown(themeIndex);
																		}}
																	>{i18next.t("Move down")}</Dropdown.Item>
																	
																	{(user.can('copyMaterials')) &&
																		<Dropdown.Item
																			eventKey="down"
																			onClick={(event) => {
																				event.stopPropagation();
																				this.themes.copy(theme);
																			}}
																		>{i18next.t("Copy to")}...</Dropdown.Item>
																	}
																
																</DropdownButton>
															
															)}
														
														</div>
													
													);
													
												})}
											
											</div>
										
										)}
									
									</div>
								
								) : (
									
									<div className={'list'}>
										
										<div className="controls">
											
											<div className="list-item list-item-disabled">
												<div className="content">
													{i18next.t("Select lesson")}
												</div>
											</div>
										
										</div>
									
									</div>
								
								)}
							
							</section>
						
						</div>
					
					</div>
				
				)}
				
				<div className="modals">
					
					<Modal
						className={'course-form-modal'}
						show={this.state.courseFormModalIsOpen}
						onHide={this.courses.toggleFormModal}
						size={'lg'}
						backdrop={'static'}
						keyboard={false}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.courseToEdit
									? i18next.t("Edit course") + ' #' + this.state.courseToEdit.id
									: i18next.t("New course")
								}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							<CourseForm
								model={this.state.courseToEdit}
								afterCreate={this.courses.afterCreate}
								afterUpdate={this.courses.afterUpdate}
								cancel={() => {
									this.setState((prevState) => {
										return {
											courseFormModalIsOpen: false,
										}
									});
								}}
								preloader={this.props.preloader}
								deps={this.state.deps}
								departmentId={this.state.currentDep ? this.state.currentDep.id : null}
							/>
						</Modal.Body>
					
					</Modal>
					
					<Modal
						className={'lesson-form-modal'}
						show={this.state.lessonFormModalIsOpen}
						onHide={this.lessons.toggleFormModal}
						size={'lg'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.currentLesson
									? i18next.t("Edit lesson #{{id}}", {"id": this.state.currentLesson.id})
									: i18next.t("New lesson")
								}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							<LessonForm
								model={this.state.lessonToEdit}
								courseId={this.state.currentCourse ? this.state.currentCourse.id : null}
								afterCreate={this.lessons.afterCreate}
								afterUpdate={this.lessons.afterUpdate}
								cancel={() => {
									this.setState((prevState) => {
										return {
											lessonFormModalIsOpen: false,
										}
									});
								}}
								preloader={this.props.preloader}
							/>
						</Modal.Body>
					
					</Modal>
					
					<Modal
						className={'theme-form-modal'}
						show={this.state.themeFormModalIsOpen}
						onHide={this.themes.toggleFormModal}
						size={'lg'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.themeToEdit
									? i18next.t("Edit theme #{{id}}", {"id": this.state.themeToEdit.id})
									: i18next.t("New theme")}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							<ThemeForm
								model={this.state.themeToEdit}
								lessonId={this.state.currentLesson ? this.state.currentLesson.id : null}
								afterCreate={this.themes.afterCreate}
								afterUpdate={this.themes.afterUpdate}
								cancel={() => {
									this.setState((prevState) => {
										return {
											themeFormModalIsOpen: false,
										}
									});
								}}
								preloader={this.props.preloader}
							/>
						</Modal.Body>
					
					</Modal>
					
					{(courseToPreview && courseToPreview.hasPreviewInfo()) &&
						
						<Modal
							className={'course-preview-modal'}
							show={this.state.coursePreviewModalIsOpen}
							onHide={this.courses.togglePreviewModal}
							size={'lg'}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{i18next.t("Course")} &laquo;{courseToPreview.name}&raquo;
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
								
								<div className="course-preview">
									
									{(courseToPreview.videos_urls) &&
										<div className="videos mb-3">
											{YouTubeHelper.widget(courseToPreview.videos_urls, '100%', 400)}
										</div>
									}
									
									{(courseToPreview.about) &&
										<div
											className={'about'}
											dangerouslySetInnerHTML={{__html: courseToPreview.about}}
										/>
									}
									
									{/*{(courseToPreview.imgs.length > 0) &&
                                        <div className="imgs mb-3">
                                            <UploadsList
                                                uploads={courseToPreview.imgs}
                                            />
                                        </div>
                                    }*/}
									
									{courseToPreview.imgs.length > 0 && (
										<div className={'imgs media-list row mb-3'}>
											{courseToPreview.imgs.map((uploadBindData, index) => {
												return (
													<div className={'media-box img-box col'}>
														<a href={uploadBindData.url}
														   className={'img-link'}
														   target={'_blank'}
														   key={'img_' + index}
														>
															<img src={uploadBindData.preview_url}
																 alt="img"
																 onClick={(event) => {
																	 // todo
																 }}
																 className={[
																	 'clickable',
																 ].join(' ')}
															/>
														</a>
														{(uploadBindData.name) &&
															<>
																<br/><small
																className={'upload-name text-muted'}>{uploadBindData.name}</small>
															</>
														}
													</div>
												);
											})}
										</div>
									)}
									
									{(courseToPreview.docs.length > 0) &&
										<div className="docs">
											<UploadsList
												uploads={courseToPreview.docs}
											/>
										</div>
									}
								
								</div>
							
							</Modal.Body>
						
						</Modal>
						
					}
				
				</div>
			
			</div>
		
		);
	}
	
}

CoursesPage.propTypes = {
	preloader: PropTypes.object,
	breadcrumbs: PropTypes.object,
	user: PropTypes.instanceOf(User),
	alert: PropTypes.object,
	skins: PropTypes.array,
};

CoursesPage.defaultProps = {
	skins: [],
};