import React from "react";
import YouTubeHelper from "../helpers/YouTubeHelper";
import i18next from "i18next";
import Question from "./Question";
import Answer from "./Answer";
import Logger from "../helpers/Logger";
import MaterialGroupBind from "./MaterialGroupBind";
import Utils from "../helpers/Utils";
import { number } from "prop-types";
import Config from "../helpers/Config";

export default class Material {
	
	static TYPE_ALIAS_THEORY = 'theory';
	static TYPE_ALIAS_TEST = 'test';
	static TYPE_ALIAS_HOMEWORK = 'homework';
	static TYPE_ALIAS_EXAM = 'exam';
	
	static TYPE_ALIAS_TRAINER_GROUP = 'trainer_group';
	static TYPE_ALIAS_TRAINER_TEST = 'trainer_test';
	static TYPE_ALIAS_TRAINER_TEST_PART = 'trainer_test_part';
	
	static uploadAttrs = [
		'images',
		'sounds',
		'files',
		'videos',
		'icons',
		'hidden_images',
	];
	
	static jsonAttrs = [
		'video_links',
		'data',
	];
	
	static likeSearchAttrs = [
		'name',
		'about',
		'youtube_urls',
	];
	
	constructor(data = {}) {
		
		this.id = data.id;
		
		this.name = data.name || '';
		this.about = data.about || '';
		this.youtube_urls = data.youtube_urls || '';
		
		this.created_at = data.created_at;
		this.created_by = data.created_by;
		
		this.is_active = data.is_active === undefined ? 1 : data.is_active;
		
		this.images = data.images || [];
		this.sounds = data.sounds || [];
		this.files = data.files || [];
		this.videos = data.videos || [];
		this.icons = data.icons || [];
		this.hidden_images = data.hidden_images || [];
		
		// this.theme_id = data.theme_id;
		this.theme_id = data.theme_id || '';
		
		this.type_alias = data.type_alias;
		
		// this.questions = data.questions || [];
		this.questions = Array.isArray(data.questions) ? data.questions.map((questionData) => {
			return new Question(questionData);
		}) : [];
		
		this.answers = Array.isArray(data.answers) ? data.answers.map((answerData) => {
			return new Answer(answerData);
		}) : [];
		
		this.children = Array.isArray(data.children) ? data.children.map((childData) => {
			return new Material(childData);
		}) : [];
		
		// this.children = data.children || [];
		
		this.progress = data.progress || 0;
		
		/*{
			max: null,
			user: null,
		}*/
		this.progress_points = data.progress_points;
		
		this.is_exam = data.is_exam || 0;
		this.is_weight_visible = data.is_weight_visible || 0;
		this.is_public = data.is_public || 0;
		this.is_del = data.is_del || 0;
		this.is_trainer = data.is_trainer || 0;
		this.parent_id = data.parent_id || '';
		this.latest_score = data.latest_score || {value: 0};
		this.biggest_score = data.biggest_score || {value: 0};
		this.max_score_value = data.max_score_value;
		this.current_score_value = data.current_score_value;
		this.copy_of_id = data.copy_of_id;
		
		this.groups_binds = data.groups_binds
			? data.groups_binds.map(data => new MaterialGroupBind(data))
			: [];
		
		this.has_details = data.has_details || 0;
		
		this.questions_page_size = data.questions_page_size;
		
		this.custom_errors = data.custom_errors || [];
		
		this.errors = data.errors || [];
		
		this.theme_id = data.theme_id;
		this.theme_name = data.theme_name;
		
		this.lesson_id = data.lesson_id;
		this.lesson_name = data.lesson_name;
		
		this.course_id = data.course_id;
		this.course_name = data.course_name;
		
		// this.video_links = typeof data.video_links === 'string' 
		// 	? JSON.parse(data.video_links) 
		// 	: data.video_links
		// 		? data.video_links
		// 		: []
		
		// this.video_links = []
		
		this.video_links = Utils.parseJson(data.video_links, []) || []
		this.restricted_access = data.restricted_access
		this.variants = Array.isArray(data.variants) ? data.variants.map(variantData => new Question(variantData)) : []
		this.course_department_id = data.course_department_id
		
		// динамическая индивидуальная самостоятельная работа
		this.is_dynamic = data.is_dynamic
		
		this.data = data.data ? Utils.parseJson(data.data, {}) : {}
		
		this.dynamic = data.dynamic 
			? new Material(data.dynamic)
			: null
		
		this.questions_count = data.questions_count
		
	}
	
	getYouTubeVideoPreviewLink = (linkClassName, imgStyle) => {
		
		let url = this.youtube_urls;
		
		if (!url) {
			return '';
		}
		
		let videoId = YouTubeHelper.videoId(url);
		let previewImageUrl = YouTubeHelper.previewImageUrl(videoId);
		
		return (
			<a className={linkClassName}
			   href={url}
			   target={'_blank'}
			>
				<img
					src={previewImageUrl}
					alt="youtube video preview image"
					style={imgStyle}
				/>
			</a>
		);
	};
	
	hasContent = () => {
		return this.about
			|| this.youtube_urls
			|| this.images.length > 0
			|| this.sounds.length > 0
			|| this.files.length > 0
			|| this.videos.length > 0
			|| this.video_links.length > 0
		;
	};
	
	hasQuestions = () => {
		return this.questions.length > 0;
	};
	
	getRightAnswers = () => {
		return this.answers.filter(answer => answer.is_right);
	};
	
	getProgress = () => {
		
		if (this.progress !== null) {
			return this.progress;
		}
		
		// todo optimize
		
		let questionsCount = this.questions.length;
		
		// let rightAnswersCount = this.getRightAnswers().length;
		let rightAnswersCount = 0;
		
		this.questions.forEach((question) => {
			let latestAnswer = question.latest_answer;
			if (latestAnswer) {
				if (latestAnswer.is_right == 1) {
					rightAnswersCount++;
				}
			}
		});
		
		return Math.ceil(rightAnswersCount / questionsCount * 100);
		
	};
	
	getNameForTeacher = () => {
		return this.isTest()
			? this.questions[0].name
			: this.name;
	}
	
	isTheory = () => {
		return this.type_alias === Material.TYPE_ALIAS_THEORY;
	};
	
	isTest = () => {
		return this.type_alias === Material.TYPE_ALIAS_TEST;
	};
	
	isExam = () => {
		// return this.type_alias === Material.TYPE_EXAM_ALIAS;
		return this.is_exam == 1;
	};
	
	getTypeName = () => {
		return Material.getTypes()[this.type_alias];
	};
	
	getIcon = () => {
		return this.icons[0];
	};
	
	static getTypes(asArray = false) {
		
		let viewTypes = {};
		
		viewTypes[Material.TYPE_ALIAS_THEORY] = i18next.t("Теория");
		viewTypes[Material.TYPE_ALIAS_TEST] = i18next.t("Группа заданий");
		viewTypes[Material.TYPE_ALIAS_EXAM] = i18next.t("Test");
		
		if (asArray) {
			return Object.getOwnPropertyNames(viewTypes).map((alias) => {
				return {
					alias: alias,
					name: viewTypes[alias],
				};
			});
		}
		
		return viewTypes;
	}
	
	isCompleted = () => {
		
		const logName = 'Material.isCompleted';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let x = true;
		
		this.questions.forEach((question) => {
			
			Logger.log(question, 'question', logAllow);
			
			Logger.log(question.latest_answer, 'question.latest_answer', logAllow);
			
			if (!question.latest_answer) {
				x = false;
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
		return x;
		
	};
	
	getMaxScoreValue = () => {
		
		let maxScore = 0;
		
		this.questions.forEach((question) => {
			maxScore += question.weight;
		});
		
		return maxScore;
		
	};
	
	getScoreValue = () => {
		
		let score = 0;
		
		this.questions.forEach((question) => {
			if (question.latest_answer && question.latest_answer.is_right) {
				score += question.weight;
			}
		});
		
		return score;
		
	};
	
	getStarsCount = (scoreValue) => {
		
		const logName = 'Material.getStarsCount';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let stars = 0;
		
		scoreValue = scoreValue === undefined ? this.getScoreValue() : scoreValue;
		Logger.log(scoreValue, 'scoreValue', logAllow);
		
		let maxScore = this.getMaxScoreValue();
		Logger.log(maxScore, 'maxScore', logAllow);
		
		let percent = Math.ceil(scoreValue / maxScore * 100);
		Logger.log(percent, 'percent', logAllow);
		
		if (percent > 0) {
			stars++;
		}
		
		if (percent > 40) {
			stars++;
		}
		
		if (percent > 80) {
			stars++;
		}
		
		Logger.log(stars, 'stars', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return stars;
		
	};
	
	getRank = (starsCount) => {
		
		let data = {
			0: i18next.t("Try again"),
			1: i18next.t("Try again"),
			2: i18next.t("Good work"),
			3: i18next.t("Excellent!"),
		};
		
		starsCount = starsCount === undefined ? this.getStarsCount() : starsCount;
		
		return data[starsCount];
		
	};
	
	hasAccess = (groupsIds) => {
		
		const logName = `Material[${this.id}].hasAccess`;
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let out = true;
		
		Logger.log(groupsIds, 'groupsIds', logAllow);
		
		Logger.log(this.id, 'this.id', logAllow);
		
		Logger.log(this, 'this', logAllow);
		
		Logger.log(this.restricted_access, 'this.restricted_access', logAllow);
		
		if (this.restricted_access == 1) {
			
			out = false;
			
			Logger.log(this.groups_binds, 'this.groups_binds', logAllow);
		
			let groupsBinds = this.groups_binds.filter(bind => groupsIds.includes(bind.group_id));
			Logger.log(groupsBinds, 'groupsBinds', logAllow);
			
			let currentUnixTime = Date.now();
			Logger.log(currentUnixTime, 'currentUnixTime', logAllow);
			
			groupsBinds.forEach((groupBind) => {
				
				let startUnixtime = parseInt(groupBind.access_start_unixtime) * 1000;
				Logger.log(startUnixtime, 'startUnixtime', logAllow);
				
				let endUnixTime = parseInt(groupBind.access_end_unixtime) * 1000;
				Logger.log(endUnixTime, 'endUnixtime', logAllow);
				
				if (currentUnixTime > startUnixtime) {
					out = true;
				}
				
				if (endUnixTime) {
					if (currentUnixTime > endUnixTime) {
						out = false;
					}
				}
				
			});
			
		}
		
		Logger.log(out, 'out', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return out;
		
	};
	
	getViewableQuestions = () => {
		
		let out = [];
		
		this.questions.forEach((question) => {
			
			if (!question.isGroup() || question.group_items.length > 0) {
				out.push(question);
			}
			
		});
		
		return out;
		
	}
	
	getGroups = () => {
		return this.questions.filter(x => x.isGroup());
	};
	
	getVariants = () => {
		return this.variants
	}
	
	getGroupsBindsWithLimitedAccess = (groupsIds) => {
		
		let out = false

		let groupsBinds = this.groups_binds
		
		let groupsBindsWithLimitedAccess = groupsBinds.filter((x) => {
			
			if (groupsIds) {
				
				let ok = groupsIds.includes(x.group_id)
				
				if (!ok) {
					return false
				}
				
			}
			
			return x.access_end != null
			
		})
		
		return groupsBindsWithLimitedAccess
		
	}
	
	hasLimitedAccess = (groupsIds) => {
		
		let groupsBindsWithLimitedAccess = this.getGroupsBindsWithLimitedAccess(groupsIds)
		
		return groupsBindsWithLimitedAccess.length > 0
		
	}
	
	getPath = (glue = ' / ') => {
		return [
			this.course_name,
			this.lesson_name,
			this.theme_name,
		].join(glue)
	}
	
	// настроить всё так, чтобы при открытии страницы /courses автоматически перейти к данному материалу
	setAsTarget = () => {
		
		localStorage.setItem(Config.coursesCurrentDepartmentIdKey, this.course_department_id)
		localStorage.setItem(Config.coursesCurrentCourseIdKey, this.course_id)
		localStorage.setItem(Config.coursesCurrentLessonIdKey, this.lesson_id)
		localStorage.setItem(Config.coursesCurrentThemeIdKey, this.theme_id)
		
		Config.setMaterialId(this.theme_id, this.id)
		
	}
	
	// dynamic data
	
	getDynamicThemeQuestionsCountMap = () => {
		return this.data?.dynamic?.questionsCountThemeMap
	}
	
	getDynamicThemeQuestionsCount(themeId) {
		let map = this.getDynamicThemeQuestionsCountMap()
		return map ? map[themeId] : null
	}
	
	setDynamicThemeQuestionsCount = (themeId, questionsCount) => {
		
		let map = this.getDynamicThemeQuestionsCountMap()
		
		if (!map) {
			
			this.data = {
				dynamic: {
					questionsCountThemeMap: {}
				}
			}
			
			map = this.getDynamicThemeQuestionsCountMap()
			
		}
		
		map[themeId] = questionsCount
		
		return map
		
	}
	
	getQuestions = (variantId) => {
		
		let questions = []
		
		if (this.is_dynamic == 1) {
			
			questions = this.dynamic && Array.isArray(this.dynamic.questions)
				? this.dynamic.questions
				: []
				
		} else if (variantId) {
			
			let variants = this.variants
			
			let variant = variants.find(x => x.id == variantId)
			
			if (variant) {
				
				questions = Array.isArray(variant.children)
					? variant.children
					: []
					
			}
			
		} else {
			
			questions = Array.isArray(this.questions)
				? this.questions
				: []
			
		}
		
		return questions
			
	}
	
	// получить отчёты об автоматическом отключении нежелательных шаблонов ответов
	getDisableUnwantedUseAnswerTemplatesData = (latestOnly = 0, touchedQuestionsCountOnly = 0) => {
		
		// вынимаем отчёты из общей кучи
		let data = this.data?.disable_unwanted_use_answer_templates
		
		// если нужен только последний отчёт
		if (data && latestOnly) {
			
			// оставляем только последний отчёт
			data = data.slice(-1)[0]
			
			// если нужно только количество затронутых ответов
			if (data && touchedQuestionsCountOnly) {
				
				let touchedQuestionsIds = data['touched_questions_ids']
				
				if (Array.isArray(touchedQuestionsIds)) {
					data = touchedQuestionsIds.length
				}
				
			}
			
		}
		
		return data
		
	}
	
}