import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {MathJaxContext} from "better-react-mathjax";

import './i18n';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import "./utils.css";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<MathJaxContext
				config={{
					enableMenu: false,
					loader: {
						load: [
							"input/asciimath",
							"output/svg",
						],
					},
					jax: [
						"input/asciimath",
						"output/svg",
					],
					chtml: {
						scale: 1,
					}
				}}
			>
				<App/>
			</MathJaxContext>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
