import React from 'react';
import PropTypes from 'prop-types';
import UploadName from "./UploadName";

export default class UploadsList extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		return (
			<div className={'UploadsList files-box list-group'}>
				{this.props.uploads.map((uploadBindData) => {
					let url = this.props.originalNames && uploadBindData.url_with_original_name
						? uploadBindData.url_with_original_name : uploadBindData.url;
					return (
						<a href={url}
						   target={'_blank'}
						   className={[
							   'list-group-item',
							   'list-group-item-action',
						   ].join(' ')}
						   download={uploadBindData.original_name}
						><UploadName uploadBindData={uploadBindData} useCustomFileName={true}/></a>
					);
				})}
			</div>
		);
	}
	
}

UploadsList.propTypes = {
	uploads: PropTypes.arrayOf(PropTypes.object),
	originalNames: PropTypes.bool,
};

UploadsList.defaultProps = {
	uploads: [],
	originalNames: true,
};