import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from "react-bootstrap/Dropdown";
import i18next from "i18next";

import "./LangMenu.css";

export default class LangMenu extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const currentLangAlias = i18next.language;
		const currentLangData = this.props.langs[currentLangAlias];
		
		return (
			
			<div className="LangMenu lang-menu">
				
				<Dropdown
					drop={'up'}
				>
					
					<Dropdown.Toggle
						variant={'light'}
						// className={'btn-unstyled'}
						// title={i18next.t("Текущий язык")  +  ': ' + currentLangData.name}
						title={i18next.t("Select language")}
					>
						<img
							className={'lang-icon'}
							src={currentLangData.img}
							alt={'lang-' + currentLangData}
						/>
						{(this.props.showCurrentLangName) &&
							<span className={'lang-name'}>{currentLangData.name}</span>
						}
					</Dropdown.Toggle>
					
					<Dropdown.Menu>
						{Object.getOwnPropertyNames(this.props.langs).map((langAlias) => {
							if (langAlias == currentLangAlias) {
								return;
							}
							let langData = this.props.langs[langAlias];
							return (
								<Dropdown.Item
									key={'lang-menu-item_' + langAlias}
									onClick={(event) => {
										this.props.changeLang(langAlias);
									}}
								>
									<div>
										<img src={langData.img} alt={'lang-img'} className={'lang-icon'}/>
										<span className={'lang-name'}>{langData.name}</span>
									</div>
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				
				</Dropdown>
			
			</div>
		
		);
	}
	
}

LangMenu.propTypes = {
	
	langs: PropTypes.object.isRequired,
	
	direction: PropTypes.oneOf(['up', 'down']),
	showCurrentLangName: PropTypes.bool,
	changeLang: PropTypes.func,
	
};

LangMenu.defaultProps = {
	direction: 'up',
	showCurrentLangName: true,
	changeLang: (langAlias) => {
		i18next.changeLanguage(langAlias);
	}
};