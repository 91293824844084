import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import axios from "axios";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import Logger from "../../helpers/Logger";
import "./UploadsGrid.css";
// import Icon from "react-images-viewer/src/components/Icon";
import * as Icon from 'react-bootstrap-icons';
import UploadName from "./UploadName";

export default class UploadsGrid extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			uploads: props.uploads,
		};
	}
	
	upd = (uploadBindId, data, useConfirm = 1, uploadBindIndex) => {
		
		if (useConfirm && !window.confirm(i18next.t("Are you shure?"))) {
			return;
		}
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'put',
			url: Utils.apiUrl('upload-binds/' + uploadBindId),
			params: {
				'accessToken': Utils.getUserToken(),
			},
			data: data,
		}).then((response) => {
			
			const logName = 'UploadsGrid.upd.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			if (this.props.afterUpd) {
				this.props.afterUpd(response, uploadBindId, data, uploadBindIndex);
			}
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			// todo catch
		});
		
	};
	
	saveSort = (array) => {
		
		const logName = 'UploadsGrid.saveSort';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		let ids = [];
		
		array.forEach((item) => {
			ids.push(item.id);
		});
		
		axios({
			method: 'post',
			url: Utils.apiUrl('upload-binds/save-sort'),
			data: {
				ids: ids,
			},
			params: {
				'accessToken': Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'UploadsGrid.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Utils.axiosErrorAlert(error);
			
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	moveUp = (currentIndex) => {
		
		const logName = 'UploadsGrid.moveUp';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(currentIndex, 'currentIndex', logAllow);
		
		let array = this.state.uploads;
		Logger.log(array, 'array', logAllow);
		
		Utils.arrayMoveUp(array, currentIndex);
		Logger.log(array, 'array', logAllow);
		
		this.saveSort(array);
		
		Logger.groupEnd(logAllow);
		
	};
	
	moveDown = (currentIndex) => {
		
		const logName = 'UploadsGrid.moveDown';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let array = this.state.uploads;
		
		Utils.arrayMoveDown(array, currentIndex);
		
		this.saveSort(array);
		
		Logger.groupEnd(logAllow);
		
	};
	
	render() {
		
		return (
			
			<div className={'UploadsGrid'}>
				
				<div className={'row'}>
					
					{this.state.uploads.map((uploadBindData, index) => {
						
						const logName = 'UploadsGrid.uploads.map';
						const logAllow = 0;
						const logCollapsed = 0;
						
						Logger.groupStart(logName, logAllow, logCollapsed);
						
						Logger.log(uploadBindData, 'uploadBindData', logAllow);
						
						if (!uploadBindData.active) {
							return '';
						}
						
						Logger.groupEnd(logAllow);
						
						return (
							
							<div key={'upload_' + uploadBindData.id} className={'col-12 upload-col'}>
								
								<div className="upload-box">
									
									<div className="upload-name-box">
										<a
											className={'upload-name'}
											href={uploadBindData.url}
											target={'_blank'}
											title={uploadBindData.original_name}
										>{index + 1}. <UploadName uploadBindData={uploadBindData}
																  useCustomFileName={false}/></a>
									</div>
									
									{uploadBindData.type === 'image' && (
										<div className="preview-box">
											<a className="preview-link" href={uploadBindData.url} target={'_blank'}>
												<img
													className={'img-preview'}
													src={uploadBindData.preview_url}
													alt={'img-preview'}
												/>
											</a>
										</div>
									)}
									
									{/*{uploadBindData.type === 'audio' && ['video/mp4'].indexOf(uploadBindData.mime) && (*/}
									{uploadBindData.type === 'audio' && (
										<div className="preview-box">
											<audio src={uploadBindData.url} controls={true} className={'preview'}/>
										</div>
									)}
									
									{/*{uploadBindData.type === 'video' && ['video/mp4', 'video/webm', 'video/ogg'].indexOf(uploadBindData.mime_type) >= 0 && (*/}
									{uploadBindData.type === 'video' && (
										<div className="preview-box">
											<video src={uploadBindData.url} controls={true} className={'preview'}/>
										</div>
									)}
									
									{['application/pdf'].indexOf(uploadBindData.mime_type) >= 0 && (
										<div className="preview-box">
											<iframe src={uploadBindData.url} className={'preview'}/>
										</div>
									)}
									
									{this.props.showControls && (
										
										<div className="controls">
											
											<input id={'name'}
												   type="text"
												   className={'form-control'}
												   onChange={(event) => {
													   let input = event.target;
													   let value = input.value;
													   uploadBindData.name = value;
													   this.setState((prevState) => {
														   return {
															   uploads: this.state.uploads,
														   }
													   });
													   // this.upd(upload.id, {name: value}, 0);
												   }}
												   required={false}
												   placeholder={i18next.t("Подпись")}
												   value={uploadBindData.name}
											/>
											
											<div className="btns-box">
												
												<button type={'button'}
														className={[
															'my-btn',
															'my-btn-sm',
															'my-btn-primary',
														].join(' ')}
														onClick={(event) => {
															this.upd(uploadBindData.id, {name: uploadBindData.name}, 0, index);
														}}
												>{i18next.t("Save")}</button>
												
												<button type={'button'}
														className={[
															'my-btn',
															'my-btn-sm',
															'my-btn-danger',
														].join(' ')}
														onClick={(event) => {
															this.upd(uploadBindData.id, {active: 0}, 1, index);
														}}
												>{i18next.t("Hide")}</button>
												
												<button type={'button'}
														className={[
															'my-btn',
															'my-btn-sm',
															// 'my-btn-danger',
														].join(' ')}
														onClick={(event) => {
															this.moveUp(index);
														}}
												><Icon.ArrowUp/></button>
												
												<button type={'button'}
														className={[
															'my-btn',
															'my-btn-sm',
															// 'my-btn-danger',
														].join(' ')}
														onClick={(event) => {
															this.moveDown(index);
														}}
												><Icon.ArrowDown/></button>
											
											</div>
										
										</div>
									
									)}
								
								</div>
							
							</div>
						
						);
						
					})}
				
				</div>
			
			</div>
		
		);
	}
	
}

UploadsGrid.propTypes = {
	uploads: PropTypes.array.isRequired,
	afterUpd: PropTypes.func,
	showControls: PropTypes.bool,
	preloader: PropTypes.object,
};

UploadsGrid.defaultProps = {
	// uploads: [],
};