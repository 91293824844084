export default class Formatter {

    static dateDefaultFormat = 'd.m.y';
    static dateInputFormat = 'y-m-d';

    static timeDefaultFormat = 'h:m';

    static date = (date, format=this.dateDefaultFormat) => {

        date = new Date(date);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }

        if (month < 10) {
            month = '0' + month;
        }

        let formattedDate = format;

        formattedDate = formattedDate.replace(/d/, day.toString());
        formattedDate = formattedDate.replace(/m/, month.toString());
        formattedDate = formattedDate.replace(/y/, year.toString());

        return formattedDate;

    };

    static time = (time=Date.now(), format=Formatter.timeDefaultFormat) => {

        time = new Date(time);

        let hours = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        let formattedTime = format;

        formattedTime = formattedTime.replace(/h/, hours);
        formattedTime = formattedTime.replace(/m/, minutes);
        formattedTime = formattedTime.replace(/s/, seconds);

        return formattedTime;

    };

    static djangoTime = (djangoTime, format=this.timeDefaultFormat) => {

        let djangoTimeData = djangoTime.split('.');
        let milliseconds = djangoTimeData[1];
        djangoTimeData = djangoTimeData[0].split(':');

        let hours = djangoTimeData[0];
        let minutes = djangoTimeData[1];
        let seconds = djangoTimeData[2];

        // hours = hours < 10 ? '0' + hours : hours;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        // seconds = seconds < 10 ? '0' + seconds : seconds;

        let formattedTime = format;

        formattedTime = formattedTime.replace(/h/, hours);
        formattedTime = formattedTime.replace(/m/, minutes);
        formattedTime = formattedTime.replace(/s/, seconds);
        formattedTime = formattedTime.replace(/ms/, milliseconds);

        return formattedTime;

    };

    static djangoDateTime = (djangoDateTime, dateFormat=this.dateDefaultFormat, timeFormat=this.timeDefaultFormat) => {
        // django datetime example:
        // 2020-09-02T23:22:27.342110+03:00

        let data = djangoDateTime.split('T');
        let date = data[0];
        data = data[1].split('+');
        let djangoTime = data[0];
        let timezone = data[1];

        let dateFormatted = this.date(date, dateFormat);
        let timeFormatted = this.djangoTime(djangoTime, timeFormat);

        return `${dateFormatted} ${timeFormatted}`;
    };

}