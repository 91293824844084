import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import LangMenu from "./LangMenu";

import "./MobileMenu.css";

export default class MobileMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // todo ajax
    }

    render() {

        const logo = <img src={this.props.logo} alt={'logo'}/>;

        return (

            <div className={'MobileMenu'}>

                <div
                    className="backdrop"
                    onClick={this.props.toggle}
                ></div>

                <div className="content">

                    <div className="container">

                        {(this.props.closeIcon) &&
                            <img
                                className={'close-btn mobile-menu-close-btn clickable'}
                                src={this.props.closeIcon}
                                onClick={this.props.toggle}
                                alt={'close mobile menu btn'}
                            />
                        }

                        {(this.props.logo) &&
                            <div className={'logo-box'}>
                                {this.props.logoUrl ? (
                                    <a
                                        className={'logo-link'}
                                        href={this.props.logoUrl}
                                        target={'_blank'}
                                    >{logo}</a>
                                ) : (
                                    <>{logo}</>
                                )}

                            </div>
                        }

                        <div className="items">
                            {this.props.items.map((item) => {
                                let url = item.url || '/' + item.alias;
                                let isCurrent = item.alias === this.props.asideItemsCurrentAlias;
                                return (
                                    // <div className={'item list-item'}>
                                    <>
                                        {item.external ? (
                                            <a
                                                key={'aside-item_' + item.alias}
                                                href={item.url}
                                                className={[
                                                    'aside-control',
                                                    item.alias,
                                                    isCurrent ? 'current' : '',
                                                    'item',
                                                    'list-item',
                                                ].join(' ')}
                                                target={'_blank'}
                                                title={item.name}
                                            >{item.name}</a>
                                        ) : (
                                            <Link
                                                key={'aside-item_' + item.alias}
                                                className={[
                                                    'aside-control',
                                                    item.alias,
                                                    isCurrent ? 'current' : '',
                                                    'item',
                                                    'list-item',
                                                ].join(' ')}
                                                to={url}
                                                title={item.name}
                                                onClick={(event) => {
                                                    this.props.toggle();
                                                }}
                                            >
                                                {(item.icon) &&
                                                    <img
                                                        className={'icon'}
                                                        src={item.icon}
                                                        alt={item.alias + '_icon'}
                                                    />
                                                }
                                                {item.name}
                                                {(item.badgeText) &&
                                                    <sup className={'badge'}>
                                                        {item.badgeText}
                                                    </sup>
                                                }
                                            </Link>
                                        )}
                                    </>
                                    // </div>
                                );
                            })}
                        </div>

                        <div className="bottom">

                            <div className="row align-items-center">

                                <div className="col-6 lang-col">
                                    <LangMenu
                                        langs={this.props.langs}
                                    />
                                </div>

                                <div className="col-6 info-col">
                                    {(this.props.infoIcon) &&
                                        <img
                                            className={'info-btn clickable'}
                                            src={this.props.infoIcon}
                                            alt="info_btn_icon"
                                        />
                                    }
                                </div>

                            </div>

                            {/*<div className="text-center">
                                <LangMenu
                                    langs={this.props.langs}
                                />
                            </div>*/}

                        </div>

                    </div>

                </div>

            </div>

        );

    }

}

MobileMenu.propTypes = {

    logo: PropTypes.any,
    logoUrl: PropTypes.string,
    items: PropTypes.array,
    currentItemAlias: PropTypes.string,
    toggle: PropTypes.func,
    langs: PropTypes.object,

    closeIcon: PropTypes.string,
    infoIcon: PropTypes.string,

};

MobileMenu.defaultProps = {};