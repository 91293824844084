import React from 'react';
import axios from "axios";
import i18next from "i18next";
import PropTypes from 'prop-types';

import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import Course from "../../models/Course";
import UploadsGrid from "./UploadsGrid";
import FormDataHelper from "../../helpers/FormDataHelper";
import YouTubeHelper from "../../helpers/YouTubeHelper";
import AcceptedFilesList from "./AcceptedFilesList";
import Rand from "../../helpers/Rand";
import Department from "../../models/Department";

import {Editor} from "@tinymce/tinymce-react";


export default class CourseForm extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Course();
		
		if (!model.id) {
			
			model.is_active = 1;
			model.sort = 999;
			
			model.department_id = props.departmentId;
			
			if (Config.allowFormFake()) {
				model.name = '__TEST__ ' + new Date().toLocaleString();
			}
			
		}
		
		this.state = {
			model: model,
		};
		
		this.nameInputRef = new React.createRef();
		this.aboutTinyEditorRef = new React.createRef();
		
		// << UPLOADS REFS
		
		this.uploadRefs = {};
		
		Course.uploadAttrs.forEach((attr) => {
			this.uploadRefs[attr] = React.createRef();
		});
		
		// >> UPLOADS REFS
		
	}
	
	uploads = {
		
		del: (uploadBindId) => {
			
			const logName = 'CourseForm.images.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!window.confirm(i18next.t("Are you shure?"))) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('upload-binds/' + uploadBindId),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				data: {
					'active': 0,
				},
			}).then((response) => {
				
				const logName = 'CourseForm.images.del';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let newUpload = response.data;
				let newModel = this.state.model;
				
				Course.uploadAttrs.forEach((attr) => {
					newModel[attr].forEach((image, index) => {
						if (image.id == uploadBindId) {
							newModel.images[index] = newUpload;
						}
					});
				});
				
				this.setState((prevState) => {
					return {
						model: newModel,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				// todo catch
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpd: (response, id, data) => {
			
			let newUpload = response.data;
			let newModel = this.state.model;
			
			Course.uploadAttrs.forEach((attr) => {
				newModel[attr].forEach((upload, index) => {
					if (upload.id == id) {
						newModel[attr][index] = newUpload;
					}
				});
			});
			
			this.setState((prevState) => {
				return {
					model: newModel,
				}
			});
			
		},
		
	};
	
	inputChange = (event) => {
		
		const logName = 'CourseForm.inputChange';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		const target = event.target;
		Logger.log(target, 'target', logAllow);
		
		const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
		Logger.log(value, 'value', logAllow);
		
		const attr = target.getAttribute('id');
		Logger.log(attr, 'attr', logAllow);
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	submit = (event) => {
		
		const logName = 'CourseForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		event.preventDefault();
		
		const model = this.state.model;
		const form = event.currentTarget;
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const formData = FormDataHelper.get(model, [
				'id',
				'progress',
				'created_by',
				'copy_of_id',
			], this.uploadRefs);
			
			// const formDataValues = FormDataHelper.getValues(formData);
			// Logger.log(formDataValues, 'formDataValues', logAllow);
			
			axios({
				method: model.id ? 'put' : 'post',
				url: Utils.apiUrl('courses') + (model.id ? '/' + model.id : ''),
				data: formData,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'CourseForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.model) {
					this.props.afterUpdate(response);
				} else {
					this.props.afterCreate(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	submit__v1 = (event) => {
		
		const logName = 'CourseForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		event.preventDefault();
		
		const form = event.currentTarget;
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let model = {...this.state.model};
			let excludedAttrs = ['id', 'progress', 'created_by'];
			
			excludedAttrs.forEach((attr) => {
				delete model[attr];
			});
			
			Logger.log(model, 'model', logAllow);
			
			axios({
				method: this.props.model ? 'put' : 'post',
				url: Utils.apiUrl('courses') + (this.props.model ? '/' + this.props.model.id : ''),
				data: model,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'CourseForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.model) {
					this.props.afterUpdate(response);
				} else {
					this.props.afterCreate(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	componentDidMount() {
		this.nameInputRef.current.focus();
	}
	
	render() {
		
		const logName = 'CourseForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		const depsFlatList = Department.flatList(this.props.deps);
		
		Logger.groupEnd(logAllow);
		
		return (
			<form className={'CourseForm'} onSubmit={this.submit}>
				
				<label htmlFor={'is_active'}>
					<input
						id={'is_active'}
						type={'checkbox'}
						checked={this.state.is_active}
						onChange={this.inputChange}
					/> {i18next.t("Активно")}
				</label>
				
				<div className={'form-group department_id'}>
					<label htmlFor={'department_id'}>{i18next.t("Department")}</label>
					<select
						id={'department_id'}
						className={[
							'form-control',
							'deps-picker',
						].join(' ')}
						value={model.department_id}
						onChange={this.inputChange}
					>
						{depsFlatList.map((dep, depIndex) => {
							let prefix = Utils.pattern(dep.level, '+', ' ');
							return (
								<option value={dep.id}>{prefix} {dep.name}</option>
							);
						})}
					</select>
				</div>
				
				<div className="form-group name required">
					<label htmlFor={'name'}>{i18next.t("Title")}</label>
					<input
						id={'name'}
						type="text"
						className={'form-control'}
						value={model.name}
						onChange={this.inputChange}
						required={true}
						autoFocus={true}
						ref={this.nameInputRef}
					/>
				</div>
				
				<div className={'form-group videos_urls'}>
					
					<label htmlFor={'videos_urls'}>{i18next.t("Promo video link")}</label>
					
					<input
						id={'videos_urls'}
						name={'videos_urls'}
						type={'text'}
						className={'form-control'}
						value={model.videos_urls}
						onChange={this.inputChange}
						required={false}
					/>
					
					{model.videos_urls && (
						<div className={[
							'youtube-widget-box',
							'embed-responsive',
							'embed-responsive-16by9',
							'w-50',
							'mt-2',
						].join(' ')}>
							{YouTubeHelper.widget(model.videos_urls)}
						</div>
					)}
				
				</div>
				
				<div className="form-group about">
					<label htmlFor={'about'}>{i18next.t("About")}</label>
					{/*<textarea
						id={'about'}
						className={'form-control'}
						value={model.about}
						rows={5}
						onChange={this.inputChange}
					/>*/}
					<Editor
						id={'tinymce_' + Rand.id()}
						onInit={(evt, editor) => {
							this.aboutTinyEditorRef.current = editor;
						}}
						initialValue={model.about}
						init={Config.tinyMceModalConfig}
					/>
				</div>
				
				<fieldset className={'imgs'}>
					
					<legend>{i18next.t("Preview images")}</legend>
					
					{model && model.imgs.length > 0 && (
						<UploadsGrid
							uploads={model.imgs}
							afterUpd={this.uploads.afterUpd}
							showControls={true}
							preloader={this.props.preloader}
						/>
					)}
					
					<div className="form-group">
						<input
							id={'imgs'}
							name={'imgs'}
							type={'file'}
							accept={'image/*'}
							ref={this.uploadRefs['imgs']}
							multiple={true}
						/>
					</div>
					
					<hr/>
				
				</fieldset>
				
				<fieldset className={'docs'}>
					
					<legend>{i18next.t("Preview documents")}</legend>
					
					{model && model.docs.length > 0 && (
						<UploadsGrid
							uploads={model.docs}
							afterUpd={this.uploads.afterUpd}
							showControls={true}
							preloader={this.props.preloader}
						/>
					)}
					
					<div className={'form-group'}>
						<input
							id={'docs'}
							name={'docs'}
							type={'file'}
							accept={Config.getAcceptedFilesMimes()}
							ref={this.uploadRefs['docs']}
							multiple={true}
						/>
					</div>
					
					<AcceptedFilesList/>
					
					<hr/>
				
				</fieldset>
				
				{/*
                <div className="form-group sort">
                    <label htmlFor={'sort'}>{i18next.t("Сортировка")}</label>
                    <input
                       id={'sort'}
                       type="number"
                       className={'form-control'}
                       value={model.sort}
                       onChange={this.inputChange}
                       min={1}
                       step={1}
                    />
                </div>
                */}
				
				<div className="controls">
					<div className="row">
						<div className="col">
							{this.props.cancel && (
								<button type={'button'}
										className={'my-btn my-btn-danger'}
										onClick={this.props.cancel}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						<div className="col text-right">
							<button type={'submit'}
									className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					</div>
				</div>
			
			</form>
		);
		
	}
	
}

CourseForm.propTypes = {
	model: PropTypes.instanceOf(Course),
	afterCreate: PropTypes.func,
	afterUpdate: PropTypes.func,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
	departmentId: PropTypes.any,
	deps: PropTypes.arrayOf(PropTypes.object),
};

CourseForm.defaultProps = {
	model: null,
};