import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from "react-bootstrap-icons";
import i18next from "i18next";
import User from "../../models/User";

class MaterialControls extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const user = this.props.user
		
		const materialsManager = this.props.materialsManager
		
		return (
			
			<div className={'MaterialControls'}>
				
				<div className="list controls material-controls">
					
					{(user.can('printMaterials')) &&
						<button
							type={'button'}
							className={[
								'print-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={() => {
								window.print();
							}}
						><Icon.Printer/> {i18next.t("Print PDF")}</button>
					}
					
					{(user.can('manageMaterials') || user.can('manageMaterialsAccess')) &&
						<button
							type={'button'}
							className={[
								'my-btn',
								'my-btn-sm',
								'edit-material-btn',
								'material-edit-btn',
							].join(' ')}
							onClick={(event) => {
								materialsManager.edit(this.props.currentMaterialIndex);
							}}
						><Icon.Pencil/> {i18next.t("Edit")}</button>
					}
					
					{(user.can('manageMaterials')) &&
						
						<>
							
							{/*{this.state.materials.length > 1 && (
								<button
									type={'button'}
									className={[
										'my-btn',
										'my-btn-sm',
									].join(' ')}
									onClick={(event) => {
										materialsManager.moveUp(this.state.currentMaterialIndex);
									}}
								><Icon.ArrowUp/> {i18next.t("Move up")}</button>
							)}*/}
							
							{/*{this.state.materials.length > 1 && (
								<button
									type={'button'}
									className={[
										'my-btn',
										'my-btn-sm',
									].join(' ')}
									onClick={(event) => {
										materialsManager.moveDown(this.state.currentMaterialIndex);
									}}
								><Icon.ArrowDown/> {i18next.t("Move down")}</button>
							)}*/}
							
							{/*<button
								type={'button'}
								className={[
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									// event.stopPropagation();
									materialsManager.upd(this.state.currentMaterialIndex, {
										is_public: 1,
									});
								}}
							><Icon.Trash/> {i18next.t("Hide")}</button>*/}
							
							<button
								type={'button'}
								className={[
									'add-task-btn',
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									
									// this.setState((prevState) => {
									// 	return {
									// 		questionToEdit: null,
									// 		questionFormModalIsOpen: true,
									// 	}
									// });
									
									// if (this.props.addTaskBtnClick) {
									// 	this.props.addTaskBtnClick()
									// }
									
									if (materialsManager.addTask) {
										materialsManager.addTask()
									}
									
								}}
							>+ {i18next.t("Task")}</button>
							
							<button
								type={'button'}
								className={[
									'add-card-btn',
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									materialsManager.addCard(this.state.currentMaterial.id);
								}}
							>+ {i18next.t("Card")}</button>
							
							<button
								type={'button'}
								className={[
									'add-group-btn',
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									materialsManager.addGroup();
								}}
								title={i18next.t("Add group of tasks")}
							>+ {i18next.t("Group")}</button>
							
							<button
								type={'button'}
								className={[
									'add-variant-btn',
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									materialsManager.addVariant();
								}}
								title={i18next.t("Add variant")}
							>+ {i18next.t("Variant")}</button>
						
						</>
						
					}
				
				</div>
				
			</div>
			
		);
		
	}
	
}

MaterialControls.propTypes = {
	preloader: PropTypes.object,
	user: PropTypes.instanceOf(User),
	materialsManager: PropTypes.object,
	currentMaterialIndex: PropTypes.number,
};

MaterialControls.defaultProps = {};

export default MaterialControls;