import React from 'react'
import PropTypes from 'prop-types'
import i18next from "i18next";
import Formula from "./Formula";
import Question from "../../models/Question";

class QuestionFormAnswerField extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {

		let questionViewTypeAlias = this.props.questionViewTypeAlias
		let answerTypeAlias = this.props.answerTypeAlias
		let value = this.props.value

		let onChange = (event) => {
			let field = event.target
			let val = field.value
			this.props.onChange(val)
		}

		let formulaEditorWithPreview = (
			<div className={'form-group formula-editor-box'}>
				<label htmlFor={'formula'}>
					{i18next.t("Formula editor with preview")}
				</label>
				<Formula
					showEditor={true}
				/>
			</div>
		)
		
		return (
			
			<div className={'QuestionFormRightAnswerField'}>
				
				{(['handwrite_ocr'].includes(questionViewTypeAlias)) &&
					<>
						<textarea
							rows={10}
							className={'form-control'}
							onChange={onChange}
							value={value}
						></textarea>
					</>
				}

				{(['string'].includes(answerTypeAlias)) && (
					<input
						type={'text'}
						className={'form-control'}
						onChange={onChange}
						value={value}
					/>
				)}

				{(['files'].includes(answerTypeAlias)) &&
					<>
						<div className={`form-text text-muted small mb-1`}>
							{i18next.t("Description of file contents (not used for auto-check)")}
						</div>
						<textarea
							rows={10}
							className={'form-control'}
							onChange={onChange}
							value={value}
						></textarea>
					</>
				}

				{(['text'].includes(answerTypeAlias)) && (
					<>
						<textarea
							rows={5}
							className={'form-control'}
							onChange={onChange}
							value={value}
						></textarea>
					</>
				)}

				{(['asciiMath_and_text'].includes(answerTypeAlias)) && (
					<>
						<textarea
							rows={5}
							className={'form-control'}
							onChange={onChange}
							value={value}
						></textarea>
						{Question.formulaHelp}
						{formulaEditorWithPreview}
					</>
				)}

				{(['int'].includes(answerTypeAlias)) && (
					<input
						type={'number'}
						step={1}
						className={'form-control'}
						onChange={onChange}
						value={value}
					/>
				)}

				{(['float'].includes(answerTypeAlias)) && (
					<input
						type={'number'}
						className={'form-control'}
						onChange={onChange}
						value={value}
					/>
				)}

				{(['date'].includes(answerTypeAlias)) && (
					<input
						type={'date'}
						className={'form-control'}
						onChange={onChange}
						value={value}
					/>
				)}

				{(['asciiMath'].includes(answerTypeAlias)) && (
					<>
						<Formula
							text={value}
							showEditor={true}
							onChange={(text) => {
								this.props.onChange(text)
							}}
						/>
					</>
				)}
				
			</div>
			
		)
		
	}
	
}

QuestionFormAnswerField.propTypes = {
	questionViewTypeAlias: PropTypes.string,
	answerTypeAlias: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.any,
}

QuestionFormAnswerField.defaultProps = {}

export default QuestionFormAnswerField