import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import Sched from "../../models/Sched";
import i18next from "i18next";
import Group from "../../models/Group";
import DateHelper from "../../helpers/DateHelper";
import User from "../../models/User";
import Rand from "../../helpers/Rand";

export default class SchedForm extends React.Component {

    constructor(props) {

        super(props);

        let model = {...props.model} || new Sched();

        if (props.currentGroup) {
            model.group_id = props.currentGroup.id;
        } else if (props.groups.length === 1) {
            model.group_id = props.groups[0].id;
        }

        if (props.user.is(['teacher'])) {
            model.teacher_id = props.user.id;
        }

        model.date = DateHelper.formatDate({date: props.date, format: 'y-m-d'});

        this.state = {
            model: model,
        };

        if (!this.props.model && Config.allowFormFake()) {
            // this.state.model.date = DateHelper.formatDate({});
            this.state.model.time = DateHelper.formatTime({});
            this.state.model.name = 'Занятие ' + DateHelper.dateTime();
            this.state.model.place = 'Подфак, 42';
            if (props.groups.length > 0) {
                this.state.model.group_id = props.groups[0].id;
                // this.state.model.group_id = Rand.fromArray(props.groups).id;
            }
        }

    }

    inputChange = (event) => {

        const model = this.state.model;

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const attr = target.getAttribute('id');

        model[attr] = value;

        this.setState({
            model: model,
        });

    };

    submit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity()) {

            if (this.props.preloader) {
                this.props.preloader.show();
            }

            axios({
                method: this.props.model ? 'put' : 'post',
                url: Utils.apiUrl('scheds') + (this.props.model ? '/' + this.props.model.id : ''),
                data: this.state.model,
                params: {
                    'accessToken': Utils.getUserToken(),
                },
            }).then((response) => {

                const logName = 'SchedForm.submit.ajax.done';
                const logAllow = 1;
                const logCollapsed = 0;

                Logger.groupStart(logName, logAllow, logCollapsed);

                Logger.log(response, 'response', logAllow);

                if (this.props.afterSubmit) {
                    this.props.afterSubmit(response);
                }

                if (this.props.preloader) {
                    this.props.preloader.hide();
                }

                Logger.groupEnd(logAllow);

            }).catch((error) => {
                Utils.axiosErrorAlert(error);
            });

        }

    };

    render() {

        const logName = 'SchedForm.render';
        const logAllow = 1;
        const logCollapsed = 0;

        Logger.groupStart(logName, logAllow, logCollapsed);

        const model = this.state.model;

        // let teachers = Group.collectTeachers(this.props.groups);
        let teachers = this.props.teachers;
        Logger.log(teachers, 'teachers', logAllow);

        // let allow = this.props.user.is(['owner', 'admin']) || this.props.user.id == model.teacher_id;

        Logger.groupEnd(logAllow);

        return (

            <form className={'SchedForm'} onSubmit={this.submit}>

                <div className="form-group group_id required">
                    <label htmlFor={'group_id'}>{i18next.t("Group")}</label>
                    <select id={'group_id'}
                           className={'form-control'}
                           value={model.group_id}
                           onChange={this.inputChange}
                           required={true}
                    >
                        {this.props.groups.length > 1 && (
                            <option value={''}>{i18next.t("Select from the list")}</option>
                        )}
                        {this.props.groups.map((group) => {
                            return (
                                <option value={group.id}>{group.name}</option>
                            );
                        })}
                    </select>
                </div>

                {!this.props.user.isAny(['teacher']) && (
                    <div className="form-group teacher_id">
                        <label htmlFor={'teacher_id'}>{i18next.t("Teacher")}</label>
                        <select id={'teacher_id'}
                                className={'form-control'}
                                value={model.teacher_id}
                                onChange={this.inputChange}
                        >
                            <option value={''}>{i18next.t("Select from the list")}</option>
                            {teachers.map((teacher) => {
                                return (
                                    <option value={teacher.id}>
                                        {teacher.getDisplayName()} [{teacher.id}]
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}

                <div className="form-group date required">
                    <label htmlFor={'date'}>{i18next.t("Date")}</label>
                    <input id={'date'}
                           type="date"
                           className={'form-control'}
                           value={model.date}
                           onChange={this.inputChange}
                           required={true}
                    />
                </div>

                <div className="form-group required">
                    <label htmlFor={'time'}>{i18next.t("Time")}</label>
                    <input id={'time'}
                           type="time"
                           className={'form-control'}
                           value={model.time}
                           onChange={this.inputChange}
                           required={true}
                    />
                </div>

                <div className="form-group name">
                    <label htmlFor={'name'}>{i18next.t("Theme")}</label>
                    <input id={'name'}
                           type="text"
                           className={'form-control'}
                           value={model.name}
                           onChange={this.inputChange}
                           required={false}
                    />
                </div>

                <div className="form-group place">
                    <label htmlFor={'place'}>{i18next.t("Place")}</label>
                    <input id={'place'}
                           type="text"
                           className={'form-control'}
                           value={model.place}
                           onChange={this.inputChange}
                           required={false}
                    />
                </div>

                <div className="form-group url">
                    <label htmlFor={'url'}>{i18next.t("Link")}</label>
                    <input id={'url'}
                           type="url"
                           className={'form-control'}
                           value={model.url}
                           onChange={this.inputChange}
                           required={false}
                    />
                </div>

                {/*<div className="form-group about">
                    <label htmlFor={'about'}>{i18next.t("About")}</label>
                    <textarea id={'about'}
                              className={'form-control'}
                              value={model.about}
                              onChange={this.inputChange}
                              required={false}
                              rows={5}
                    />
                </div>*/}

                {this.props.model && (
                    <label htmlFor={'is_active'}>
                        <input
                            id={'is_active'}
                            type={'checkbox'}
                            checked={model.is_active}
                            onChange={this.inputChange}
                        /> {i18next.t("Active")}
                    </label>
                )}

                <div className="controls">

                    <div className="row">

                        <div className="col-6">
                            {this.props.cancel && (
                                <button type={'button'}
                                        className={'btn btn-outline-danger'}
                                        onClick={(event) => {
                                            this.props.cancel();
                                        }}
                                >{i18next.t("Cancel")}</button>
                            )}
                        </div>

                        <div className="col-6 text-right">
                            <button type={'submit'}
                                    className={'my-btn my-btn-primary'}
                            >{i18next.t("Save")}</button>
                        </div>

                    </div>

                </div>

            </form>

        );

    }

}

SchedForm.propTypes = {
    model: PropTypes.instanceOf(Sched),
    afterSubmit: PropTypes.func,
    cancel: PropTypes.func,
    groups: PropTypes.arrayOf(PropTypes.instanceOf(Group)).isRequired,
    currentGroup: PropTypes.instanceOf(Group),
    user: PropTypes.instanceOf(User).isRequired,
    teachers: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
    preloader: PropTypes.object,
    date: PropTypes.object,
};

SchedForm.defaultProps = {
    model: null,
};