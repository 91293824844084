import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import Logger from "../../helpers/Logger";
import Utils from "../../helpers/Utils";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import GridView from "./GridView";
import Range from "../../helpers/Range";
import qs from "qs/lib";
import * as Icon from 'react-bootstrap-icons';

import "./ModelsPage.css";

export default class ModelsPage extends React.Component {
	
	model = this.props.model;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			page: props.page,
			perPage: props.pageSize,
			pagesCount: 1,
			
			currentModel: null,
			formModalIsOpen: false,
			
			models: [],
			filters: {},
			
			modelsTotalCount: null,
			
		};
		
	}
	
	formModalToggle = () => {
		this.setState((prevState) => {
			return {
				formModalIsOpen: !prevState.formModalIsOpen,
			}
		});
	};
	
	setPage = (pageNum) => {
		
		this.setState((prevState) => {
			return {
				page: pageNum,
			}
		});
		
		// this.models.load(pageNum);
		
	};
	
	prevPage = () => {
		let currentPageNum = parseInt(this.state.page);
		let pagesCount = parseInt(this.state.pagesCount);
		let prevPageNum = currentPageNum > 1 ? currentPageNum - 1 : pagesCount;
		this.setPage(prevPageNum);
	};
	
	nextPage = () => {
		let currentPageNum = parseInt(this.state.page);
		let pagesCount = parseInt(this.state.pagesCount);
		let nextPageNum = currentPageNum < pagesCount ? currentPageNum + 1 : 1;
		this.setPage(nextPageNum);
	};
	
	models = {
		
		load: (page = this.state.page, perPage = this.state.perPage, filters = this.state.filters) => {
			
			const logName = 'ModelsPage.models.load';
			const logAllow = 1;
			const logCollapsed = 1;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!this.props.loadUrl) {
				Logger.log(this.props.loadUrl, 'this.props.loadUrl', logAllow);
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'per-page': perPage,
				'page': page,
				'sort': '-id',
				'use-filters': true,
			};
			
			let customParams = this.props.loadParams;
			
			Object.getOwnPropertyNames(customParams).forEach((attr) => {
				params[attr] = customParams[attr];
			});
			
			Logger.log(filters, 'filters', logAllow);
			
			Object.getOwnPropertyNames(filters).forEach((attr) => {
				if (this.props.gridViewCols[attr].filterLike || (this.model && this.model.likeSearchAttrs && this.model.likeSearchAttrs.indexOf(attr) >= 0)) {
					params['filter[' + attr + '][like]'] = filters[attr];
				} else {
					params['filter[' + attr + ']'] = filters[attr];
				}
			});
			
			Logger.log(params, 'params', logAllow);
			
			axios.get(this.props.loadUrl, {
				params: params,
				paramsSerializer: params => {
					return qs.stringify(params)
				}
			}).then((response) => {
				
				const logName = 'ModelsPage.models.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let pagesCount = parseInt(response.headers['x-pagination-page-count']);
				Logger.log(pagesCount, 'pagesCount', logAllow);
				
				let modelsTotalCount = parseInt(response.headers['x-pagination-total-count'])
				Logger.log(modelsTotalCount, 'modelsTotalCount', logAllow)
				
				let models = response.data;
				
				if (this.model) {
					models = response.data.map((modelData) => {
						return new this.model(modelData);
					});
				}
				
				Logger.log(models, 'models', logAllow);
				
				this.setState((prevState) => {
					return {
						models: models,
						pagesCount: pagesCount,
						filters: filters,
						modelsTotalCount: modelsTotalCount,
					}
				});
				
				if (this.props.afterLoad) {
					this.props.afterLoad(response, models, page);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
			return false;
			
		},
		
		afterSubmit: (response) => {
			
			this.setState((prevState) => {
				return {
					formModalIsOpen: false,
				}
			});
			
			this.models.load();
			
		},
		
	};
	
	componentDidMount() {
		this.models.load();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if (!Utils.isEqualObjects(prevProps.loadParams, this.props.loadParams)
			|| !Utils.isEqualObjects(prevState.filters, this.state.filters)
			|| this.state.page !== prevState.page
		) {
			this.models.load();
		}
		
		
	}
	
	render() {
		
		const logName = 'ModelsPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const currentModel = this.state.currentModel;
		Logger.log(currentModel, 'model', logAllow);
		
		let cols = this.props.gridViewCols;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'ModelsPage' + (this.props.cssClass ? ' ' + this.props.cssClass : '')}>
				
				{(this.props.title) &&
					<h1>{this.props.title}</h1>
				}
				
				<div className="row controls mb-2">
					
					<div className="col-auto">
						
						<div className="btn-group">
							
							{(this.props.showRealoadBtn) &&
								<button
									className={'reload-btn btn btn-light'}
									type={'button'}
									onClick={() => {
										this.models.load();
									}}
									title={i18next.t("Reload")}
								><Icon.ArrowRepeat/></button>
							}
							
							{(this.props.showResetBtn) &&
								<button
									className={'reset-btn btn btn-light'}
									type={'button'}
									onClick={() => {
										this.setState((prevState) => {
											return {
												filters: {},
												page: 1,
											}
										});
									}}
									title={i18next.t("Reset filters")}
								><Icon.X/></button>
							}
							
						</div>
						
					</div>
					
					{this.props.formMaker && (
						<div className="col-2">
							<button
								type={'button'}
								className={'add-btn btn btn-primary'}
								onClick={() => {
									this.setState((prevState) => {
										return {
											currentModel: null,
											formModalIsOpen: true,
										}
									});
								}}
							>{i18next.t("Add")}</button>
						</div>
					)}
					
					{(this.state.pagesCount > 1) &&
						
						<>
						
							<div className="col-auto nav-btn-col">
								
								<div className="btn-group">
								
									<button
										type={'button'}
										className={[
											'btn',
											'btn-light',
										].join(' ')}
										onClick={(event) => {
											this.prevPage();
										}}
									><Icon.ChevronLeft/></button>
									
									<button
										type={'button'}
										className={[
											'btn',
											'btn-light',
										].join(' ')}
										onClick={(event) => {
											this.nextPage();
										}}
									><Icon.ChevronRight/></button>
								
								</div>
							
							</div>
							
							<div className="col-auto pager-col">
								
								<select
									className={'page-selector form-control float-left'}
									onChange={(event) => {
										this.setPage(event.target.value);
									}}
									value={this.state.page}
								>
									{Range.get(1, this.state.pagesCount).map((pageNum) => {
										return (
											<option value={pageNum}>{pageNum} / {this.state.pagesCount}</option>
										);
									})}
								</select>
							
							</div>
							
							<div className={`col-auto stats-col`}>
								{(this.state.modelsTotalCount) && (
									<span className={`d-inline-block pt-2`}>
										{i18next.t("Total")}: {this.state.modelsTotalCount}
									</span>
								)}
							</div>
						
						</>
						
					}
				
				</div>
				
				<GridView
					className={'models-grid'}
					cols={cols}
					content={this.state.models}
					filtersHandler={(filtersValues) => {
						
						this.models.load(0, this.state.perPage, filtersValues);
						
						// this.setState((prevState) => {
						// 	return {
						// 		filters: filtersValues,
						// 	}
						// });
						
					}}
					rowOnClick={this.props.rowOnClick}
					rowTitleMaker={this.props.rowTitleMaker}
					rowClassNameMaker={this.props.rowClassNameMaker}
					filterWaitForEnterKey={!this.props.instantFilter}
					filtersValues={this.state.filters}
				/>
				
				{this.props.formMaker && (
					
					<Modal
						show={this.state.formModalIsOpen}
						onHide={this.formModalToggle}
						size={'lg'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>{currentModel ? this.props.updMsg(currentModel) : this.props.addMsg}</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							{this.props.formMaker(currentModel, (response) => {
								
								this.setState((prevState) => {
									return {
										formModalIsOpen: false,
									}
								});
								
								this.models.load();
								
							})}
						</Modal.Body>
					
					</Modal>
				
				)}
			
			</div>
		
		);
		
	}
	
}

ModelsPage.propTypes = {
	
	title: PropTypes.string,
	
	loadUrl: PropTypes.string.isRequired,
	loadParams: PropTypes.object,
	
	gridViewCols: PropTypes.object.isRequired,
	
	model: PropTypes.object,
	formMaker: PropTypes.func,
	
	addMsg: PropTypes.string,
	updMsg: PropTypes.func,
	
	delMsg: PropTypes.string,
	delModel: PropTypes.func,
	
	preloader: PropTypes.object,
	
	pagerControlsConfig: PropTypes.oneOf([
		'start',
		'edge',
	]),
	
	page: PropTypes.number,
	pageSize: PropTypes.number,
	
	afterLoad: PropTypes.func,
	
	rowOnClick: PropTypes.func,
	rowTitleMaker: PropTypes.func,
	rowClassNameMaker: PropTypes.func,
	// selectedModelsIds: PropTypes.array,
	
	instantFilter: PropTypes.bool,
	
	// models: PropTypes.arrayOf(PropTypes.object),
	
	reload: PropTypes.bool,
	
	cssClass: PropTypes.string,
	
	showRealoadBtn: PropTypes.bool,
	showResetBtn: PropTypes.bool,
	
};

ModelsPage.defaultProps = {
	gridViewCols: {
		id: 'ID',
		name: 'Name',
	},
	pagerControlsConfig: 'start',
	page: 1,
	pageSize: 7,
	reload: false,
	instantFilter: false,
	loadParams: {},
	showRealoadBtn: true,
	showResetBtn: true,
};
